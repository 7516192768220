import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as TripleDotSvg } from "../../assets/triple-vertical-dot.svg";
import { useActiveOrgRole, useOrgs } from "../../backend/resources/orgRole";
import type { NetworkRoleType } from "../../backend/resources/userRole";
import { useActiveUserRole, useUserIsFamilyMember } from "../../backend/resources/userRole";
import {
  useProductAccess
} from "../../hooks/product/product";
import {
  useSideNavStore
} from "../../state/navItems";
import { NAV_ITEMS_CONFIG } from "../../state/navItems/navItemModels";
import { BottomSheet } from "../Sheet";
import NavItem from "./components/NavItem";

export default function BottomNavBar() {
  const { userRole } = useActiveUserRole();
  const { data: orgRole } = useActiveOrgRole();
  const navigate = useNavigate();
  const configureNavContext = useSideNavStore(
    (state) => state.configureNavContext
  );
  const items = useSideNavStore((state) => state.items);
  const extendedItems = useSideNavStore((state) => state.extendedItems);

  const { isSuperSuperUser } = useOrgs();
  const { data: productAccess } = useProductAccess();
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const { userIsFamilyMember, isLoadingUserIsFamilyMember } = useUserIsFamilyMember();


  useEffect(() => {
    configureNavContext(
      {
        role: userRole?.role as NetworkRoleType,
        isMobile: true,
        product: productAccess,
        isSuperUser: orgRole?.is_superuser,
        isSuperSuperUser: isSuperSuperUser,
      })
  }, [userRole, productAccess, orgRole, orgRole?.is_superuser]);

  return (
    <div className="w-full flex justify-center absolute bottom-0">
      <div className="w-[100%]  px-6 items-center h-8 py-8 bg-gray-50 border z-10 flex justify-around overflow-clip">
        {items.map((item, index) => (
          <button
            key={`button-${item.name}-${index}`}
            onClick={(event) => {
              event.preventDefault();
              navigate(NAV_ITEMS_CONFIG[item.name].route);
            }}
            className={` flex flex-grow ${index < items.length - 1 ? "" : ""
              } border-gray-300 justify-center`}>
            <NavItem
              key={item.name}
              name={""}
              icon={item.icon}
              pathname={NAV_ITEMS_CONFIG[item.name].route}
            />
          </button>
        ))}
        {extendedItems.length > 0 ? (
          <button
            onClick={(event) => {
              event.preventDefault();
              setIsBottomSheetOpen(true);
            }}
            className={` flex flex-grow border-gray-300 justify-center`}>
            <TripleDotSvg className="w-5 h-5" />
          </button>
        ) : null}
      </div>
      <BottomSheet
        isOpen={isBottomSheetOpen}
        onClose={() => setIsBottomSheetOpen(false)}>
        <MobileMenuNavItems setIsOpen={setIsBottomSheetOpen} />
      </BottomSheet>
    </div>
  );
}

function MobileMenuNavItems({
  setIsOpen,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const extendedItems = useSideNavStore((state) => state.extendedItems);

  return (
    <ul className=" flex flex-col justify-center">
      {extendedItems.map((item, index) => {
        return (
          <MobileMenuNavItem
            key={`mobile-menu-item-${item.name}-${index}`}
            isFirst={false}
            setIsOpen={setIsOpen}
            href={NAV_ITEMS_CONFIG[item.name].route}
            label={item.name}
          />
        );
      })}
    </ul>
  );
}

function MobileMenuNavItem({
  isFirst,
  href,
  label,
  setIsOpen,
}: {
  isFirst?: boolean;
  href: string;
  label: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  return (
    <li
      className={`p-5 border-b-[1px] border-black flex justify-center hover:bg-gray-100`}>
      <button
        className="w-full"
        onClick={() => {
          setIsOpen(false);
          navigate(href);
        }}>
        {label}
      </button>
    </li>
  );
}

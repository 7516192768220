import { ConversationType } from "../../../backend/resources/chatGptConversation";
import { useNetworksInOrganization } from "../../../backend/resources/network/network";
import { useDiscussionFilterStore } from "../../../state/discussionsFilter/discussionsFilter";
import { useNetworkStore } from "../../../state/network/network";
import { Select } from "../../Select";




export const DiscussionTypeToColor: Record<ConversationType, string> = {
  [ConversationType.Request]: "#8185F0",
  [ConversationType.Service]: "#98C3EA",
  [ConversationType.General]: "#F6B26B",
  [ConversationType.Private]: "#2A8EE9",
  [ConversationType.Family]: "#6064BE",
  [ConversationType.Alfred]: "",
}


export enum DiscussionFilterTypes {
  // ORG = "Org",
  CARESPACE = "Carespace",
  TYPE = "Type",
}

export interface DiscussionsFilterConfig {
  // [DiscussionFilterTypes.ORG]?: boolean;
  [DiscussionFilterTypes.CARESPACE]?: boolean;
  [DiscussionFilterTypes.TYPE]?: boolean;
}

export enum DiscussionFields {
  isUnread = "isUnread",
  Carespace = "Carespace",
  Title = "Title",
  Type = "Type",
  LatestMessage = "Latest Message",
  SentAt = "Sent",
  SentBy = "Sent By",
  UNDERLYING_OBJECT_ID = "underlying_object_Id",
  network_id = "network_id",
  isExternal = "isExternal",
  Id = "id"
}

export type DiscussionTableType = {
  [DiscussionFields.isUnread]: boolean;
  [DiscussionFields.SentAt]: string;
  [DiscussionFields.LatestMessage]: string;
  [DiscussionFields.Title]: string;
  [DiscussionFields.Type]: ConversationType;
  [DiscussionFields.Carespace]: string;
  [DiscussionFields.UNDERLYING_OBJECT_ID]: string;
  [DiscussionFields.network_id]: string;
  [DiscussionFields.isExternal]: boolean;
  [DiscussionFields.Id]: string;
  [DiscussionFields.SentBy]: string;
  subRows?: DiscussionTableType[] | undefined;
};

interface DiscussionFiltersProps {
  filterConfig: DiscussionsFilterConfig;
}

export function DiscussionFilters({ filterConfig }: DiscussionFiltersProps) {
  const { carespace, type, setCarespace, setType } = useDiscussionFilterStore();
  const setActiveNetworkId = useNetworkStore(
    (state) => state.setActiveNetworkId
  );
  const { carespacesOptions } = useNetworksInOrganization();

  return <div className="whitespace-nowrap w-full flex items-center overflow-auto text-sm h-min gap-x-6 gap-y-2 py-2">
    {filterConfig[DiscussionFilterTypes.CARESPACE] && (
      <div className="flex gap-4 items-center">
        <p>{DiscussionFilterTypes.CARESPACE}</p>
        <Select
          currentOption={carespacesOptions.find((row) => row.value === carespace)}
          options={carespacesOptions}
          onChange={(carespaceId) => {
            setActiveNetworkId(carespaceId)
            setCarespace(carespaceId)
          }}
          classNames=" w-min"
        />
      </div>
    )}
    {filterConfig[DiscussionFilterTypes.TYPE] && (
      <div className="flex gap-4 items-center">
        <p>{DiscussionFilterTypes.TYPE}</p>
        <Select
          currentOption={type ? { value: type.toString(), label: type.toString() } : { value: undefined, label: "All" }}
          options={[{ value: "All", label: "All" }, ...Object.values(ConversationType).filter(type => type !== ConversationType.Alfred).map((discussionType) => ({ label: discussionType, value: discussionType }))]}
          onChange={(type) => setType(type)}
          classNames="w-[280px] max-w-[280px] "
        />
      </div>
    )}
  </div >
}

import { ReactNode } from "react";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import Logo from "../../components/Logo/logo";
import { BottomSheet } from "../../components/Sheet";
import { CraniometrixProduct } from "../../hooks/product/product";
import { useWindowSize } from "../../hooks/useWindowSize";
import { Popup } from "./popup";

type Props = {
  isOpen: boolean;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  isNetworkCallInProgress?: boolean;
  closeText?: string;
  title?: ReactNode;
  icon?: ReactNode;
  description?: ReactNode;
  showLogo?: CraniometrixProduct | "base";
  isLogoCentered?: boolean;
  logoSize?: "small" | "large";
  footerButtons?: ReactNode;
  children?: ReactNode;
  fixedHeight?: string;
};

export function PopupOrSheet(props: Props) {
  const {
    isOpen,
    onClose,
    isNetworkCallInProgress,
    closeText,
    title,
    icon,
    description,
    showLogo,
    isLogoCentered,
    logoSize = "small",
    footerButtons,
    children,
    fixedHeight
  } = props

  const { isMobile } = useWindowSize()

  if (isMobile) {
    return (
      <BottomSheet
        isOpen={isOpen}
        onClose={() => onClose && onClose(false)}>
        <div className="p-4 flex flex-col gap-3">
          {icon ? (
            <div className="flex justify-center">
              {icon}
            </div>
          ) : null}

          {title ? (
            <>
              {showLogo ? (
                <div className="pb-4">
                  <Logo
                    isDisabled
                    product={showLogo}
                    isCentered={isLogoCentered}
                    size={logoSize}
                  />
                </div>
              ) : null}
              {title}
            </>
          ) : null}

          {children}

          {footerButtons ? (
            <div className="flex flex-row justify-around sm:justify-end sm:space-x-2 sm:items-end gap-2">
              {isNetworkCallInProgress ? (
                <div className="h-full flex flex-col justify-center">
                  <LoadingSpinner className="w-4 h-4" />
                </div>
              ) : null}
              {footerButtons}
            </div>
          ) : null}
        </div>
      </BottomSheet>
    )
  } else {
    return (
      <Popup {...props} />
    )
  }
}
/**
 * @fileoverview Shared page header component, used as the main header for all page routes.
 */

import { useActiveNetworkName } from "../../backend/resources/network/network";
import {
  CraniometrixProduct,
  useProductAccess,
} from "../../hooks/product/product";
import { LoadingSpinner } from "../LoadingSpinner";

interface Props {
  text: React.ReactNode;
  className?: string;
}

export function PageMainHeaderWithNetworkName({ text, className }: Props) {
  const { networkName } = useActiveNetworkName();
  const { data, isLoading } = useProductAccess();
  return (
    <div
      className={`text-3xl font-light flex flex-col md:flex-col-reverse md:items-start gap-1 md:gap-2 w-full md:w-auto  text-center  md:text-left ${className}`}>
      <p className="whitespace-nowrap">{text}</p>
      {isLoading ? (
        <LoadingSpinner className="w-8 h-8" />
      ) : data === CraniometrixProduct.CARE_CENTRAL ? <HeaderNamePill text={networkName} /> : null}
    </div>
  );
}

export function HeaderNamePill({ text, color = "#3496CE" }: { text: string | undefined | null, color?: string }) {
  return <p className="truncate rounded-full text-sm text-white px-2 h-min" style={{backgroundColor: color}}>
    {text ? `${text}` : ""}
  </p>
}

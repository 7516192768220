import { useState } from "react";
import { ReactComponent as AddToFolder } from "../../assets/add-to-folder.svg";
import { ReactComponent as Bookmark } from "../../assets/orange_bookmark.svg";
import { ReactComponent as FilledInBookmark } from "../../assets/orange_filled_bookmark.svg";
import { ReactComponent as Notes } from "../../assets/orange_notes.svg";
import { ReactComponent as Tag } from "../../assets/orange_tag.svg";
import type { BookmarkableItem } from "../../backend/resources/userBookmark";
import {
  useBookmarkQuery,
  useBookmarkQueryByBookmarkableItem,
  useQueryTagsAndFoldersBookmark,
} from "../../backend/resources/userBookmark";
import { useUserIsFamilyMember } from "../../backend/resources/userRole";
import { useActionBarStore } from "../../state/actionBar";
import { useActiveNetworkId } from "../../state/network/network";
import { useUserStore } from "../../state/user";
import AddNotePopup from "../AddNotePopup/AddNotePopup";
import { AddTagsPopup } from "../AddTagsPopup";
import { AddToEntryPopup } from "../LibraryPage/AddToEntryPopup";
import AddToFolderPopup from "../LibraryPage/Bookmarks/Bookmark/Popups/AddToFolderPopup/AddToFolderPopup";
import { RemoveFromFolderPopup } from "../LibraryPage/Bookmarks/Bookmark/Popups/RemoveFromFolderPopup";
import { ShowAllTagsPopup } from "../LibraryPage/Bookmarks/Bookmark/Popups/ShowAllTagsPopup";
import {
  deleteBookmark,
  getBookmarkType,
  getIdOfBookmarkedItem,
  saveBookmark,
} from "../LibraryPage/Bookmarks/Bookmark/utils";

interface Props {
  bookmarkableItem: BookmarkableItem;
  onlyShowBookmark?: boolean;
}

export function ActionBar({ bookmarkableItem, onlyShowBookmark }: Props) {
  const [tagsIsOpen, setTagsIsOpen] = useState(false);
  const [showAllTagsPopup, setShowAllTagsPopup] = useState(false);
  const [noteIsOpen, setNoteIsOpen] = useState(false);
  const [addToFolderPopupIsOpen, setAddToFolderPopupIsOpen] = useState(false);
  const [removeFromFolderPopupIsOpen, setRemoveFromFolderPopupIsOpen] =
    useState(false);
  const [addToEntryPopupIsOpen, setAddToEntryPopupIsOpen] = useState(false);
  const authUser = useUserStore((state) => state.user);
  const activeNetworkId = useActiveNetworkId();

  const { userBookmark, refetchUserBookmark } =
    useBookmarkQueryByBookmarkableItem(bookmarkableItem);
  const { isLoadingTags, isFetchingTags, tags, refetchTags } =
    useQueryTagsAndFoldersBookmark(userBookmark?.id);
  const { refetchBookmarks } = useBookmarkQuery();

  const isDisabled = useActionBarStore((state) => state.isDisabled);
  const { userIsFamilyMember } = useUserIsFamilyMember();

  return (
    <div
      className={`z-[1] ${
        // onlyShowBookmark
        //   ?
        "absolute -top-6"
        // : "fixed md:absolute top-[50%] transform -translate-y-1/2 md:-bottom-8 md:top-auto"
      }   right-0 self-end flex flex-row gap-4 opacity-100 p-2 rounded-md border border-faint-gray shadow-md bg-white ${
        !isDisabled && !userIsFamilyMember
          ? "opacity-100"
          : "opacity-0 pointer-events-none hidden"
      }`}>
      {tagsIsOpen && userBookmark && (
        <AddTagsPopup
          close={() => setTagsIsOpen(false)}
          bookmarkId={userBookmark.id}
          tags={tags}
          refetchTags={() => {
            refetchTags();
          }}
          isLoadingTags={isLoadingTags}
          isFetchingTags={isFetchingTags}
        />
      )}
      {noteIsOpen && userBookmark && (
        <AddNotePopup
          close={() => setNoteIsOpen(false)}
          bookmarkItemId={getIdOfBookmarkedItem(userBookmark)}
          bookmarkType={getBookmarkType(userBookmark)}
        />
      )}
      {showAllTagsPopup && userBookmark && (
        <ShowAllTagsPopup
          close={() => setShowAllTagsPopup(false)}
          tags={tags}
        />
      )}
      {addToFolderPopupIsOpen && userBookmark && (
        <AddToFolderPopup
          close={() => setAddToFolderPopupIsOpen(false)}
          userBookmarkId={userBookmark.id}
        />
      )}
      {removeFromFolderPopupIsOpen && userBookmark && (
        <RemoveFromFolderPopup
          close={() => setRemoveFromFolderPopupIsOpen(false)}
          userBookmarkId={userBookmark.id}
        />
      )}

      {addToEntryPopupIsOpen && userBookmark && (
        <AddToEntryPopup
          close={() => setAddToEntryPopupIsOpen(false)}
          bookmarkId={userBookmark.id}
        />
      )}
      <button
        onClick={(e) => {
          e.stopPropagation();
          if (userBookmark) {
            deleteBookmark(userBookmark, authUser?.id, refetchUserBookmark);
          } else {
            saveBookmark(
              bookmarkableItem,
              authUser?.id,
              activeNetworkId,
              refetchUserBookmark
            );
          }
          refetchBookmarks();
        }}>
        {userBookmark ? (
          <FilledInBookmark className="w-6 h-6 hover:scale-[1.15] transition-transform hover:-translate-y-1" />
        ) : (
          <Bookmark className="w-6 h-6 hover:scale-[1.15] transition-transform hover:-translate-y-1" />
        )}
      </button>
      {!onlyShowBookmark ? (
        <>
          <button
            onClick={(e) => {
              e.stopPropagation();
              if (userBookmark) setNoteIsOpen(true);
            }}
            disabled={!userBookmark}>
            <Notes
              className={`w-6 h-6   ${
                !userBookmark
                  ? "saturate-0  cursor-default"
                  : "hover:scale-[1.15] transition-transform hover:-translate-y-1"
              }`}
            />
          </button>
          <button
            disabled={!userBookmark}
            onClick={(e) => {
              e.stopPropagation();
              if (userBookmark) setAddToFolderPopupIsOpen(true);
            }}>
            <AddToFolder
              className={`w-6 h-6   ${
                !userBookmark
                  ? "saturate-0 cursor-default"
                  : "hover:scale-[1.15] transition-transform hover:-translate-y-1"
              }`}
            />
          </button>
          <button
            disabled={!userBookmark}
            onClick={(e) => {
              e.stopPropagation();
              if (userBookmark) setTagsIsOpen(true);
            }}>
            <Tag
              className={`w-6 h-6   ${
                !userBookmark
                  ? "saturate-0  cursor-default"
                  : "hover:scale-[1.15] transition-transform hover:-translate-y-1"
              }`}
            />
          </button>
          {/* <button
            disabled={!userBookmark}
            onClick={(e) => {
              e.stopPropagation();
              if (userBookmark) setAddToEntryPopupIsOpen(true);
            }}>
            <MyPlanIconSvg
              className={`w-6 h-6   ${
                !userBookmark
                  ? "saturate-0  cursor-default"
                  : "hover:scale-[1.15] transition-transform hover:-translate-y-1"
              }`}
            />
          </button> */}
        </>
      ) : null}
    </div>
  );
}

import { useMatch } from "react-router-dom";
import { ReactComponent as SmallRightArrowSvg } from "../../../assets/small-right-arrow.svg";
import { CareCentralRoute, CarePilotRoute } from "../../../lib/routing";


export function GoalRecSuggestionsBreadcrumbs() {
  const matchesGoal = useMatch(CarePilotRoute.GOAL_ID)
  const matchesRecommendation = useMatch(CarePilotRoute.RECOMMENDATION)
  const matchesSuggestion = useMatch(CarePilotRoute.INTERVENTION)

  const matchesGoalTemplate = useMatch(CareCentralRoute.GOAL_TEMPLATE_ID)
  const matchesRecommendationTemplate = useMatch(CareCentralRoute.SHAREABLE_RECOMMENDATION)
  const matchesSuggestionTemplate = useMatch(CareCentralRoute.SHAREABLE_INTERVENTION)


  const currentPage = () => {
    if (matchesSuggestion || matchesSuggestionTemplate) {
      return "suggestion";
    }
    if (matchesRecommendation || matchesRecommendationTemplate) {
      return "recommendation";
    }
    if (matchesGoal || matchesGoalTemplate) {
      return "goal";
    }

    return "";
  };

  return (
    <div
      className={`flex md:justify-start justify-center items-center flex-grow gap-2 py-1`}>
      {/* goal */}
      <div
        className="text-sm"
        style={{
          color: currentPage() === "goal" ? "#F8A01B" : "black",
        }}>
        Goal
      </div>
      <SmallRightArrowSvg className="w-3 h-3" />
      {/* recommendation */}
      <div
        className="text-sm"
        style={{
          color: currentPage() === "recommendation" ? "#F8A01B" : "black",
        }}>
        Recommendation
      </div>
      <SmallRightArrowSvg className="w-3 h-3" />
      {/* suggestion */}
      <div
        className="text-sm"
        style={{
          color: currentPage() === "suggestion" ? "#F8A01B" : "black",
        }}>
        Suggestion
      </div>
    </div>
  );
}

import { outlinedInputClasses } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useState } from "react";
import { useBillingPageData } from "../../backend/functions";
import { Checkbox } from "../Checkbox";
import { PageContainer } from "../PageContainer";
import { PageMainHeader } from "../PageMainHeader";
import PatientList from "./components/PatientBreakdownSection";
import { RespiteCareSection } from "./components/RespiteCareSection";
import StandardCareSection from "./components/StandardCareSection";
import TotalsSection from "./components/TotalsSection";


export default function BillingPage() {
  // States
  const [hideIneligible, setHideIneligible] = useState(true);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const [selectedMonth, setSelectedMonth] = useState<number>(currentMonth);
  const [selectedYear, setSelectedYear] = useState<number>(currentYear);

  // Query
  const { data } = useBillingPageData(hideIneligible, selectedMonth, selectedYear);

return <PageContainer>
    <div className="flex flex-col gap-8 max-w-4xl">
      <PageMainHeader text="Billing" />
      {/* Filters */}
      <div className="flex gap-4">
        <DatePicker
          maxDate={dayjs()}  // Block future dates
          label="Period"
          views={['year', 'month']}
          className='h-[30px] '
          onChange={(value) => {
            if (value && value.isBefore(dayjs())) {
              setSelectedMonth(value.month());
              setSelectedYear(value.year());
            }
          }}
          value={dayjs(new Date(selectedYear, selectedMonth))}
          slotProps={{
            field: {
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              },
            },
            textField: {
              sx: {
                fieldset: {
                  borderRadius: 99, borderColor: '#d1d5db',
                },
                [`.${outlinedInputClasses.root}`]: {
                  height: 30,
                  width: 260,
                  fontSize: 12,
                  fontWeight: 400,
                  fontFamily: 'Poppins',
                },
              },
            },
          }}
        />
        <Checkbox label="Hide Ineligible" isChecked={hideIneligible} onCheck={setHideIneligible} />
      </div>
      {/* Totals (TLDR) */}
      <TotalsSection data={data} />
      {/* Billing breakdown: */}
      <StandardCareSection data={data} />
      <RespiteCareSection data={data} />
      <div className="pt-24">
        <PatientList data={data?.patient_list ?? []} />
      </div>
    </div>
  </PageContainer>
}

import create from "zustand";
import type { CarePlanGoalWithProgress } from "../../backend/resources/carePlan/carePlan";
import { Goal } from "../../backend/resources/goal/goal";

type CarePlanState = {
  currentCarePlanGoalsByAdlo: { [user_adlo_id: string]: CarePlanGoalWithProgress[] };
  hasEditedCarePlan: boolean;
  newCustomGoals: Goal[];
};

type CarePlanActions = {
  setNewCustomGoals: (newCustomGoals: Goal[]) => void;
  setCurrentCarePlanGoalsByAdlo: (
    user_adlo_id: string,
    currentCarePlanGoals: CarePlanGoalWithProgress[]
  ) => void;
  setHasEditedCarePlan: (hasEditedCarePlan: boolean) => void;
  reset: () => void;
};

export const useCarePlanStore = create<CarePlanState & CarePlanActions>(
  (set) => ({
    newCustomGoals: [],
    currentCarePlanGoalsByAdlo: {},
    hasEditedCarePlan: false,
    setNewCustomGoals: (newCustomGoals) => {
      const uniqueNewCustomGoals = newCustomGoals.filter(
        (v, i, a) => a.findIndex((g) => g.id === v.id) === i
      );
      set({ newCustomGoals: uniqueNewCustomGoals });
    },
    setCurrentCarePlanGoalsByAdlo: (user_adlo_id: string, currentCarePlanGoals: CarePlanGoalWithProgress[]) => {
      // Set the goals for the specific user_adlo_id
      set((state) => ({
        currentCarePlanGoalsByAdlo: {
          ...state.currentCarePlanGoalsByAdlo,
          [user_adlo_id]: currentCarePlanGoals,
        },
      }));
    },
    setHasEditedCarePlan: (hasEditedCarePlan) => {
      set({ hasEditedCarePlan });
    },
    reset: () =>
      set({
        currentCarePlanGoalsByAdlo: {},
        hasEditedCarePlan: false,
      }),
  })
);

import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useGuideTasks } from '../../backend/resources/guide/guideTask';
import { useNetworksInOrganization } from '../../backend/resources/network/network';
import { TaskStatus, TaskStatusLabel, useAddConversationToPlanEntry, useDeletePlanEntry, usePlanEntryData } from '../../backend/resources/planEntry';
import { useCreateServiceEngagement, useServiceEngagementByServiceRequestId } from '../../backend/resources/services/serviceEngagement';
import { useUpsertServiceEngagementToGuideTask } from '../../backend/resources/services/serviceEngagementToGuideTask';
import { useServiceResourcesFromPlanEntry } from '../../backend/resources/services/serviceResource';
import { usePlanEntries } from '../../hooks/usePlanEntries';
import { CareCentralRoute, CarePilotRoute, SharedRoute, useAppNavigate, useNavigateBack } from '../../lib/routing';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRoot } from '../../shared/ui/form';
import { Input } from '../../shared/ui/input';
import { ResponsiveModal } from '../../shared/ui/responsive-modal';
import { ButtonWithIcon, IconOption } from '../ButtonWithIcon';
import Who, { UserType } from '../MyPlanPage/components/Who';
import { PageContainer } from '../PageContainer';
import { PageMainHeader } from '../PageMainHeader';
import { LocalResourceCard } from '../ResourcesPage/components/LocalResourceCard';
import { Select } from '../Select';
import { TextArea } from '../TextArea';
import { ServiceRequestSchema } from './CreateOrEditServiceRequestPage';

import { useServiceRequestDiscussion } from '../../backend/resources/chatGptConversation';
import { useConversationDocumentsByConversationId } from '../../backend/resources/conversation_document/conversation_document';
import { useOrgs } from '../../backend/resources/orgRole';
import { useUpdatePlanEntry } from '../../backend/resources/planEntry';
import BackButton from '../BackButton/BackButton';
import { CollapsedUserDiscussion, DiscussionType, ExternalDiscussion } from '../ChatGptSideBar';
import { LoadingSpinner } from '../LoadingSpinner';
import { ServicePageTabs } from '../ServicePage/ServicePage';
import DocumentsUploadSection, { DocumentSchemaType } from '../ServicePage/components/DocumentsSection';
import { ServicesHubTabs } from '../Services Hub/ServicesHubPage';
import { TaskTable, TaskTableFields } from '../Tables/TaskTable';
import Tabs from '../Tabs/Tabs';

const VIEW_SERVICE_REQUEST_FORM_ID = "view-service-request-form";

const useViewServiceRequestForm = () => {
  return useForm({
    resolver: zodResolver(ServiceRequestSchema),
    mode: "onSubmit",
  });
};

export default function ViewServiceRequestPage() {
  const navigateBack = useNavigateBack()
  const navigate = useAppNavigate();
  const { id: service_request_id } = useParams()

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isActivationConfirmationModalOpen, setIsActivationConfirmationModalOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState<ServicePageTabs>(ServicePageTabs.INTERNAL);

  // Queries
  const { networks } = useNetworksInOrganization();
  const { data: guideTasks, isLoading: isGuideTasksLoading } = useGuideTasks();
  const { data: planEntry, isLoading: isPlanEntryLoading } = usePlanEntryData(service_request_id);
  const { sortedAndFilteredPlanEntries: planEntriesInServiceRequest, isLoading: isPlanEntriesInServiceRequestLoading } = usePlanEntries({ parentPlanEntryId: service_request_id });
  const { data: documents, isLoading: isDocumentsLoading } = useConversationDocumentsByConversationId(
    currentTab === ServicePageTabs.INTERNAL ?
      planEntry?.conversation_id :
      planEntry?.external_conversation_id
  );
  const { data: serviceResource, isLoading: isServiceResourceLoading } = useServiceResourcesFromPlanEntry(service_request_id);
  const { data: serviceEngagementData } = useServiceEngagementByServiceRequestId({ service_request_id: planEntry?.id })
  const { hasCareCentralAccess, isLoading: isHasCareCentralAccessLoading } = useOrgs();
  const { data: serviceRequestConversationId, isLoading, } = useServiceRequestDiscussion(service_request_id, currentTab === ServicePageTabs.EXTERNAL);
  const addConversationToPlanEntry = useAddConversationToPlanEntry().mutateAsync;
  // Mutations
  const updatePlanEntry = useUpdatePlanEntry().mutateAsync;
  // Form
  const form = useViewServiceRequestForm();

  // Effects
  useEffect(() => {
    // form valuess setting
    form.reset({
      ...planEntry,
      documents: documents ?? undefined,
      service: serviceResource ?? undefined,
    });
  }, [planEntry, documents, serviceResource]);

  useEffect(() => {
    if (!serviceRequestConversationId && !isLoading) {
      addConversationToPlanEntry({ taskId: service_request_id, isExternal: currentTab === ServicePageTabs.EXTERNAL, external_participant_id: serviceResource?.id, networkId: planEntry?.network_id ?? undefined });
    }
  }, [serviceRequestConversationId, isLoading, planEntry, serviceResource]);

  const formValues = form.watch();

  // Consts
  const carespaceOptions = networks?.map(network => ({
    value: network.id,
    label: network.name ?? "",
  }));

  const statusOptions = Object.values(TaskStatus).map(status => ({
    value: status,
    label: TaskStatusLabel[status],
  }));

  const guideTaskOptions = guideTasks?.map(guideTask => ({
    value: guideTask.id,
    label: guideTask.title,
  })) ?? [];


  if (isGuideTasksLoading || isPlanEntryLoading || isPlanEntriesInServiceRequestLoading || isDocumentsLoading || isServiceResourceLoading || isHasCareCentralAccessLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner className="w-20 h-20" />
      </div>
    );
  }

  return (
    <PageContainer >
      <div className='relative'>
        <div className='max-w-4xl flex-grow'>
          <BackButton className="mb-6 " />
          <div className='flex flex-wrap justify-between'>
            <PageMainHeader text="View Service Request" />
            {hasCareCentralAccess && <div className='flex gap-4 py-2'>
              {/* Edit Button */}
              <ButtonWithIcon onClick={() => {
                if (service_request_id) {
                  navigate({
                    path: SharedRoute.SERVICE_REQUEST_EDIT,
                    params: {
                      id: service_request_id
                    }
                  })
                }
              }} size={"small"}
                text={'Edit'} icon={IconOption.EDIT} />
              {/* Delete Button */}
              <ButtonWithIcon
                onClick={() => setIsCancelModalOpen(true)}
                text={'Cancel'}
                size={"small"}
                icon={IconOption.TRASH} />
              {/* Resource related buttons */}
              {!serviceEngagementData ?
                // If no service engagement yet, button to create one:
                // Set as Active
                <ButtonWithIcon onClick={() => setIsActivationConfirmationModalOpen(true)}
                  text={'Activate as Service'}
                  icon={IconOption.ARROW}
                  truncate={false}
                  size={"small"}
                />
                :
                // Otherwise navigate to service:
                < ButtonWithIcon onClick={async () => {
                  navigate({
                    path: SharedRoute.SERVICE_PAGE,
                    params: {
                      id: serviceEngagementData.id
                    }
                  })
                }}
                  text={'Go to service'}
                  icon={IconOption.FORWARD}
                  size={"small"}
                />
              }
            </div>
            }
          </div>
          <Tabs tabs={Object.values(ServicePageTabs)} currentTab={currentTab} setCurrentTab={setCurrentTab} />

          <Form {...form}>
            <FormRoot id={VIEW_SERVICE_REQUEST_FORM_ID}>
              {/* Title Field */}
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem >
                    <FormLabel>Title*</FormLabel>
                    <FormControl>
                      <Input {...field} disabled />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {currentTab === ServicePageTabs.INTERNAL && <>
                {/* Description Field */}
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem >
                      <FormLabel>Details</FormLabel>
                      <FormControl>
                        <TextArea {...field} className='w-full' disabled />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className='flex flex-wrap gap-y-4 gap-x-10'>
                  {/* Carespace Field */}
                  <FormField
                    control={form.control}
                    name="network_id"
                    render={({ field }) => (
                      <FormItem className='flex flex-col min-w-[200px]'>
                        <FormLabel>Carespace*</FormLabel>
                        <FormControl>
                          <Select
                            placeHolder='Please Select'
                            {...field}
                            disabled
                            options={carespaceOptions}
                            currentOption={carespaceOptions.find((option) => option.value === field.value)}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {/* Status field */}
                  <FormField
                    control={form.control}
                    name="status"
                    render={({ field }) => (
                      <FormItem className='flex flex-col min-w-[200px]'>
                        <FormLabel>Status</FormLabel>
                        <FormControl>
                          <Select
                            {...field}
                            disabled={!hasCareCentralAccess}
                            options={statusOptions}
                            onChange={async (value) => {
                              if (value) {
                                form.setValue("status", value);
                                await updatePlanEntry({ id: formValues.id, status: value })
                                if (value === TaskStatus.Done && !serviceEngagementData) {
                                  setIsActivationConfirmationModalOpen(true)
                                }
                              }
                            }}
                            currentOption={statusOptions.find((option) => option.value === field.value)}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {/* Who field */}
                  <FormField
                    control={form.control}
                    name="user_id"
                    render={({ field }) => (
                      <FormItem className='flex flex-col min-w-[200px]'>
                        <FormLabel>Assigned To*</FormLabel>
                        <FormControl>
                          <Who
                            userType={UserType.ORG_USERS}
                            isEditing={false}
                            networkId={formValues.network_id}
                            entry={undefined}
                            selectedPlanEntryOwnerId={field.value}
                            setPlanEntryOwner={() => { }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {/* Date field */}

                  <FormField
                    control={form.control}
                    name="scheduled_date_time"
                    render={({ field }) => (
                      <FormItem className='flex flex-col min-w-[200px]'>
                        <FormLabel>Date*</FormLabel>
                        <FormControl>
                          <Input {...field} disabled value={new Date(field.value).toLocaleDateString()} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {/* GUIDE Task ID Field */}
                  <FormField
                    control={form.control}
                    name="guide_task_id"
                    render={({ field }) => (
                      <FormItem className='flex flex-col min-w-[200px]'>
                        <FormLabel>GUIDE Task</FormLabel>
                        <FormControl>
                          <Select
                            {...field}
                            placeHolder='No attached task'
                            disabled
                            options={guideTaskOptions}
                            currentOption={guideTaskOptions.find((option) => option.value === field.value)}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                {/* Local Service Field */}
                <FormField
                  control={form.control}
                  name="service"
                  render={({ field }) => (
                    <FormItem className='flex flex-col min-w-[200px]'>
                      <FormLabel className='text-lg'>Resource / Referral</FormLabel>
                      <FormControl>
                        {field.value ?
                          <div onClick={(e) => { e.preventDefault(); navigate({ path: SharedRoute.SERVICE_PROVIDER_PAGE, params: { id: field.value.id } }) }}>
                            <LocalResourceCard hideCheckbox listing={field.value} />
                          </div>
                          : <p className='text-sm text-zinc-400'>This service Request has no referral.</p>}
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <CollapsedUserDiscussion discussionType={DiscussionType.ServiceRequest} threadId={service_request_id} title="Internal Discussion (care team and caregiver ONLY)" />


                <div className='flex gap-3'>
                  <p className='text-lg'>Subtasks</p>
                  <ButtonWithIcon
                    icon={IconOption.PLUS}
                    text='Create New'
                    size={"small"}
                    onClick={() =>
                      navigate({
                        path: CarePilotRoute.PLAN_NEW,
                        queryParams: {
                          parent_plan_entry_id: service_request_id ?? null,
                        }
                      })} />
                </div>
                <TaskTable data={planEntriesInServiceRequest ?? []} updateTask={updatePlanEntry} hiddenColumns={[TaskTableFields.TaskType, TaskTableFields.Category, TaskTableFields.ServiceStatus, TaskTableFields.CreatedAt]} />
              </>
              }
              {currentTab === ServicePageTabs.EXTERNAL && <ExternalDiscussion email={serviceResource?.email} discussionType={DiscussionType.ServiceRequestExternal} threadId={service_request_id} resourceId={serviceResource?.id ?? ''} />}
              {/* Documents Field */}
              <FormField
                control={form.control}
                name="documents"
                render={({ field }) => (
                  <FormItem className='flex flex-col min-w-[200px]'>
                    <DocumentsUploadSection
                      documents={field.value?.map((doc: DocumentSchemaType) => ({ ...doc, is_new: false })) ?? []}
                      conversationId={currentTab === ServicePageTabs.INTERNAL ?
                        planEntry?.conversation_id :
                        planEntry?.external_conversation_id
                      }
                      networkId={formValues.network_id}
                      isExternal={currentTab === ServicePageTabs.EXTERNAL}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </FormRoot>
          </Form>
        </div>
      </div>

      {/* Cancel Modal */}
      <CancelServiceRequestModal
        isCancelModalOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        serviceRequestId={service_request_id}
      />
      {/* Activation Confirmation Modal */}
      <ActivationConfirmationModal
        isActivationConfirmationModalOpen={isActivationConfirmationModalOpen}
        onClose={() => setIsActivationConfirmationModalOpen(false)}
        serviceRequestId={service_request_id}
        formValues={formValues}
      />

    </PageContainer>
  );
}


interface CancelServiceRequestModalProps {
  isCancelModalOpen: boolean;
  onClose: () => void;
  serviceRequestId: string | undefined;
}

function CancelServiceRequestModal({ isCancelModalOpen, onClose, serviceRequestId }: CancelServiceRequestModalProps) {
  const deleteServiceRequest = useDeletePlanEntry().mutateAsync
  const navigateBack = useNavigateBack()

  return (<ResponsiveModal isOpen={isCancelModalOpen} closeText='Back' onClose={onClose} title={"Cancel Service Request?"} footerButtons={<>
    <ButtonWithIcon onClick={onClose} text={'No, go back'} icon={IconOption.CANCEL} size={"small"} />
    <ButtonWithIcon onClick={async () => {
      if (serviceRequestId) {
        await deleteServiceRequest({
          planEntryId: serviceRequestId
        });
        navigateBack();
      };
    }} text={'Yes, cancel it'} icon={IconOption.CHECKMARK} size={"small"} />
  </>}>
    <p> Are you sure you want to cancel this request?</p>
  </ResponsiveModal>);
}

interface ActivationConfirmationModalProps {
  isActivationConfirmationModalOpen: boolean;
  onClose: () => void;
  serviceRequestId: string | undefined;
  formValues: FieldValues;
}

function ActivationConfirmationModal({ isActivationConfirmationModalOpen, onClose, serviceRequestId, formValues }: ActivationConfirmationModalProps) {
  const navigate = useAppNavigate();

  //Mutations
  const createServiceEngagement = useCreateServiceEngagement().mutateAsync;
  const upsertServiceEngagementToGuideTask = useUpsertServiceEngagementToGuideTask().mutateAsync;
  const updatePlanEntry = useUpdatePlanEntry().mutateAsync

  const isPlanEntryDone = formValues.status === TaskStatus.Done

  async function activateService() {
    if (!serviceRequestId) return null
    // ensure the plan Entry is Done
    await updatePlanEntry({
      id: serviceRequestId,
      status: TaskStatus.Done
    });

    // Create service engagement
    const serviceEngagement = await createServiceEngagement({
      serviceEngagement: {
        service_resource_id: formValues.service.id,
        network_id: formValues.network_id,
        name: formValues.name,
        details: formValues.description,
      },
      serviceRequestId,
    });

    if (!serviceEngagement) return

    // create a relationship b/guide task and service
    if (formValues.guide_task_id) {
      await upsertServiceEngagementToGuideTask({
        network_id: formValues.network_id,
        guide_task_id: formValues.guide_task_id,
        service_engagement_id: serviceEngagement.id,
      });
    }
    // navigate to the service page
    navigate({
      path: CareCentralRoute.SERVICE_HUB,
      queryParams: {
        tab: ServicesHubTabs.SERVICES
      }
    }, { replace: true })
  }


  //---------*---------*---------*---------*---------*---------*---------*---------
  if (!serviceRequestId) return null
  return (
    <ResponsiveModal isOpen={isActivationConfirmationModalOpen} closeText='Back' onClose={onClose} title={""} footerButtons={
      <>
        {/* No/Cancel Button */}
        <ButtonWithIcon onClick={onClose} text={isPlanEntryDone ? 'No, just set request to Done' : 'Cancel'} icon={IconOption.CANCEL} size={"small"} />
        {/* Activate Button */}
        <ButtonWithIcon onClick={activateService}
          text={'Yes, activate it'}
          icon={IconOption.CHECKMARK} size={"small"} />
      </>
    }>
      <div className='text-center py-5'>
        <p> Do you want to make this an active service?</p>
        {!isPlanEntryDone && <p> This will also set the request to Done.</p>}
      </div>
    </ResponsiveModal>
  );
}


import { useEffect, useState } from "react";

import { SplideSlide } from "@splidejs/react-splide";
import { supabase } from "../../../clients/supabaseClient";

import { CarePilotRoute, useAppNavigate } from "../../../lib/routing";
import Carousel from "../../Carousel/Carousel";
import { VideoDisplay } from "../VideoDisplay";

type Video = {
  content_category: {
    name: string;
  };
  video: {
    id: string;
    slug: string;
    title: string;
    description: string;
    url: string;
  };
};

interface Props {
  category: string;
  showAsCarousel: boolean;
}

export function VideosWithCategory({ category, showAsCarousel }: Props) {
  const navigate = useAppNavigate();

  const [videos, setVideos] = useState<Video[]>([]);

  async function fetchVideos() {
    const { data, error } = await supabase
      .from("video_categorization")
      .select(
        "video!inner(id, slug, title, description, url, hide), content_category!inner(name)"
      )
      .eq("content_category.name", category)
      .eq("video.hide", false);
    if (data) {
      setVideos(data as any);
    }
  }

  useEffect(() => {
    fetchVideos();
  }, []);

  if (!videos || videos.length === 0) {
    return null
  }

  return (
    <>
      <button
        onClick={() =>
          navigate({
            path: CarePilotRoute.EDUCATION_CATEGORY,
            params: {
              category: category
            }
          })
        }
        className="text-lg mt-4 w-full">
        <p className="md:px-8 text-center md:text-start md:pl-12 w-full pb-5">
          {category}
        </p>
      </button>
      <Carousel
        showArrowsInMobile
        items={videos.map(({ video }, i) => (
          <SplideSlide>
            <VideoDisplay
              uniqueId={`${`scrollableVideoContainer-${category}`}-${video.id}`}
              key={video.id}
              id={video.id}
              slug={video.slug}
              isFirst={i === 0}
              isLast={i === videos.length - 1}
              setShowLeftArrow={() => { }}
              setShowRightArrow={() => { }}
              src={video.url}
              title={video.title}
              description={video.description}
            />
          </SplideSlide>
        ))}
      />
    </>
  );
}

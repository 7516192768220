import { useEffect, useState } from "react";
import { ReactComponent as WarningIcon } from "../../assets/warning.svg";
import { NotificationType, sendNotification } from "../../backend/functions";
import type { CarePlanGoalWithProgress } from "../../backend/resources/carePlan/carePlan";
import {
  CarePlanGoalStatuses,
  useCurrentCarePlanGoalsQuery,
  useDraftCarePlanGoalsQuery,
  usePublishCarePlanMutation,
  useSaveDraftCarePlan,
} from "../../backend/resources/carePlan/carePlan";
import { setAlfredsPickOfTheDayInLocaleStrage } from "../../backend/resources/carePlanHighlights/carePlanHighlights";
import type { GoalWithStatus } from "../../backend/resources/goal/goal";
import { useArchivedGoalQuery, useGoalQuery } from "../../backend/resources/goal/goal";
import { useUserAdlos } from "../../backend/resources/userAdlo";
import { useWindowSize } from "../../hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate, useNavigateBack } from "../../lib/routing";
import { useCarePlanStore } from "../../state/carePlan/carePlan";
import { useUserStore } from "../../state/user";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
import FooterButtons from "../FooterButtons/FooterButtons";
import { TimeFrame, Timeline } from "../GoalPage/utils";
import { LoadingSpinner } from "../LoadingSpinner";
import ModalView from "../ModalView/ModalView";
import { PageContainer } from "../PageContainer";
import { PageMainHeader } from "../PageMainHeader";
import UpdateGoalPopup from "./UpdateGoalPopup/UpdateGoalPopup";

const TITLE = "Edit Care Plan";

export default function EditCarePlanPage() {
  const { isMobile } = useWindowSize();
  const { reset: resetCarePlanStore } = useCarePlanStore();
  const navigateBack = useNavigateBack();

  async function onCancel() {
    resetCarePlanStore();
    navigateBack()
  }
  if (isMobile) {
    return (
      <ModalView
        onClose={onCancel}
        isOpen={true}
        closeText={"Back"}
        title={TITLE}>
        <EditCarePlanPageComponent onCancel={onCancel} />
      </ModalView>
    );
  } else {
    return <EditCarePlanPageComponent onCancel={onCancel} />;
  }
}

export function EditCarePlanPageComponent({ onCancel }: { onCancel: any }) {
  const { isMobile } = useWindowSize();
  const authUser = useUserStore((state) => state.user);
  const navigate = useAppNavigate();
  const navigateBack = useNavigateBack();

  // const { latestCompletedAssesmentId } = useLatestCompletedAssesment({
  //   isCaregiverSelfAssessment: false,
  // });
  const { data: userAdlos } = useUserAdlos();
  const userAdlo = userAdlos?.[0];

  const { suggestedGoals, goals } = useGoalQuery();
  const { goals: archivedGoals } = useArchivedGoalQuery()
  const {
    data: goalsInSavedDraft,
    refetch: refetchGoalsInSavedDraft,
    isLoading: isLoadingGoalsInSavedDraft,
  } = useDraftCarePlanGoalsQuery();

  const { data: goalsInPublishedPlan } = useCurrentCarePlanGoalsQuery();
  const [updatePopup, setUpdatePopup] = useState<{
    isOpen: boolean,
    suggestedUpdate: CarePlanGoalWithProgress | null,
    existingGoal: CarePlanGoalWithProgress | null
  }>({ isOpen: false, suggestedUpdate: null, existingGoal: null });

  // global state
  const {
    reset: resetCarePlanStore,
    currentCarePlanGoalsByAdlo,
    setCurrentCarePlanGoalsByAdlo,
  } = useCarePlanStore();
  const currentCarePlanGoals = userAdlo ? currentCarePlanGoalsByAdlo[userAdlo.id] ?? [] : [];
  const [goalsByTimeline, setGoalsByTimeline] = useState<Record<Timeline, CarePlanGoalWithProgress[]>>({ short: [], mid: [], long: [] });
  const [draftGoalsByTimeline, setDraftGoalsByTimeline] = useState<Record<Timeline, CarePlanGoalWithProgress[]>>({ short: [], mid: [], long: [] });
  const [archivedGoalsByTimeline, setArchivedGoalsByTimeline] = useState<Record<Timeline, CarePlanGoalWithProgress[]>>({ short: [], mid: [], long: [] });

  const saveDraftCarePlan = useSaveDraftCarePlan().mutateAsync;
  const publishCarePlan = usePublishCarePlanMutation().mutateAsync;

  function hasDuplicateTitle(
    goal: CarePlanGoalWithProgress,
    goals?: CarePlanGoalWithProgress[]
  ): boolean {
    return goals !== undefined && goals?.some((g) => g.title === goal.title);
  }

  function isNewGoal(
    goal: CarePlanGoalWithProgress,
    goals?: CarePlanGoalWithProgress[]
  ): boolean {
    return goals !== undefined && !goals?.some((g) => g.id === goal.id)
  }

  async function saveGoalsAsDraft() {
    await saveDraftCarePlan();
    navigateBack();
    await refetchGoalsInSavedDraft()
    resetCarePlanStore();
  }

  async function saveAndPublishCarePlan() {
    const newPlan = await publishCarePlan();
    setAlfredsPickOfTheDayInLocaleStrage([], authUser?.id); // reset it
    if (newPlan) {
      sendNotification(
        newPlan.id,
        NotificationType.DOCTOR_REVIEWED
      )
    }
    navigateBack();
    await refetchGoalsInSavedDraft()
    resetCarePlanStore();
  }

  useEffect(() => {
    if ((goalsInSavedDraft || goalsInPublishedPlan) && userAdlo && currentCarePlanGoals.length === 0) {
      setCurrentCarePlanGoalsByAdlo(userAdlo.id, goalsInSavedDraft ?? goalsInPublishedPlan ?? []);
    }
  }, [goalsInSavedDraft, goalsInPublishedPlan, currentCarePlanGoals]);

  useEffect(() => {
    const filteredCurrentCarePlanGoals = currentCarePlanGoals.filter(carePlanGoal =>
      [CarePlanGoalStatuses.INCLUDED, CarePlanGoalStatuses.ARCHIVED].includes(carePlanGoal.status as CarePlanGoalStatuses))
    if (filteredCurrentCarePlanGoals) {
      const newGoalsByTimeline: Record<Timeline, CarePlanGoalWithProgress[]> = {
        short: filteredCurrentCarePlanGoals.filter((goal) => goal.timeline === Timeline.SHORT_TERM),
        mid: filteredCurrentCarePlanGoals.filter((goal) => goal.timeline === Timeline.MID_TERM),
        long: filteredCurrentCarePlanGoals.filter((goal) => goal.timeline === Timeline.LONG_TERM),
      };

      // Only update state if it has changed
      if (JSON.stringify(newGoalsByTimeline) !== JSON.stringify(goalsByTimeline)) {
        setGoalsByTimeline(newGoalsByTimeline);
      }
    }
  }, [currentCarePlanGoals]);

  useEffect(() => {
    const filteredSuggestedGoals = suggestedGoals?.filter(goal => !currentCarePlanGoals.some((g) => g.id === goal.id))
    if (suggestedGoals) {
      const newDraftGoalsByTimeline = {
        short: filteredSuggestedGoals?.filter((goal) => goal.timeline === Timeline.SHORT_TERM) ?? [],
        mid: filteredSuggestedGoals?.filter((goal) => goal.timeline === Timeline.MID_TERM) ?? [],
        long: filteredSuggestedGoals?.filter((goal) => goal.timeline === Timeline.LONG_TERM) ?? [],
      };
      // Only update state if it has changed
      if (JSON.stringify(newDraftGoalsByTimeline) !== JSON.stringify(draftGoalsByTimeline)) {
        setDraftGoalsByTimeline(newDraftGoalsByTimeline);
      }
    }
  }, [suggestedGoals, draftGoalsByTimeline]);


  useEffect(() => {
    const newArchivedGoalsByTimeline = {
      short: archivedGoals?.filter((goal) => goal.timeline === Timeline.SHORT_TERM) ?? [],
      mid: archivedGoals?.filter((goal) => goal.timeline === Timeline.MID_TERM) ?? [],
      long: archivedGoals?.filter((goal) => goal.timeline === Timeline.LONG_TERM) ?? [],
    };
    // Only update state if it has changed
    if (JSON.stringify(newArchivedGoalsByTimeline) !== JSON.stringify(archivedGoalsByTimeline)) {
      setArchivedGoalsByTimeline(newArchivedGoalsByTimeline);
    }
  }, [archivedGoals, archivedGoalsByTimeline]);

  if (isLoadingGoalsInSavedDraft) {
    return <LoadingSpinner />;
  }
  return (
    <PageContainer>
      {updatePopup.suggestedUpdate && updatePopup.existingGoal &&
        <UpdateGoalPopup
          suggestedUpdate={updatePopup.suggestedUpdate}
          existingGoal={updatePopup.existingGoal}
          isOpen={updatePopup.isOpen}
          close={() => setUpdatePopup({ ...updatePopup, isOpen: false })}
        />
      }
      {!isMobile ? (
        <>
          <PageMainHeader text={TITLE} />
          <div className="flex justify-end gap-5 my-5 w-full">
            <ButtonWithIcon
              onClick={onCancel}
              text={"Cancel"}
              size="small"
              icon={IconOption.CANCEL}
            />
            <ButtonWithIcon
              onClick={saveGoalsAsDraft}
              text={"Save as Draft"}
              size="small"
              icon={IconOption.CHECKMARK}
            />
            <ButtonWithIcon
              onClick={saveAndPublishCarePlan}
              text={"Publish"}
              size="small"
              icon={IconOption.UPLOAD}
            />
          </div>
        </>
      ) : null}
      <div>
        <ButtonWithIcon
          onClick={() =>
            navigate({
              path: CarePilotRoute.GOAL_EDIT,
              queryParams: {
                "user_adlo_id": userAdlo?.id ?? ""
              }
            })
          }
          className="text-left mb-5"
          size="small"
          text={"Add Custom Goal"}
          icon={IconOption.PLUS}
        />
        {/* Suggested / Current Care Plan  */}
        <div className="grid grid-cols-2 gap-4">
          <p>Suggested New Goals</p>
          <p>Current Care Plan</p>
          {["short", "mid", "long"].map((timeline) => (
            <>

              <TimelineComponent
                middleColumn={undefined}
                timeline={timeline as Timeline}
                goals={draftGoalsByTimeline[timeline as Timeline].filter(
                  (goal) => !currentCarePlanGoals.some((g) => g.id === goal.id)
                )}
                buttonAction={(goal: GoalWithStatus) => {

                  if (hasDuplicateTitle(goal, currentCarePlanGoals)) {
                    setUpdatePopup({
                      isOpen: true,
                      suggestedUpdate: goal,
                      existingGoal: currentCarePlanGoals.find(g => g.title === goal.title) ?? null
                    })
                  }
                  else {
                    setCurrentCarePlanGoalsByAdlo(
                      userAdlo?.id ?? "",
                      [...currentCarePlanGoals, { ...goal, status: CarePlanGoalStatuses.INCLUDED }]
                    );
                  }
                }}
                isNewGoal={() => false}
                hasDuplicateTitle={(goal) => hasDuplicateTitle(goal, currentCarePlanGoals)}
              />
              <TimelineComponent
                timeline={timeline as Timeline}
                goals={goalsByTimeline[timeline as Timeline]}
                middleColumn="Progress"
                buttonAction={(goal: GoalWithStatus) => {
                  setCurrentCarePlanGoalsByAdlo(
                    userAdlo?.id ?? "",
                    currentCarePlanGoals?.map((carePlanGoalWithProgress) =>
                      carePlanGoalWithProgress.id === goal.id
                        ? { ...goal, status: carePlanGoalWithProgress?.status === CarePlanGoalStatuses.ARCHIVED ? CarePlanGoalStatuses.INCLUDED : CarePlanGoalStatuses.ARCHIVED }
                        : carePlanGoalWithProgress
                    )
                  );
                }}
                isNewGoal={(goal) => isNewGoal(goal, goalsInPublishedPlan ?? [])}
                hasDuplicateTitle={(goal) => hasDuplicateTitle(goal, suggestedGoals?.filter(goal => !currentCarePlanGoals.some((g) => g.id === goal.id)))}
              />
            </>
          ))}
          {/* Archived Goals  */}
          <p>Archived Goals</p>
          <p />
          {["short", "mid", "long"].map((timeline) => (
            <>

              <TimelineComponent
                middleColumn={undefined}
                timeline={timeline as Timeline}
                goals={archivedGoalsByTimeline[timeline as Timeline].filter(
                  (goal) => !currentCarePlanGoals.some((g) => g.id === goal.id)
                )}
                buttonAction={(goal: GoalWithStatus) => {
                  if (hasDuplicateTitle(goal, currentCarePlanGoals)) {
                    setUpdatePopup({
                      isOpen: true,
                      suggestedUpdate: goal,
                      existingGoal: currentCarePlanGoals.find(g => g.title === goal.title) ?? null
                    })
                  }
                  else {
                    setCurrentCarePlanGoalsByAdlo(
                      userAdlo?.id ?? "",
                      [...currentCarePlanGoals, { ...goal, status: CarePlanGoalStatuses.INCLUDED }]
                    );
                  }
                }}
                isNewGoal={() => false}
                hasDuplicateTitle={(goal) => hasDuplicateTitle(goal, currentCarePlanGoals)}
              />
              <p></p>
            </>
          ))}
        </div>

        {isMobile ? (
          <FooterButtons>
            <ButtonWithIcon
              onClick={onCancel}
              text={"Cancel"}
              size={"small"}
              icon={IconOption.CANCEL}
            />
            <ButtonWithIcon
              onClick={saveGoalsAsDraft}
              text={"Save Draft"}
              size={"small"}
              icon={IconOption.CHECKMARK}
            />
            <ButtonWithIcon
              onClick={saveAndPublishCarePlan}
              text={"Publish"}
              icon={IconOption.UPLOAD}
            />
          </FooterButtons>
        ) : null}
      </div>
    </PageContainer>
  );
}

function TimelineComponent({
  timeline,
  goals,
  middleColumn,
  buttonAction,
  hasDuplicateTitle,
  isNewGoal
}: {
  timeline: Timeline;
  goals: CarePlanGoalWithProgress[];
  middleColumn?: string;
  buttonAction: (goal: CarePlanGoalWithProgress) => void;
  hasDuplicateTitle: (goal: GoalWithStatus) => boolean;
  isNewGoal: (goal: GoalWithStatus) => boolean;
}) {
  const hasGoals = goals && goals.length > 0;
  const navigate = useAppNavigate();

  return (
    <div className="pb-5">
      <div className="grid grid-cols-[1fr,.5fr,.5fr] gap-2 mt-4 text-center items-center">
        <>
          <h2 className="font-bold text-sm text-start">
            {TimeFrame.getLabelFromValue(timeline)}
          </h2>
          <p /> <p />
          {!hasGoals ? <p className="text-start text-sm">No goals</p> : null}
          {goals
            .sort((a, b) =>
              a.created_at > b.created_at
                ? -1
                : b.created_at > a.created_at
                  ? 1
                  : 0
            )
            .map((carePlanGoal: CarePlanGoalWithProgress) => (
              <>
                <button
                  className={`line-clamp-2 text-sm text-left flex items-center break-words ${carePlanGoal.status === CarePlanGoalStatuses.ARCHIVED ? "text-gray-300" : ""
                    }`}
                  onClick={() => {
                    navigate({
                      path: CarePilotRoute.GOAL_ID,
                      params: {
                        id: carePlanGoal.id
                      }
                    })
                  }}>
                  <p
                    className={`line-clamp-2 text-sm text-left break-words ${isNewGoal(carePlanGoal) ? "text-blue-500" : ""
                      }`}>
                    {carePlanGoal.title} {!isNewGoal(carePlanGoal) && hasDuplicateTitle(carePlanGoal) && <WarningIcon className="w-3 h-3 mb-[3px] inline" />}
                  </p>
                </button>
                {middleColumn ? (
                  <p
                    className={`text-sm ${carePlanGoal.progress === 0 ? "text-gray-300" : ""
                      }`}>
                    {carePlanGoal.progress}%
                  </p>
                ) : (
                  <p></p>
                )}
                <ButtonWithIcon
                  onClick={() => buttonAction(carePlanGoal)}
                  className=" flex justify-center"
                  text={""}
                  size="small"
                  icon={
                    carePlanGoal.status === CarePlanGoalStatuses.ARCHIVED
                      ? IconOption.RESTORE
                      : carePlanGoal.status === CarePlanGoalStatuses.INCLUDED ?
                        IconOption.ARCHIVE
                        : carePlanGoal.is_archived ?
                          IconOption.PLUS : IconOption.ARROW
                  }
                />
              </>
            ))}
        </>
      </div>
    </div>
  );
}

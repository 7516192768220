import { type VariantProps } from "class-variance-authority";
import { useEffect } from "react";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { useLibraryFoldersStore } from "../../../../state/libraryFolders";

import { useQueryTagsAndFoldersBookmark } from "../../../../backend/resources/userBookmark";
import BookmarkableCard from "../../../Card/BookmarkableCard";
import type { cardStyles } from "../../../Card/Card";
import { ChatGptMessageBookmark } from "./ChatGptMessageBookmark";
import { GuidanceResourceBookmark } from "./GuidanceResourceBookmark";
import { InterventionBookmark } from "./InterventionBookmark";
import { getBookmarkType, getIdOfBookmarkedItem } from "./utils";

interface Props {
  bookmark: any;
  refetchBookmarks: () => void;
  hideActionbar?: boolean;
  isInPlanEntry?: boolean;
  disableClick?: boolean;
}

export function Bookmark({
  bookmark,
  refetchBookmarks,
  hideActionbar,
  isInPlanEntry,
  disableClick,
  ...variantProps
}: Props & VariantProps<typeof cardStyles>) {
  const activeFolder = useLibraryFoldersStore((state) => state.activeFolder);

  const activeBookmarkId = useLibraryFoldersStore(
    (state) => state.activeBookmarkId
  );
  const setActiveBookmarkId = useLibraryFoldersStore(
    (state) => state.setActiveBookmarkId
  );
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (activeBookmarkId === bookmark.id) {
      setActiveBookmarkId();
    }
  }, [activeBookmarkId]);

  const { libraryFoldersForBookmark } = useQueryTagsAndFoldersBookmark(
    bookmark.id
  );

  // Dont show if not in current folder
  if (
    activeFolder !== undefined &&
    !libraryFoldersForBookmark?.find(
      // @ts-ignore
      (folder) => folder.library_folder?.name === activeFolder.name
    )
  ) {
    return null;
  }

  return (
    <div
      className={`relative ${
        isMobile && variantProps.size !== "thumbnail" ? "" : ""
      } ${disableClick ? "pointer-events-none" : ""}`}>
      <BookmarkableCard
        {...variantProps}
        bookmarkableItem={{
          itemId: getIdOfBookmarkedItem(bookmark),
          type: getBookmarkType(bookmark),
        }}
        refetchBookmarks={refetchBookmarks}>
        <button
          className={`cursor-default w-full h-full flex flex-col justify-around items-center`}
          style={{ textAlign: "inherit" }}>
          {bookmark.user_intervention ? (
            <InterventionBookmark
              bookmark={bookmark}
              {...variantProps}
            />
          ) : bookmark.user_guidance_resource ? (
            <GuidanceResourceBookmark
              bookmark={bookmark}
              {...variantProps}
            />
          ) : bookmark.chat_gpt_message ? (
            <ChatGptMessageBookmark
              bookmark={bookmark}
              {...variantProps}
            />
          ) : null}
        </button>
      </BookmarkableCard>
    </div>
  );
}

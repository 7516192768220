/**
 * @fileoverview Sets up the Zustand library folders store.
 * This store holds information about library folders state.
 */
import create from "zustand";
import { persist } from "zustand/middleware";
import { LocalStorageKeys } from "../localStorageKeys";

type State = {
  invitationId?: string | null;
  isForOrganization: boolean;
};

type Actions = {
  setInvitationId: (invitationId: string | null) => void;
  setIsForOrganization: (isForOrganization: boolean) => void;
  resetInvitationStore: () => void;
};

const initialState: State = {
  invitationId: undefined,
  isForOrganization: false,
};

/**
 * Hook to access the library folders store
 */
export const useInvitationStore = create<State & Actions>()(
  persist(
    (set) => ({
      ...initialState,
      setInvitationId: (invitationId: string | null) => {
        set((state) => ({ ...state, invitationId }));
      },
      setIsForOrganization: (isForOrganization: boolean) => {
        set((state) => ({ ...state, isForOrganization }));
      },
      resetInvitationStore: () => {
        set((state) => ({ ...state, invitationId: null }));
      },
    }),
    {
      name: LocalStorageKeys.invitation,
      getStorage: () => localStorage,
    }
  )
);

import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import type { Database } from "../../../../types/supabase";
import { queryClient } from "../../../App";
import { supabase } from "../../../clients/supabaseClient";
import { QUERY_KEYS } from "../../queryKeys";
import type { ShareableRecommendationType } from "../shareableRecommendation/shareableRecommendation";

const TABLE = "goal_template_to_recommendation";

export type GoalTemplateToRecommendation =
  Database["public"]["Tables"]["goal_template_to_recommendation"]["Row"] & {
    recommendation: ShareableRecommendationType;
  };

export function useRecommendationsForTemplate(templateId?: string) {
  return useQuery({
    queryKey: [QUERY_KEYS.goalTemplateToRecommendation, templateId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from(TABLE)
        .select("*, recommendation(*)")
        .eq("goal_template_id", templateId ?? "");

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }

      return data.map(
        (item) => item.recommendation
      ) as ShareableRecommendationType[];
    },
  });
}

// Add a list of recommendations to a specific template
export function useAddRecommendationsToTemplate() {
  return useMutation({
    mutationFn: async ({
      templateId,
      recommendations,
    }: {
      templateId: string;
      recommendations: string[];
    }) => {
      const { error } = await supabase.from(TABLE).insert(
        recommendations.map((rec) => ({
          goal_template_id: templateId,
          recommendation_id: rec,
        }))
      );

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
      return { templateId };
    },
    onSuccess: (data) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.goalTemplateToRecommendation,
          { templateId: data.templateId },
        ]
      });
    },
  }
  );
}

export function useSaveRecommendationsToTemplate(templateId?: string) {
  const { data: existingRecommendations } =
    useRecommendationsForTemplate(templateId);
  return useMutation({
    mutationFn: async ({
      recommendations,
      templateId,
    }: {
      recommendations: string[];
      templateId: string;
    }) => {
      const existingRecommendationIds = new Set(
        existingRecommendations?.map((rec) => rec.id)
      );
      const newRecommendationIds = new Set(recommendations);

      const toDelete = [...existingRecommendationIds].filter(
        (recommendationId) => !newRecommendationIds.has(recommendationId)
      );
      const toInsert = [...newRecommendationIds].filter(
        (recommendationId) => !existingRecommendationIds.has(recommendationId)
      );

      await deleteRecommendations(templateId, toDelete);
      await insertRecommendations(templateId, toInsert);

      return { templateId };
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.goalTemplateToRecommendation,
        ]
      });
    },
  }
  );
}

async function deleteRecommendations(
  templateId: string | undefined,
  recommendationIds: string[]
) {
  if (recommendationIds.length === 0 || !templateId) return;

  const { data, error } = await supabase
    .from(TABLE)
    .delete()
    .in("recommendation_id", recommendationIds)
    .eq("goal_template_id", templateId);

  if (error) {
    Sentry.captureException(error);
  }
}

async function insertRecommendations(
  templateId: string | undefined,
  recommendationIds: string[]
) {
  if (recommendationIds.length === 0 || !templateId) return;

  const itemsToInsert = recommendationIds.map((recommendationId) => ({
    goal_template_id: templateId,
    recommendation_id: recommendationId,
  }));

  const { data, error } = await supabase.from(TABLE).insert(itemsToInsert);

  if (error) {
    Sentry.captureException(error);
  }
}

import type { VariantProps } from "class-variance-authority";
import type { GoalTemplate } from "../../../backend/resources/goalTemplate/goalTemplate";
import { useRecommendationsForTemplate } from "../../../backend/resources/goalTemplate/goalTemplateToRecommendation";
import { CareCentralRoute, useAppNavigate } from "../../../lib/routing";
import type { cardStyles } from "../../Card/Card";
import { Card } from "../../Card/Card";
import { TimeFrame } from "../utils";

interface Props extends VariantProps<typeof cardStyles> {
  goalTemplate: GoalTemplate;
  disableClick?: boolean;
}

export default function GoalTemplateCard({
  goalTemplate,
  disableClick,
  ...variantProps
}: Props) {
  const navigate = useAppNavigate();
  const { data: recommendationsInTemplate } = useRecommendationsForTemplate(
    goalTemplate.id
  );
  return (
    <button
      onClick={() => {
        if (!disableClick) {
          navigate({
            path: CareCentralRoute.GOAL_TEMPLATE_ID,
            params: {
              id: goalTemplate.id
            }
          })
        }
      }}>
      <Card
        {...variantProps}
        color={"lightBlue"}>
        <div
          className={`select-text flex flex-col gap-2 h-full ${variantProps.size !== "thumbnail"
            ? "justify-around"
            : "justify-center"
            } px-2`}>
          <div className="w-full flex items-center ">
            <p className="text-lg line-clamp-4">{goalTemplate.title}</p>
          </div>
          <div>
            {variantProps.size !== "thumbnail" ? (
              <p className="text-sm font-extralight line-clamp-[2]">
                {goalTemplate.description.replace('"', "").replace('"', "")}
              </p>
            ) : null}
          </div>
        </div>
        <div className="px-2 flex w-full items-center py-3 justify-between">
          <label className="text-xs py-2 pb-1">
            Time frame: {TimeFrame.getLabelFromValue(goalTemplate?.timeline)}
          </label>
          <label className="text-xs py-2 pb-1">
            Recs: {recommendationsInTemplate?.length ?? 0}
          </label>
        </div>
      </Card>
    </button>
  );
}

import { TaskStatus, TaskStatusColor, TaskStatusLabel, TaskWithGuideInfo } from "../../../backend/resources/planEntry";
import { DonutChartData } from "../../DonutChart/DonutChart";
import DonutChartWithLabels from "../../DonutChart/DonutChartWithLabels";
import { isTaskOverdue, useFilteredData } from "../taskTableUtils";
import { FilterConfig } from "./TaskFilters";



interface TaskDonutChartsProps {
  title: string;
  data: TaskWithGuideInfo[] | undefined;
  filterConfig: FilterConfig;
}


export function TaskDonutCharts({ title, data, filterConfig }: TaskDonutChartsProps) {
  const filteredData = useFilteredData({ data, config: filterConfig })?.filter((item) => item.status !== TaskStatus.NotApplicable);

  function dataToSection(acc: Record<string, DonutChartData>, item: TaskWithGuideInfo) {
    const status = item.status as TaskStatus
    if (status !== TaskStatus.NotApplicable) {
      const isOverdue = item.scheduled_date_time ? isTaskOverdue(item.scheduled_date_time, status) : false;
      const statusKey = isOverdue ? 'Overdue' : status;
      if (!acc[statusKey]) {
        acc[statusKey] = { section: isOverdue ? 'Overdue' : TaskStatusLabel[status as TaskStatus], value: 0 };
      }
      acc[statusKey].value++;
    }
    return acc;
  }
  const totalTasks = filteredData?.length || 0;
  const completedTasks = filteredData?.filter((item) => item.status === TaskStatus.Done).length || 0;
  const percentageComplete = (totalTasks && !isNaN(completedTasks) && !isNaN(totalTasks)) ? parseFloat(((completedTasks / totalTasks) * 100).toFixed(0)) : 0;

  const copyOfTaskStatusLabel = Object.fromEntries(
    Object.entries({ Overdue: "Overdue", ...TaskStatusLabel })
      .filter(([statusKey]) => statusKey !== TaskStatus.NotApplicable)
  ) as Record<string, string>;

  return <DonutChartWithLabels title={title + ` (${percentageComplete}% Complete)`} data={filteredData} colorMapping={TaskStatusColor} dataToSection={dataToSection} labels={copyOfTaskStatusLabel} radius={80} />
}

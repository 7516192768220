import { Cell, Row, flexRender } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { CarePlanGenerationStatuses, useCarePlanGenerationStatus } from '../../backend/resources/carePlanGenerationStatus';
import { PastAssessment, UserAssessment } from '../../backend/resources/userAssessment';
import { useAssessmentStore } from '../../state/assessment';
import { AssessmentComponent } from '../AssessmentComponent/AssessmentComponent';
import { InfiniteScrollingTable, TableDataRowType } from './InfiniteScrollingTable';


export enum AssessmentStatus {
  Draft = "Draft",
  Completed = "Completed",
  New = "New",
  All = "All",
  ImmediateAttention = "Immediate Attention",
}

export const AssessmentStatusLabels = {
  [AssessmentStatus.Draft]: "Draft",
  [AssessmentStatus.Completed]: "Current",
  [AssessmentStatus.New]: "New",
  [AssessmentStatus.All]: "All",
  [AssessmentStatus.ImmediateAttention]: "Immediate Attn",
  Processing: "Processing",
}

export enum AssessmentsTableFields {
  Title = "Title",
  Status = "Status",
  DateCompleted = "Date Completed",
  For = "For",
  TakenBy = "Taken By",
  object = "object",
}

type BaseAssessmentType = {
  [AssessmentsTableFields.Title]: string;
  [AssessmentsTableFields.Status]: string;
  [AssessmentsTableFields.DateCompleted]: string;
  [AssessmentsTableFields.TakenBy]: string;
  [AssessmentsTableFields.object]: UserAssessment;
};

export type AssessmentType = BaseAssessmentType & TableDataRowType<BaseAssessmentType>;

interface AssessmentsTableProps {
  data: PastAssessment[] | undefined;
  statusFilter: AssessmentStatus | undefined;
}


export function AssessmentsTable({ data: rawData, statusFilter }: AssessmentsTableProps) {
  // States
  const [data, setData] = useState<AssessmentType[]>(() => []);
  const [userAssessment, setUserAssessment] = useState<UserAssessment | undefined>(undefined);

  // Store 
  const setActiveUserAssessmentId = useAssessmentStore((state) => state.setActiveUserAssessmentId);

  // hook 
  const { data: carePlanGenerationStatus } = useCarePlanGenerationStatus()

  // Effects
  useEffect(() => {
    // Updates data
    if (rawData) {
      const updatedData = rawData
        .map(item => {
          // While Creating care plan, show assessment status as "Processing" for assessments used in care plan generation (i.e., non-drafts, non-screening assessments)
          return {
            [AssessmentsTableFields.Title]: `${item.assessment?.name ?? ""}`,
            [AssessmentsTableFields.Status]: getAssessmentStatusLabel(item, carePlanGenerationStatus?.status as CarePlanGenerationStatuses),
            [AssessmentsTableFields.DateCompleted]: item.completed_at ? new Date(item.completed_at).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' }) : '',
            [AssessmentsTableFields.For]: item.caregiver ? `${item.caregiver?.first_name ?? ""} ${item.caregiver?.last_name ?? ""}` : `${item.user_adlo?.first_name} ${item.user_adlo?.last_name}`,
            [AssessmentsTableFields.TakenBy]: `${item.user?.first_name ?? ""} ${item.user?.last_name ?? ""}`,
            [AssessmentsTableFields.object]: item,
          }
        }
        )
        .filter(item => !statusFilter || statusFilter === AssessmentStatus.All || item[AssessmentsTableFields.Status] === statusFilter)
        .reduce((acc: AssessmentType[], current: AssessmentType) => {
          const existing = acc.find(a => a[AssessmentsTableFields.Title] === current[AssessmentsTableFields.Title] && current[AssessmentsTableFields.Status] === AssessmentStatusLabels.Processing);
          if (existing) {
            // Compare dates and keep the most recent
            if (new Date(existing[AssessmentsTableFields.DateCompleted]) < new Date(current[AssessmentsTableFields.DateCompleted])) {
              acc.splice(acc.indexOf(existing), 1, current);
            }
          } else {
            acc.push(current);
          }
          return acc;
        }, []);
      setData(updatedData);
    }
  }, [rawData, statusFilter, carePlanGenerationStatus]);

  // Handlers
  function handleClick(_: Cell<AssessmentType, unknown>, row: Row<AssessmentType>) {
    setActiveUserAssessmentId(row.original[AssessmentsTableFields.object].id)
    setUserAssessment(row.original[AssessmentsTableFields.object])
  }

  return (
    <>
      <AssessmentComponent
        viewModeOptions={{
          userAssessmentId: userAssessment?.id,
          isInViewMode: userAssessment?.status !== AssessmentStatus.Draft,
        }}
        isOpen={!!userAssessment?.id}
        onClose={() => setUserAssessment(undefined)}
      />
      <InfiniteScrollingTable
        data={data}
        mobileColumns={[AssessmentsTableFields.Title, AssessmentsTableFields.Status]}
        hiddenColumns={[AssessmentsTableFields.object]}
        CellContent={({ cell, row }) => <CellContent cell={cell} row={row} />}
        columnFields={Object.values(AssessmentsTableFields)}
        handleClick={handleClick}
      />
    </>
  )
}



function CellContent({ cell, row }: { cell: Cell<AssessmentType, unknown>, row: Row<AssessmentType> }) {

  function getStatusColor(status: string) {
    switch (status) {
      case AssessmentStatusLabels[AssessmentStatus.New]:
        return 'bg-[#8690EE]'
      case AssessmentStatusLabels[AssessmentStatus.Completed]:
        return 'bg-[#78D08F]'
      case AssessmentStatusLabels[AssessmentStatus.ImmediateAttention]:
        return 'bg-[#E06666]'
      case AssessmentStatusLabels[AssessmentStatus.Draft]:
        return 'bg-[#64BAEB]'
      case AssessmentStatusLabels.Processing:
        return 'bg-[#9C9191]'

    }
  }
  switch (cell.column.id) {
    case AssessmentsTableFields.Title:
      return (
        <p className={`rounded-full truncate text-left w-[160px] w-min-[160px] max-w-[160px] md:w-[300px] md:w-min-[300px] md:max-w-[300px]`}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </p>
      );
    case AssessmentsTableFields.Status:
      const status = row.original.Status;
      return (
        <p className={`rounded-full text-white text-xs py-1 text-center whitespace-nowrap px-4 ${getStatusColor(status)} w-[120px] w-min-[120px] max-w-[120px]`}>
          {status}
        </p>
      );
    default:
      return (
        <p className={`rounded-full truncate text-left w-[200px] w-min-[200px] max-w-[200px]`}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </p>
      );
  }
}

function getAssessmentStatusLabel(item: PastAssessment, carePlanGenerationStatus: CarePlanGenerationStatuses | undefined) {

  if (carePlanGenerationStatus === CarePlanGenerationStatuses.PENDING && !item.assessment?.is_for_screening && item.status !== AssessmentStatus.Draft) {
    return AssessmentStatusLabels.Processing;
  } else if (!item.status) {
    return AssessmentStatusLabels.Completed;
  } else {
    return AssessmentStatusLabels[item.status as AssessmentStatus];
  }
}


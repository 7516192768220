import { formatDistance } from "date-fns";
import { useParams } from "react-router-dom";
import type { ChatGptMessage } from "../../backend/resources/chatGptMessage";
import { useChatGptMessage } from "../../backend/resources/chatGptMessage";
import { useBookmarkQueryByBookmarkId } from "../../backend/resources/userBookmark";
import type {
  AmazonMetadata,
  NewsMetadata,
  YouTubeMetadata,
} from "../../backend/resources/userGuidanceResource";
import { useUserGuidanceResource } from "../../backend/resources/userGuidanceResource";
import { CarePilotRoute, useAppNavigate, useNavigateBack } from "../../lib/routing";
import { ActionBar } from "../ActionBar";
import AmazonCarousel from "../AmazonCarousel/AmazonCarousel";
import BackButton from "../BackButton/BackButton";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
import { renderContentWithBreaks } from "../ChatGptSideBar/Message/utils";
import FooterButtons from "../FooterButtons/FooterButtons";
import {
  getBookmarkType,
  getIdOfBookmarkedItem,
} from "../LibraryPage/Bookmarks/Bookmark/utils";
import { PageContainer } from "../PageContainer";
import { PageMainHeader } from "../PageMainHeader";
import { YouTubeVideo } from "../YouTubeVideo";

export default function BookmarkDetailPage() {
  const navigate = useAppNavigate();
  const navigateBack = useNavigateBack();
  const { id: bookmarkId } = useParams();
  const { bookmark } = useBookmarkQueryByBookmarkId(bookmarkId);

  const { userGuidanceResource } = useUserGuidanceResource(
    bookmark?.user_guidance_resource_id
  );
  const { message } = useChatGptMessage(bookmark?.chat_gpt_message_id);
  const bookmarkType = message
    ? "chatGpt"
    : userGuidanceResource?.guidance_resource?.type;

  if (!bookmark) return null;
  return (
    <PageContainer>
      <div className="min-h-full flex flex-col justify-between pb-40">
        <div>
        <BackButton  />
          <div className="relative mt-4 md:mb-8">
            <PageMainHeader
              text={
                bookmarkType === "news"
                  ? "News Article"
                  : bookmarkType === "chatGpt"
                    ? "Message Snippet"
                    : ""
              }
            />
            <ActionBar
              bookmarkableItem={{
                itemId: getIdOfBookmarkedItem(bookmark),
                type: getBookmarkType(bookmark),
              }}
            />
          </div>
          {bookmarkType === "chatGpt" ? (
            <AlfredBookmarkComponent message={message} />
          ) : bookmarkType === "news" ? (
            <NewsBookmarkDetailComponent
              metadata={
                userGuidanceResource?.guidance_resource
                  ?.metadata as NewsMetadata
              }
            />
          ) : bookmarkType === "YouTube" ? (
            <YoutubeBookmarkComponent
              metadata={
                userGuidanceResource?.guidance_resource
                  ?.metadata as YouTubeMetadata
              }
            />
          ) : bookmarkType === "Amazon" ? (
            <AmazonBookmarkComponent
              metadata={
                userGuidanceResource?.guidance_resource
                  ?.metadata as AmazonMetadata
              }
              title={userGuidanceResource?.guidance_resource?.name}
            />
          ) : null}
        </div>
        {bookmarkType !== "chatGpt" ? (
          <FooterButtons>
            <div className="pb-14">
              <ButtonWithIcon
                onClick={() => {
                  if (
                    userGuidanceResource?.guidance_resource?.type === "news"
                  ) {
                    window.location.href = (
                      userGuidanceResource.guidance_resource
                        ?.metadata as NewsMetadata
                    ).url;
                  } else {
                    if (userGuidanceResource && userGuidanceResource.user_intervention_id) {
                      navigate({
                        path: CarePilotRoute.INTERVENTION,
                        params: {
                          id: userGuidanceResource.user_intervention_id
                        },
                        queryParams: {
                          "show-go-to-recommendation-button": String(true)
                        }
                      })
                    }
                  }
                }}
                text={"Source"}
                icon={IconOption.ARROW}
              />
            </div>
          </FooterButtons>
        ) : null}
      </div>
    </PageContainer>
  );
}

function AlfredBookmarkComponent({
  message,
}: {
  message: ChatGptMessage | undefined | null;
}) {
  return (
    <div className="flex items-center flex-col min-w-0 gap-3 mt-5">
      <p className="bg-gray-50 pt-4 text-sm px-4 py-2 rounded-md">
        {renderContentWithBreaks(message?.content ?? "")}
      </p>
    </div>
  );
}

function AmazonBookmarkComponent({
  metadata,
  title,
}: {
  metadata: AmazonMetadata | undefined;
  title?: string | null;
}) {
  return (
    <div className="md:px-10 flex flex-col items-center gap-4 md:gap-20">
      <p className="text-center font-medium">{title}</p>
      <div className="md:max-w-[66%] w-full">
        <AmazonCarousel metadata={metadata as AmazonMetadata} />
      </div>
    </div>
  );
}

function YoutubeBookmarkComponent({ metadata }: { metadata: YouTubeMetadata }) {
  return (
    <div className="flex flex-col gap-1  mt-5 h-[40vh] items-center w-full">
      <YouTubeVideo
        url={`https://www.youtube.com/embed/${metadata?.youTubeMetadata?.id}`}
      />
      <p className="text-sm text-center">{metadata?.youTubeMetadata?.title}</p>
    </div>
  );
}

function NewsBookmarkDetailComponent({
  metadata,
}: {
  metadata: NewsMetadata | undefined;
}) {
  const thumbnailUrl = metadata?.image?.thumbnail.contentUrl
    ? `${metadata?.image?.thumbnail.contentUrl}&h=250&p=0`
    : undefined;
  if (!thumbnailUrl) return null;
  return (
    <button
      className="px-8 md:px-0 mt-5 text-left items-start w-full flex  flex-col md:flex-row gap-4"
      onClick={() => {
        if (metadata?.url) window.location.href = metadata?.url;
      }}>
      <img
        src={thumbnailUrl}
        alt={metadata?.name}
        className="flex object-cover object-left rounded-md h-[40vh]"
      />
      <div className="flex flex-col md:justify-between md:h-[40vh]">
        <p className="text-xl mt-2 w-full font-medium flex">{metadata?.name}</p>
        <div className="items-end  w-full flex justify-between mt-5">
          <p className="line-clamp-1 w-full text-xs">
            {metadata?.provider[0].name}
          </p>
          <p className="text-xs md:text-sm text-right w-full items-end">
            {formatDistance(
              new Date(metadata?.datePublished || Date.now()),
              new Date(),
              {
                addSuffix: true,
              }
            ).replace("about", "")}
          </p>
        </div>
      </div>
    </button>
  );
}

import { useAlfredPageSideBar } from "../../backend/resources/chatGptConversation";
import { useOrgs } from "../../backend/resources/orgRole";
import { LoadingSpinner } from "../LoadingSpinner";
import CareCentralHome from "./CareCentralHome/CareCentralHome";
import { CaregiverHome } from "./CaregiverHome";

export function HomePage() {
  useAlfredPageSideBar("homePage")
  const { isLoading: isUserAccessLoading, hasCareCentralAccess } = useOrgs();

  if (isUserAccessLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner className="w-20 h-20" />
      </div>
    );
  } else if (hasCareCentralAccess) {
    return <CareCentralHome />;
  } else {
    return <CaregiverHome />
  }
}

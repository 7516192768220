import { useNetworksInOrganization } from "../../../backend/resources/network/network";
import { NetworkRoleType } from "../../../backend/resources/userRole";
import { CareCentralRoute, useAppNavigate } from "../../../lib/routing";

export default function CarespacesTab() {
  const navigate = useAppNavigate();

  const { networks } = useNetworksInOrganization();

  return (
    <div className="gap-4 grid grid-cols-[2fr,1.5fr,1fr] items-center text-xs md:text-base">
      <p className="font-medium">Name</p>
      <p className="font-medium text-center">Patient(s)</p>
      <p className="font-medium text-center">CN(s)</p>
      {networks?.map((network) => (
        <>
          <button
            className="text-start"
            onClick={() =>
              navigate({
                path: CareCentralRoute.CARESPACE,
                params: {
                  carespaceId: network.id
                }
              })
            }>
            {network.name}
          </button>
          <button
            className="line-clamp-1"
            onClick={() => navigate({
              path: CareCentralRoute.CARESPACE,
              params: {
                carespaceId: network.id
              }
            })}>
            {network.user_adlo.map((adlo) => adlo.first_name).join(", ")}
          </button>
          <button
            onClick={() => navigate({
              path: CareCentralRoute.CARESPACE,
              params: {
                carespaceId: network.id
              }
            })}>
            <p className="line-clamp-1">
              {network.user_role
                .filter((user_role) => user_role.role === NetworkRoleType.CARE_NAVIGATOR)
                .map((user_role) => user_role?.user?.first_name)
                .join(", ")}
            </p>
          </button>
        </>
      ))}
    </div>
  );
}

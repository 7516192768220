import { zodResolver } from "@hookform/resolvers/zod"
import { outlinedInputClasses } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { UseFormReturn, useForm } from "react-hook-form"
import { z } from "zod"
import { Select } from "../../components/Select"
import { useWindowSize } from "../../hooks/useWindowSize"
import { CustomFormField, Form, FormRoot } from "../ui/form"

export enum RespiteServiceActivityLocation {
  "In Home" = "In Home",
  "Adult Day Center" = "Adult Day Center",
  "Facility Based" = "Facility Based",
}

export const RespiteServiceActivityDateSchema = z.object({
  date_of_service: z.string().refine(date => !dayjs(date).isAfter(dayjs(), 'day'), {
    message: "Date of service cannot be later than today",
  }),
  hours_of_service: z.number().min(1).max(20),
  location: z.nativeEnum(RespiteServiceActivityLocation),
})

export type RespiteServiceActivityDateType = z.infer<typeof RespiteServiceActivityDateSchema>
export type AddRespiteServiceActivityFormHook = UseFormReturn<RespiteServiceActivityDateType>

export const useAddRespiteServiceActivityForm = () => useForm<RespiteServiceActivityDateType>({
  resolver: zodResolver(RespiteServiceActivityDateSchema),
  mode: "onSubmit",
  defaultValues: {
    date_of_service: dayjs().toDate().toDateString(),
    hours_of_service: undefined,
    location: undefined,
  }
});

export default function AddRespiteServiceActivityForm({ form }: { form: AddRespiteServiceActivityFormHook }) {
  const { isMobile } = useWindowSize()
  // Constants
  const hourOptions = Array.from({ length: 20 }, (_, i) => ({
    label: (i + 1).toString(),
    value: i + 1,
  }))

  const locationOptions = Object.values(RespiteServiceActivityLocation).map((location) => ({
    label: location,
    value: location,
  }))

  return <div className="flex flex-col gap-2">
    <Form {...form} >
      <FormRoot id={"add-respite-service-activity-form"} >
        <CustomFormField
          labelName="Date of Service"
          form={form}
          fieldName="date_of_service"
        >
          {(field) =>
            <DatePicker
              value={dayjs(field.value)}
              disableOpenPicker={!isMobile}
              maxDate={dayjs()}
              onChange={date => {
                field.onChange(date?.toDate().toDateString())
              }}
              slotProps={{
                field: {
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  },
                },
                textField: {
                  sx: {
                    fieldset: {
                      borderRadius: 2, borderColor: '#E4E4E7',
                    },
                    [`.${outlinedInputClasses.root}`]: {
                      height: 40,
                      width: 165,
                      fontSize: 14,
                      fontWeight: 400,
                      fontFamily: 'Poppins',
                    },
                  },
                },
              }} />}
        </CustomFormField>

        <CustomFormField
          labelName="Hours of Service"
          form={form}
          fieldName="hours_of_service"
        >
          {(field) =>
            <Select
              placeHolder="Please select..."
              classNames={`p-0.5 pr-2 h-[2.5rem] w-full bg-white`}
              borderClass="border-2 border-faint-gray"
              currentOption={hourOptions.find(
                (option) => option.value === field.value
              )}
              options={hourOptions}
              onChange={
                (option) => form.setValue("hours_of_service", parseInt(option))
              }
            />
          }
        </CustomFormField>

        <CustomFormField
          labelName="Location"
          form={form}
          fieldName="location">
          {(field) =>
            <Select
              placeHolder="Please select..."
              classNames={`p-0.5 pr-2 h-[2.5rem] w-full bg-white`}
              borderClass="border-2 border-faint-gray"
              currentOption={locationOptions.find(
                (option) => option.value === field
              )}
              options={locationOptions}
              onChange={
                (option) => form.setValue("location", option as RespiteServiceActivityLocation)
              }
            />
          }
        </CustomFormField>
      </FormRoot>
    </Form >
  </div>
}

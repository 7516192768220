import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useOrgs } from "../../../../backend/resources/orgRole";
import type { ShareableInterventionType } from "../../../../backend/resources/shareableIntervention/shareableIntervention";
import { useShareableInterventionsFromShareableRecommendationId } from "../../../../backend/resources/shareableIntervention/shareableIntervention";
import { useShareableRecommendationsFromShareableRecommendationIds } from "../../../../backend/resources/shareableRecommendation/shareableRecommendation";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { CareCentralRoute, useAppNavigate, useNavigateBack } from "../../../../lib/routing";
import BackButton from "../../../BackButton/BackButton";
import BottomActionBar from "../../../BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "../../../ButtonWithIcon";
import { GoalRecSuggestionsBreadcrumbs } from "../../../GoalPage/GoalRecSuggestionsBreadcrumbs";
import { PageContainer } from "../../../PageContainer";
import { PageMainHeader } from "../../../PageMainHeader";
import ShareableIntervention from "./ShareableRecommendation/ShareableIntervention/ShareableIntervention";

export default function ShareableRecommendationPage() {
  const { id: recommendationId } = useParams();
  const { isMobile } = useWindowSize();
  const [state, setState] = useState<{
    title: string;
    details: string;
    interventions: ShareableInterventionType[];
  }>({
    title: "",
    details: "",
    interventions: [],
  });
  const { hasCareCentralAccess } = useOrgs();

  const { data: recommendationData } =
    useShareableRecommendationsFromShareableRecommendationIds(
      recommendationId ? [recommendationId] : []
    );
  const recommendation = recommendationData?.[0];
  const navigate = useAppNavigate();
  const navigateBack = useNavigateBack();

  const { data: interventionsData } =
    useShareableInterventionsFromShareableRecommendationId(recommendationId);

  useEffect(() => {
    if (recommendationId && interventionsData && recommendation) {
      setState({
        title: recommendation.title,
        details: recommendation.details,
        interventions: interventionsData,
      });
    }
  }, [interventionsData, recommendation]);

  return (
    <PageContainer>
      <div className="flex gap-4 flex-col">
        <BackButton />

        <GoalRecSuggestionsBreadcrumbs />

        <div className="flex gap-5 items-center -mt-3">
          <PageMainHeader text={state.title} />
          {!isMobile && hasCareCentralAccess && (
            <ButtonWithIcon
              onClick={() => {
                if (recommendationId) {
                  navigate({
                    path: CareCentralRoute.SHAREABLE_RECOMMENDATION_ADD_ID,
                    params: {
                      id: recommendationId
                    }
                  })
                }
              }}
              text={"Edit Recommendation"}
              size={"small"}
              icon={IconOption.EDIT}
            />
          )}
        </div>
        <h1>{state.details.replace('"', "").replace('"', "")}</h1>
        <div className="flex w-full">Suggestions ({state.interventions?.length || 0})</div>
        <div className="flex flex-wrap gap-4 justify-center md:justify-start pb-40">
          {/* if there's a recommendationId, we show */}
          {state.interventions
            ?.sort((a, b) => {
              return a.title.localeCompare(b.title);
            })
            ?.map((intervention) => (
              <ShareableIntervention intervention={intervention} />
            ))}
        </div>
      </div>
      <BottomActionBar
        centerSlot={
          <ButtonWithIcon
            className="bg-white rounded-full"
            onClick={() => {
              if (recommendationId) {
                navigate({
                  path: CareCentralRoute.SHAREABLE_RECOMMENDATION_ADD_ID,
                  params: {
                    id: recommendationId
                  }
                })
              }
            }}
            text={""}
            icon={IconOption.EDIT}
          />
        }
      />
    </PageContainer>
  );
}

import { ReactComponent as InformationSvg } from "../../../assets/info-circle.svg";
import { PopupOrSheet } from "../../../shared/ui/popup-bottom-sheet";
import { ButtonWithIcon, IconOption } from "../../ButtonWithIcon";

interface Props {
  onClose: () => void;
}
export default function AssessmentEmptyAnswersPage({ onClose }: Props) {
  return (
    <PopupOrSheet
      isOpen={true}
      onClose={onClose}
      icon={<InformationSvg className="w-10 h-10" />}
      footerButtons={
        <ButtonWithIcon
          className="text-xs md:text-base"
          onClick={onClose}
          size={"small"}
          text={"OK"}
          icon={IconOption.CHECKMARK}
        />
      }
      title={"Empty Assessment"}
    >
      <p className="text-center">Please answer at least one of the questions before submitting.</p>
    </PopupOrSheet>
  )
}

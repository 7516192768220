import { useMutation, useQuery } from "@tanstack/react-query";
import { Database } from "../../../../types/supabase";
import { queryClient } from "../../../App";
import { supabase } from "../../../clients/supabaseClient";
import { useActiveOrganizationId } from "../../../state/organization/organization";
import { fetchAllDataInChunks } from "../../../utils";
import { QUERY_KEYS } from "../../queryKeys";
import { TaskStatus, useUpdatePlanEntry } from "../planEntry";

const TABLE = "guide_event"
export type GuideEvent = Database["public"]["Tables"]["guide_event"]["Row"] & { network: Database["public"]["Tables"]["network"]["Row"] | null };
export type GuideEventInsert = Database["public"]["Tables"]["guide_event"]["Insert"];


export function useGuideEvents() {
  const activeOrgId = useActiveOrganizationId();
  return useQuery({
    queryKey: [QUERY_KEYS.guideEvent, { activeOrgId }],
    queryFn: async () => {
      const queryBuilder = supabase
        .from(TABLE)
        .select("*, network(*)");


      const allData = await fetchAllDataInChunks<GuideEvent>(queryBuilder);
      const data = allData.filter(item => item.network?.organization_id === activeOrgId);

      return data || [];
    },
  }
  );
}

export function useGuideEvent(guideEventId: string | undefined | null) {
  const activeOrgId = useActiveOrganizationId();
  return useQuery({
    queryKey: [QUERY_KEYS.guideEvent, { activeOrgId, guideEventId }],
    queryFn: async () => {
      if (!guideEventId) return null;
      const { data, error } = await supabase
        .from(TABLE)
        .select("*, network(*)")
        .eq('id', guideEventId)
        .limit(1)
        .maybeSingle();
      return data
    },
  });
}


export function useGuideEventByPlanEntryId(perform_event_plan_entry_id: string | undefined | null) {
  const activeOrgId = useActiveOrganizationId();
  return useQuery({
    queryKey: [QUERY_KEYS.guideEvent, { activeOrgId, perform_event_plan_entry_id }],
    queryFn: async () => {
      if (!perform_event_plan_entry_id) return null;
      const { data, error } = await supabase
        .from(TABLE)
        .select("*, network(*)")
        .eq('perform_event_plan_entry_id', perform_event_plan_entry_id)
        .limit(1)
        .maybeSingle();
      return data
    },
  });
}


export function useUpdateGuideEvent() {
  const savePlanEntry = useUpdatePlanEntry().mutateAsync
  return useMutation({
    mutationFn: async (updateData: GuideEvent) => {
      const { network, ...updateDataWithoutNetwork } = updateData;
      const { data, error } = await supabase
        .from(TABLE)
        .update(updateDataWithoutNetwork)
        .eq("id", updateDataWithoutNetwork.id);

      if (updateDataWithoutNetwork.perform_event_plan_entry_id) {
        await savePlanEntry({
          id: updateDataWithoutNetwork.perform_event_plan_entry_id,
          status: TaskStatus.Done
        })
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.guideEvent] });
    },
  }
  );
}

export function useCreateGuideEvent() {
  const createGuideEvent = async (guideEvent: GuideEventInsert) => {
    const { data, error } = await supabase
      .from(TABLE)
      .insert(guideEvent)
      .single();
    if (error) {
      throw error;
    }
    return data;
  };

  return useMutation({
    mutationFn: createGuideEvent,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.guideEvent] });
    },
  });
}


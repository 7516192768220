// add provider

import { useParams } from "react-router-dom";
import { z } from "zod";
import { NotificationSection, useUpsertNotificationPreferenceMutation } from "../../backend/resources/notificationPreferences/notificationPreferences";
import { OrgRoleType, OrgRoleTypeToLabel } from "../../backend/resources/orgRole";
import { useInsertUserRole } from "../../backend/resources/userRole";
import { SharedRoute, useAppNavigate } from "../../lib/routing";
import { AddOrgMemberNavigatorForm, orgMemberSchema, useAddOrgMemberForm } from "../../shared/forms/CarespaceCreationWizard/AddOrgMemberNavigatorForm";
import { ResponsiveModal } from "../../shared/ui/responsive-modal";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";

export function AddProviderView({ roleType, onClose }: { roleType: OrgRoleType, onClose: () => void }) {
  const PAGE_TITLE = `Add ${OrgRoleTypeToLabel[roleType]}`;
  const form = useAddOrgMemberForm()
  const formValues = form.watch()
  const navigate = useAppNavigate()

  const { carespaceId } = useParams();

  const insertUserRole = useInsertUserRole().mutateAsync;
  const upsertUserPreference = useUpsertNotificationPreferenceMutation().mutateAsync;

  async function handleSubmit(validatedForm: z.infer<typeof orgMemberSchema>) {
    const {
      org_role,
      user_id
    } = validatedForm.orgMember

    const roleRes = await insertUserRole({
      userRoleInsert: {
        is_deactivated: false,
        network_id: carespaceId,
        role: org_role,
        user_id: user_id,
      },
    });

    const createdRole = roleRes?.[0];

    if (createdRole && createdRole.network_id) {
      // 2 - create notification preferences for the provider
      const notificationPromises = Object.values(NotificationSection).map((section) => {
        return upsertUserPreference({
          user_id: user_id,
          section_name: section as any,
          email: true,
          sms: false,
          push: true,
        });
      });
      await Promise.all(notificationPromises);
    }
    onClose()
  }

  return (
    <ResponsiveModal
      isOpen={true}
      title={PAGE_TITLE}
      closeText="Back"
      onClose={() => {
        onClose()
        form.reset()
      }}
      footerButtons={
        <>
          <ButtonWithIcon
            onClick={onClose}
            text="Cancel"
            icon={IconOption.CANCEL}
          />
          <ButtonWithIcon
            text="Done"
            icon={IconOption.CHECKMARK}
            onClick={async () => {             
              form.handleSubmit((values) => {
                handleSubmit(values)
                onClose()
              })()
            }}
            disabled={
              !Object.values(formValues).every((value) => value)
            }
          />
        </>
      }
    >
      <AddOrgMemberNavigatorForm form={form} roleType={roleType} />
      <div className="flex w-full py-4">
        <ButtonWithIcon
          size={"small"}
          onClick={() => {
            navigate({
              path: SharedRoute.ADMIN
            })
          }}
          text="Go to Organization"
          icon={IconOption.ARROW}
        />
      </div>
    </ResponsiveModal>
  )
}
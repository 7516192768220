import * as RadixCheckbox from "@radix-ui/react-checkbox";
import { useId } from "react";
import { Checkmark } from "../../icons/Checkmark";

interface Props {
  isChecked: boolean;
  onCheck?: (isChecked: boolean) => void;
  disabled?: boolean;
  label?: string;
  sizeClasses?: string;
  isRounded?: boolean
}

export function Checkbox({
  isChecked,
  onCheck,
  disabled,
  label,
  sizeClasses,
  isRounded
}: Props) {
  const id = useId();
  return (<div className="flex gap-2 items-center">
    <div
      className={`flex items-center gap-2 w-fit border
          ${isChecked ? "bg-normal-green border-border-gray" : "transparent border-black"}
          ${disabled && 'saturate-0'}
          ${!isChecked && !disabled && "hover:bg-gray-200"}
          ${isRounded ? "rounded-full overflow-clip" : "rounded-md"}`
      }>
      <RadixCheckbox.Root
        className={`flex items-center justify-center ${sizeClasses ?? "h-6 w-6"}`}
        checked={isChecked}
        onCheckedChange={(checked: boolean) => {
          onCheck?.(checked);
        }}
        onClick={(e) => e.stopPropagation()}
        disabled={disabled}
        id={id}>
        <RadixCheckbox.Indicator>
          <Checkmark size="10" fill="white" />
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
    </div>
    {label && (
      <label
        className="text-sm font-light"
        htmlFor={id}>
        {label}
      </label>
    )}
  </div>
  );
}

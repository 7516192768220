import { OrgAndIdentityReturnType } from "../backend/resources/orgRole";

export function checkCareCentralAccess(
  data: OrgAndIdentityReturnType[] | null | undefined
) {
  // if the user is a member of at least one non-family org, they have care central access
  if (
    data &&
    data?.some((orgIdentity) => {
      return (
        orgIdentity.organization &&
        !orgIdentity.organization?.is_family_organization
      );
    })
  ) {
    return true;
  }
  // else, they do not have access
  return false;
}

export function checkIsSuperSuperUser(
  self_user_id?: string,
  data?: OrgAndIdentityReturnType[] | null
) {
  if (
    data &&
    data?.some(
      (orgIdentity) =>
        orgIdentity.organization?.is_super_org &&
        orgIdentity.user_id === self_user_id
    )
  ) {
    return true;
  }
  // else, they do not have access
  return false;
}

import { useWindowSize } from "../../../hooks/useWindowSize";
import AlfredIcon from "../../Alfred/Alfred";
import { CollapseButton } from "../ChatGptSideBar";
import { MessagesThread } from "../MessagesThread";

export function SideBarServicesPage() {
  const { isMobile } = useWindowSize();
  return (
    <div className="flex flex-col max-h-full h-full w-full">
      <div className="py-3 px-2">{!isMobile && <CollapseButton />}</div>
      <MessagesThread
        threadId={"servicesPage"}
        pageType="servicesPage">
        <InitialContent />
      </MessagesThread>
    </div>
  );
}

function InitialContent() {
  return (
    <div className="flex min-w-0 gap-3 pb-6">
      <AlfredIcon className="flex-shrink-0 w-8 h-8 mt-1" />
      {/* text and action buttons */}
      <div className="flex flex-col gap-6 bg-gray-50  text-sm px-4 py-4 rounded-md">
        {/* intro PP */}
        <p>
          This is the <strong>Services</strong> section where you can search for local resources and then ask your Care Team to help you contact and coordinate with that resource.
        </p>
        <p>
          When you request help, it will become a service request that you can monitor and interact with to track progress. 
        </p>
        <p>
          Finally, if you and the Care Team decide it is the right resource for you, it will then become an active service (if applicable).
        </p>
      </div>
    </div>
  );
}

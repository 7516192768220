import { useEffect } from "react";
import { useUserIsFamilyMember } from "../../backend/resources/userRole";
import { CarePilotRoute, SharedRoute, useAppNavigate } from "../../lib/routing";

export function HomeRedirect() {
  const navigate = useAppNavigate();
  const { userIsFamilyMember, isLoadingUserIsFamilyMember } =
    useUserIsFamilyMember();
  useEffect(() => {
    navigate({
      path:
        userIsFamilyMember || isLoadingUserIsFamilyMember
          ? CarePilotRoute.FAMILY_HUB
          : SharedRoute.HOME,
    });
  }, []);

  return null;
}

// ... other imports

import { Cell, Row, flexRender } from "@tanstack/react-table";
import { formatDistance } from "date-fns";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { ConversationType } from "../../backend/resources/chatGptConversation";
import { DiscussionMessage } from "../../backend/resources/chatGptMessage";
import { useNetworksInOrganization } from "../../backend/resources/network/network";
import { useWindowSize } from "../../hooks/useWindowSize";
import { CarePilotRoute, SharedRoute, useAppNavigate } from "../../lib/routing";
import { useNetworkStore } from "../../state/network/network";
import { setActiveUserUpdateId } from "../../state/userUpdate/userUpdate";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
import { DiscussionFields, DiscussionFilterTypes, DiscussionTableType, DiscussionTypeToColor } from "../DiscussionComponent/components/DiscussionsFilter";
import { useDiscussionsFilteredData } from "../DiscussionComponent/utils";
import { ServicePageTabs } from "../ServicePage/ServicePage";
import { InfiniteScrollingTable } from "./InfiniteScrollingTable";

interface DiscussionTableProps {
  data: DiscussionMessage[];
  hiddenColumns?: DiscussionFields[];
  maxHeight?: string;
  latestMessageWidth?: string;
}

const filterConfig = {
  [DiscussionFilterTypes.CARESPACE]: true,
  [DiscussionFilterTypes.TYPE]: true
}
export default function DiscussionTable({ data: rawData, hiddenColumns, maxHeight, latestMessageWidth = "150px" }: DiscussionTableProps) {
  const navigate = useAppNavigate();
  const { isMobile } = useWindowSize();

  const { networks } = useNetworksInOrganization();
  const setActiveNetworkId = useNetworkStore(
    (state) => state.setActiveNetworkId
  );
  const columnFields = Object.values(DiscussionFields);

  const [data, setData] = useState<DiscussionTableType[]>([]);

  const filteredData = useDiscussionsFilteredData({
    data: rawData,
    config: filterConfig,
  });

  useEffect(() => {
    // Updates data
    if (filteredData) {
      const readData = filteredData.filter(message => message !== undefined).map(message => {
        const networkName = networks?.find((network) => network.id === message?.network_id)?.name ?? ""
        return {
          [DiscussionFields.isUnread]: message?.read_message?.length === 0,
          [DiscussionFields.SentAt]: message.created_at,
          [DiscussionFields.LatestMessage]: message?.content,
          [DiscussionFields.Title]: message?.title,
          [DiscussionFields.Type]: message?.type as ConversationType,
          [DiscussionFields.Carespace]: networkName,
          [DiscussionFields.UNDERLYING_OBJECT_ID]: message.id,
          [DiscussionFields.network_id]: message?.network_id,
          [DiscussionFields.isExternal]: !!message.external_participant_id,
          [DiscussionFields.Id]: message.conversation_id,
          [DiscussionFields.SentBy]: message.sent_by
        }
      })
      const sortedData = readData.sort((a, b) => Date.parse(b[DiscussionFields.SentAt]) - Date.parse(a[DiscussionFields.SentAt]));
      const uniqueData = Array.from(new Set(sortedData.map(item => item[DiscussionFields.Id])))
        .map(id => sortedData.find(item => item[DiscussionFields.Id] === id)) as DiscussionTableType[];
      setData(uniqueData);
    }
  }, [filteredData]);

  function handleClick(_: Cell<DiscussionTableType, unknown>, row: Row<DiscussionTableType>) {
    setActiveNetworkId(row.original[DiscussionFields.network_id]);
    if (ConversationType.Request === row.original[DiscussionFields.Type]) {
      navigate({
        path: SharedRoute.SERVICE_REQUEST_VIEW,
        params: { id: row.original[DiscussionFields.UNDERLYING_OBJECT_ID] },
        queryParams: {
          tab: row.original[DiscussionFields.isExternal] ? ServicePageTabs.EXTERNAL : ServicePageTabs.INTERNAL
        }
      });
    } else if (ConversationType.Service === row.original[DiscussionFields.Type]) {
      navigate({
        path: SharedRoute.SERVICE_PAGE,
        params: { id: row.original[DiscussionFields.UNDERLYING_OBJECT_ID] },
        queryParams: {
          tab: row.original[DiscussionFields.isExternal] ? ServicePageTabs.EXTERNAL : ServicePageTabs.INTERNAL
        }
      });
    } else if (ConversationType.Family === row.original[DiscussionFields.Type]) {
      setActiveUserUpdateId(row.original[DiscussionFields.UNDERLYING_OBJECT_ID]);
      navigate({
        path: CarePilotRoute.FAMILY_HUB,
      });
    } else if (ConversationType.Private === row.original[DiscussionFields.Type]) {
      navigate({
        path: CarePilotRoute.CONVERSATION,
        params: { conversation_id: row.original[DiscussionFields.Id] },
      });
    } else {
      navigate({
        path: CarePilotRoute.GENERAL_DISCUSSION,
      });
    }
  }

  function CellContent({ cell, row }: { cell: Cell<DiscussionTableType, unknown>, row: Row<DiscussionTableType> }) {
    switch (cell.column.id) {
      case DiscussionFields.isUnread:
        return <div className="relative w-[26px] md:w-[42px]">
          {row.original[DiscussionFields.isUnread] ? (
            <div className="rounded-full bg-blue-600 absolute top-0 left-5 w-2 h-2" />
          ) : null}
          <ButtonWithIcon
            className="text-start text-sm"
            onClick={() => { }}
            size="small"
            text=""
            icon={IconOption.MESSAGE}
          />
        </div>;
      case DiscussionFields.Title:
        return <p className=" w-[160px] min-w-[160px] max-w-[160px] md:w-[300px] md:w-min-[300px] md:max-w-[300px]">
          {row.original[DiscussionFields.Title]}
        </p>;
      case DiscussionFields.Carespace:
        return <p className="w-[200px] max-w-[200px]">
          {row.original[DiscussionFields.Carespace]}
        </p>;
      case DiscussionFields.Type:
        return <p
          onClick={() => handleClick(cell, row)}
          style={{ background: DiscussionTypeToColor[row.original[DiscussionFields.Type]] }}
          className="rounded-full text-white text-xs text-center px-4 w-[120px] max-w-[120px] py-1">
          {flexRender(cell.column.columnDef.cell, cell.getContext())} {row.original[DiscussionFields.isExternal] ? "-EXT" : ""}
        </p>;
      case DiscussionFields.LatestMessage:
        return <button
          className={` break-words w-[${latestMessageWidth}] max-w-[${latestMessageWidth}]`}
          onClick={() => handleClick(cell, row)}>
          <ReactMarkdown
            className="line-clamp-1  break-all text-left"
            components={{
              a: ({ node, children, ...props }) => (
                <a
                  {...props}
                  style={{ textDecoration: "underline", wordWrap: "break-word" }}
                  target="_blank">
                  {children}
                </a>
              ),
            }}>
            {row.original[DiscussionFields.LatestMessage]}
          </ReactMarkdown>
        </button>;
      case DiscussionFields.SentAt:
        return <p className="text-start text-sm w-[100px] max-w-[100px]">
          {formatDistance(new Date(row.original[DiscussionFields.SentAt] ?? Date.now()), new Date(), { addSuffix: true }).replace("about", "")}
        </p>;
      case DiscussionFields.SentBy:
        return <p className="w-[80px] max-w-[80px] md:w-[100px] md:max-w-[100px]">
          {row.original[DiscussionFields.SentBy]}
        </p>;
      default:
        return <button
          onClick={() => handleClick(cell, row)}>
          <p className="text-start text-sm w-[200px] max-w-[200px]">
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </p>
        </button>;

    }
  }

  return (
    <InfiniteScrollingTable
      data={data}
      maxHeight={maxHeight}
      headersToCenter={[DiscussionFields.Type]}
      mobileColumns={[DiscussionFields.Title, DiscussionFields.SentBy, DiscussionFields.isUnread]}
      hiddenColumns={[
        ...(hiddenColumns ?? []),
        DiscussionFields.UNDERLYING_OBJECT_ID,
        DiscussionFields.network_id,
        DiscussionFields.isExternal,
        DiscussionFields.Id
      ]}
      columnFields={columnFields}
      handleClick={handleClick}
      hiddenColumnHeaders={[DiscussionFields.isUnread]}
      CellContent={CellContent}
      emptyText="There are no messages"
    />
  );
}
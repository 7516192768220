import create from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import type { OrgRoleType } from "../../backend/resources/orgRole";
import { LocalStorageKeys } from "../localStorageKeys";

export type CareCentralOnboardingPageType =
  | "welcome"
  | "create profile"
  | "create org"
  | null;

export type CarePilotOnboardingPageType =
  | "welcome"
  | "sms approval"
  | "completed"
  | null;

type State = {
  // role form
  selectedOrgRole: OrgRoleType | null | undefined;
  isNetworkCallInProgress: boolean | undefined;
  // care pilot
  currentCarePilotOnboardingPage: CarePilotOnboardingPageType | undefined;
  // carecentral page index state
  currentCareCentralOnboardingPage: CareCentralOnboardingPageType | undefined;
  // carecentral page 1
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  cellNumber: string | null | undefined;
  profileImage: string | null | undefined;
  wouldLikeToReceiveSMSNotifications: boolean;
  // carecentral page 2
  orgName: string | undefined;
  orgAddress: string | undefined;
  orgCity: string | undefined;
  orgState: string | undefined;
  orgZip: string | undefined;
};

type Actions = {
  // role form
  setSelectedOrgRole: (orgRole: OrgRoleType) => void;
  setIsNetworkCallInProgress: (
    isNetworkCallInProgress: boolean | undefined
  ) => void;
  // care pilot
  setCurrentCarePilotOnboardingPage: (
    page: CarePilotOnboardingPageType
  ) => void;
  // carecentral
  setCareCentralOnboardingPage: (page: CareCentralOnboardingPageType) => void;
  // carecentral page 1
  setFirstName: (firstName: string | null) => void;
  setLastName: (lastName: string | null) => void;
  setCellNumber: (cellNumber: string) => void;
  setProfileImage: (profileImage: string) => void;
  setWouldLikeToReceiveSMSNotifications: (wouldLikeToReceiveSMSNotifications: boolean) => void;
  // carecentral page 2
  setOrgName: (orgName: string) => void;
  setOrgAddress: (orgAddress: string) => void;
  setOrgCity: (orgCity: string) => void;
  setOrgState: (orgState: string) => void;
  setOrgZip: (orgZip: string) => void;
  // shared

  reset: () => void;
};

const initialState: State = {
  // role form
  selectedOrgRole: undefined,
  isNetworkCallInProgress: undefined,
  // carecentral page index state
  currentCarePilotOnboardingPage: undefined,
  currentCareCentralOnboardingPage: undefined,
  // carecentral page 1
  firstName: undefined,
  lastName: undefined,
  cellNumber: undefined,
  profileImage: undefined,
  wouldLikeToReceiveSMSNotifications: false,
  // carecentral page 2
  orgName: undefined,
  orgAddress: undefined,
  orgCity: undefined,
  orgState: undefined,
  orgZip: undefined,
};

export const useAppOnboardingStore = create<State & Actions>()(
  persist(
    immer((set, get) => ({
      /**
       * state
       */
      ...initialState,

      /**
       * actions
       */

      // user role selection

      setSelectedOrgRole: (orgRole: OrgRoleType) =>
        set((state) => {
          state.selectedOrgRole = orgRole;
        }),

      setIsNetworkCallInProgress: (
        isNetworkCallInProgress: boolean | undefined
      ) =>
        set((state) => {
          state.isNetworkCallInProgress = isNetworkCallInProgress;
        }),

      // carepilot onboarding page index

      setCurrentCarePilotOnboardingPage: (page: CarePilotOnboardingPageType) =>
        set((state) => {
          state.currentCarePilotOnboardingPage = page;
        }),

      // carecentral onboarding page index state

      setCareCentralOnboardingPage: (page: CareCentralOnboardingPageType) =>
        set((state) => {
          state.currentCareCentralOnboardingPage = page;
        }),

      // carecentral page 1

      setFirstName: (firstName: string | null) =>
        set((state) => {
          state.firstName = firstName;
        }),

      setLastName: (lastName: string | null) =>
        set((state) => {
          state.lastName = lastName;
        }),

      setCellNumber: (cellNumber: string) =>
        set((state) => {
          state.cellNumber = cellNumber;
        }),

      setProfileImage: (profileImage: string) =>
        set((state) => {
          state.profileImage = profileImage;
        }),
      setWouldLikeToReceiveSMSNotifications: (wouldLikeToReceiveSMSNotifications: boolean) =>
        set((state) => {
          state.wouldLikeToReceiveSMSNotifications = wouldLikeToReceiveSMSNotifications;
        }),

      // carecentral page 2

      setOrgName: (orgName: string) =>
        set((state) => {
          state.orgName = orgName;
        }),

      setOrgAddress: (orgAddress: string) =>
        set((state) => {
          state.orgAddress = orgAddress;
        }),

      setOrgCity: (orgCity: string) =>
        set((state) => {
          state.orgCity = orgCity;
        }),

      setOrgState: (orgState: string) =>
        set((state) => {
          state.orgState = orgState;
        }),

      setOrgZip: (orgZip: string) =>
        set((state) => {
          state.orgZip = orgZip;
        }),

      reset: () => set(initialState),
    })),
    {
      name: LocalStorageKeys.appOnboarding,
      getStorage: () => localStorage,
    }
  )
);

// notes on the create<T>()() 'currying' syntax
// https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#basic-usage

import { Cell, Row, flexRender } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { ResponsiveModal } from '../../../shared/ui/responsive-modal';
import { ButtonWithIcon, IconOption } from '../../ButtonWithIcon';
import { CloseButton } from '../../CloseButton/CloseButton';
import { ProfileImage } from '../../Profile/ProfileImage';
import { InfiniteScrollingTable, TableDataRowType } from '../../Tables/InfiniteScrollingTable';
import { useUsersInActiveNetworkAndOrganization } from '../utils';


enum UserTableFields {
  USER_NAME = 'Name',
  USER_ID = 'User ID',
};

type BaseUserTableData = {
  [UserTableFields.USER_NAME]: string;
  [UserTableFields.USER_ID]: string;
};
type UserTableData = BaseUserTableData & TableDataRowType<BaseUserTableData>;

interface AttendeeChangeHandler {
  setAttendees: (attendees: string[]) => void;
}

interface AttendeeListProps {
  initialAttendeeIds: string[];
  editOptions?: AttendeeChangeHandler;
}


export default function AddAttendeesButtonPopup({ editOptions, initialAttendeeIds }: AttendeeListProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState(initialAttendeeIds);
  const [userTableData, setUserTableData] = useState<UserTableData[]>([]);

  // Queries
  const { usersInNetwork, } = useUsersInActiveNetworkAndOrganization()
  const stringifiedUsers = JSON.stringify(usersInNetwork);

  // Effects
  useEffect(() => {
    // initialize the selected users
    setSelectedUsers(initialAttendeeIds);
  }, [initialAttendeeIds]);

  useEffect(() => {
    // transform the users to the table data
    if (stringifiedUsers) {
      const users = usersInNetwork?.map((userWithRole) => ({
        [UserTableFields.USER_ID]: userWithRole.user.id,
        [UserTableFields.USER_NAME]: `${userWithRole.user.first_name} ${userWithRole.user.last_name} (${userWithRole.role})`,
      }))
      setUserTableData(users ?? []);
    }
  }, [stringifiedUsers]);

  // handlers
  const handleCheckboxChange = (userId: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedUsers([...selectedUsers, userId]);
    } else {
      setSelectedUsers(selectedUsers.filter(id => id !== userId));
    }
  };

  function handleCancel() {
    setIsOpen(false);
    setSelectedUsers(initialAttendeeIds);
  }

  return (
    <div>
      {editOptions ? (
        <ButtonWithIcon
          type='button'
          size={"small"}
          onClick={() => setIsOpen(true)}
          text="Add Attendee(s)"
          className='flex flex-row-reverse justify-end text-sm '
          icon={IconOption.PLUS}
        />
      ) : (
        <p>Attendee(s)</p>
      )}
      <div className="flex flex-col gap-1">
        {selectedUsers.length > 0 ? (
          usersInNetwork?.filter(user => selectedUsers.includes(user.user.id))
            .map((user) => (
              <div className="flex items-center gap-2">
                {editOptions ? (
                  <>
                    <button
                      type="button"
                      className="bg-red-500 rounded-full h-5 w-5 flex items-center justify-center "
                      onClick={() => {
                        editOptions.setAttendees(selectedUsers.filter(id => id !== user.user.id))
                      }}
                    >
                      <CloseButton />
                    </button>
                    <p key={user.user.id} className='text-sm'>{`${user.user.first_name} ${user.user.last_name} (${user.role})`}</p>
                  </>
                ) :
                  <div className='flex gap-1'>
                    <ProfileImage userId={user.user.id} size={20} />
                    <p key={user.user.id} className='text-sm'>{`${user.user.first_name} ${user.user.last_name} (${user.role})`}</p>
                  </div>
                }
              </div>
            ))
        ) : (
          <p className='text-sm text-zinc-500 mt-2'>None</p>
        )}
      </div>
      {/* Add Attendees modal */}
      <ResponsiveModal
        isOpen={isOpen}
        onClose={handleCancel}
        title="Add Attendees"
        footerButtons={<>
          <ButtonWithIcon
            onClick={handleCancel}
            text="Cancel"
            icon={IconOption.CANCEL}
          />
          <ButtonWithIcon
            onClick={() => {
              editOptions?.setAttendees(selectedUsers);
              setIsOpen(false);
            }}
            text="Done"
            icon={IconOption.CHECKMARK}
          />
        </>
        }
      >
        <InfiniteScrollingTable
          data={userTableData}

          checkboxOptions={{
            hideStrikeThrough: true,
            isChecked: (item: Row<UserTableData>) =>
              selectedUsers.includes(item.original[UserTableFields.USER_ID]),
            onCheck: (item: Row<UserTableData>, isChecked: boolean) =>
              handleCheckboxChange(item.original[UserTableFields.USER_ID], isChecked),
          }}
          columnFields={[
            UserTableFields.USER_NAME,
            UserTableFields.USER_ID
          ]}
          hiddenColumns={[
            UserTableFields.USER_ID
          ]}
          handleClick={(cell: Cell<UserTableData, unknown>) => {
            handleCheckboxChange(
              cell.row.original[UserTableFields.USER_ID],
              !selectedUsers.includes(cell.row.original[UserTableFields.USER_ID])
            );
          }}
          CellContent={({ cell }: { cell: Cell<UserTableData, unknown> }) => {
            return (
              <p className='truncate'>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </p>
            );
          }}
        />
      </ResponsiveModal>
    </div>
  );
};
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as NotificationBell } from "../../../assets/nav/notification-bell.svg";
import { useNetworkName } from "../../../backend/resources/network/network";
import type { UserNotification } from "../../../backend/resources/notification/notification";
import {
  useLatestNotificationsQuery,
  useUpdateNotificationToReadMutation,
} from "../../../backend/resources/notification/notification";
import { useClickOutside } from "../../../hooks/useClickOutside";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useNetworkStore } from "../../../state/network/network";
import { useUserStore } from "../../../state/user";
import { ProfileImage } from "../../Profile/ProfileImage";
import { BottomSheet } from "../../Sheet";

export function NotificationMenu() {
  const userMenuRef = useRef<HTMLDivElement>(null);
  const userMenuTriggerRef = useRef<HTMLButtonElement>(null);
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);

  const [showBellBerry, setShowBellBerry] = useState(false);
  const authUser = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const { notifications } = useLatestNotificationsQuery(() =>
    setShowBellBerry(true)
  );
  const markNotificationAsRead =
    useUpdateNotificationToReadMutation().mutateAsync;
  const { isMobile } = useWindowSize();

  useClickOutside({
    ref: userMenuRef,
    isEnabled: isNotificationMenuOpen,
    onClickOutside: () => setIsNotificationMenuOpen(false),
    excludedElementRefs: [userMenuTriggerRef],
  });

  useEffect(() => {
    if (!menuOpened && notifications)
      for (const notification of notifications) {
        if (!notification.is_read) {
          setShowBellBerry(true);
          continue;
        }
      }
  }, [notifications, menuOpened]);

  function groupNotificationsByDate(notifications: UserNotification[]) {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const isSameDay = (d1: Date, d2: Date) =>
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();

    const todayNotifications: UserNotification[] = [];
    const yesterdayNotifications: UserNotification[] = [];
    const earlierNotifications: UserNotification[] = [];

    for (const notification of notifications) {
      const createdAt = new Date(notification.created_at);
      if (isSameDay(createdAt, today)) {
        todayNotifications.push(notification);
      } else if (isSameDay(createdAt, yesterday)) {
        yesterdayNotifications.push(notification);
      } else {
        earlierNotifications.push(notification);
      }
    }

    return {
      today: todayNotifications,
      yesterday: yesterdayNotifications,
      earlier: earlierNotifications,
    };
  }

  return (
    <div className="flex justify-end md:items-center w-full md:w-auto">
      {authUser && (
        <div className=" flex justify-center">
          <button
            ref={userMenuTriggerRef}
            className="flex relative items-center rounded-lg"
            onClick={() => {
              setIsNotificationMenuOpen(
                (prevIsUserMenuOpen) => !prevIsUserMenuOpen
              );
              setShowBellBerry(false);
              setMenuOpened(true); // Set menuOpened to true here
            }}
            type="button">
            {showBellBerry ? (
              <div className="rounded-full absolute top-0 right-0 full w-2 h-2 bg-blue-600" />
            ) : null}
            <NotificationBell className="h-7" />
          </button>
          {isMobile ? (
            <BottomSheet
              isOpen={isNotificationMenuOpen}
              onClose={() => setIsNotificationMenuOpen(false)}>
              <div>
                <p className="text-lg pl-2">Notifications</p>
                <Notifications />
              </div>
            </BottomSheet>
          ) : (
            isNotificationMenuOpen && <Notifications />
          )}
        </div>
      )}
    </div>
  );

  function Notifications() {
    return (
      <div
        ref={userMenuRef}
        className=" pb-6 md:pb-0 md:z-10 md:absolute top-[80%] right-0  w-full md:w-[360px] md:h-[20rem] md:overflow-y-scroll rounded-lg mt-2 bg-white md:border border-faint-gray md:drop-shadow-md">
        <ul>
          {notifications && notifications.length > 0 ? (
            (() => {
              const grouped = groupNotificationsByDate(notifications);
              return (
                <>
                  {grouped.today.length > 0 && (
                    <>
                      <li className="py-4 pl-2">Today</li>
                      {grouped.today.map((notification, index) => (
                        <Notification
                          notification={notification}
                          isLastNotif={index === grouped.today.length - 1}
                        />
                      ))}
                    </>
                  )}

                  {grouped.yesterday.length > 0 && (
                    <>
                      <li className="py-4 pl-2">Yesterday</li>
                      {grouped.yesterday.map((notification, index) => (
                        <Notification
                          notification={notification}
                          isLastNotif={index === grouped.yesterday.length - 1}
                        />
                      ))}
                    </>
                  )}

                  {grouped.earlier.length > 0 && (
                    <>
                      <li className="py-4 pl-2">Earlier</li>
                      {grouped.earlier.map((notification, index) => (
                        <Notification
                          notification={notification}
                          isLastNotif={index === grouped.earlier.length - 1}
                        />
                      ))}
                    </>
                  )}
                </>
              );
            })()
          ) : (
            <li className="p-2 md:border-b-[1px] border-faint-gray flex justify-center hover:bg-gray-100 rounded-t-lg text-xs">
              <p className="flex flex-nowrap">No Notifications Yet</p>
            </li>
          )}
        </ul>
      </div>
    );
  }

  function Notification({
    notification,
    isLastNotif,
  }: {
    notification: UserNotification;
    isLastNotif: boolean;
  }) {
    const setActiveNetworkId = useNetworkStore(
      (state) => state.setActiveNetworkId
    );
    const { networkName } = useNetworkName(notification.network_id || "");
    // const { data: org } = useActiveOrg();
    return (
      <li
        className={`p-2 ${
          isLastNotif ? "" : "border-b-[1px]"
        } border-faint-gray flex justify-center hover:bg-gray-100 rounded-t-lg `}>
        <button
          className="grid-cols-[.5fr,2fr.1fr] grid gap-1 text-sm w-full items-center"
          onClick={() => {
            setActiveNetworkId(notification.network_id || undefined);
            setIsNotificationMenuOpen(false);
            markNotificationAsRead(notification.id);
            navigate(notification.deeplink_url);
          }}>
          <ProfileImage
            userId={notification.creator_id || undefined}
            size={40}
          />
          <div>
            <p className="text-left text-xs">
              {networkName
                ? `${networkName}: ${notification.text}`
                : `${notification.text}`}
            </p>
            <p className="text-left text-xxs">
              {new Date(notification.created_at).toLocaleTimeString()}
            </p>
          </div>

          {!notification.is_read ? (
            <div className="rounded-full bg-blue-600 w-2 h-2"></div>
          ) : null}
        </button>
      </li>
    );
  }
}

import { useState } from "react";
import type { ShareableRecommendationType } from "../../../backend/resources/shareableRecommendation/shareableRecommendation";
import { useUserCreatedRecommendationsQuery } from "../../../backend/resources/userCreatedRecommendations/userCreatedRecommendations";
import { useCreateUserRecommendations } from "../../../backend/resources/userRecommendation";
import { ResponsiveModal } from "../../../shared/ui/responsive-modal";
import { useGoalStore } from "../../../state/goal/goal";
import { ButtonWithIcon, IconOption } from "../../ButtonWithIcon";
import { Checkbox } from "../../Checkbox";
import { ShareableRecommendation } from "../../GuidancePage/Recommendations/ShareableRecommendationPage/ShareableRecommendation/ShareableRecommendation";

interface Props {
  close: any;
  isOpen: boolean;
  isAddingToGoal?: boolean;
  goalId: string;
}
const TITLE = "Add from Recommendations";


export function ShareableRecommendationAddPopover(props: Props) {
  const { data: shareableRecommendations } =
    useUserCreatedRecommendationsQuery();
  const [
    selectedShareableRecommendations,
    setSelectedShareableRecommendations,
  ] = useState<ShareableRecommendationType[]>([]);

  const { setSession, sessions } = useGoalStore();

  const setRecommendationIds = (recommendationIds: string[]) => {
    setSession(props.goalId, {
      recommendationIds,
    });
  };

  const createUserRecommendations = useCreateUserRecommendations().mutateAsync;

  async function onSave() {
    let newRecommendationIds = selectedShareableRecommendations?.map((rec) => rec.id) ?? [];

    if (props.isAddingToGoal) {
      const userRecommendations = await createUserRecommendations(selectedShareableRecommendations);
      newRecommendationIds = userRecommendations?.map((rec) => rec.id) ?? [];
    }

    setRecommendationIds([
      ...(sessions[props.goalId]?.recommendationIds ?? []),
      ...newRecommendationIds,
    ]);
  }
  function onCheck(shareableRecommendation: ShareableRecommendationType) {
    if (selectedShareableRecommendations.includes(shareableRecommendation)) {
      setSelectedShareableRecommendations(
        selectedShareableRecommendations.filter(
          (rec) => rec !== shareableRecommendation
        )
      );
    } else {
      setSelectedShareableRecommendations([
        ...selectedShareableRecommendations,
        shareableRecommendation,
      ]);
    }
  }

  return (
    <ResponsiveModal isOpen={props.isOpen} onClose={props.close} title={TITLE} closeText="Close" footerButtons={<> <ButtonWithIcon
      onClick={props.close}
      text={"Cancel"}
      size={"small"}
      icon={IconOption.CANCEL}
    />
      <ButtonWithIcon
        onClick={() => {
          onSave();
          props.close();
        }}
        text={"Save"}
        disabled={!selectedShareableRecommendations}
        size={"small"}
        icon={IconOption.CHECKMARK}
      /></>}>
      <div className="flex flex-wrap gap-4 justify-around md:justify-start">
        {shareableRecommendations && shareableRecommendations.length > 0 ? shareableRecommendations?.map((shareableRecommendation) => (
          <div className="flex gap-2 items-center">
            <Checkbox
              isChecked={selectedShareableRecommendations.includes(
                shareableRecommendation
              )}
              onCheck={() => {
                onCheck(shareableRecommendation);
              }}
            />
            <button
              onClick={(event) => {
                event.preventDefault();
                onCheck(shareableRecommendation);
              }}>
              <ShareableRecommendation
                recommendation={shareableRecommendation}
                disableClick
              />
            </button>
          </div>
        )) : <p className="py-2"> You have no Templates</p>}
      </div>
    </ResponsiveModal>
  );
}

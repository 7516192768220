import type { ChangeEvent, ComponentProps } from "react";
import { TextArea } from "../../../../TextArea/TextArea";

interface Props {
  onChange: (value: string) => void;
  answer?: string | null;
}

type TextAreaProps = ComponentProps<typeof TextArea>;

export function FreeFormText({
  onChange,
  answer,
  ...props
}: Props & TextAreaProps) {
  function onChangeTextArea(e: ChangeEvent<HTMLTextAreaElement>) {
    onChange(e.currentTarget.value);
  }

  return (
    <TextArea
      data-hj-allow
      {...props}
      onChange={onChangeTextArea}
      value={answer ?? ""}
    />
  );
}

import { useState } from "react"
import { NetworksWithAdlosAndCaregivers, useNetworksInOrganization } from "../../../backend/resources/network/network"
import { useUserRolesInNetwork } from "../../../backend/resources/userRole"
import { useWindowSize } from "../../../hooks/useWindowSize"
import { CareCentralRoute, CarePilotRoute, useAppNavigate } from "../../../lib/routing"
import { CarespaceCreationWizard } from "../../../shared/forms/CarespaceCreationWizard/CarespaceCreationWizard"
import { useCarespaceCreationWizard } from "../../../shared/forms/CarespaceCreationWizard/useCarespaceCreationWizard"
import ActionButtons from "../../../shared/ui/action-buttons"
import { useNetworkStore } from "../../../state/network/network"
import AdloComponent from "../../AdloComponent/AdloComponent"
import BottomActionBar from "../../BottomActionBar/BottomActionBar"
import { ButtonWithIcon, IconOption } from "../../ButtonWithIcon"
import { Card } from "../../Card/Card"
import CardLayoutSelector, { CardLayoutTypes } from "../../CardLayoutSelector/CardLayoutSelector"
import { LoadingSpinner } from "../../LoadingSpinner"
import { PageContainer } from "../../PageContainer"
import { PageMainHeader } from "../../PageMainHeader"

interface Props {
  isCreatePopoverOpen: boolean;
  setIsCreatePopoverOpen: (isOpen: boolean) => void;
  activeLayout: CardLayoutTypes;
  setActiveLayout: (layout: CardLayoutTypes) => void;
}

export function CarespacesPage() {
  const reset = useCarespaceCreationWizard((state) => state.reset)

  const [isCreatePopoverOpen, setIsCreatePopoverOpen] =
    useState<boolean>(false);
  const [activeLayout, setActiveLayout] = useState<CardLayoutTypes>(
    CardLayoutTypes.DEFAULT
  );
  return (
    <PageContainer>
      <div className="flex items-center gap-5">
        <PageMainHeader text={"Carespaces"} />
      </div>
      <div className="flex justify-center md:justify-between gap-3 items-center py-3 max-w-[68rem] w-full">

        <ActionButtons>
          <ButtonWithIcon
            onClick={() => {
              reset()
              setIsCreatePopoverOpen(true)
            }}
            text={"Add Carespace"}
            icon={IconOption.PLUS}
          />
        </ActionButtons>
        <CardLayoutSelector
          activeLayout={activeLayout}
          setActiveLayout={setActiveLayout}
        />
      </div>
      <CarespacesSubPage
        isCreatePopoverOpen={isCreatePopoverOpen}
        setIsCreatePopoverOpen={setIsCreatePopoverOpen}
        activeLayout={activeLayout}
        setActiveLayout={setActiveLayout}
      />
    </PageContainer>

  )
}

export default function CarespacesSubPage({ isCreatePopoverOpen, setIsCreatePopoverOpen, activeLayout, setActiveLayout }: Props) {
  const { networks, isLoading } = useNetworksInOrganization();
  const reset = useCarespaceCreationWizard((state) => state.reset)


  if (isLoading) {
    return <div className="flex justify-center items-center min-h-screen">
      <LoadingSpinner className="w-32 h-32" />
    </div>
  }

  return (
    <>
      {/* Carespaces */}
      <div className="flex flex-col w-full gap-3">
        {networks && networks.length > 0 ?
          <div
            className={`flex flex-wrap justify-center md:justify-start items-center md:items-start gap-1 md:gap-2 pt-2`}>
            {networks.map((network) => {
              return (
                <CarespaceCardView
                  network={network}
                  activeLayout={activeLayout}
                />
              );
            })}
          </div>
          : <p className="mt-2 text-sm">
            You currently don’t have any carespaces. Click on "Create New" to add a carespace.
          </p>
        }
      </div>

      {/* Carespace Creation Popup */}
      {isCreatePopoverOpen &&
        <CarespaceCreationWizard
          onClose={() => {
            reset()
            setIsCreatePopoverOpen(false)
          }}
        />
      }

      {/* Bottom Action Bar */}
      <BottomActionBar
        centerSlot={
          <ButtonWithIcon
            onClick={() => {
              reset()
              setIsCreatePopoverOpen(true)
            }}
            text={""}
            icon={IconOption.PLUS}
          />
        }
      />
    </ >
  )
}

export function DesktopOnlyCareCentralTopActionBar({
  setIsCreatePopoverOpen,
}: {
  setIsCreatePopoverOpen: (isOpen: boolean) => void;
}) {
  const reset = useCarespaceCreationWizard((state) => state.reset)

  const { isMobile } = useWindowSize();

  if (isMobile) return null;

  return (
    <div className="flex">
      <ButtonWithIcon
        size="small"
        onClick={() => {
          reset()
          setIsCreatePopoverOpen(true)
        }}
        icon={IconOption.PLUS}
        text="Create new"
      />
    </div>
  );
}


export function CarespaceCardView({
  network,
  activeLayout,
  shouldNavigateToConversation
}: {
  activeLayout: CardLayoutTypes;
  network: NetworksWithAdlosAndCaregivers;
  shouldNavigateToConversation?: boolean
}) {
  const navigate = useAppNavigate();
  const firstAdlo = network.user_adlo[0]; // TODO: support multi-adlo, probably with carousel (also check grid mode)
  const setActiveNetworkId = useNetworkStore(
    (state) => state.setActiveNetworkId
  );
  const { primaryCaregivers, isLoading } = useUserRolesInNetwork(
    network?.id
  );
  const firstPrimaryCaregiver = primaryCaregivers && primaryCaregivers[0];

  return (
    <div className=
      {`flex flex-col gap-2 pt-2 
          ${activeLayout === "thumbnail" ?
          "w-[160px] max-w-[160px]" :
          "w-[360px] max-w-[360px]"} 
          overflow-clip `}>
      {/* title */}
      <div className="flex flex-col w-full max-w-full overflow-clip justify-center pt-2 ">
        <p
          className={`text-xl truncate w-full ${activeLayout === "thumbnail" ? "text-center" : ""
            }`}>
          {network.name}
        </p>
      </div>
      <button
        onClick={() => {
          setActiveNetworkId(network.id);
          navigate({
            path: shouldNavigateToConversation ? CarePilotRoute.GENERAL_DISCUSSION : CareCentralRoute.CARESPACE,
            params: {
              carespaceId: network.id
            }
          })
        }}>
        {firstAdlo ? (
          <Card
            color="darkBlue"
            size={activeLayout}>
            <AdloComponent
              userAdlo={firstAdlo}
              size={activeLayout}
              isLoading={isLoading}
              withCaregiver={firstPrimaryCaregiver?.user}
            />
          </Card>
        ) : (
          <Card
            color="white"
            size={activeLayout}>
            <div className="text-black/50">No patients.</div>
          </Card>
        )}
      </button>
    </div>
  );
}

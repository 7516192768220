import { useEffect } from "react";
import { oneSignal } from "../../../backend/functions";
import { useMutateUserOnboarding } from "../../../backend/resources/userOnboarding";
import { CraniometrixProduct } from "../../../hooks/product/product";
import { ResponsiveModal } from "../../../shared/ui/responsive-modal";
import { resetAssessmentStore } from "../../../state/assessment";
import { useUserStore } from "../../../state/user";
import { ButtonWithIcon, IconOption } from "../../ButtonWithIcon";

interface Props {
  onClose: () => void;
}

export function CarePilotWelcomeMessage({ onClose }: Props) {
  const authUser = useUserStore((state) => state.user);
  const resetUserStore = useUserStore((state) => state.reset);
  const signOut = useUserStore((state) => state.signOut)
  const setUserOnboarding = useMutateUserOnboarding().mutateAsync;

  async function closeOnboarding() {
    const data = await setUserOnboarding({ userId: authUser?.id });
    if (data && data.length > 0) {
      onClose();
    }
  }

  async function handleLogout(e: any) {
    e.stopPropagation();
    resetUserStore();
    resetAssessmentStore();
    await signOut();
  }

  useEffect(() => {
    if (authUser) {
      oneSignal(
        authUser.id,
        /* is_start_assessment */ undefined,
        /* is_onboarding */ true
      );
    }
  }, []);

  return (
    <ResponsiveModal
      isOpen={true}
      title={"Welcome to Craniometrix!"}
      showLogo={CraniometrixProduct.CARE_PILOT}
      closeText={undefined} // do not allow closing
      footerButtons={
        <>
          <div className="flex md:flex-grow">
            <ButtonWithIcon
              onClick={handleLogout}
              text="Logout"
              icon={IconOption.LOGOUT}
            />
          </div>
          <ButtonWithIcon
            className="flex justify-end"
            onClick={closeOnboarding}
            text={"Next"}
            icon={IconOption.ARROW} />
        </>
      }
    >
      <div className="flex flex-col gap-4 z-1000 text-sm">
        <p>
          Taking care of someone with dementia is tough — I know, I’ve been there.
          So at Craniometrix, we’re continually striving to make the lives of
          family caregivers and their loved ones as comfortable as possible.
        </p>
        <p>
          We help you get the information, support, and services you need to
          provide care in the most effective and impactful way. And we help you
          plan everything out so that your job is a little bit easier.
        </p>
        <p>
          I hope our tools are as helpful to you as they have been to me and my
          family.
        </p>
        <p>Warmly,</p>
        <p className="flex flex-col gap-1">
          <span>Nikhil Patel</span>
          <span>Founder & CEO, Craniometrix</span>
        </p>
      </div>
    </ ResponsiveModal>
  )
}

import type { VariantProps } from "class-variance-authority";
import { useEffect } from "react";
import { useRecommendationStatusForShareableRecommendation } from "../../../../backend/resources/goal/goalUserRecommendationStatus";
import { useUserAdloInUserAssessment } from "../../../../backend/resources/userAssessment";
import { useUpdateRecommendationLastOpened, type RecommendationType } from "../../../../backend/resources/userRecommendation";
import { CarePilotRoute, useAppNavigate } from "../../../../lib/routing";
import { useGptStore } from "../../../../state/gpt";
import { AdloInitials } from "../../../AdloComponent/AdloInitials";
import type { cardStyles } from "../../../Card/Card";
import { Card } from "../../../Card/Card";
import { Checkbox } from "../../../Checkbox";
import Rating from "../../../GoalPage/Rating";

interface Props extends VariantProps<typeof cardStyles> {
  recommendation: RecommendationType;
  isSample?: boolean;
  isInUserUpdate?: boolean;
}

export function RecommendationCard({
  recommendation,
  isSample,
  isInUserUpdate,
  ...variantProps
}: Props) {
  const navigate = useAppNavigate();

  /**
   * Stores
   */

  const userAssessmentChatGptRecommendationId = useGptStore(
    (state) => state.userAssessmentChatGptRecommendationId
  );
  const setUserAssessmentChatGptRecommendationId = useGptStore(
    (state) => state.setUserAssessmentChatGptRecommendationId
  );
  const setUserAssessmentChatGptInterventionId = useGptStore(
    (state) => state.setUserAssessmentChatGptInterventionId
  );
  const setUserAssessmentChatGptInterventionResourceId = useGptStore(
    (state) => state.setUserAssessmentChatGptInterventionResourceId
  );

  // Queries
  const userAdlo = useUserAdloInUserAssessment(
    recommendation.user_assessment_id
  )?.data;

  const { data: recommendationStatus } =
    useRecommendationStatusForShareableRecommendation(recommendation.recommendation.id);

  const updateRecommendationLastOpened = useUpdateRecommendationLastOpened().mutateAsync

  /**
   * Handlers
   */

  // TODO: check if can delete this
  async function expandRecommendation(shouldExpand: boolean) {
    const isGoingToSource =
      userAssessmentChatGptRecommendationId === recommendation.id &&
      shouldExpand === true;
    if (!isGoingToSource) {
      setUserAssessmentChatGptInterventionId();
      setUserAssessmentChatGptInterventionResourceId();
    }
    setUserAssessmentChatGptRecommendationId(recommendation.id);
  }

  /**
   * Effects
   */

  useEffect(() => {
    if (userAssessmentChatGptRecommendationId === recommendation.id) {
      expandRecommendation(true);
    }
  }, [userAssessmentChatGptRecommendationId]);

  return (
    <button
      onClick={() => {
        navigate({
          path: CarePilotRoute.RECOMMENDATION,
          params: { id: recommendation.id },
          queryParams: isInUserUpdate
            ? { "only-show-interventions-in-user-update": "true" }
            : {},
        });
        updateRecommendationLastOpened(recommendation.id)
      }}>
      <Card {...variantProps}>
        {recommendationStatus?.is_marked_done && !isInUserUpdate ? (
          <div className="absolute -top-2 -right-2 rounded-full overflow-clip">
            <Checkbox isChecked={recommendationStatus?.is_marked_done} />
          </div>
        ) : null}
        <div
          className={`select-text flex flex-col gap-2 h-full ${variantProps.size !== "thumbnail"
            ? "justify-around"
            : "justify-center"
            } px-2`}>
          <div className="w-full flex items-center ">
            <p className="text-lg line-clamp-4">
              {recommendation.title}
            </p>
            <div className="fle items-center">
              {isSample && <span className="italic">Sample</span>}
            </div>
          </div>
          <div>
            {variantProps.size !== "thumbnail" ? (
              <p className="text-sm font-extralight line-clamp-[2]">
                {recommendation.details
                  .replace('"', "")
                  .replace('"', "")}
              </p>
            ) : null}
          </div>
        </div>
        <div className="flex w-full justify-between border-t-[1px] py-2 border-gray-400 h-min pb-0">
          <AdloInitials
            userAdlo={userAdlo}
            size={"small"}
          />
          <Rating
            rating={recommendationStatus?.rating ?? null}
            size={"large"}
          />
        </div>
      </Card>
    </button>
  );
}

import {
  useAlfredPageSideBar
} from "../../backend/resources/chatGptConversation";
import { PageContainer } from "../PageContainer";
import { PageMainHeader } from "../PageMainHeader";
import { VideoLibraryPage } from "../VideoLibraryPage";

export default function EducationPage() {
  useAlfredPageSideBar("educationPage")
  return (
    <PageContainer>
      <PageMainHeader
        text={"Education"}
        className="text-center md:text-start w-full pb-2"
      />
      <VideoLibraryPage />
    </PageContainer>
  );
}

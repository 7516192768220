import { useMutation } from "@tanstack/react-query";
import { Database } from "../../../../types/supabase";
import { queryClient } from "../../../App";
import { supabase } from "../../../clients/supabaseClient";
import { useActiveOrganizationId } from "../../../state/organization/organization";
import { QUERY_KEYS } from "../../queryKeys";

export type Organization = Database["public"]["Tables"]["organization"]["Row"];
export type OrganizationUpdate = Database["public"]["Tables"]["organization"]["Update"];

const TABLE = "organization";

async function updateOrg(organizationUpdate: OrganizationUpdate) {
  if (!organizationUpdate||!organizationUpdate.id) return null
  const { data, error } = await supabase
    .from(TABLE)
    .update(organizationUpdate)
    .eq("id", organizationUpdate.id)
    .select("*")
    .limit(1)
    .order("id", { ascending: true }) // noop
    .maybeSingle();

  if (error) {
    throw new Error(error.message);
  }

  return data;
}


export function useUpdateOrg() {
  const organizationId = useActiveOrganizationId()
  return useMutation({
    mutationFn: async (organizationUpdate: OrganizationUpdate) => {
      const response = await updateOrg(
        organizationUpdate,
        );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.orgAndIdentities],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.orgs],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.activeOrg],
      });
    },
  });
}
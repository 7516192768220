import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { z } from 'zod';
import { useCreatePrivateConversationMutation } from "../../../backend/functions";
import { useServiceResourcePlanEntryByName } from "../../../backend/resources/services/serviceResource";
import { CarePilotRoute, useAppNavigate } from "../../../lib/routing";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRoot, FormSection } from "../../../shared/ui/form";
import { ResponsiveModal } from "../../../shared/ui/responsive-modal";
import { useDiscussionFilterStore } from "../../../state/discussionsFilter/discussionsFilter";
import { ButtonWithIcon, IconOption } from "../../ButtonWithIcon";
import Who, { UserType } from "../../MyPlanPage/components/Who";
import { RadioButtonsGroup } from "../../RadioGroup";
import { Select } from "../../Select";

enum ConversationCreationModalSubPage {
  TYPE_SELECTION = "Select Type",
  SELECT_PROVIDER = "Select Provider",
  MEMBER_SELECTION = "Select Member"
}

enum NewConversationType {
  General = "General",
  Private = "Private",
  PrivateEXT = "Private-EXT"
}

const ConversationSchema = z.object({
  type: z.nativeEnum(NewConversationType),
  provider: z.string().uuid().optional(),
  orgMember: z.string().uuid().optional(),
}).refine(data => data.provider || data.orgMember, {
  message: "Either provider or member must be provided",
  path: ['provider', 'orgMember']
});

const useConversationForm = (): UseFormReturn<z.infer<typeof ConversationSchema>> => {
  return useForm({
    resolver: zodResolver(ConversationSchema),
    mode: "onSubmit",
  })
};


export default function CreateConversationModal({ isOpen, onClose: onCloseModal }: { isOpen: boolean, onClose: () => void }) {
  const navigate = useAppNavigate();
  const form = useConversationForm()
  const formValues = form.watch();

  // states
  const [page, setPage] = useState<ConversationCreationModalSubPage>(ConversationCreationModalSubPage.TYPE_SELECTION);
  // Store
  const { carespace: carespaceFilter } = useDiscussionFilterStore();

  // Mutations
  const createPrivateConversation = useCreatePrivateConversationMutation().mutateAsync;

  // Constants
  const isCarespaceSelected = !!carespaceFilter && carespaceFilter !== 'All';
  // functions
  function isNextButtonDisabled() {
    const pageFormValueMap = {
      [ConversationCreationModalSubPage.TYPE_SELECTION]: formValues.type,
      [ConversationCreationModalSubPage.SELECT_PROVIDER]: formValues.provider,
      [ConversationCreationModalSubPage.MEMBER_SELECTION]: formValues.orgMember,
    };
    return !pageFormValueMap[page];
  }

  async function handleNextButtonClick() {
    switch (page) {
      case ConversationCreationModalSubPage.TYPE_SELECTION:
        switch (formValues.type) {
          case NewConversationType.General:
            navigate({ path: CarePilotRoute.GENERAL_DISCUSSION, queryParams: { network_id: carespaceFilter ?? null } });
            break;
          case NewConversationType.Private:
            setPage(ConversationCreationModalSubPage.MEMBER_SELECTION);
            break;
          case NewConversationType.PrivateEXT:
            setPage(ConversationCreationModalSubPage.SELECT_PROVIDER);
            break;
          default:
            break;
        }
        break;
      case ConversationCreationModalSubPage.SELECT_PROVIDER:
        if (!formValues.provider || !carespaceFilter) return;
        const extConversationId = await createPrivateConversation({ network_id: carespaceFilter, service_provider_id: formValues.provider });
        if (extConversationId) {
          navigate({ path: CarePilotRoute.CONVERSATION, params: { conversation_id: extConversationId } });
        }
        break;
      case ConversationCreationModalSubPage.MEMBER_SELECTION:
        if (!formValues.orgMember || !carespaceFilter) return;
        const conversationId = await createPrivateConversation({ user_id: formValues.orgMember, network_id: carespaceFilter });
        if (conversationId) {
          navigate({ path: CarePilotRoute.CONVERSATION, params: { conversation_id: conversationId } });
        }
        break;
    }
  }

  function onClose() {
    onCloseModal();
    setPage(ConversationCreationModalSubPage.TYPE_SELECTION);
  }

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      title="Create New Discussion"
      footerButtons={
        isCarespaceSelected?
        <>
          <ButtonWithIcon text="Cancel" onClick={onClose} icon={IconOption.CANCEL} />
          <ButtonWithIcon
            disabled={isNextButtonDisabled()}
            text="Next"
            onClick={handleNextButtonClick}
            icon={IconOption.ARROW}
          />
        </>:
          <ButtonWithIcon text="Close" onClick={onClose} icon={IconOption.CHECKMARK}/>
      }
    >
      <div>
        <Form {...form}>
          <FormRoot id={"create-conversation-form"} >
            {isCarespaceSelected ?
              <>
                {page === ConversationCreationModalSubPage.TYPE_SELECTION && <TypeSelection form={form} />}
                {page === ConversationCreationModalSubPage.SELECT_PROVIDER && <SelectProvider form={form} />}
                {page === ConversationCreationModalSubPage.MEMBER_SELECTION && <MemberSelection form={form} />}
              </>
              : <p className="w-full text-center">Please select a carespace first</p>}
          </FormRoot>

        </Form>

      </div>
    </ResponsiveModal>
  );

  interface FormProps {
    form: UseFormReturn<z.infer<typeof ConversationSchema>>
  }

  function TypeSelection({ form }: FormProps) {
    return <FormSection>
      <FormField
        control={form.control}
        name="type"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="flex gap-2 text-base">
              Type
            </FormLabel>
            <FormControl>
              <RadioButtonsGroup
                id={"type"}
                options={Object.values(NewConversationType).map((value) => ({ value, label: value }))}
                {...field}
                row={false}
              >
              </RadioButtonsGroup>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </FormSection>;
  }

  function MemberSelection({ form }: FormProps) {
    return <FormSection>
      <FormField
        control={form.control}
        name="orgMember"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              Select Member
            </FormLabel>
            <Who
              isEditing
              selectedPlanEntryOwnerId={field.value}
              setPlanEntryOwner={field.onChange}
              networkId={carespaceFilter}
              userType={UserType.ORG_USERS_IN_NETWORK}
            />
            <FormMessage />
          </FormItem>
        )}
      />
    </FormSection>
  }

  function SelectProvider({ form }: FormProps) {
    const { data: resources } = useServiceResourcePlanEntryByName();
    const options = resources?.map(serviceProvider => ({ value: serviceProvider.id, label: serviceProvider.name })) ?? [];

    return <FormSection>
      <FormField
        control={form.control}
        name="provider"
        render={({ field }) => (
          <FormItem>
            <div className="flex flex-col gap-2">
              <FormLabel>
                Provider
              </FormLabel>
              <Select
                placeHolder="Select Provider"
                currentOption={options.find((serviceProvider) => serviceProvider.value === field.value)}
                options={options}
                onChange={(value) => form.setValue("provider", value)}
              />
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
    </FormSection>
  }
}
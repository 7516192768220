import * as Sentry from "@sentry/react";
import { Session } from "@supabase/supabase-js";
import { UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";
import qs from "qs";
import { z } from "zod";
import { queryClient } from "../../App";
import { supabase } from "../../clients/supabaseClient";
import { BillingPageData } from "../../components/BillingPage/types";
import { YellowPagesListingSchema } from "../../shared/forms/types";
import { useAnalyticsPageFilterStore } from "../../state/analyticsPageFilter/analyticsPageFilter";
import { useActiveOrganizationId } from "../../state/organization/organization";
import { useUserStore } from "../../state/user";
import { QUERY_KEYS } from "../queryKeys";
import type { OrgRoleType } from "../resources/orgRole";
import { UserAdlo } from "../resources/userAdlo";
import type {
  NewsMetadata,
  UserAssessmentChatGptInterventionResource,
} from "../resources/userGuidanceResource";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

async function getSupabaseAccessToken(session?: Session | null) {
  if (!session) {
    session = (await supabase.auth.getSession()).data.session;

  }
  const accessToken = session?.access_token;
  return accessToken
}

export async function fetchNews() {
  const response = await fetch(`${BACKEND_URL}/news`, {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "authorization, x-client-info, apikey, content-type",
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  const responseBody = await response.json();

  return (
    responseBody.data as UserAssessmentChatGptInterventionResource[]
  ).filter(
    (newsResource) =>
      (newsResource.guidance_resource?.metadata as NewsMetadata).provider[0]
        .image &&
      (newsResource.guidance_resource?.metadata as NewsMetadata).image
        ?.thumbnail?.contentUrl
  );
}

export async function getSimilarYouTubeVideo({
  id,
  title,
  description,
}: {
  id: string;
  title: string;
  description: string;
}) {
  const response = await fetch(`${BACKEND_URL}/similar-youtube-video`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
      "Access-Control-Allow-Headers":
        "authorization, x-client-info, apikey, content-type",
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({
      id,
      title,
      description,
    }),
  });
  const responseBody = await response.json();
  return responseBody;
}

export async function getDifferentYouTubeVideo({
  id,
  title,
  description,
  reason,
}: {
  id: string;
  title: string;
  description: string;
  reason: string;
}) {
  const response = await fetch(`${BACKEND_URL}/different-youtube-video`, {
    method: "POST",
    mode: "cors",
    headers: {
      'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "authorization, x-client-info, apikey, content-type",
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({
      id,
      title,
      description,
      reason,
    }),
  });
  const responseBody = await response.json();
  return responseBody;
}

/**
 * Google APIs
 */

export type GoogleApiScope =
  | "https://www.googleapis.com/auth/userinfo.email" // email
  | "https://www.googleapis.com/auth/userinfo.profile" // profile
  | "https://www.googleapis.com/auth/calendar.events" // calendar events
  | "openid";


export interface SimpleGoogleCalendarEvent {
  summary: string; // Title or main heading of the event
  description: string; // Description of the event
  location: string; // Location where the event is taking place
  start: EventDateTime; // Start time of the event
  end: EventDateTime; // End time of the event
  colorId?: string; // (Optional) The color of the calendar event
}

interface EventDateTime {
  dateTime: string; // The date and time in ISO format
  timeZone: string; // The time zone
}

/**
 * get_recommendations batch job
 */

interface UserAssessmentResults {
  prompts: {
    question: string;
    type: string;
    answer: string;
    user_assessment_answer_id: string;
    assessment_question_id: string;
  }[];
  userAssessmentId: string;
  userId: string;
}

const GET_RECOMMENDATIONS_JOB_DEF =
  import.meta.env.VITE_ENV_NAME === "dev"
    ? "dev-get_recs-def"
    : import.meta.env.VITE_ENV_NAME === "qa"
      ? "qa-get_recs-def"
      : import.meta.env.VITE_ENV_NAME === "staging"
        ? "staging-get_recs-def"
        : "prod-get_recs-def";

const GET_RECOMMENDATIONS_JOB_QUEUE =
  import.meta.env.VITE_ENV_NAME === "dev"
    ? "dev-get_recs-queue"
    : import.meta.env.VITE_ENV_NAME === "qa"
      ? "qa-get_recs-queue"
      : import.meta.env.VITE_ENV_NAME === "staging"
        ? "staging-get_recs-queue"
        : "prod-get_recs-queue";

const GET_JOB_ENV =
  import.meta.env.VITE_ENV_NAME === "dev"
    ? "dev"
    : import.meta.env.VITE_ENV_NAME === "qa"
      ? "qa"
      : import.meta.env.VITE_ENV_NAME === "staging"
        ? "staging"
        : "prod";

function makeGetRecommendationsJobName(userAssessmentId: string) {
  return `get_recommendations-job-${userAssessmentId}`;
}

export async function get_recommendations({ care_plan_generation_status_id }: { care_plan_generation_status_id: string }) {
  try {
    const response = await fetch(
      `${BACKEND_URL}/user-assessment-chat_gpt_recommendation`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ care_plan_generation_status_id }),
      }
    );
    const responseBody = await response.json();
    return responseBody;
  } catch (error: any) {
    return { error: error.message };
  }
}

export async function submitGetRecommendationsJob({ care_plan_generation_status_id }: { care_plan_generation_status_id: string }) {
  // form the submit job input
  const getRecommendationsJobInput = {
    jobName: makeGetRecommendationsJobName(care_plan_generation_status_id),
    jobQueue: GET_RECOMMENDATIONS_JOB_QUEUE,
    jobDefinition: GET_RECOMMENDATIONS_JOB_DEF,
    containerOverrides: {
      environment: [
        { name: "env", value: GET_JOB_ENV },
        { name: "care_plan_generation_status_id", value: care_plan_generation_status_id },
        { name: "save_name", value: `get_recommendations-${care_plan_generation_status_id}` },
      ],
    },
  };

  // submit the job using an edge function
  return supabase.functions.invoke("submit_batch_job", {
    body: { care_plan_generation_status_id, jobInput: getRecommendationsJobInput },
  });
}

/**
 * Onboarding
 */


export enum NotificationType {
  REC_IS_READY = "Recommendations are Ready",
  DOCTOR_REVIEWED = "Doctor has Reviewed",
  UPDATE_POSTED_IN_HUB = "New Hub Update",
  MESSAGE_POSTED_IN_HUB = "New Comment in The Hub",
  MESSAGE_POSTED_IN_CARESPACE = "New Comment in The Discussion",
  MED_REMINDER = "Medication Reminder",
  MED_CHECKING_IN = "Medication Reminder (Post)",
  TODO = "Todos",
  CARESPACE_DEACTIVATION = "Carespace Deactivation",
  CARESPACE_INVITATION = "Carespace Invitation",
  WORKSPACE_INVITATION_ACCEPTANCE = "Carespace Invitation Acceptance",
  ORGANIZATION_INVITATION = "Organization Invitation",
  WELCOME = "Welcome",
  EXTERNAL_SERVICE = "External Service",
  EXTERNAL_SERVICE_REQUEST = "External Service Request",
  EXTERNAL_DIRECT_MESSAGE = "External Direct Message",
  EXTERNAL_DOCUMENT_SHARING = "External Document Sharing"
}

export async function sendNotification(
  related_object_id: string,
  notif_type: NotificationType
) {
  try {
    const response = await fetch(`${BACKEND_URL}/send_notification`, {
      method: "POST",
      mode: "cors",
      headers: {
        'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        related_object_id,
        notif_type,
      }),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}



export async function submitAssessment(user_assessment_id: string) {
  try {
    const response = await fetch(`${BACKEND_URL}/submit_assessment`, {
      method: "POST",
      mode: "cors",
      headers: {
        'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        user_assessment_id,
      }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    return responseBody;
  } catch (error: any) {
    return { error: error.message };
  }
}


export async function oneSignal(
  user_id: string,
  is_starting_assessment?: boolean,
  is_onboarding?: boolean
) {
  try {
    const response = await fetch(`${BACKEND_URL}/one_signal`, {
      method: "POST",
      mode: "cors",
      headers: {
        'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        user_id,
        is_starting_assessment,
        is_onboarding,
      }),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export async function createUser(
  invitationId: string | undefined | null,
  authId: string,
  isForOrganization: boolean,
  session: Session | null
) {
  try {
    const response = await fetch(`${BACKEND_URL}/create_user`, {
      method: "POST",
      mode: "cors",
      headers: {
        'Authorization': `Bearer ${await getSupabaseAccessToken(session)}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        invitation_id: invitationId,
        auth_id: authId,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        is_for_organization: isForOrganization,
      }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}


export async function acceptOrgInvitation(
  authId: string,
  invitationId: string
) {
  try {
    const response = await fetch(`${BACKEND_URL}/accept-org-invitation`, {
      method: "POST",
      mode: "cors",
      headers: {
        'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        auth_id: authId,
        invitation_id: invitationId,
      }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export async function signupWithInvitation(
  password: string,
  invitationId: string | undefined | null,
  isForOrganization: boolean
) {
  try {
    const response = await fetch(`${BACKEND_URL}/invitation_signup`, {
      method: "POST",
      mode: "cors",
      headers: {
        'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        invitation_id: invitationId,
        password: password,
        is_for_organization: isForOrganization,
      }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}


export async function renewPlanEntry({ plan_entry_id }: { plan_entry_id: string }) {
  try {
    const response = await fetch(`${BACKEND_URL}/renew_plan_entry`, {
      method: "POST",
      mode: "cors",
      headers: {
        'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({ plan_entry_id }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEYS.chatGptConversation,
      ]
    });
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEYS.planEntries,
      ]
    });
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEYS.guidePlanEntry,
      ]
    });
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEYS.baseAllPlanEntries,
      ]
    });
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEYS.serviceRequests,
      ]
    });
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEYS.guideEvent,
      ]
    });
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEYS.guidePlanEntryForCategory,
      ]
    });
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEYS.guidePlanEntryForCall,
      ]
    });
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}


export async function updateDyadBasedGuideTasks({ network_id }: { network_id: string }) {
  try {
    const response = await fetch(`${BACKEND_URL}/update_dyad_based_guide_tasks`, {
      method: "POST",
      mode: "cors",
      headers: {
        'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({ network_id }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEYS.guidePlanEntry,
      ]
    });
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}


export async function getLaunchDarklyInfo({
  userId,
}: {
  userId?: string;
}) {
  try {
    const url = new URL(`${BACKEND_URL}/launch_darkly_information`);
    url.searchParams.append('user_id', userId ?? "");
    const response = await fetch(url, {
      mode: "cors",
      headers: {
        'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
    });
    if (response.status != 200) {
      throw new Error(await response.text());
    }
    const responseBody = await response.json();
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export const useLaunchDarklyInfoQuery = () => {
  const authUser = useUserStore((state) => state.user);
  return useQuery({
    queryKey: [QUERY_KEYS.launchDarklyInfo, authUser?.id],
    queryFn: () => {
      return getLaunchDarklyInfo({
        userId: authUser?.id,
      });
    },
    enabled: !!authUser?.id,
  });
};

async function createPrivateConversation({ user_ids, network_id, service_provider_id }: { user_ids: string[], network_id: string, service_provider_id?: string | undefined }) {
  const response = await fetch(`${BACKEND_URL}/create_private_conversation`, {
    method: "POST",
    mode: "cors",
    headers: {
      'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "authorization, x-client-info, apikey, content-type",
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ user_ids, network_id, service_provider_id }),
  });
  const responseBody = await response.json();
  return responseBody;
}

export const useCreatePrivateConversationMutation = () => {
  const authUser = useUserStore((state) => state.user);
  return useMutation({
    mutationFn: async ({ user_id, network_id, service_provider_id }: { user_id?: string | undefined, network_id: string, service_provider_id?: string | undefined }) => {
      if (!authUser || (!user_id && !service_provider_id)) return null;
      const userIds = user_id ? [authUser.id, user_id] : [authUser.id];
      return await createPrivateConversation({ user_ids: userIds, network_id, service_provider_id })
    },
  });
};


export async function createCarespace({
  name,
  orgId,
  carespace_creator_id
}: {
  name: string;
  orgId: string;
  carespace_creator_id: string;
}) {
  try {
    const response = await fetch(`${BACKEND_URL}/create_carespace`, {
      method: "POST",
      mode: "cors",
      headers: {
        'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        name,
        org_id: orgId,
        carespace_creator_id,
      }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export async function createOrgAndIdentity({
  authId,
  orgRole,
  orgName,
  isFamilyOrg,
  address,
  city,
  state,
  zip,
}: {
  authId: string;
  orgRole: OrgRoleType;
  orgName: string;
  isFamilyOrg: boolean;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
}) {
  try {
    const response = await fetch(`${BACKEND_URL}/create_org_and_identity`, {
      method: "POST",
      mode: "cors",
      headers: {
        'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        auth_id: authId,
        org_role: orgRole,
        org_name: orgName,
        is_family_org: isFamilyOrg,
        address,
        city,
        state,
        zip,
      }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    // invalidate affected queries
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.orgs],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.orgAndIdentities],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.userRole],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.networkIds],
    });
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

/**
 * FDB
 */

export type DispensableDrug = {
  DispensableDrugID: string;
  MedStrength?: string;
  MedStrengthUnit?: string;
};

export type DrugClass = {
  DrugNameID: string;
  DrugNameDesc: string;
  DoseFormDesc: string;
  RoutedDoseFormDrugID: string;
  RoutedDoseFormDrugDesc: string;
  RoutedDoseFormDrugTallManDesc: string;
  DispensableDrugs: DispensableDrug[];
};

export async function searchDrugClasses({
  searchText,
  userId,
}: {
  searchText: string;
  userId: string;
}) {
  try {
    const query = qs.stringify({ userId, searchText });
    const response = await fetch(`${BACKEND_URL}/drug_class?${query}`, {
      mode: "cors",
      headers: {
        'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
    });
    if (response.status != 200) {
      throw new Error(await response.text());
    }
    const responseBody = await response.json();
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export async function getDrugScreen({ drugIds }: { drugIds: string[] }) {
  if (drugIds.length === 0) return [];
  try {
    const query = qs.stringify(
      {
        drug_ids: drugIds.map(String),
      },
      { arrayFormat: "repeat" }
    );
    const response = await fetch(`${BACKEND_URL}/drug_screen?${query}`, {
      mode: "cors",
      headers: {
        'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
    });
    if (response.status != 200) {
      throw new Error(await response.text());
    }
    const responseBody = await response.json();
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export const useDrugScreenQuery = (drugIds?: string[]) => {
  const {
    isLoading: isLoadingDrugScreen,
    data: drugScreen,
    error: drugScreenError,
    refetch: refetchDrugScreen,
  } = useQuery({
    queryKey: [QUERY_KEYS.drugScreen, drugIds],
    queryFn: () => {
      return getDrugScreen({
        drugIds: drugIds || [],
      });
    },
    enabled: !!drugIds?.length,
  });

  const severe_DDI = drugScreen?.ddiScreenResults
    ?.filter((interaction: any) => interaction.Severity === "1")
    .map((interaction: any) => ({
      screenDrug1ID: interaction.ScreenDrug1.DrugID,
      screenDrug2: interaction.ScreenDrug2.DrugID,
    }));

  const serious_DDI = drugScreen?.ddiScreenResults
    ?.filter((interaction: any) => interaction.Severity === "2")
    .map((interaction: any) => ({
      screenDrug1ID: interaction.ScreenDrug1.DrugID,
      screenDrug2ID: interaction.ScreenDrug2.DrugID,
    }));

  const moderate_DDI = drugScreen?.ddiScreenResults
    ?.filter((interaction: any) => interaction.Severity === "3")
    .map((interaction: any) => ({
      screenDrug1ID: interaction.ScreenDrug1.DrugID,
      screenDrug2ID: interaction.ScreenDrug2.DrugID,
    }));

  return {
    isLoadingDrugScreen,
    drugScreen,
    severe_DDI,
    serious_DDI,
    moderate_DDI,
    drugScreenError,
    refetchDrugScreen,
  };
};

export async function getDrugInformation({
  dispensable_drug_id,
}: {
  dispensable_drug_id: string;
}) {
  try {
    const query = qs.stringify({ dispensable_drug_id });
    const response = await fetch(`${BACKEND_URL}/drug_information?${query}`, {
      mode: "cors",
      headers: {
        'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
    });
    if (response.status != 200) {
      throw new Error(await response.text());
    }
    const responseBody = await response.json();
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export const useDrugInformationQuery = (dispensableDrugId?: string) => {
  const {
    isLoading: isLoadingDrugInformation,
    data: drugInformation,
    error: drugInformationError,
    refetch: refetchDrugInformation,
  } = useQuery({
    queryKey: [QUERY_KEYS.drugInformation, dispensableDrugId],
    queryFn: () => {
      return getDrugInformation({
        dispensable_drug_id: dispensableDrugId || "",
      });
    },
    enabled: !!dispensableDrugId?.length,
  });

  return {
    isLoadingDrugInformation,
    drugInformation,
    drugInformationError,
    refetchDrugInformation,
  };
};

/**
 * search_local_resources
 */




export type YellowPagesListing = z.infer<typeof YellowPagesListingSchema>;


export const useSearchLocalResources = (yplocation?: string, ypkeyword?: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.localResources, yplocation, ypkeyword],
    queryFn: async () => {
      const { data } = await searchLocalResources({
        yplocation: yplocation || "",
        ypkeyword: ypkeyword || "",
      });
      return data
    },
  });
};


export async function getGoogleMeeting() {
  try {
    const response = await fetch(
      `${BACKEND_URL}/create_google_meeting`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    if (response.status != 200) {
      throw new Error(await response.text());
    }
    const responseBody = await response.json();
    return { data: responseBody, error: null };
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}


export function useBillingPageData(hideIneligible: boolean, selectedMonth: number | undefined, selectedYear: number | undefined) {
  const organizationId: string | undefined = useActiveOrganizationId();
  return useQuery({
    queryKey: [QUERY_KEYS.billingPageData, { organizationId, hideIneligible, month: selectedMonth, year: selectedYear }],
    queryFn: async () => {
      try {
        const response = await fetch(
          `${BACKEND_URL}/get_billing_page_info?organization_id=${organizationId}&filter_out_ineligible=${hideIneligible}&month=${selectedMonth}&year=${selectedYear}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers":
                "authorization, x-client-info, apikey, content-type",
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        );
        if (response.status != 200) {
          throw new Error(await response.text());
        }
        const responseBody = await response.json();
        return responseBody as BillingPageData;
      } catch (error: any) {
        Sentry.captureException(error);
        return null;
      }
    },
  });
}


export const useGetIntercomIdentityHash = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.intercomIdentityHash],
    queryFn: getIntercomIdentityHash,
  });
};


export async function getIntercomIdentityHash() {
  try {
    const response = await fetch(
      `${BACKEND_URL}/intercom_identifier`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/pdf",
        },
      }
    );
    if (response.status != 200) {
      throw new Error(await response.text());
    }
    return response.json();
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export const useCreateGoogleMeetingMutation = () => {
  return useMutation({
    mutationFn: getGoogleMeeting,
  });
};


export async function getCarePlanPdfURL(care_plan_id: string, user_adlo: UserAdlo | undefined) {
  try {
    const response = await fetch(
      `${BACKEND_URL}/download_care_plan_pdf?care_plan_id=${care_plan_id}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/pdf",
        },
      }
    );
    if (response.status != 200) {
      throw new Error(await response.text());
    }
    const blob = await response.blob();
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a')
    a.setAttribute("href", downloadUrl);
    a.setAttribute("download", `${user_adlo?.first_name}_${user_adlo?.last_name}_care_plan.pdf`);
    a.click();
    a.remove();
    URL.revokeObjectURL(downloadUrl);
    return { data: blob, error: null };
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}


export async function searchLocalResources({
  yplocation,
  ypkeyword,
}: {
  yplocation: string;
  ypkeyword: string;
}) {
  try {
    const query = qs.stringify({ yplocation, ypkeyword });
    const response = await fetch(
      `${BACKEND_URL}/find_local_resources?${query}`,
      {
        mode: "cors",
        headers: {
          'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    if (response.status != 200) {
      throw new Error(await response.text());
    }
    const responseBody = (await response.json()) as YellowPagesListing[];
    return { data: responseBody, error: null };
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export type AssessmentQuestionAnalytics = {
  dyad_breakdown: Record<string, number>;
  average_age: number;
  sex_breakdown: Record<string, number>;
  caregiver_breakdown: Record<string, number>;
  total_answers: number;
};


export function useAssessmentAnalytics(): UseQueryResult<{
  "possible_answers": string[],
  "total_breakdown": { [key: string]: AssessmentQuestionAnalytics },
  "month_by_month_breakdown": { [key: string]: { [key: string]: AssessmentQuestionAnalytics } },
  "total_patients": number
}> {
  const organizationId: string | undefined = useActiveOrganizationId();
  const { questionId, dyad, sex, hasCaregiver, monthRange , ageRange} = useAnalyticsPageFilterStore()
  return useQuery({
    queryKey: [QUERY_KEYS.assessmentAnalytics, { organizationId, questionId, dyad, sex, hasCaregiver, monthRange, ageRange }],
    queryFn: async () => {
      try {
        if (!questionId) {
          return null
        }
        const response = await fetch(
          `${BACKEND_URL}/get_user_assessment_analytics?organization_id=${organizationId}&assessment_question_id=${questionId}&dyad=${dyad}&sex=${sex}&has_caregiver=${hasCaregiver}&month=${monthRange}&age_range=${ageRange}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers":
                "authorization, x-client-info, apikey, content-type",
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        );
        if (response.status != 200) {
          throw new Error(await response.text());
        }
        const responseBody = await response.json();
        return responseBody as {};
      } catch (error: any) {
        Sentry.captureException(error);
        return null;
      }
    },
  });
}
import type { UserBookmarkWithGuidanceResource } from "../../../../../backend/resources/userBookmark";
import type {
  AmazonMetadata,
  NewsMetadata,
  YouTubeMetadata,
} from "../../../../../backend/resources/userGuidanceResource";
import { GuidanceResourceType } from "../../../../../backend/resources/userGuidanceResource";

export function getResourceMetadataForBookmark(
  bookmark: UserBookmarkWithGuidanceResource,
  resourceType: GuidanceResourceType | undefined
) {
  const metadata =
    bookmark?.user_guidance_resource?.guidance_resource?.metadata;
  switch (resourceType) {
    case GuidanceResourceType.AMAZON: {
      // we add the "name" field to metadata so we can display it on bookmark
      return metadata as AmazonMetadata;
    }
    case GuidanceResourceType.YOUTUBE: {
      const videoId = (metadata as YouTubeMetadata).youTubeMetadata?.id;
      return `https://www.youtube.com/embed/${videoId}`;
    }
    case GuidanceResourceType.NEWS: {
      return metadata as NewsMetadata;
    }
    default: {
      return null;
    }
  }
}

import type { VariantProps } from "class-variance-authority";
import type { ShareableRecommendationType } from "../../../../../backend/resources/shareableRecommendation/shareableRecommendation";
import { CareCentralRoute, useAppNavigate } from "../../../../../lib/routing";
import { Card, type cardStyles } from "../../../../Card/Card";

interface Props extends VariantProps<typeof cardStyles> {
  recommendation: ShareableRecommendationType;
  disableClick?: boolean;
}

export function ShareableRecommendation({
  recommendation,
  disableClick,
  ...variantProps
}: Props) {
  const navigate = useAppNavigate();
  /**
   * Effects
   */
  return (
    <button
      onClick={() => {
        if (!disableClick) {
          navigate({
            path: CareCentralRoute.SHAREABLE_RECOMMENDATION,
            params: {
              id: recommendation.id
            }
          })
        }
      }}>
      <Card {...variantProps}>
        <div
          className={`select-text flex flex-col gap-2 h-full ${variantProps.size !== "thumbnail"
            ? "justify-around"
            : "justify-center"
            } px-2`}>
          <div className="w-full flex items-center ">
            <p className="text-lg line-clamp-4">{recommendation.title}</p>
          </div>
          <div>
            {variantProps.size !== "thumbnail" ? (
              <p className="text-sm font-extralight line-clamp-[2]">
                {recommendation.details.replace('"', "").replace('"', "")}
              </p>
            ) : null}
          </div>
        </div>
      </Card>
    </button>
  );
}

import { useEffect } from "react";
import create from 'zustand';
import { UserAssessmentAnswer } from "../../../../../backend/resources/userAssessmentAnswer";
import { Checkbox } from "../../../../Checkbox";
import { YesOrNoOptions } from "./YesOrNoInput";

interface SelectionOptionProps {
  currentAnswer: UserAssessmentAnswer | undefined;
  updateAnswer: (obj: { answer?: string; context?: string }) => void;
  disabled: boolean
}

export function AssessmentRadioButton({
  currentAnswer,
  updateAnswer,
  disabled,
}: SelectionOptionProps) {
  const { selectedRadioButtonAnswerId, setSelectedRadioButtonAnswerId } = useRadioButtonStore()
  useEffect(() => {
    if (!disabled) {
      updateAnswer({ answer: currentAnswer && currentAnswer?.id === selectedRadioButtonAnswerId ? YesOrNoOptions.YES : YesOrNoOptions.NO })
    }
  }, [selectedRadioButtonAnswerId])

  useEffect(() => {
    if (currentAnswer && currentAnswer.answer === YesOrNoOptions.YES) {
      setSelectedRadioButtonAnswerId(currentAnswer.id)
    }
  }, [currentAnswer])

  return <div className="flex justify-center items-center">
    <Checkbox
      isRounded
      isChecked={!!selectedRadioButtonAnswerId && selectedRadioButtonAnswerId === currentAnswer?.id}
      disabled={disabled}
      onCheck={() => {
        setSelectedRadioButtonAnswerId(currentAnswer?.id || null)
      }
      }
    />
  </div>
}


interface RadioButtonStore {
  selectedRadioButtonAnswerId: string | null;
  setSelectedRadioButtonAnswerId: (questionId: string | null) => void;
}

export const useRadioButtonStore = create<RadioButtonStore>((set) => ({
  selectedRadioButtonAnswerId: null,
  setSelectedRadioButtonAnswerId: (answerId) => set({ selectedRadioButtonAnswerId: answerId }),
}));





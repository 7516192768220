import { ReactNode, useState } from "react";
import { Arrow } from "../../icons/Arrow";

export const MinimizableButton = ({ title, children, titleButton }: { title: string, children: ReactNode, titleButton?: ReactNode }) => {
  const [isMinimized, setIsMinimized] = useState<boolean>(true);

  return (
    <div>
      <div className="flex gap-3">
        <button className="text-left flex items-center gap-2"
          onClick={(e) => {
            e.preventDefault();
            setIsMinimized(!isMinimized);
          }}>
          {title}
          <Arrow
            className={`${!isMinimized ? "rotate-0" : "rotate-180"
              } transition-all fill-brand-orange`}
            size={10}
          />
        </button>
        {titleButton}
      </div>
      {/* content */}
      {!isMinimized &&
        <div className="mt-1 ml-2">
          {children}
        </div>
      }
    </div>
  );
};

import { useState } from "react";
import { useDeleteInvitationMutation } from "../../backend/resources/invitation/invitation";
import { useDeleteOrganizationInvitationMutation } from "../../backend/resources/organizationInvitations/organizationInvitation";
import { ResponsiveModal } from "../../shared/ui/responsive-modal";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
// Usage
export default function DeleteInvitationButton({
  invitationId,
  isOrgInvite,
}: {
  invitationId: string;
  isOrgInvite?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="items-center flex">
      <ButtonWithIcon
        onClick={() => setIsOpen(true)}
        text={""}
        icon={IconOption.TRASH}
      />
      <ResponsiveModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)} 
        title={"Delete Invitation?"}>
        <DeletePopupComponent
          invitationId={invitationId}
          close={() => setIsOpen(false)}
          isOrgInvite={isOrgInvite}
        />
      </ResponsiveModal>
    </div>
  );
}

function DeletePopupComponent({
  invitationId,
  close,
  isOrgInvite,
}: {
  invitationId: string;
  isOrgInvite?: boolean;
  close: any;
}) {
  const deleteInvitationMutation = useDeleteInvitationMutation().mutateAsync;
  const deleteOrgInvitationMutation =
    useDeleteOrganizationInvitationMutation().mutateAsync;
  return (
    <div className="w-full flex flex-col h-full text-center gap-6 md:gap-10 justify-center items-center pb-6 px-10 md:pb-0 md:px-0">
      <p className="font-light">
        Are you sure you want to delete this invitation?
      </p>
      <div className="flex justify-around w-full">
        <ButtonWithIcon
          icon={IconOption.CANCEL}
          onClick={close}
          text="Cancel"
          size="small"
        />
        <ButtonWithIcon
          size="small"
          icon={IconOption.CHECKMARK}
          onClick={async () => {
            await (isOrgInvite
              ? deleteOrgInvitationMutation(invitationId)
              : deleteInvitationMutation(invitationId));
            close();
          }}
          text="Yes"
        />
      </div>
    </div>
  );
}

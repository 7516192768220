import { zodResolver } from "@hookform/resolvers/zod";
import { UseFormReturn, useForm } from "react-hook-form";
import * as z from "zod";

import { useState } from "react";
import { Organization, useActiveOrg } from "../../backend/resources/orgRole";
import { useUpdateOrg } from "../../backend/resources/organization/organization";
import { ButtonWithIcon, IconOption } from "../../components/ButtonWithIcon";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import PhoneNumberInput from "../../components/PhoneNumberInput/PhoneNumberInput";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormRoot,
  FormSection
} from "../ui/form";
import { Input } from "../ui/input";
import { ResponsiveModal } from "../ui/responsive-modal";
import { PhoneNumberSchema, ResponsiveModalWizardProps, ZipCodeSchema } from "./types";

/**
 * schema
 */
export const editOrganizationFormSchema = z.object({
  id: z.string().uuid(),
  name: z.string().min(1),
  address: z.string().min(1),
  city: z.string().min(1),
  state: z.string().min(2),
  zip: ZipCodeSchema,
  helpline_phone_number: PhoneNumberSchema.optional().nullable()
});

/**
 * form hook
 */
export const useEditOrganizationForm = (activeOrg: Organization | undefined | null) => {
  const defaultValues = {
    id: activeOrg?.id,
    name: activeOrg?.name ?? undefined,
    address: activeOrg?.address ?? undefined,
    city: activeOrg?.city ?? undefined,
    state: activeOrg?.state ?? undefined,
    zip: activeOrg?.zip ?? undefined,
    helpline_phone_number: activeOrg?.helpline_phone_number ?? undefined
  };

  return useForm<z.infer<typeof editOrganizationFormSchema>>({
    resolver: zodResolver(editOrganizationFormSchema),
    defaultValues,
    mode: "onSubmit",
  });
};

export type EditOrganizationFormHook = UseFormReturn<z.infer<typeof editOrganizationFormSchema>>

/**
 * form component
 */
export const editOrganizationFormId = "edit-organization-form";

export function EditOrganizationForm(
  { onClose }: ResponsiveModalWizardProps
) {
  // State
  const [isSending, setIsSending] = useState(false);

  // Hooks
  const { data: activeOrg, isLoading } = useActiveOrg()
  const updateOrg = useUpdateOrg().mutateAsync
  const form = useEditOrganizationForm(activeOrg);

  function close() {
    onClose();
    form.reset();
  }

  // Handler
  async function handleSubmit(validatedForm: z.infer<typeof editOrganizationFormSchema>) {
    try {
      setIsSending(true);
      await updateOrg({ ...validatedForm })
    } catch (error) {
      console.error(error)
    } finally {
      setIsSending(false)
      close()
    }
  }

  // Render
  if (isLoading) {
    return null
  }
  return (
    <ResponsiveModal
      isOpen={true}
      closeText="Back"
      title={"Edit Organization"}
      isNetworkCallInProgress={isSending}
      onClose={close}
      footerButtons={
        <>
          <ButtonWithIcon
            text="Cancel"
            icon={IconOption.CANCEL}
            onClick={close}
          />
          <ButtonWithIcon
            text="Done"
            icon={IconOption.CHECKMARK}
            onClick={form.handleSubmit(handleSubmit)}
          />
        </>
      }>
      {isLoading ? (
        <LoadingSpinner className="w-6 h-6" />
      ) : (
        <Form {...form}>
          <FormRoot id={editOrganizationFormId}>
            <FormSection>
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Organization Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="address"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Address</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex gap-3">
                <FormField
                  control={form.control}
                  name="city"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>City</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="state"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>State</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="zip"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Zip</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={form.control}
                name="helpline_phone_number"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Helpline Phone Number</FormLabel>
                    <FormControl>
                      <PhoneNumberInput cellPhone={field.value ?? undefined} setCellPhone={field.onChange} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </FormSection>
          </FormRoot>
        </Form>
      )}
    </ResponsiveModal>
  );
}

import { flexRender } from "@tanstack/react-table";
import { Cell } from "@tanstack/table-core";
import { VariantProps, cva } from "class-variance-authority";
import { useState } from "react";
import { CareCentralRoute, SharedRoute, useAppNavigate } from "../../lib/routing";
import { useNetworkStore } from "../../state/network/network";
import { useOrganizationStore } from "../../state/organization/organization";
import { UserDetailView } from "../CarespacePage/OrgMemberDetailPage";
import { InfiniteScrollingTable, TableDataRowType } from "./InfiniteScrollingTable";

export enum RoleTableFields {
  MemberName = "Member Name",
  WorkSpaceName = "Name",
  Role = "Role",
  Id = "Id",
  UserId = "UserId"
}

type BaseRoleTableType = {
  [RoleTableFields.MemberName]?: string
  [RoleTableFields.WorkSpaceName]: string;
  [RoleTableFields.Role]: string;
  [RoleTableFields.Id]: string;
  [RoleTableFields.UserId]: string;
}

export type RoleTableType = BaseRoleTableType & TableDataRowType<BaseRoleTableType>;


export enum ListType {
  Users = "Users",
  Orgs = "Orgs",
  Carespaces = "Carespaces"
}

const tableStyles = cva([], {
  variants: {
    cellWidth: {
      small: ["w-[10rem]"],
      large: ["w-[20rem]"],
    },
  },
  defaultVariants: {
    cellWidth: "large",
  },
});

export function UserRoleTable({ data, listType, hiddenColumns = [RoleTableFields.MemberName], ...variantProps  }: { data: RoleTableType[], listType: ListType, hiddenColumns?: RoleTableFields[] } & VariantProps<typeof tableStyles> ) {
  const tableStyle = tableStyles({ ...variantProps });

  const navigate = useAppNavigate()
  const setActiveOrganizationId = useOrganizationStore(
    (state) => state.setActiveOrganizationId
  );
  const setActiveNetworkId = useNetworkStore(
    (state) => state.setActiveNetworkId
  );
  const activeNetworkId = useNetworkStore(
    (state) => state.activeNetworkId
  );
  const [focusedUserId, setFocusedUserId] = useState<string | null>(null);
  return (
    <>
      {focusedUserId ? (
        <UserDetailView
          userId={focusedUserId}
          setUserId={setFocusedUserId}
          DMOptions={{ selectedCarespaceId: listType === ListType.Carespaces ? activeNetworkId : undefined }}
        />
      ) : null}

      <InfiniteScrollingTable
        data={data}
        emptyText="No rows to display"
        columnFields={[RoleTableFields.MemberName, RoleTableFields.WorkSpaceName, RoleTableFields.Role]}
        handleClick={(cell, row) => {
          switch (listType) {
            case ListType.Users:
              setFocusedUserId(row.original[RoleTableFields.UserId])
              break;
            case ListType.Orgs:
              setActiveOrganizationId(row.original[RoleTableFields.Id])
              navigate({
                path: SharedRoute.ROOT
              })
              break;
            case ListType.Carespaces:
              setActiveNetworkId(row.original[RoleTableFields.Id])
              navigate({
                path: CareCentralRoute.CARESPACE,
                params: {
                  carespaceId: row.original[RoleTableFields.Id]
                }
              })
              break;
          }
        }}
        hiddenColumns={[RoleTableFields.Id, RoleTableFields.UserId, ...hiddenColumns]}
        CellContent={({ cell }: { cell: Cell<RoleTableType, unknown>; }) =>
          <p className={`truncate text-base ${tableStyle}`}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </p>
        }
      />
    </>
  );
}
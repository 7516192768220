import { Radio } from "@mui/material";
import { useState } from "react";
import type { GoalTemplate } from "../../../backend/resources/goalTemplate/goalTemplate";
import { useGoalTemplateQuery } from "../../../backend/resources/goalTemplate/goalTemplate";
import { useRecommendationsForTemplate } from "../../../backend/resources/goalTemplate/goalTemplateToRecommendation";
import { useCreateUserRecommendations } from "../../../backend/resources/userRecommendation";
import { ResponsiveModal } from "../../../shared/ui/responsive-modal";
import { useGoalStore } from "../../../state/goal/goal";
import { ButtonWithIcon, IconOption } from "../../ButtonWithIcon";
import type { Timeline } from "../utils";
import GoalTemplateCard from "./GoalTemplateCard";

interface Props {
  close: any;
  isOpen: boolean;
  isAddingToGoal?: boolean;
}


export function GoalTemplateAddPopover(props: Props) {
  const { goalTemplates } = useGoalTemplateQuery();
  const [selectedGoalTemplate, setSelectedGoalTemplate] =
    useState<GoalTemplate>();


  const { setSession } = useGoalStore();

  const { data: recommendationsInTemplate } = useRecommendationsForTemplate(
    selectedGoalTemplate?.id
  );

  const createUserRecommendations = useCreateUserRecommendations().mutateAsync;

  async function onSave() {
    if (selectedGoalTemplate) {
      const sessionData = {
        title: selectedGoalTemplate.title,
        details: selectedGoalTemplate.description,
        timeframe: selectedGoalTemplate.timeline as Timeline,
        activeTemplateId: selectedGoalTemplate.id,
        recommendationIds: [] as string[],
      };
      if (props.isAddingToGoal) {
        const userRecommendations = await createUserRecommendations(
          recommendationsInTemplate ?? []
        );
        sessionData.recommendationIds = userRecommendations?.map((rec) => rec.id) ?? [];
      } else {
        sessionData.recommendationIds = recommendationsInTemplate?.map((rec) => rec.id) ?? [];
      }
      setSession("", sessionData);
    }
  }

  return (
    <ResponsiveModal
      isOpen={props.isOpen}
      onClose={props.close}
      title="Prefill from Template"
      closeText="Close"
      footerButtons={<>
        <ButtonWithIcon
          onClick={props.close}
          text={"Cancel"}
          size={"small"}
          icon={IconOption.CANCEL}
        />
        <ButtonWithIcon
          onClick={() => {
            onSave();
            props.close();
          }}
          text={"Save"}
          disabled={!selectedGoalTemplate}
          size={"small"}
          icon={IconOption.CHECKMARK}
        />
      </>}>
      <div className="flex flex-wrap gap-4 justify-around md:justify-start pb-20">
        {goalTemplates && goalTemplates.length > 0 ? goalTemplates?.map((goalTemplate) => (
          <div className="flex gap-2 items-center">
            <Radio
              className="h-min"
              checked={selectedGoalTemplate?.id === goalTemplate.id}
              onChange={() => setSelectedGoalTemplate(goalTemplate)}
            />
            <button
              onClick={(event) => {
                event.preventDefault();
                setSelectedGoalTemplate(goalTemplate);
              }}>
              <GoalTemplateCard
                goalTemplate={goalTemplate}
                disableClick
              />
            </button>
          </div>
        )) : <p className="py-2"> You have no Templates</p>}
      </div>
    </ResponsiveModal>
  );
}

// intake view
import { Popover } from "@mui/material";
import { useState } from "react";
import { ReactComponent as InteractionsIconSvg } from "../../assets/interactions-icon.svg";
import { ReactComponent as ModerateInteractionIconSvg } from "../../assets/moderate-interaction.svg";
import { ReactComponent as PillBottleIconSvg } from "../../assets/pill-bottle.svg";
import { ReactComponent as RefillIconSvg } from "../../assets/refill-icon.svg";
import { ReactComponent as SeriousInteractionIconSvg } from "../../assets/serious-interaction.svg";
import { ReactComponent as SevereInteractionIconSvg } from "../../assets/severe-interaction.svg";
import { useDrugScreenQuery } from "../../backend/functions";
import { useCareBinderName } from "../../backend/resources/userAdlo";
import type { UserPrescription } from "../../backend/resources/userPrescription/userPrescription";
import {
  useRefillPrescription,
  useUserPrescriptionsQuery,
} from "../../backend/resources/userPrescription/userPrescription";
import { useWindowSize } from "../../hooks/useWindowSize";
import { CarePilotRoute, createPath, useAppNavigate, useNavigateBack } from "../../lib/routing";
import { useUserStore } from "../../state/user";
import BottomActionBar from "../BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
import { LoadingSpinner } from "../LoadingSpinner";
import { PageContainer } from "../PageContainer";
import { PageMainHeader } from "../PageMainHeader";

// TODO this whole thing needs to be redone... No need for a seperate page for this

export function IntakeView() {
  const navigate = useAppNavigate();
  const { isMobile } = useWindowSize();
  const content = (
    <div className={`flex flex-col h-full gap-8`}>
      <PrescriptionsSection />
      {/* <InteractionsSection /> */}

      {/* mobile only */}
      <BottomActionBar
        centerSlot={
          <ButtonWithIcon
            onClick={() => {
              navigate({
                path: CarePilotRoute.DAY_INTAKE_NEW
              })
            }}
            icon={IconOption.PLUS}
            text=""
          />
        }
      />
    </div>
  );

  return isMobile ? <PageContainer>{content}</PageContainer> : content;
}

// - prescriptions

function PrescriptionsSection() {
  const navigate = useAppNavigate();
  const navigateBack = useNavigateBack();
  const authUser = useUserStore((state) => state.user);
  const authUserId = authUser?.id;
  const { careBinderName, isLoadingCareBinderName } = useCareBinderName();

  const { isMobile } = useWindowSize();
  const { userPrescriptions } = useUserPrescriptionsQuery();

  return (
    <div className="flex flex-col gap-4">
      {isMobile && (
        <>
          <div>
            {isLoadingCareBinderName ? (
            <LoadingSpinner className="h-6 w-6" />
          ) : (
            <ButtonWithIcon
              onClick={() => {
                navigateBack()
              }}
              disabled={false}
              text={careBinderName}
              icon={IconOption.BACK_ARROW}
              size={"small"}
            />
            )}
          </div>
          <PageMainHeader text="Intake" />
        </>
      )}
      {/* section header */}
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-2 pt-4 ">
          {/* icon */}
          <div className="flex items-center justify-center w-8 h-8">
            <PillBottleIconSvg className="w-12 h-12" />
          </div>
          {/* text */}
          <h2 className="font-light text-2xl">Prescriptions</h2>
        </div>
        {/* add button */}
        {!isMobile ? (
          <ButtonWithIcon
            size={"small"}
            icon={IconOption.PLUS}
            text="Add Prescription"
            onClick={() =>
              navigate({
                path: CarePilotRoute.DAY_INTAKE_NEW
              })
            }
          />
        ) : null}
      </div>
      {/* section content */}
      <div className={`${isMobile ? "pl-4" : "pl-10"}`}>
        {/* rows */}
        {isMobile ? (
          <PrescriptionsRowHeaderMobile />
        ) : (
          <PrescriptionsRowHeader />
        )}
        {userPrescriptions?.map((val) => {
          if (isMobile) {
            return <PrescriptionsRowMobile prescription={val} />;
          } else {
            return <PrescriptionsRow prescription={val} />;
          }
        })}
      </div>
    </div>
  );
}

function PrescriptionsRowHeader() {
  return (
    <div className="grid grid-cols-6 items-end text-center">
      {/* name */}
      <div className="text-sm font-medium text-left">Name</div>
      {/* dosage */}
      <div className="text-sm font-medium">Dosage</div>
      {/* Fill Qty */}
      <div className="text-sm font-medium">Fill Quantity</div>
      {/* Qty Remaining */}
      <div className="text-sm font-medium">Quantity Remaining</div>
      {/* Refill */}
      <div className="text-sm font-medium">Refill</div>
    </div>
  );
}

function PrescriptionsRowHeaderMobile() {
  return (
    <div className="grid grid-cols-[2fr,1fr,1fr,1fr,1fr] text-center gap-1">
      {/* name */}
      <div className="text-sm font-medium text-left">Name</div>
      {/* dosage */}
      <div className="text-sm font-medium">Dosage</div>
      {/* Qty Remaining */}
      <div className="text-sm font-medium">Qty</div>
      {/* Refill */}
      <div className="text-sm font-medium">Refill</div>
    </div>
  );
}

function PrescriptionsRow({
  prescription,
}: {
  prescription: UserPrescription;
}) {
  const navigate = useAppNavigate();
  return (
    <div className={`grid group grid-cols-6 text-center`}>
      {/* prescription name */}
      <a
        href={
          createPath({
            path: CarePilotRoute.DAY_PRESCRIPTION,
            params: {
              dispensable_drug_id: prescription.dispensable_drug_id || ""
            }
          })
        }
        className="flex flex-grow items-center py-2 gap-2 relative cursor-pointer hover:underline text-left">
        {prescription.routed_dose_form_drug_desc}
      </a>
      {/* dosage */}
      <div className="flex items-center justify-center p-2 gap-2 truncate">
        {prescription.med_strength !== "N/A" ? (
          <p className="w-[80px] w-max-[80px] truncate">
            {prescription.med_strength}{" "}
          </p>
        ) : (
          <div>N/A</div>
        )}
      </div>
      {/* Fill Qty */}
      <div className="flex items-center justify-center p-2 gap-2">
        {prescription.doses_fill_qty}
      </div>
      {/* Qty Remaining */}
      <div className="flex items-center justify-center p-2 gap-2">
        {prescription.doses_remaining}
      </div>
      {/* Tap to refill button */}
      <div className="flex items-center justify-center p-2 gap-2">
        <RefillButtonPopover prescription={prescription} />
      </div>
      {/* edit button */}
      <div className="flex items-center justify-center p-2 gap-2">
        <ButtonWithIcon
          text=""
          icon={IconOption.EDIT}
          size={"small"}
          onClick={() => {
            navigate({
              path: CarePilotRoute.DAY_PRESCRIPTION_EDIT,
              params: {
                dispensable_drug_id: prescription.dispensable_drug_id || ""
              }
            })
          }}
        />
      </div>
    </div>
  );
}

function PrescriptionsRowMobile({
  prescription,
}: {
  prescription: UserPrescription;
}) {
  const navigate = useAppNavigate();
  return (
    <div
      className={`grid group grid-cols-[2fr,1fr,1fr,1fr,1fr] text-center gap-1`}>
      {/* prescription name */}
      <a
        href={
          createPath({
            path: CarePilotRoute.DAY_PRESCRIPTION,
            params: {
              dispensable_drug_id: prescription.dispensable_drug_id || ""
            }
          })
        }
        className="flex flex-grow items-center py-2 gap-2 relative cursor-pointer hover:underline text-left">
        {prescription.routed_dose_form_drug_desc}
      </a>
      {/* dosage */}
      <div className="flex items-center justify-center p-2 gap-2">
        {prescription.med_strength !== "N/A" ? (
          <p className="w-[80px] w-max-[80px] truncate">
            {prescription.med_strength}
          </p>
        ) : (
          <div>N/A</div>
        )}
      </div>
      {/* Qty Remaining */}
      <div className="flex items-center justify-center p-2 gap-2">
        {prescription.doses_remaining}
      </div>
      {/* Tap to refill button */}
      <div className="flex items-center justify-center p-2 gap-2">
        <RefillButtonPopover prescription={prescription} />
      </div>
      {/* edit button */}
      <div className="flex items-center justify-center p-2 gap-2">
        <ButtonWithIcon
          text=""
          icon={IconOption.EDIT}
          size={"small"}
          onClick={() => {
            if (prescription.dispensable_drug_id) {
              navigate({
                path: CarePilotRoute.DAY_PRESCRIPTION_EDIT,
                params: {
                  dispensable_drug_id: prescription.dispensable_drug_id || ""
                }
              })
            }
          }}
        />
      </div>
    </div>
  );
}

function RefillButtonPopover({
  prescription,
}: {
  prescription: UserPrescription;
}) {
  const authUser = useUserStore((state) => state.user);
  const authUserId = authUser?.id;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { refill } = useRefillPrescription();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRefill = async () => {
    try {
      await refill(prescription); // Pass the prescription object
    } catch (error) {
      console.error("Error refilling prescription:", error);
    }
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "prescription-refill-popover" : undefined;

  return (
    <div>
      <button
        aria-describedby={id}
        className={`flex items-center gap-2 p-2 text-sm rounded-sm ${open && "bg-zinc-50"
          }`}
        onClick={handleClick}>
        <RefillIconSvg className="w-6 h-6" />
      </button>
      <Popover
        elevation={2}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <div className="flex flex-col items-center w-96 text-sm p-6">
          <span className="p-2 text-center">
            Would you like to refill this prescription?
          </span>
          <div className="flex gap-3">
            <ButtonWithIcon
              onClick={handleClose}
              className="p-2"
              text="Cancel"
              icon={IconOption.CANCEL}
            />
            <ButtonWithIcon
              onClick={handleRefill}
              className="p-2"
              text="Yes, refill prescription"
              icon={IconOption.CHECKMARK}
            />
          </div>
        </div>
      </Popover>
    </div>
  );
}

// - interactions

function InteractionsSection() {
  const authUser = useUserStore((state) => state.user);
  const authUserId = authUser?.id;
  const { isMobile } = useWindowSize();

  // user screen profile
  const { userPrescriptions } = useUserPrescriptionsQuery();

  const userPrescriptionDrugIds = userPrescriptions?.reduce<string[]>(
    (result, prescription) => {
      if (prescription.dispensable_drug_id !== null) {
        result.push(prescription.dispensable_drug_id);
      }
      return result;
    },
    []
  );

  const { isLoadingDrugScreen, severe_DDI, serious_DDI, moderate_DDI } =
    useDrugScreenQuery(userPrescriptionDrugIds);

  return (
    <div className={`flex flex-col gap-4 ${isMobile ? "pb-36" : ""}`}>
      {/* section header */}
      <div className="flex items-center gap-2">
        {/* icon */}
        <div className="flex items-center justify-center w-8 h-8">
          <InteractionsIconSvg />
        </div>
        {/* text */}
        <h2 className="font-light text-2xl">Drug Interactions</h2>
      </div>
      {/* section content */}
      {userPrescriptions?.length === 0 ? (
        <div className={`${isMobile ? "pl-4" : "pl-10"} text-gray-400`}>
          None
        </div>
      ) : isLoadingDrugScreen ? (
        <div className="flex w-full h-full items-center justify-center">
          <LoadingSpinner className="w-20 h-20" />
        </div>
      ) : (
        <div className={`${isMobile ? "pl-4" : "pl-10"}`}>
          {/* severe */}
          <div className="flex flex-col">
            <div className="flex items-center gap-2">
              <SevereInteractionIconSvg />
              <h3>Severe</h3>
            </div>
            <ul>
              {severe_DDI?.length > 0 ? (
                severe_DDI.map((val: any) => {
                  return (
                    <li className="list-disc list-inside pl-8">
                      {
                        userPrescriptions?.find(
                          (prescription) =>
                            prescription.dispensable_drug_id ===
                            val.screenDrug1ID
                        )?.routed_dose_form_drug_desc
                      }{" "}
                      and{" "}
                      {
                        userPrescriptions?.find(
                          (prescription) =>
                            prescription.dispensable_drug_id ===
                            val.screenDrug2ID
                        )?.routed_dose_form_drug_desc
                      }
                    </li>
                  );
                })
              ) : (
                <li className="list-disc list-inside pl-8">None</li>
              )}
            </ul>
          </div>
          {/* serious */}
          <div className="flex flex-col pt-4">
            <div className="flex items-center gap-2">
              <SeriousInteractionIconSvg />
              <h3>Serious</h3>
            </div>
            <ul>
              {serious_DDI?.length > 0 ? (
                serious_DDI.map((val: any) => {
                  return (
                    <li className="list-disc list-inside pl-8">
                      {
                        userPrescriptions?.find(
                          (prescription) =>
                            prescription.dispensable_drug_id ===
                            val.screenDrug1ID
                        )?.routed_dose_form_drug_desc
                      }{" "}
                      and{" "}
                      {
                        userPrescriptions?.find(
                          (prescription) =>
                            prescription.dispensable_drug_id ===
                            val.screenDrug2ID
                        )?.routed_dose_form_drug_desc
                      }
                    </li>
                  );
                })
              ) : (
                <li className="list-disc list-inside pl-8">None</li>
              )}
            </ul>
          </div>
          {/* moderate */}
          <div className="flex flex-col pt-4">
            <div className="flex items-center gap-2">
              <ModerateInteractionIconSvg />
              <h3>Moderate</h3>
            </div>
            <ul>
              {moderate_DDI?.length > 0 ? (
                moderate_DDI.map((val: any) => {
                  return (
                    <li className="list-disc list-inside pl-8">
                      {
                        userPrescriptions?.find(
                          (prescription) =>
                            prescription.dispensable_drug_id ===
                            val.screenDrug1ID
                        )?.routed_dose_form_drug_desc
                      }{" "}
                      and{" "}
                      {
                        userPrescriptions?.find(
                          (prescription) =>
                            prescription.dispensable_drug_id ===
                            val.screenDrug2ID
                        )?.routed_dose_form_drug_desc
                      }
                    </li>
                  );
                })
              ) : (
                <li className="list-disc list-inside pl-8">None</li>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

import { useState } from "react";
import { useQueryLibraryFolders } from "../../../backend/resources/libraryFolder";
import { supabase } from "../../../clients/supabaseClient";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useLibraryFoldersStore } from "../../../state/libraryFolders";
import { ButtonWithIcon, IconOption } from "../../ButtonWithIcon";
import { Popup } from "../../Popup";
import { BottomSheet } from "../../Sheet";

interface Props {
  close: () => void;
}

export function DeleteFolderPopup({ close }: Props) {
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return (
      <BottomSheet
        isOpen={true}
        onClose={close}>
        <DeleteFolderComponent close={close} />
      </BottomSheet>
    );
  }
  return (
    <Popup
      close={close}
      closeOnClickOutside={false}>
      <DeleteFolderComponent close={close} />
    </Popup>
  );
}

function DeleteFolderComponent({ close }: Props) {
  const activeFolder = useLibraryFoldersStore((state) => state.activeFolder);
  const setActiveFolder = useLibraryFoldersStore(
    (state) => state.setActiveFolder
  );
  const [error, setError] = useState<string>("");

  const { refetchLibraryFolders } = useQueryLibraryFolders();
  if (!activeFolder) return null;

  async function deleteFolder() {
    const { data, error } = await supabase
      .from("library_folder")
      .delete()
      .eq("id", activeFolder?.id ?? "")
      .select();

    if (data && data.length > 0) {
      refetchLibraryFolders();
      setActiveFolder();
      close();
    } else {
      setError("Something went wrong.");
    }
  }

  return (
    <div className="flex flex-col gap-6 px-8 md:px-0 pb-6 md:pb-0">
      <p className="text-center">
        Are you sure you want to delete the folder{" "}
        <span className="font-bold">{activeFolder.name}</span>?
      </p>
      <div className="w-full justify-center flex gap-3">
        <ButtonWithIcon
          text="Cancel"
          onClick={close}
          icon={IconOption.CANCEL}
        />
        <ButtonWithIcon
          text="Yes, delete"
          onClick={deleteFolder}
          icon={IconOption.CHECKMARK}
        />
        {error && (
          <p className="text-red-500 self-center text-sm text-center">
            {error}
          </p>
        )}
      </div>
    </div>
  );
}

import { useState } from "react";
import { useServiceEngagements } from "../../backend/resources/services/serviceEngagement";
import { CareCentralRoute, SharedRoute, useAppNavigate } from "../../lib/routing";
import { ServiceFilterTypes } from "../../state/serviceFilters/serviceFilters";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
import { PageContainer } from "../PageContainer";
import { PageMainHeader } from "../PageMainHeader";
import ServicesTable from "../Tables/ServicesTable";
import Tabs from "../Tabs/Tabs";
import ServiceProvidersTab from "./components/ServiceProvidersTab";
import ServiceRequestsTab from "./components/ServiceRequestsTab";

export enum ServicesHubTabs {
  SERVICE_REQUESTS = "Service Requests",
  SERVICES = "Services",
  PROVIDERS = "Providers"
};

const FILTER_CONFIG = {
  [ServiceFilterTypes.ORG]: true,
  [ServiceFilterTypes.CARESPACE]: true,
  [ServiceFilterTypes.PROVIDER]: true,
  [ServiceFilterTypes.SERVICE_TYPE]: true,
  [ServiceFilterTypes.HIDE_INACTIVE]: true,
}


export default function ServiceHubPage() {
  const navigate = useAppNavigate()
  const [currentTab, setCurrentTab] = useState<ServicesHubTabs>(ServicesHubTabs.SERVICE_REQUESTS);
  const { data } = useServiceEngagements();

  return (
    <PageContainer >
      <PageMainHeader text={"Service Hub"} />
      <div className="flex">
        <Tabs
          tabs={Object.values(ServicesHubTabs)}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
        {currentTab === ServicesHubTabs.SERVICE_REQUESTS &&
          <ButtonWithIcon
            size={"small"}
            onClick={() => navigate({ path: SharedRoute.SERVICE_REQUEST_NEW })}
            text={"Create Service Request"}
            icon={IconOption.PLUS}
          />
        }
        {currentTab === ServicesHubTabs.PROVIDERS &&
          <ButtonWithIcon
            size={"small"}
            onClick={() => navigate({ path: CareCentralRoute.ADD_NEW_PROVIDER })}
            text={"Add New Provider"}
            icon={IconOption.PLUS}
          />
        }
      </div>
      {currentTab === ServicesHubTabs.SERVICE_REQUESTS && <ServiceRequestsTab />}
      {currentTab === ServicesHubTabs.SERVICES && <ServicesTable data={data} filterConfig={FILTER_CONFIG} filtersToShow={FILTER_CONFIG} />}
      {currentTab === ServicesHubTabs.PROVIDERS && <ServiceProvidersTab />}
    </PageContainer>
  );
}

import { useEffect, useState } from "react";
import { useScheduleDatesQuery } from "../../backend/resources/userPrescriptionScheduleDate/userPrescriptionScheduleDate";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useNanasDayStore } from "../../state/nanasDay";
import { LoadingSpinner } from "../LoadingSpinner";
import { PageContainer } from "../PageContainer";
import { Select } from "../Select";
import { AddPrescriptionView } from "./AddPrescriptionView";
import { EditPrescriptionView } from "./EditPrescriptionView";
import { EditScheduleView } from "./EditScheduleView";
import { IntakeView } from "./IntakeView";
import { MobileNotesView } from "./MobileNotesView";
import { NotesView } from "./NotesView";
import { PrescriptionDetailView } from "./PrescriptionDetailView";
import { ScheduleView } from "./ScheduleView";
import { getCurrentYYYYMMDD } from "./utils";

type Props = {
  should_display_create_form?: boolean;
  should_display_detail_page?: boolean;
  should_display_edit_page?: boolean;
  should_display_edit_schedule_page?: boolean;
  should_display_notes_page?: boolean;
  should_display_intake_view?: boolean;
  should_display_schedule_view?: boolean;
};

export function NanasDay(props: Props) {
  const {
    should_display_create_form,
    should_display_detail_page,
    should_display_edit_page,
    should_display_edit_schedule_page,
    should_display_notes_page,
    should_display_intake_view,
    should_display_schedule_view,
  } = props;

  const isRootView = !(
    should_display_edit_schedule_page ||
    should_display_notes_page ||
    should_display_create_form ||
    should_display_edit_page ||
    should_display_detail_page
  );

  const renderPageContent = () => {
    if (should_display_edit_schedule_page) return <EditScheduleView />;
    if (should_display_notes_page) return <MobileNotesView />;
    if (should_display_create_form) return <AddPrescriptionView />;
    if (should_display_edit_page) return <EditPrescriptionView />;
    if (should_display_detail_page) return <PrescriptionDetailView />;
    return null;
  };

  return (
    <>
      {isRootView ? (
        <NanasDayRootView
          should_display_intake_view={should_display_intake_view}
          should_display_schedule_view={should_display_schedule_view}
        />
      ) : (
        <PageContainer>{renderPageContent()}</PageContainer>
      )}
    </>
  );
}

function NanasDayRootView({ should_display_intake_view }: Props) {
  const [selectedTab, setSelectedTab] = useState<"schedule" | "notes" | "intake">(
    "schedule"
  );
  const selectedScheduleDate = useNanasDayStore(
    (state) => state.selectedScheduleDate
  );
  const setSelectedDate = useNanasDayStore((state) => state.setSelectedDate);

  const { isMobile } = useWindowSize();

  const { scheduleDates, isLoadingScheduleDates } = useScheduleDatesQuery();

  const scheduleDateSelectOptions = (scheduleDates || []).reduce<
    { value: string; label: string }[]
  >((acc, val) => {
    if (val.unique_date) {
      acc.push({
        value: val.unique_date,
        label: val.unique_date,
      });
    }
    return acc;
  }, []);

  useEffect(() => {
    const selectedScheduleDateOption = scheduleDateSelectOptions.find(
      (scheduleDate) => scheduleDate.value === selectedScheduleDate
    );

    if (!selectedScheduleDateOption) {
      setSelectedDate(getCurrentYYYYMMDD());
    }
  }, []);

  if (should_display_intake_view) {
    return <IntakeView />;
  } else {
    return (
      <div className="flex flex-col h-full gap-6">
        {/* header */}
        {isMobile ? (
          <div className="flex w-full justify-between p-1">
            {/* center  */}
            <div className="flex-grow flex flex-col items-center justify-center gap-2">
              {isLoadingScheduleDates ? (
                <LoadingSpinner className="w-6 h-6" />
              ) : (
                <div>
                  <Select
                    borderClass="border-none"
                    onChange={(item) => {
                      setSelectedDate(item);
                    }}
                    disabled={scheduleDateSelectOptions?.length === 0}
                    options={scheduleDateSelectOptions}
                    currentOption={
                      scheduleDateSelectOptions.find(
                        (scheduleDate) =>
                          scheduleDate.value === selectedScheduleDate
                      ) ||
                      scheduleDateSelectOptions.find(
                        (scheduleDate) =>
                          scheduleDate.value ===
                          scheduleDateSelectOptions?.[0]?.value
                      )
                    }
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
          
            {/* tabs */}
            <div className="flex gap-4">
              <button
                onClick={() => {
                  setSelectedTab("schedule");
                }}
                className={`text-xl font-light  ${selectedTab === "schedule" && "text-brand-orange font-medium"
                  }`}>
                Schedule
              </button>
              <button
                onClick={() => {
                  setSelectedTab("notes");
                }}
                className={`text-xl font-light  ${selectedTab === "notes" && "text-brand-orange font-medium"
                  }`}>
                Notes
              </button>
              <button
                onClick={() => {
                  setSelectedTab("intake");
                }}
                className={`text-xl font-light  ${selectedTab === "intake" && "text-brand-orange font-medium"
                  }`}>
                Intake
              </button>
            </div>
            {/* header */}
            <div className="flex items-center gap-4">
              <span>Date</span>
              {isLoadingScheduleDates ? (
                <LoadingSpinner className="w-6 h-6" />
              ) : (
                <Select
                  onChange={(item) => {
                    setSelectedDate(item);
                  }}
                  disabled={scheduleDateSelectOptions?.length === 0}
                  options={scheduleDateSelectOptions}
                  currentOption={
                    scheduleDateSelectOptions.find(
                      (scheduleDate) =>
                        scheduleDate.value === selectedScheduleDate
                    ) ||
                    scheduleDateSelectOptions.find(
                      (scheduleDate) =>
                        scheduleDate.value ===
                        scheduleDateSelectOptions?.[0]?.value
                    )
                  }
                />
              )}
            </div>
          </>
        )}
        {/* content */}
        {selectedTab === "schedule" ? <ScheduleView /> : null}
        {selectedTab === "notes" ? <NotesView /> : null}
        {selectedTab === "intake" ? <IntakeView /> : null}
      </div>
    );
  }
}

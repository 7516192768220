import { useWindowSize } from "../../../hooks/useWindowSize";
import AlfredIcon from "../../Alfred/Alfred";
import { CollapseButton } from "../ChatGptSideBar";
import { MessagesThread } from "../MessagesThread";

export function SideBarLocalSearchPage() {
  const { isMobile } = useWindowSize();
  return (
    <div className="flex flex-col max-h-full h-full w-full">
      <div className="py-3 px-2">{!isMobile && <CollapseButton />}</div>
      <MessagesThread
        threadId={"localSearchPage"}
        pageType="localSearchPage">
        <InitialContent />
      </MessagesThread>
    </div>
  );
}

function InitialContent() {
  return (
    <div className="flex min-w-0 gap-3 pb-6">
      <AlfredIcon className="flex-shrink-0 w-8 h-8 mt-1" />
      {/* text and action buttons */}
      <div className="flex flex-col gap-6 bg-gray-50  text-sm px-4 py-4 rounded-md">
        {/* intro PP */}
        <p>
          This is Local Search, where you can either search for local resources
          like a cleaning service, or for doctors that you are interested in
          seeing. Once you see something you like, you can send that listing to
          your care team for a review and/or referral by clicking on the
          referral button on the listing card.
        </p>
      </div>
    </div>
  );
}

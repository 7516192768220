import DashboardIcon from "../../assets/dashboard.png";
import { ReactComponent as GridIcon } from "../../assets/grid.svg";
import { ReactComponent as ListIconSvg } from "../../assets/list-icon.svg";

export enum CardLayoutTypes {
  THUMBNAIL = "thumbnail",
  DEFAULT = "default",
  DASHBOARD = "skinny",
}

export default function CardLayoutSelector({
  activeLayout,
  setActiveLayout,
  showListMode,
  className,
}: {
  activeLayout: CardLayoutTypes;
  setActiveLayout: any;
  showListMode?: boolean;
  className?: string;
}) {
  return (
    <div className={`flex self-end ${className}`}>
      {showListMode ? (
        <button
          className={`flex items-center gap-2 pr-2 py-1 text-sm ${
            activeLayout === "skinny"
              ? "saturate-100"
              : "saturate-0 focus:saturate-50 hover:saturate-50"
          }`}
          onClick={() => setActiveLayout("skinny")}>
          <img
            className="h-6"
            src={DashboardIcon}
            alt="Dash"
          />
        </button>
      ) : null}
      <button
        className={`flex items-center gap-2 pr-2 py-1 text-sm ${
          activeLayout === "default"
            ? "saturate-100"
            : "saturate-0 focus:saturate-50 hover:saturate-50"
        }`}
        onClick={() => setActiveLayout("default")}>
        <ListIconSvg className="w-6 h-6" />
      </button>
      <button
        className={`flex items-center gap-2 py-1 text-sm ${
          activeLayout === "thumbnail"
            ? "saturate-100"
            : "saturate-0 focus:saturate-50 hover:saturate-50"
        }`}
        onClick={() => setActiveLayout("thumbnail")}>
        <GridIcon className="w-6 h-6" />
      </button>
    </div>
  );
}

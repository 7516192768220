import MuiSlider from '@mui/material/Slider';
import { SLIDER_COLORS, getColor } from '../../../utils';
import './SliderStyles.css';

interface SliderProps {
  disabled: boolean | undefined;
  currentAnswer: number | undefined;
  updateAnswer: (obj: { answer?: number; context?: string }) => void;
  scale: number;
  options?:string[]
}


export function Slider({
  disabled,
  currentAnswer,
  updateAnswer,
  scale,
  options
}: SliderProps) {
  // dynamically setting styling for slider
  const sliderStyle = {
    '--slider-linear-gradient': `linear-gradient(to right, ${SLIDER_COLORS.join(', ')})`,
    '--slider-thumb-color': getColor(currentAnswer, scale),
  } as React.CSSProperties;

  // Stores

  // Handlers
  const handleSliderChange = (_: Event, newValue: number | number[]) => {
    const value = Array.isArray(newValue) ? newValue[0] : newValue;
    updateAnswer({ answer: value });
  };

  // -----*-----*-----*-----* Render *-----*-----*-----*-----*
  return (
    <MuiSlider
      className={`${disabled ? "saturate-0" : ""}`}
      style={sliderStyle}
      disabled={disabled}
      defaultValue={currentAnswer ?? 0}
      value={currentAnswer ?? 0}
      onChange={handleSliderChange}
      aria-labelledby="discrete-slider"
      valueLabelDisplay="auto"
      step={1}
      marks={Array.from({ length: scale + 1 }, (_, i) => ({
        value: i,
        label: options?.[i] ?? i.toString(),
      }))}
      min={0}
      max={scale}
    />
  );
}
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUserAdloById } from "../../backend/resources/userAdlo";
import {
  useAdloNoteQuery,
  useUpsertAdloNote,
} from "../../backend/resources/userNote";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useNavigateBack } from "../../lib/routing";
import BackButton from "../BackButton/BackButton";
import BottomActionBar from "../BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
import FooterButtons from "../FooterButtons/FooterButtons";
import { LoadingSpinner } from "../LoadingSpinner";
import ModalView from "../ModalView/ModalView";
import { PageContainer } from "../PageContainer";
import { TextArea } from "../TextArea";

export function AdloNotes() {
  const { isMobile } = useWindowSize();
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing && isMobile) {
    return (
      <ModalView
        isOpen={true}
        closeText="Back"
        onClose={() => {
          setIsEditing(false);
        }}>
        <AdloNotesComponent
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      </ModalView>
    );
  } else {
    return (
      <AdloNotesComponent
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    );
  }
}

function AdloNotesComponent({
  isEditing,
  setIsEditing,
}: {
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigateBack = useNavigateBack()
  const { isMobile } = useWindowSize();
  const { adloId } = useParams();
  const { data: userAdlo, isLoading: isLoadingUserAdlo } =
    useUserAdloById(adloId);
  const { adloNote, isLoadingAdloNote } = useAdloNoteQuery(adloId);
  const adloNoteUpdatedAt = adloNote?.adlo_note_updated_at;
  const saveAdloNote = useUpsertAdloNote().mutateAsync;
  const [currentNote, setCurrentNote] = useState(adloNote?.note);
  const [isModified, setIsModified] = useState(false);

  function handleNoteChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setCurrentNote(e.target.value);
    setIsModified(e.target.value !== adloNote?.note);
  }

  function saveNote() {
    if (isModified) {
      saveAdloNote({
        user_adlo_id: adloId,
        note: currentNote,
        adlo_note_updated_at: new Date().toISOString(),
      });
      setIsModified(false);
    }
  }

  // Update currentNote and isModified when note changes (e.g. refetch or on load)
  useEffect(() => {
    setCurrentNote(adloNote?.note);
    setIsModified(false);
  }, [adloNote]);

  return (
    <PageContainer>
      <div className="flex flex-col h-full pb-36 md:px-6 md:pb-0">
        {!isEditing ? (
          <BackButton className="mb-6 md:-ml-6" />
        ) : null}
        {/* title and select component */}
        <div className="flex justify-center">
          {isLoadingUserAdlo ? (
            <LoadingSpinner className="w-5 h-5" />
          ) : (
            <div className="flex flex-col items-center gap-2">
              <h1 className="text-2xl font-light">
                {isEditing ? "Editing " : ""} Notes for {userAdlo?.first_name}
              </h1>
              <span className="text-sm">
                Last updated:{" "}
                {adloNoteUpdatedAt
                  ? new Date(adloNoteUpdatedAt).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                  : "Not updated yet"}
              </span>
            </div>
          )}
        </div>
        {/* content */}
        {isLoadingAdloNote ? (
          <LoadingSpinner className="w-20 h-20" />
        ) : (
          <TextArea
            className="flex-grow w-full mt-6"
            data-hj-allow
            value={currentNote || ""}
            onChange={handleNoteChange}
            disabled={isMobile && !isEditing}
          />
        )}
        {!isMobile ? (
          <FooterButtons>
            <ButtonWithIcon
              disabled={!isModified}
              text="Save"
              onClick={(e: any) => {
                e.stopPropagation();
                saveNote();
              }}
              icon={IconOption.CHECKMARK}
            />
          </FooterButtons>
        ) : isEditing ? (
          <FooterButtons>
            <ButtonWithIcon
              onClick={() => {
                setIsEditing(false);
              }}
              icon={IconOption.CANCEL}
              text="Cancel"
            />
            <ButtonWithIcon
              disabled={!isModified}
              text="Save"
              onClick={(e: any) => {
                e.stopPropagation();
                saveNote();
              }}
              icon={IconOption.CHECKMARK}
            />
          </FooterButtons>
        ) : (
          <BottomActionBar
            centerSlot={
              <ButtonWithIcon
                onClick={() => setIsEditing(true)}
                text={""}
                icon={IconOption.EDIT}
              />
            }
          />
        )}
      </div>
    </PageContainer>
  );
}

import type { VariantProps } from "class-variance-authority";
import type { UserBookmarkWithGuidanceResource } from "../../../../../backend/resources/userBookmark";
import { useGuidanceResourceByUserInterventionId } from "../../../../../backend/resources/userGuidanceResource";
import {
  useIntervention,
  type UserAssessmentChatGptIntervention,
} from "../../../../../backend/resources/userIntervention";
import { CarePilotRoute, useAppNavigate } from "../../../../../lib/routing";
import type { cardStyles } from "../../../../Card/Card";
import InterventionComponent from "../../../../GuidancePage/Recommendations/Recommendation/Interventions/Intervention/InterventionComponent";

interface Props {
  bookmark: UserBookmarkWithGuidanceResource & {
    user_intervention: UserAssessmentChatGptIntervention;
  };
}

export function InterventionBookmark({
  bookmark,
  ...variantProps
}: Props & VariantProps<typeof cardStyles>) {
  const interventionId = bookmark.user_intervention?.id;
  const navigate = useAppNavigate();

  const { intervention } = useIntervention(interventionId);
  const { userGuidanceResource } = useGuidanceResourceByUserInterventionId(
    intervention?.id
  );
  if (!intervention) return null;
  return (
    <button
      style={{ textAlign: "inherit" }}
      className="flex flex-col flex-grow justify-center w-full h-full"
      onClick={() => {
        if (bookmark.user_intervention_id) {
          navigate({
            path: CarePilotRoute.INTERVENTION,
            params: {
              id: bookmark.user_intervention_id
            },
            queryParams: {
              "show-go-to-recommendation-button": String(true)
            }
          })
        }
      }}>
      <InterventionComponent
        intervention={intervention}
        guidanceResource={userGuidanceResource?.guidance_resource}
        {...variantProps}
      />
    </button>
  );
}

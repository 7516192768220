import { useState } from "react";
import { YellowPagesListing } from "../../../backend/functions";
import { ResponsiveModal } from "../../../shared/ui/responsive-modal";
import { ButtonWithIcon, IconOption } from "../../ButtonWithIcon";
import { Card } from "../../Card/Card";
import { Checkbox } from "../../Checkbox";
import { ProfileImage } from "../../Profile/ProfileImage";
import LocalResourceDetailView from './LocalResourceDetailView';
import LocalResourceServiceRequestPopupButton from './LocalResourceServiceRequestPopupButton';

interface LocalResourceCardProps {
  listing?: YellowPagesListing | null;
  selectedLocalResources?: YellowPagesListing[] | undefined | null;
  onSelectLocalResource?: (value: YellowPagesListing | null) => void;
  hideCheckbox?: boolean;
  showServiceRequestButton?: boolean;
  isCheckBoxSquare?: boolean;
}
export function LocalResourceCard({ listing, selectedLocalResources, onSelectLocalResource, hideCheckbox, showServiceRequestButton, isCheckBoxSquare }: LocalResourceCardProps) {
  const [localResourceFocus, setLocalResourceFocus] =
    useState<YellowPagesListing | null>(null);
  if (!listing) return null;
  return <div className="flex gap-2 px-1 items-center ">
    {localResourceFocus && <ResponsiveModal isOpen={!!localResourceFocus} closeText="Back" onClose={() => setLocalResourceFocus(null)} title={undefined}>
      <LocalResourceDetailView
        listing={localResourceFocus}
      />
    </ResponsiveModal>}
    {!hideCheckbox &&
      <Checkbox
        isRounded={!isCheckBoxSquare}
        isChecked={selectedLocalResources?.some(resource => resource?.listing_id === listing.listing_id) ?? false}
        onCheck={(isChecked) => { if (isChecked && onSelectLocalResource) { onSelectLocalResource(listing) } }}
      />
    }
    <Card color="plum">
      <button
        type="button"
        className="h-4/6"
        onClick={() => setLocalResourceFocus(listing)}>
        <div className="flex items-center pb-6 h-[90%] h-max-full gap-3">
          {/* left half */}
          <ProfileImage className="w-[6rem] w-min-[6rem] h-[6rem]" />
          {/* right half */}
          <div>{listing.name}</div>
        </div>
      </button>
      <div className="flex w-full items-center border-t-[1px] border-gray-400 gap-2 p-2 pb-0">
        {/* phone number */}
        {listing.phone ? (
          <ButtonWithIcon
            text=""
            icon={IconOption.PHONE}
            onClick={() => {
              window.open(`tel:${listing.phone}`);
            }}
          />
        ) : null}
        {listing.email ? (
          <ButtonWithIcon
            type="button"

            text=""
            icon={IconOption.EMAIL}
            onClick={() => {
              window.open(`mailto:${listing.email}`);
            }}
          />
        ) : null}
        {listing.website ? (
          <ButtonWithIcon
            type="button"
            text=""
            icon={IconOption.WEB}
            onClick={() => {
              if (listing.website) {
                window.open(listing.website, "_blank");
              }
            }}
          />
        ) : null}
        {showServiceRequestButton && <LocalResourceServiceRequestPopupButton listing={listing} />}
      </div>
    </Card>
  </div>
}


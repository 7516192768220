import Skeleton from "react-loading-skeleton";

import { useBookmarkQuery } from "../../../backend/resources/userBookmark";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { CardLayoutTypes } from "../../CardLayoutSelector/CardLayoutSelector";
import { Bookmark } from "./Bookmark";

export function Bookmarks({ activeLayout }: { activeLayout: CardLayoutTypes }) {
  const { isMobile } = useWindowSize();
  const {
    isLoadingBookmarks,
    bookmarks,
    isFetchingBookmarks,
    refetchBookmarks,
  } = useBookmarkQuery();
  const hasBookmarks = bookmarks && bookmarks.length > 0;

  return (
    <div className="flex flex-col gap-3 w-full ">
      {!hasBookmarks && !isLoadingBookmarks && (
        <p className="mt-10 font-light">No bookmarks yet.</p>
      )}

      <div className="flex flex-wrap gap-2 md:gap-6 mt-8 justify-center md:justify-start w-full">
        {isLoadingBookmarks ? (
          <Skeleton
            count={3}
            containerClassName="w-full flex gap-4 w-[700px] h-[200px]"
          />
        ) : (
          hasBookmarks &&
          bookmarks.map((bookmark: any) => (
            <Bookmark
              key={bookmark.id}
              hideActionbar={isMobile}
              bookmark={bookmark}
              refetchBookmarks={() => {
                refetchBookmarks();
              }}
              size={activeLayout}
            />
          ))
        )}
      </div>
    </div>
  );
}

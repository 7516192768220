import type { ShareableInterventionType } from "../../../../../../backend/resources/shareableIntervention/shareableIntervention";
import { CareCentralRoute, useAppNavigate } from "../../../../../../lib/routing";
import { Card } from "../../../../../Card/Card";
import InterventionComponent from "../../../Recommendation/Interventions/Intervention/InterventionComponent";

export default function ShareableIntervention({
  intervention,
  isEditing,
}: {
  intervention: ShareableInterventionType;
  isEditing?: boolean;
}) {
  const navigate = useAppNavigate();
  return (
    <Card color="darkBlue">
      <button
        className="overflow-clip max-h-full h-full"
        onClick={() => {
          if (isEditing) {
            navigate({
              path: CareCentralRoute.SHAREABLE_INTERVENTION_ADD_ID,
              params: {
                id: intervention.id
              }
            })
          } else {
            navigate({
              path: CareCentralRoute.SHAREABLE_INTERVENTION,
              params: {
                id: intervention.id
              }
            })
          }
        }}>
        <InterventionComponent
          intervention={intervention}
          guidanceResource={undefined}
        />
      </button>
    </Card>
  );
}

import { useState } from "react";
import { useAlfredPageSideBar } from "../../backend/resources/chatGptConversation";
import { useOrgs } from "../../backend/resources/orgRole";
import { PageContainer } from "../PageContainer";
import { PageMainHeader } from "../PageMainHeader";
import { ProfilePage } from "../Profile";
import SettingsPage from "../SettingsPage/SettingsPage";
import Tabs from "../Tabs/Tabs";

export enum MyAccountTab {
  Profile = "Profile",
  Settings = "Settings",
}


export default function MyAccountPage() {
  const [tab, setTab] = useState<MyAccountTab>(MyAccountTab.Profile);

  // Queries
  useAlfredPageSideBar("adminPage")
  const { hasCareCentralAccess } = useOrgs();

  return (
    <PageContainer>
      <div className="flex flex-col w-full pb-20 gap-2">
        <PageMainHeader text="My Account" />
        <Tabs
          tabs={ [MyAccountTab.Profile, MyAccountTab.Settings]}
          currentTab={tab}
          setCurrentTab={setTab}
        />
        {tab === MyAccountTab.Profile && <ProfilePage />}
        {tab === MyAccountTab.Settings && <SettingsPage />}
      </div>
    </PageContainer>

  );
}

import { useState } from "react";
import { ReactComponent as NotTakenCircleSvg } from "../../assets/ellipse-circle.svg";
import { ReactComponent as TakenCircleSvg } from "../../assets/taken-circle.svg";
import { createOrgAndIdentity } from "../../backend/functions";
import { OrgRoleType } from "../../backend/resources/orgRole";
import { ResponsiveModal } from "../../shared/ui/responsive-modal";
import { useAppOnboardingStore } from "../../state/appOnboarding";
import { resetAssessmentStore } from "../../state/assessment";
import { useUserStore } from "../../state/user";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";

export function UserRoleForm() {
  const [open, setOpen] = useState(true)

  const authUser = useUserStore((state) => state.user);
  const resetUserStore = useUserStore((state) => state.reset);
  const signOut = useUserStore((state) => state.signOut)

  // local form state
  const [selectedPersona, setSelectedPersona] = useState<UserPersona>("family");
  const [isCreatingOrg, setIsCreatingOrg] = useState(false);

  // persisted store
  const setSelectedOrgRole = useAppOnboardingStore(
    (state) => state.setSelectedOrgRole
  );
  const setCurrentCarePilotOnboardingPage = useAppOnboardingStore(
    (state) => state.setCurrentCarePilotOnboardingPage
  );
  const setCareCentralOnboardingPage = useAppOnboardingStore(
    (state) => state.setCareCentralOnboardingPage
  );

  async function handleSubmit(e: any) {
    e.stopPropagation();
    switch (selectedPersona) {
      case "family": {
        if (authUser) {
          setIsCreatingOrg(true);
          await createOrgAndIdentity({
            authId: authUser?.id,
            orgRole: OrgRoleType.ADMIN,
            orgName: "",
            isFamilyOrg: true,
          });
          setIsCreatingOrg(false);
          setCurrentCarePilotOnboardingPage("welcome")
        }
        break;
      }
      default: {
        setSelectedOrgRole(getOrgRoleForUserPersona(selectedPersona));
        setCareCentralOnboardingPage("welcome");
      }
    }
  }

  async function handleLogout(e: any) {
    e.stopPropagation();
    resetUserStore();
    resetAssessmentStore();
    await signOut();
  }

  return (
    <ResponsiveModal
      isOpen={open}
      onClose={setOpen}
      isNetworkCallInProgress={isCreatingOrg}
      title={"Select a Role"}
      showLogo={"base"}
      isLogoCentered={true}
      logoSize="large"
      footerButtons={
        <>
          <div className="flex w-full">
            <ButtonWithIcon
              onClick={handleLogout}
              text="Logout"
              icon={IconOption.LOGOUT}
            />
          </div>
          <ButtonWithIcon
            disabled={isCreatingOrg}
            onClick={handleSubmit}
            text="Next"
            icon={IconOption.ARROW}
          />
        </>
      }
      fixedHeight="h-[80vh]"
    >
      <div className="flex flex-col flex-grow gap-2">
        <RoleCheckboxOption
          persona={"doctor"}
          isSelected={selectedPersona === "doctor"}
          setSelectedRole={setSelectedPersona}
        />
        <RoleCheckboxOption
          persona={"professional caregiver"}
          isSelected={selectedPersona === "professional caregiver"}
          setSelectedRole={setSelectedPersona}
        />
        <RoleCheckboxOption
          persona={"admin"}
          isSelected={selectedPersona === "admin"}
          setSelectedRole={setSelectedPersona}
        />
      </div>
    </ResponsiveModal>
  )
}

type UserPersona = "family" | "doctor" | "professional caregiver" | "admin";

function RoleCheckboxOption({
  persona,
  isSelected,
  setSelectedRole,
}: {
  persona: UserPersona;
  isSelected: boolean;
  setSelectedRole: any;
}) {
  if (isSelected) {
    return (
      <div className="flex items-center p-3 gap-3 text-start">
        <TakenCircleSvg className="w-7 h-7 shrink-0" />
        <p>{getUserPersonaOptionText(persona)}</p>
      </div>
    );
  } else {
    return (
      <button
        className={`flex items-center p-3 gap-3 text-start`}
        onClick={async () => {
          setSelectedRole(persona);
        }}>
        <NotTakenCircleSvg className="w-6 h-6 shrink-0" />
        <p className="break-words">{getUserPersonaOptionText(persona)}</p>
      </button>
    );
  }
}

function getUserPersonaOptionText(persona: UserPersona) {
  switch (persona) {
    case "doctor": {
      return "I am a doctor or nurse.";
    }
    case "professional caregiver": {
      return "I am a Care Navigator.";
    }
    case "admin": {
      return "I am an owner/administrator for a care based agency, e.g., hospital, practice or senior care facility";
    }
    default: {
      return "Other.";
    }
  }
}

function getOrgRoleForUserPersona(persona: UserPersona): OrgRoleType {
  switch (persona) {
    case "family":
    case "admin": {
      return OrgRoleType.ADMIN;
    }
    case "doctor": {
      return OrgRoleType.DOCTOR;
    }
    case "professional caregiver": {
      return OrgRoleType.CARE_NAVIGATOR;
    }
  }
}

import { createPortal } from "react-dom";
import { ReactComponent as ErrorIcon } from "../../assets/error.svg";

export default function DeletedUserPage() {
  return createPortal(
    <div className="text-center md:text-left bg-white px-8 w-full h-full flex flex-col items-center justify-center overflow-y-scroll text-lg absolute z-50">
      <ErrorIcon className="w-20 h-20 justify-center flex  bg-[#bd3e30] rounded-full mb-10" />
      <h1 className="text-2xl font-bold mb-10">
        The account Admin has de-activated your account. Contact them to
        re-activate.
      </h1>
      <p>
        If you need additional support or want to keep using Craniometrix,
        contact support@craniometrix.com
      </p>
    </div>,
    document.body
  );
}

import { useUserAssessmentsInNetworkForAssessment } from "../../../backend/resources/userAssessment";
import {
  useAssessmentStore,
} from "../../../state/assessment";
import { createDropDownOptions } from "../../../utils";
import { Select } from "../../Select/Select";

export default function AssessmentDropdown({
  assessmentId,
  selectedCaregiver,
  viewMode = "dropdown"
}: {
  assessmentId?: string;
  selectedCaregiver?: string;
  viewMode?: "dropdown" | "text";
}) {
  const { data: pastAssessments } = useUserAssessmentsInNetworkForAssessment(assessmentId)
  const activeUserAssessmentId = useAssessmentStore(
    (state) => state.activeUserAssessmentId
  );
  const setActiveUserAssessmentId = useAssessmentStore((state) => state.setActiveUserAssessmentId);

  const options = createDropDownOptions(pastAssessments?.filter((assessment) => !selectedCaregiver || assessment.subject_caregiver_id === selectedCaregiver));

  if (!options || options.length === 0) {
    return null;
  } else {
    return (
      <div className="flex items-center gap-4 mb-2">
        <p> Date Completed</p>

        {viewMode === "text" ? <p>
          {options.find(
            (option) => option.value === activeUserAssessmentId
          )?.label}
        </p> :
          <Select
            onChange={(item) => {
              setActiveUserAssessmentId(item);
            }}
            options={
              options.length > 0 ? options : [{ label: "None", value: "None" }]
            }
            currentOption={options.find(
              (option) => option.value === activeUserAssessmentId
            )}
          />}
      </div>
    );
  }
}

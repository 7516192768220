import { toDollar } from "../../utils";

export default function BillingPageBanner({ text, color, patientCount, amount, numColumns }: { text: string, color: string, patientCount?: number, amount?: number, numColumns?: number }) {
  return <div style={{ backgroundColor: color, gridTemplateColumns: `repeat(${numColumns}, minmax(0, 1fr))` }} className={`px-3 py-1 w-full grid text-lg font-bold text-black rounded-lg`}>
    <p className="w-[300px] ">{text}</p>
    <p />
    <p />
    <p className="text-center">{patientCount}</p>
    <p className="text-end ">{amount ? toDollar(amount) : ''}</p>
  </div>
}

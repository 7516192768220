import { useUserAdloInUserAssessment } from "../../../../../../backend/resources/userAssessment";
import {
  UserBookmarkType,
  useBookmarkQuery,
  useBookmarkQueryByInterventionId,
} from "../../../../../../backend/resources/userBookmark";
import { useGuidanceResourceByUserInterventionId } from "../../../../../../backend/resources/userGuidanceResource";
import type { UserAssessmentChatGptIntervention } from "../../../../../../backend/resources/userIntervention";
import { useRecommendation } from "../../../../../../backend/resources/userRecommendation";
import { CarePilotRoute, useAppNavigate } from "../../../../../../lib/routing";
import BookmarkableCard from "../../../../../Card/BookmarkableCard";
import { Checkbox } from "../../../../../Checkbox";
import InterventionComponent from "./InterventionComponent";

export default function InterventionCard({
  intervention,
}: {
  intervention: UserAssessmentChatGptIntervention;
}) {
  const navigate = useAppNavigate();
  /**
   * State
   */
  const { refetchBookmarks } = useBookmarkQuery();
  const { refetchUserBookmark } = useBookmarkQueryByInterventionId(
    intervention.id
  );
  const { userGuidanceResource } = useGuidanceResourceByUserInterventionId(
    intervention?.id
  );
  const { recommendation } = useRecommendation(
    intervention.user_recommendation_id
  );
  const { data: userAdlo } = useUserAdloInUserAssessment(
    recommendation?.user_assessment_id
  );

  return (
    <BookmarkableCard
      userAdlo={userAdlo}
      bookmarkableItem={{
        itemId: intervention.id,
        type: UserBookmarkType.INTERVENTION,
      }}
      refetchBookmarks={() => {
        refetchBookmarks();
        refetchUserBookmark();
      }}>
      <button
        className="overflow-clip max-h-full h-full w-full"
        onClick={() => {
          navigate({
            path: CarePilotRoute.INTERVENTION,
            params: {
              id: intervention.id
            }
          })
        }}>
        <InterventionComponent
          intervention={intervention}
          guidanceResource={userGuidanceResource?.guidance_resource}
        />
      </button>
      {intervention.is_marked_done ? (
        <div className="absolute -top-2 -right-2 rounded-full overflow-clip">
          <Checkbox isChecked={intervention.is_marked_done} />
        </div>
      ) : null}
    </BookmarkableCard>
  );
}

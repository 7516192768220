import { Cell, Row, flexRender } from "@tanstack/react-table";
import { useSignedUrl } from "../../hooks/useSignedUrl";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
import Who, { UserType } from "../MyPlanPage/components/Who";
import { DeleteOptions, InfiniteScrollingTable, TableDataRowType } from "./InfiniteScrollingTable";

export enum DocumentTableFields {
  File = "File",
  Name = "Name",
  Date = "Date",
  Who = "Who",
}

type BaseDocumentsTableType = {
  [DocumentTableFields.File]?: File;
  [DocumentTableFields.Name]: string;
  [DocumentTableFields.Date]: string;
  [DocumentTableFields.Who]: string;
};
type DocumentsTableType = BaseDocumentsTableType & TableDataRowType<BaseDocumentsTableType>;

interface DocumentsTableProps {
  documents: DocumentsTableType[] | undefined;
  deleteOptions?: DeleteOptions<DocumentsTableType>;
  networkId: string;
}

export function DocumentsTable({ documents, deleteOptions, networkId }: DocumentsTableProps) {
  return (
    <InfiniteScrollingTable
      data={documents}
      mobileColumns={[DocumentTableFields.File, DocumentTableFields.Name]}
      deleteOptions={deleteOptions}
      columnFields={[DocumentTableFields.File, DocumentTableFields.Name, DocumentTableFields.Date, DocumentTableFields.Who]}
      handleClick={() => { }}
      hiddenColumnHeaders={[DocumentTableFields.File]}
      CellContent={function (props: { cell: Cell<DocumentsTableType, unknown>; row: Row<DocumentsTableType>; }) {
        const completeFileName = `${networkId}/${props.cell.row.original.Name}`
        const { signedUrl } = useSignedUrl({
          path: completeFileName,
          bucket: "documents",
        });
        switch (props.cell.column.id) {
          case DocumentTableFields.File:
            return (
              <ButtonWithIcon
                onClick={() => {
                  if (signedUrl) {
                    window.open(signedUrl, "_blank")
                  } else if (props.row.original[DocumentTableFields.File]) {
                    const url = URL.createObjectURL(props.row.original[DocumentTableFields.File]);
                    window.open(url, "_blank");
                  }
                }}
                text={""}
                size={"small"}
                icon={IconOption.DOWNLOAD}
              />
            );
          case DocumentTableFields.Who:
            return <div className='flex w-min-[60px] w-[60px]'>
              <Who
                userType={UserType.BOTH}
                entry={null}
                selectedPlanEntryOwnerId={props.cell.row.original.Who}
                setPlanEntryOwner={() => { }}
              />
            </div>
          case DocumentTableFields.Name:
            const fileName = props.cell.row.original.Name;
            const truncatedFileName = fileName?.includes('/') ? fileName?.split('/').pop() : fileName;
            return <p className='truncate w-[220px] md:w-[350px]'>{truncatedFileName}</p>
          default:
            return <div className='truncate'>
              {flexRender(props.cell.column.columnDef.cell, props.cell.getContext())}
            </div>;
        }
      }}
    />
  )
}
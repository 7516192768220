import { useParams } from "react-router-dom";
import { useChatGptMessages } from "../../../backend/resources/chatGptMessage";
import { MessagesThread } from "../MessagesThread";

export function ServiceRequestDiscussion() {
  const { id: serviceRequestId } = useParams();
  const { messages } = useChatGptMessages(serviceRequestId, "serviceRequest");
  
  return (
    <div className="flex flex-col max-h-[40rem] flex-grow w-full h-full">
      <MessagesThread
        threadId={serviceRequestId}
        pageType="serviceRequest">
        {!messages && <InitialContent />}{" "}
      </MessagesThread>
    </div>
  );
}

function InitialContent() {
  return (
    <div className="flex flex-col pt-6 text-sm">
      <p>No Discussion yet. Write into the chat below to get started.</p>
    </div>
  );
}

import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CarePlanGenerationStatuses, useCarePlanGenerationStatus } from "../../../backend/resources/carePlanGenerationStatus";
import { useOrgs } from "../../../backend/resources/orgRole";
import { usePastAssessments } from "../../../backend/resources/userAssessment";
import { CarePilotRoute, useAppNavigate } from "../../../lib/routing";
import {
  useAssessmentStore,
} from "../../../state/assessment";
import { AssessmentComponent, AssessmentType } from "../../AssessmentComponent/AssessmentComponent";
import { ButtonWithIcon, IconOption } from "../../ButtonWithIcon";
import { Select } from "../../Select";
import { AssessmentStatus, AssessmentStatusLabels, AssessmentsTable } from "../../Tables/AssessmentsTable";
import { CareBinderTabs } from "../CareBinder";
import GenerateCarePlanSubmissionPopup from "./GenerateCarePlanSubmissionPopup";



export function AssessmentsTab() {
  const [searchParams] = useSearchParams();
  const navigate = useAppNavigate();
  // states 
  const [isCarePlanConfirmationPopupShowing, setIsCarePlanConfirmationPopupShowing] = useState(false)
  const [newAssessmentType, setNewAssessmentType] = useState<AssessmentType | undefined>(undefined);
  const assessmentId = searchParams.get("assessmentId");
  const [isOpen, setIsOpen] = useState(!!assessmentId)
  const [assessmentStatusForComprehensiveAssessments, setAssessmentStatusForComprehensiveAssessments] = useState<AssessmentStatus>(AssessmentStatus.All);
  const [assessmentStatusForScreenings, setAssessmentStatusForScreenings] = useState<AssessmentStatus>(AssessmentStatus.All);

  // Stores

  const setActiveUserAssessmentId = useAssessmentStore((state) => state.setActiveUserAssessmentId);

  // Queries
  const { data: goalStatus } = useCarePlanGenerationStatus();
  const { data: pastAssessments } = usePastAssessments();
  const { hasCareCentralAccess } = useOrgs();

  // Cnstants
  const pastComprehensiveAssessments = pastAssessments?.filter((user_assessment) => !user_assessment.assessment?.is_for_screening)
  const pastScreeningAssessments = pastAssessments?.filter((user_assessment) => user_assessment.assessment?.is_for_screening)
  const assessmentStatusOptions = Object.values(AssessmentStatusLabels).map(status => ({ value: status, label: status }))

  // Handlers
  function onCloseNewAssessment() {
    setNewAssessmentType(undefined);
    setActiveUserAssessmentId(pastAssessments?.[0]?.id);
    setIsOpen(false)
    // resets url in case we sent the user to a new assessment
    navigate({
      path: hasCareCentralAccess ? CarePilotRoute.BINDER : CarePilotRoute.ASSESSMENTS,
      queryParams: {
        tab: CareBinderTabs.ASSESSMENTS
      },
    });
  }
  // -----* Render *-----*
  return (
    <>
      {/* Modals */}
      <AssessmentComponent
        assessmentId={assessmentId ?? undefined}
        isOpen={!!newAssessmentType || isOpen}
        onClose={onCloseNewAssessment}
        newAssessmentType={newAssessmentType}
      />
      <GenerateCarePlanSubmissionPopup
        isOpen={isCarePlanConfirmationPopupShowing}
        onClose={() => {
          setIsCarePlanConfirmationPopupShowing(false);
        }}
      />
      {/*  Assessments */}
      <div className="flex gap-5">
        <p className="text-xl">Assessments</p>
        {hasCareCentralAccess && (
          <>
            <ButtonWithIcon
              onClick={() => {
                setNewAssessmentType(AssessmentType.COMPREHENSIVE)
              }}
              className="whitespace-nowrap"
              text="Take New Assessment"
              icon={IconOption.PLUS}
              disabled={goalStatus?.status === CarePlanGenerationStatuses.PENDING}
              size={"small"}
            />
            <ButtonWithIcon
              onClick={async () => {
                setIsCarePlanConfirmationPopupShowing(true);
              }}
              className="whitespace-nowrap"
              text="Generate New Care Plan"
              disabled={goalStatus?.status === CarePlanGenerationStatuses.PENDING}
              icon={IconOption.ARROW}
              size={"small"}
            />
          </>
        )}

      </div>
      {/* Pending/Ready Care Plan icon */}
      {goalStatus?.status === CarePlanGenerationStatuses.PENDING && <p className="text-sm text-zinc-400">(NOTE: New assessments will be available when the suggested care plan is ready)</p>}
      {/* Status Filter for comprehensive assessments */}
      <div className="flex gap-5 items-center">
        <p>Status</p>
        <Select
          options={assessmentStatusOptions}
          onChange={setAssessmentStatusForComprehensiveAssessments}
          currentOption={assessmentStatusOptions?.find((option) => option.value === assessmentStatusForComprehensiveAssessments)}
        />
      </div>
      {/* Table  for comprehensive assessments */}
      <AssessmentsTable data={pastComprehensiveAssessments ?? []} statusFilter={assessmentStatusForComprehensiveAssessments} />

      {/* Screening Assessments */}
      <div className="flex gap-5 items-center">
        <p className="text-xl">Screenings</p>
        {hasCareCentralAccess && <ButtonWithIcon
          onClick={() => { setNewAssessmentType(AssessmentType.SCREENINGS) }}
          className="whitespace-nowrap"
          text="Take New Screening"
          icon={IconOption.PLUS}
          disabled={goalStatus?.status === CarePlanGenerationStatuses.PENDING}
          size={"small"}
        />}
      </div>
      {/* Status Filter for screenings */}
      <div className="flex gap-5 items-center">
        <p>Status</p>
        <Select
          options={assessmentStatusOptions}
          onChange={setAssessmentStatusForScreenings}
          currentOption={assessmentStatusOptions?.find((option) => option.value === assessmentStatusForScreenings)}
        />
      </div>
      {/* Table  for screenings */}
      <AssessmentsTable data={pastScreeningAssessments ?? []} statusFilter={assessmentStatusForScreenings} />
    </>
  );
}

import { zodResolver } from "@hookform/resolvers/zod";
import { outlinedInputClasses } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRoot, FormSection, FormSectionHeader } from "../../../shared/ui/form";
import { Input } from "../../../shared/ui/input";
import { ButtonWithIcon, IconOption } from "../../ButtonWithIcon";
/**
 * schema
 */
export const patientSearchFormSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  birthday: z.string(),
});

/**
 * form hook
 */
export const usePatientSearchForm = (defaultValues?: z.infer<typeof patientSearchFormSchema>) => useForm<z.infer<typeof patientSearchFormSchema>>({
  resolver: zodResolver(patientSearchFormSchema),
  defaultValues: defaultValues ?? {},
  mode: "onSubmit",
});


export default function PatientSearchForm(props: { onSubmit: (data: any) => void }) {

  const form = usePatientSearchForm();
  function onSubmit(data: z.infer<typeof patientSearchFormSchema>) {
    props.onSubmit(data)
  }
  return (
    <Form {...form}>
      <FormRoot id={"patient-search-form"}>
        <FormSection>
          <FormSectionHeader title="Patient Search" />
          <div className="flex gap-4 items-end">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="birthday"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Date of Birth</FormLabel>
                  <FormControl>
                    <DatePicker value={dayjs(field.value)}
                      onChange={date => field.onChange(date?.toDate().toDateString())}
                      slotProps={{
                        field: {
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                            }
                          },
                        },
                        textField: {
                          sx: {
                            fieldset: {
                              borderRadius: 2, borderColor: '#E4E4E7',
                            },
                            [`.${outlinedInputClasses.root}`]: {
                              height: 40,
                              width: 165,
                              fontSize: 14,
                              fontWeight: 400,
                              fontFamily: 'Poppins',
                            },
                          },
                        },
                      }} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <ButtonWithIcon
              className="mb-1"
              onClick={() => {
                form.handleSubmit((values) => {
                  onSubmit(values)
                })()
              }} text={"Search"}
              icon={IconOption.ARROW} />
          </div>
        </FormSection>
      </FormRoot>
    </Form>
  );
}

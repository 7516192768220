import { useState } from "react";
import { useRecommendationsFromActiveUserUpdateQuery } from "../../backend/resources/userUpdates/userUpdateToIntervention";
import { useNavigateBack } from "../../lib/routing";
import { userUpdatesStore } from "../../state/userUpdate/userUpdate";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
import CardLayoutSelector, { CardLayoutTypes } from "../CardLayoutSelector/CardLayoutSelector";
import { PageContainer } from "../PageContainer";
import { RecommendationCard } from "./Recommendations/Recommendation";
import BackButton from "../BackButton/BackButton";

export function ViewAllRecommendationsInUserUpdatePage() {
  // States
  const [activeLayout, setActiveLayout] = useState<CardLayoutTypes>(
    CardLayoutTypes.DEFAULT
  );

  const interventionIdsInUserUpdate = userUpdatesStore(
    (state) => state.interventionIdsInUserUpdate
  );
  const navigateBack = useNavigateBack();

  const { recommendationsInUserUpdate: recommendations } =
    useRecommendationsFromActiveUserUpdateQuery(interventionIdsInUserUpdate);

  return (
    <PageContainer>
      {/* main content area */}
      <div
        className="flex flex-col h-full">
        <BackButton />
        <div className="gap-5 flex flex-col  pb-40">
          <div className="bg-white rounded-md pt-6 pb-8 flex flex-col gap-2 w-full items-start">
            <div className="flex items-center gap-3 font-light">
              <p className="text-[22px]">Recommendations</p>
            </div>
            {recommendations ? (
              <div className="flex flex-col text-xs gap-4 mt-6 w-full">
                <div className="flex w-full justify-between px-1 md:justify-start items-center mb-5">
                  <CardLayoutSelector
                    activeLayout={activeLayout}
                    setActiveLayout={setActiveLayout}
                  />
                </div>
                <div className="md:ml-11 flex gap-2 md:gap-4 flex-wrap justify-around md:justify-start">
                  {recommendations?.map((recommendation) => (
                    <div
                      className="flex flex-wrap"
                      key={recommendation.id}>
                      <RecommendationCard
                        key={recommendation.id}
                        recommendation={recommendation}
                        size={activeLayout}
                        isInUserUpdate={true}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

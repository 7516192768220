import type { ReactNode } from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import { Popup } from "../../shared/ui/popup";
import type { ModalProps } from "../ModalView/ModalView";
import ModalView from "../ModalView/ModalView";
import { BottomSheet } from "../Sheet";

export default function ResponsivePopup({
  isOpen,
  onClose,
  children,
  showPopupAsModalInMobile,
  footerButtons,
  ...modalProps
}: {
  isOpen: boolean;
  onClose: any;
  children: ReactNode;
  footerButtons?:ReactNode,
  showPopupAsModalInMobile?: boolean;
} & ModalProps) {
  const { isMobile } = useWindowSize();

  if (isMobile) {
    if (showPopupAsModalInMobile) {
      return (
        <ModalView
          isOpen={isOpen}
          onClose={onClose}
          {...modalProps}>
          {children}
        </ModalView>
      );
    }
    return (
      <BottomSheet
        isOpen={isOpen}
        onClose={onClose}>
        {children}
      </BottomSheet>
    );
  } else if (isOpen) {
    return <Popup isOpen={isOpen} onClose={onClose} footerButtons={footerButtons}>{children}</Popup>;
  } else {
    return null;
  }
}

import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ReactComponent as InformationSvg } from "../../assets/info-circle.svg";
import { useAlfredPageSideBar } from "../../backend/resources/chatGptConversation";
import { useIncomingCallGuidePlanEntryByNetworkId } from "../../backend/resources/guide/guideTask";
import { useNetwork, useNetworkPCP, type NetworksWithAdlosAndCaregivers } from "../../backend/resources/network/network";
import { OrgRoleType, useOrgs } from "../../backend/resources/orgRole";
import { useCareBinderName } from "../../backend/resources/userAdlo";
import { useAdloNoteQuery } from "../../backend/resources/userNote";
import { NetworkRoleType, useUserRolesInNetwork } from "../../backend/resources/userRole";
import { useWindowSize } from "../../hooks/useWindowSize";
import {
  CareCentralRoute,
  CarePilotRoute,
  SharedRoute,
  useAppNavigate,
  useNavigateBack,
} from "../../lib/routing";
import { PopupOrSheet } from "../../shared/ui/popup-bottom-sheet";
import { useDiscussionFilterStore } from "../../state/discussionsFilter/discussionsFilter";
import { useNetworkStore } from "../../state/network/network";
import { useServiceFilterStore } from "../../state/serviceFilters/serviceFilters";
import { useUserStore } from "../../state/user";
import AdloComponent from "../AdloComponent/AdloComponent";
import BackButton from "../BackButton/BackButton";
import BottomActionBar from "../BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
import CarespaceMembers from "../CarespaceMembers/CarespaceMembers";
import { LoadingSpinner } from "../LoadingSpinner";
import { PageContainer } from "../PageContainer";
import { PageMainHeader } from "../PageMainHeader";
import { ServicesHubTabs } from "../Services Hub/ServicesHubPage";
import Tabs from "../Tabs/Tabs";
import { AddCaregiverView } from "./AddCaregiver";
import { AddProviderView } from "./AddProviderView";
import ChangePCPView from "./ChangePCPView";
import { EditCarespaceView } from "./EditCarespaceView";

enum CarespacePageTabs {
  GENERAL = "General",
  CARE_TEAM = "Care Team",
}



export function CarespacePage() {
  const { carespaceId } = useParams();
  const { isMobile } = useWindowSize();

  const navigateBack = useNavigateBack();
  // States
  const [activeTab, setActiveTab] = useState<CarespacePageTabs>(
    CarespacePageTabs.GENERAL
  );
  const [isEditCarespaceOpen, setIsEditCarespaceOpen] = useState(false);

  // Stores
  const authUser = useUserStore((state) => state.user);
  const setActiveNetworkId = useNetworkStore(
    (state) => state.setActiveNetworkId
  );

  // Queries 
  useAlfredPageSideBar("homePage")
  const { data, isLoading } = useNetwork(carespaceId);
  const { data: userRoles, isLoading: isLoadingUserRoles } =
    useUserRolesInNetwork();

  // Constants
  const isActiveUserInCarespace = userRoles?.some(
    (userRole: any) => userRole?.user_id === authUser?.id
  );

  // Effects 
  useEffect(() => {
    setActiveNetworkId(carespaceId);
  }, [carespaceId]);


  return (
    <>
      {isEditCarespaceOpen ? (
        <EditCarespaceView onClose={() => setIsEditCarespaceOpen(false)} />
      ) : null}
      <PageContainer>
        <div className="flex flex-col">
          <BackButton className="mb-2" />
          {/* title */}
          {isLoading ? (
            <div className="flex justify-center pt-2">
              <LoadingSpinner className="w-6 h-6" />
            </div>
          ) : (
            <>
              <PageMainHeader text={data?.name} />
              <div className="flex gap-2 items-center mt-3">
                {/* tab selection row */}
                <Tabs
                  tabs={Object.values(CarespacePageTabs)}
                  currentTab={activeTab}
                  setCurrentTab={setActiveTab}
                />
                {!isMobile && !isEditCarespaceOpen && activeTab === CarespacePageTabs.GENERAL ? (
                  <ButtonWithIcon
                    size={"small"}
                    onClick={() => setIsEditCarespaceOpen(true)}
                    icon={IconOption.EDIT}
                    text="Edit"
                  />
                ) : null}
              </div>
              {/* content */}
              {activeTab === CarespacePageTabs.GENERAL ? (
                <GeneralTabView network={data} />
              ) : activeTab === CarespacePageTabs.CARE_TEAM ? (
                <CareTeamTabView />
              ) : null}
            </>
          )}
        </div>
      </PageContainer>
      <BottomActionBar
        centerSlot={
          <ButtonWithIcon
            onClick={() => setIsEditCarespaceOpen(true)}
            text={""}
            icon={IconOption.EDIT}
          />
        }
      />
    </>
  );
}

function GeneralTabView({
  network,
}: {
  network?: NetworksWithAdlosAndCaregivers | null;
}) {
  const { isSuperSuperUser } = useOrgs()
  const { careBinderName } = useCareBinderName();
  const navigate = useAppNavigate();
  const { primaryCaregivers } = useUserRolesInNetwork();
  const { setCarespace: setServiceFilterCarespace } = useServiceFilterStore()
  const firstAdlo = network?.user_adlo && network.user_adlo[0]; // TODO: replace with active adlo & dropdown for selecting
  const { adloNote, isLoadingAdloNote } = useAdloNoteQuery(firstAdlo?.id);
  const [isAddPrimaryCaregiverModalOpen, setIsAddPrimaryCaregiverModalOpen] = useState(false);
  const { setCarespace, setType } = useDiscussionFilterStore();
  const { data: incomingCallGuidePlanEntryId } = useIncomingCallGuidePlanEntryByNetworkId(network?.id);
  if (isLoadingAdloNote) {
    return (
      <div className="flex justify-center">
        <LoadingSpinner className="w-6 h-6" />
      </div>
    );
  } else {
    return (
      <div className="flex flex-col gap-5 mt-6">
        {/* patient */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
          {/* patient */}
          <div className="flex flex-col gap-2">
            <h2>Patient</h2>
            <div className="flex gap-4 w-full truncate">
              {/* detail */}
              <AdloComponent userAdlo={firstAdlo} />
            </div>
          </div>
    
        </div>
        {/* buttons */}
        <div className="flex flex-col gap-3">
          <ButtonWithIcon
            className=""
            onClick={(e: any) => {
              e.stopPropagation();
              if (firstAdlo?.id) {
                navigate({
                  path: CareCentralRoute.NOTES,
                  params: {
                    adloId: firstAdlo?.id,
                  },
                });
              }
            }}
            text={`Notes ${adloNote?.adlo_note_updated_at
              ? `(Last updated: ${formatTime(
                new Date(adloNote?.adlo_note_updated_at)
              )}, ${new Date(
                adloNote?.adlo_note_updated_at
              ).toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })})`
              : ""
              }`}
            icon={IconOption.ARROW}
          />
          <ButtonWithIcon
            className=""
            onClick={(e: any) => {
              e.stopPropagation();
              navigate({
                path: CarePilotRoute.BINDER,
              });
            }}
            text={careBinderName}
            icon={IconOption.ARROW}
          />
          <ButtonWithIcon
            className=""
            onClick={(e: any) => {
              e.stopPropagation();
              setCarespace(network?.id)
              setType(undefined)
              navigate({
                path: CareCentralRoute.DISCUSSIONS,
              });
            }}
            text="Discussions"
            icon={IconOption.ARROW}
          />
          <ButtonWithIcon
            className=""
            onClick={(e: any) => {
              e.stopPropagation();
              navigate({
                path: CarePilotRoute.FAMILY_HUB,
              });
            }}
            text="Family Hub"
            icon={IconOption.ARROW}
          />
          <ButtonWithIcon
            className=""
            onClick={(e: any) => {
              e.stopPropagation();
              // Navigates to the guide navigator, pre-filtered by the carespace
              setServiceFilterCarespace(network?.id)
              navigate({
                path: CareCentralRoute.SERVICE_HUB,
                queryParams: { tab: ServicesHubTabs.PROVIDERS }
              });
            }}
            text="Service Hub"
            icon={IconOption.ARROW}
          />
          {isSuperSuperUser && <ButtonWithIcon
            className=""
            onClick={(e: any) => {
              e.stopPropagation();
              navigate({
                path: CarePilotRoute.PLAN_NEW,
                queryParams: {
                  network_id: network?.id ?? null,
                  parent_plan_entry_id: incomingCallGuidePlanEntryId ?? null,
                }
              });
            }}
            text="Log Support Call"
            icon={IconOption.ARROW}
          />}
        </div>
        {/* Modals */}
        <PopupOrSheet
          isOpen={isAddPrimaryCaregiverModalOpen}
          title={""}
          icon={<InformationSvg className="w-10 h-10" />}
          footerButtons={<ButtonWithIcon onClick={() => setIsAddPrimaryCaregiverModalOpen(false)} text={"Done"} icon={IconOption.CHECKMARK} />}>
          <p> There is no primary caregiver. Please add one so they can take their self care assessment.</p>
        </PopupOrSheet>
      </div>
    );
  }
}

function CareTeamTabView() {
  const navigate = useAppNavigate()
  const { data: pcp } = useNetworkPCP()
  const [searchParams] = useSearchParams();
  const should_display_add_caregiver = searchParams.get("should_display_add_caregiver") === "true";
  const [shouldDisplayAddCaregiver, setShouldDisplayAddCaregiver] = useState(should_display_add_caregiver);
  const [shouldDisplayAddProvider, setShouldDisplayAddProvider] = useState(false);
  const [shouldDisplayAddNavigator, setShouldDisplayAddNavigator] = useState(false);
  const [shouldDisplayAddPCP, setShouldDisplayAddPCP] = useState(false);

  return (
    <div className="flex flex-col gap-2">
      {shouldDisplayAddCaregiver ? <AddCaregiverView onClose={() => setShouldDisplayAddCaregiver(false)} /> : null}
      {shouldDisplayAddProvider ? <AddProviderView roleType={OrgRoleType.DOCTOR} onClose={() => setShouldDisplayAddProvider(false)} /> : null}
      {shouldDisplayAddNavigator ? <AddProviderView roleType={OrgRoleType.CARE_NAVIGATOR} onClose={() => setShouldDisplayAddNavigator(false)} /> : null}
      {shouldDisplayAddPCP ? <ChangePCPView onClose={() => setShouldDisplayAddPCP(false)} /> : null}
      <div className="flex items-center gap-5 max-w-[50%] pt-10">
        <div className="text-lg">Primary Caregiver(s)</div>
        <ButtonWithIcon
          size={"small"}
          onClick={() => setShouldDisplayAddCaregiver(true)}
          icon={IconOption.PLUS}
          text=""
        />
      </div>
      <CarespaceMembers
        rolesToFilterBy={[NetworkRoleType.PRIMARY_CAREGIVER]}
        disableEditingCarespaceMember
        hideSuperSuperUsers
      />
      <div className="flex items-center gap-3 mt-5">
        <div className="text-lg">Provider(s)</div>
        <ButtonWithIcon
          size={"small"}
          onClick={() => setShouldDisplayAddProvider(true)}
          icon={IconOption.PLUS}
          text=""
        />
      </div>
      <CarespaceMembers
        rolesToFilterBy={[OrgRoleType.DOCTOR, OrgRoleType.ADMIN]}
        hideSuperSuperUsers
        disableEditingCarespaceMember
      />
      <div className="flex items-center gap-3 mt-5">
        <div className="text-lg">Care Navigator(s)</div>
        <ButtonWithIcon
          size={"small"}
          onClick={() => setShouldDisplayAddNavigator(true)}
          icon={IconOption.PLUS}
          text=""
        />
      </div>
      <CarespaceMembers
        rolesToFilterBy={[OrgRoleType.CARE_NAVIGATOR]}
        disableEditingCarespaceMember
      />
      <div className="flex items-center gap-3 mt-5">
        <div className="text-lg">PCP</div>
        <ButtonWithIcon
          size={"small"}
          onClick={() => setShouldDisplayAddPCP(true)}
          icon={pcp ? IconOption.EDIT : IconOption.PLUS}
          text=""
        />
      </div>
      {pcp &&
        <button
          className="text-start"
          onClick={() => navigate({
            path: SharedRoute.SERVICE_PROVIDER_PAGE,
            params: {
              id: pcp.id
            }
          })}>
          {pcp.name}
        </button>
      }

    </div>
  );
}

// helpers

function formatTime(date: Date) {
  let hours = date.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  const strTime = `${hours} ${ampm}`;
  return strTime;
}

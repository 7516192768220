import type { AssessmentQuestion } from "../../../../backend/resources/assessmentQuestion";
import { UserAssessmentAnswer } from "../../../../backend/resources/userAssessmentAnswer";
import { TextArea } from "../../../TextArea";
import AssessmentMultiSelectInput from "./inputTypes/AssessmentMultiSelectInput";
import { AssessmentRadioButton } from "./inputTypes/AssessmentRadioInput";
import AssessmentSelectInput from "./inputTypes/AssessmentSelectInput";
import NPIQInput, { NPIQUserAssessmentAnswer } from "./inputTypes/NPIQInput";
import { Slider } from "./inputTypes/SliderInput";
import { YesOrNo, YesOrNoOptions } from "./inputTypes/YesOrNoInput";

interface Props {
  question: AssessmentQuestion;
  disabled: boolean;
  localAnswerObject: UserAssessmentAnswer | null | undefined;
  updateLocalAnswerObject: (update: Partial<UserAssessmentAnswer>) => void;
}

export function Input({ question, disabled, localAnswerObject, updateLocalAnswerObject }: Props) {
  const optionsLength = question.raw_data?.options?.length
  switch (question.type) {
    case "slider":
      return (
        <div className="px-5 w-min-[360px] w-[360px]">
          <Slider
            disabled={disabled}
            currentAnswer={localAnswerObject?.answer as number}
            updateAnswer={updateLocalAnswerObject}
            scale={optionsLength ? (optionsLength - 1) : (question.raw_data?.scale ?? 3)}
            options={question.raw_data?.options ?? []}
          />
        </div>
      );
    case "yes_or_no":
      return (
        <YesOrNo
          disabled={disabled}
          currentAnswer={localAnswerObject?.answer as string}
          updateAnswer={updateLocalAnswerObject}
          additionalOptions={question.raw_data?.additional_options as YesOrNoOptions[]}
        />
      );
    case "radio":
      return (
        <AssessmentRadioButton
          disabled={disabled}
          currentAnswer={localAnswerObject as UserAssessmentAnswer}
          updateAnswer={updateLocalAnswerObject}
        />
      )
    case "npi-q":
      return (
        <NPIQInput
          disabled={disabled}
          currentAnswer={localAnswerObject?.answer as NPIQUserAssessmentAnswer}
          updateAnswer={updateLocalAnswerObject}
        />
      )
    case "free_text":
      return (
        <TextArea
          disabled={disabled}
          value={localAnswerObject?.answer as string}
          onChange={(e) => updateLocalAnswerObject({ answer: e.target.value })}
        />
      )
    case "select":
      return (
        <AssessmentSelectInput
          disabled={disabled}
          isVertical={question.raw_data?.type === "vertical"}
          options={question.raw_data?.options ?? []}
          currentAnswer={localAnswerObject?.answer as number}
          updateAnswer={updateLocalAnswerObject}
        />
      )
    case "multi_select":
      return (
        <AssessmentMultiSelectInput
          disabled={disabled}
          options={question.raw_data?.options ?? []}
          currentAnswer={localAnswerObject?.answer as number[]}
          updateAnswer={updateLocalAnswerObject}
        />
      )
    default:
      return null;
  }
}

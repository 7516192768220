/**
 * @fileoverview Entry point to the React app.
 */
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { ReactNode } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { Route, Routes } from "react-router-dom";
import { fetchUserRole } from "./backend/resources/userRole";
import AddNewProvider from "./components/AddNewProviderPage/AddNewProvider";
import AddNewProviderManually from "./components/AddNewProviderPage/AddNewProviderManually";
import { AdminPage } from "./components/AdminPage/AdminPage";
import EditMemberPage from "./components/AdminPage/EditMemberPage";
import InvitationDetailsPage from "./components/AdminPage/ViewInvitationPage";
import { Auth } from "./components/Auth";
import BillingPage from "./components/BillingPage/BillingPage";
import BookmarkDetailPage from "./components/BookmarkDetailPage/BookmarkDetailPage";
import CareBinder from "./components/CareBinder/CareBinder";
import { AssessmentsTab } from "./components/CareBinder/components/AssessmentsTab";
import { CareCentralOnboarding } from "./components/CareCentralOnboarding/CareCentralOnboarding";
import CarePilotOnboarding from "./components/CarePilotOnboarding/CarePilotOnboarding";
import CarePlan from "./components/CarePlan/CarePlan";
import EditCarePlanPage from "./components/CarePlan/EditCarePlanPage";
import { CarespacePage } from "./components/CarespacePage";
import { AdloNotes } from "./components/CarespacePage/AdloNotesPage";
import { Alfred } from "./components/ChatGptSideBar";
import { FloatingAlfredButton } from "./components/ChatGptSideBar/FloatingAlfredButton";
import ConversationPage from "./components/ConversationPage/ConversationPage";
import { CookieUse } from "./components/CookieUse/cookieUse";
import DeletedUserPage from "./components/DeletedUserPage/DeletedUserPage";
import DirectoryPage from "./components/DirectoryPage/DirectoryPage";
import DiscussionsPage from "./components/DiscussionsPage/DiscussionPage";
import EducationPage from "./components/EducationPage/ResourcesPage";
import { GUIDETaskPage } from "./components/GUIDETaskPage/GUIDETaskPage";
import GeneralDiscussionPage from "./components/GeneralDiscussionPage/GeneralDiscussionPage";
import CreateOrEditGoalPage from "./components/GoalPage/Goal/CreateOrEditGoalPage";
import GoalPage from "./components/GoalPage/Goal/GoalPage";
import CreateOrEditGoalTemplatePage from "./components/GoalPage/GoalTemplate/CreateOrEditGoalTemplatePage";
import GoalTemplatePage from "./components/GoalPage/GoalTemplate/GoalTemplatePage";
import { ViewAllRecommendationsInUserUpdatePage } from "./components/GuidancePage";
import EditInterventionPage from "./components/GuidancePage/Recommendations/Recommendation/Interventions/EditInterventionPage";
import InterventionPage from "./components/GuidancePage/Recommendations/Recommendation/Interventions/InterventionPage";
import EditRecommendationPage from "./components/GuidancePage/Recommendations/RecommendationPage/EditRecommendationPage";
import RecommendationPage from "./components/GuidancePage/Recommendations/RecommendationPage/RecommendationPage";
import CreateOrEditShareableRecommendationPage from "./components/GuidancePage/Recommendations/ShareableRecommendationPage/ShareableRecommendation/CreateOrEditShareableRecommendationPage";
import CreateOrEditShareableInterventionPage from "./components/GuidancePage/Recommendations/ShareableRecommendationPage/ShareableRecommendation/ShareableIntervention/CreateOrEditShareableInterventionPage";
import ShareableInterventionPage from "./components/GuidancePage/Recommendations/ShareableRecommendationPage/ShareableRecommendation/ShareableIntervention/ShareableInterventionPage";
import ShareableRecommendationPage from "./components/GuidancePage/Recommendations/ShareableRecommendationPage/ShareableRecommendationPage";
import GuideCategoryPage from "./components/GuideCategoryPage/GuideCategoryPage";
import { Header, MobileHeader } from "./components/Header";
import { HomePage } from "./components/HomePage";
import { CarespacesPage } from "./components/HomePage/CareCentralHome/Carespaces";
import PatientSearch from "./components/HomePage/SuperSuperUserHome/PatientSearch";
import InvalidInvitationPage from "./components/InvalidInvitationPage/InvalidInvitationPage";
import LaunchDarklyProvider from "./components/LaunchDarklyProvider/LaunchDarklyProvider";
import { LibraryPage } from "./components/LibraryPage";
import { LogIn } from "./components/LogIn";
import MyAccountPage from "./components/MyAccountPage/MyAccountPage";
import MyCarePage from "./components/MyCarePage/MyCarePage";
import { MyPlanPage } from "./components/MyPlanPage";
import { NanasDay } from "./components/NanasDay";
import { SideNav } from "./components/NavBar";
import BottomNavBar from "./components/NavBar/BottomNavBar";
import OneSignalComponent from "./components/OneSignalComponent/OneSignalComponent";
import OrganizationPage from "./components/OrganizationPage/OrganizationPage";
import { PageContainer } from "./components/PageContainer";
import { PageMainHeader } from "./components/PageMainHeader";
import { PrivacyPolicyPage } from "./components/PrivacyPolicyPage";
import ProductFruitsWrapper from "./components/ProductFruitsWrapper/ProductFruitsWrapper";
import { ResetPassword } from "./components/ResetPassword";
import ResourcesPage from "./components/ResourcesPage/ResourcesPage";
import ServicePage from "./components/ServicePage/ServicePage";
import ServiceProviderPage from "./components/ServiceProviderPage.tsx/ServiceProviderPage";
import CreateOrEditServiceRequestPage from "./components/ServiceRequest/CreateOrEditServiceRequestPage";
import ViewServiceRequestPage from "./components/ServiceRequest/ViewServiceRequestPage";
import ServiceHubPage from "./components/Services Hub/ServicesHubPage";
import SettingsPage from "./components/SettingsPage/SettingsPage";
import { SignUp } from "./components/SignUp";
import TaskNavigatorPage from "./components/TaskNavigatorPage/TaskNavigatorPage";
import TemplatesPage from "./components/TemplatesPage/TemplatesPage";
import { TermsOfServicePage } from "./components/TermsOfServicePage";
import UpdatesPage from "./components/UpdatesPage/UpdatesPage";
import { UserRoleForm } from "./components/UserRoleForm/UserRoleForm";
import VideoCategoryPage from "./components/VideoLibraryPage/VideoCategoryPage/VideoCategoryPage";
import { useUserOnboarding } from "./hooks/useUserOnboarding";
import { useWindowSize } from "./hooks/useWindowSize";
import { CareCentralRoute, CarePilotRoute, SharedRoute, SuperSuperUserRoute } from "./lib/routing";
import { HomeRedirect } from "./routes/HomeRedirect";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import { resetAssessmentStore } from "./state/assessment";
import { getActiveNetworkIdDirectly } from "./state/network/network";
import {
  getUserIdDirectly,
  resetUserStoreDirectly,
  signOutDirectly
} from "./state/user";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: async (error: any) => {
        if (
          (error.message as string).includes(
            "new row violates row-level security policy"
          )
        ) {
          const userRole = await fetchUserRole(
            getUserIdDirectly(),
            getActiveNetworkIdDirectly()
          );
          if (userRole?.is_deactivated) {
            await signOutDirectly();
            resetAssessmentStore();
            resetUserStoreDirectly();
            window.location.href = `${window.origin}/deleted`;
          }
        }
      },
    },
  },
});

function AppLayout({
  children,
  hideSideNav = false,
}: {
  children: ReactNode;
  hideSideNav?: boolean;
}) {
  // chatgpt sidebar state
  const { isMobile } = useWindowSize();

  // onboarding state
  const {
    isUserRoleSelectionShowing,
    isCareCentralOnboardingShowing,
    isCarePilotOnboardingShowing,
  } = useUserOnboarding();
  return (
    <div className="flex flex-col font-poppins font-light w-full h-full">
      {isUserRoleSelectionShowing ? (
        <UserRoleForm />
      ) : isCareCentralOnboardingShowing ? (
        <CareCentralOnboarding />
      ) : isCarePilotOnboardingShowing ? (
        <CarePilotOnboarding />
      ) : null}

      <AppHeader />

      <div className="flex flex-grow overflow-y-hidden">
        {hideSideNav ? null : isMobile ? <BottomNavBar /> : <SideNav />}

        <div className="min-w-0 h-full flex-grow relative">{children}</div>

        <FloatingAlfredButton />

        <Alfred />
      </div>
    </div>
  );
}

function AppHeader() {
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return <MobileHeader />;
  } else {
    return <Header />;
  }
}

export function ProtectedRouteWithAppLayout({
  children,
}: {
  children: ReactNode;
}) {
  return <ProtectedRoute component={<AppLayout>{children}</AppLayout>} />;
}

/**
 * Renders the entry point for the app.
 */
export function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <Auth>
          <LaunchDarklyProvider>
            <OneSignalComponent />
            <ProductFruitsWrapper />
            <Routes>
              {/* Care Pilot Routes */}
              <Route
                path={CarePilotRoute.CARE_PLAN}
                element={
                  <ProtectedRouteWithAppLayout>
                    <PageContainer>
                      <div className="flex flex-col gap-4 pb-20">
                        <PageMainHeader text="Care Plan" />
                        <CarePlan />
                      </div>
                    </PageContainer>
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.MEDICATIONS}
                element={
                  <ProtectedRouteWithAppLayout>
                    <PageContainer>
                      <div className="flex flex-col gap-4 pb-20">
                        <PageMainHeader text="Medications" />
                        <NanasDay />
                      </div>
                    </PageContainer>
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.ASSESSMENTS}
                element={
                  <ProtectedRouteWithAppLayout>
                    <PageContainer>
                      <div className="flex flex-col gap-4 pb-20">
                        <PageMainHeader text="Assessments" />
                        <AssessmentsTab />
                      </div>
                    </PageContainer>
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.RECOMMENDATION}
                element={
                  <ProtectedRouteWithAppLayout>
                    <RecommendationPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.RECOMMENDATION_EDIT}
                element={
                  <ProtectedRouteWithAppLayout>
                    <EditRecommendationPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.INTERVENTION_EDIT}
                element={
                  <ProtectedRouteWithAppLayout>
                    <EditInterventionPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
                <Route
                path={CareCentralRoute.INTERVENTION_CREATE}
                element={
                  <ProtectedRouteWithAppLayout>
                    <EditInterventionPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.INTERVENTION}
                element={
                  <ProtectedRouteWithAppLayout>
                    <InterventionPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.VIEW_ALL_RECOMMENDATION}
                element={
                  <ProtectedRouteWithAppLayout>
                    <ViewAllRecommendationsInUserUpdatePage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.PLAN}
                element={
                  <ProtectedRouteWithAppLayout>
                    <MyPlanPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.PLAN_NEW}
                element={
                  <ProtectedRouteWithAppLayout>
                    <MyPlanPage should_display_new_task_form />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.PLAN_ID}
                element={
                  <ProtectedRouteWithAppLayout>
                    <MyPlanPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.PLAN_NEW_TASK}
                element={
                  <ProtectedRouteWithAppLayout>
                    <MyPlanPage should_display_new_task_form />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.PLAN_EDIT}
                element={
                  <ProtectedRouteWithAppLayout>
                    <MyPlanPage should_display_edit_task_form />
                  </ProtectedRouteWithAppLayout>
                }
              />

              <Route
                path={CarePilotRoute.RECOMMENDATION_ADD}
                element={
                  <ProtectedRouteWithAppLayout>
                    <CreateOrEditShareableRecommendationPage isSavingToGoal />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.RECOMMENDATION_ADD_ID}
                element={
                  <ProtectedRouteWithAppLayout>
                    <CreateOrEditShareableRecommendationPage isSavingToGoal />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.MY_CARE}
                element={
                  <ProtectedRouteWithAppLayout>
                    <MyCarePage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.MY_CARE_ID}
                element={
                  <ProtectedRouteWithAppLayout>
                    <MyCarePage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.GOAL_ID}
                element={
                  <ProtectedRouteWithAppLayout>
                    <GoalPage />
                  </ProtectedRouteWithAppLayout>
                }
              />

              <Route
                path={CarePilotRoute.GOAL_EDIT_ID}
                element={
                  <ProtectedRouteWithAppLayout>
                    <CreateOrEditGoalPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.GOAL_EDIT}
                element={
                  <ProtectedRouteWithAppLayout>
                    <CreateOrEditGoalPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.GENERAL_DISCUSSION}
                element={
                  <ProtectedRouteWithAppLayout>
                    <GeneralDiscussionPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.CONVERSATION}
                element={
                  <ProtectedRouteWithAppLayout>
                    <ConversationPage />
                  </ProtectedRouteWithAppLayout>
                }
              />

              <Route
                path={CarePilotRoute.BINDER}
                element={
                  <ProtectedRouteWithAppLayout>
                    <CareBinder />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.CARE_PLAN_EDIT}
                element={
                  <ProtectedRouteWithAppLayout>
                    <EditCarePlanPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.DAY_EDIT}
                element={
                  <ProtectedRouteWithAppLayout>
                    <NanasDay should_display_edit_schedule_page />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.DAY_NOTES}
                element={
                  <ProtectedRouteWithAppLayout>
                    <NanasDay should_display_notes_page />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.DAY_INTAKE}
                element={
                  <ProtectedRouteWithAppLayout>
                    <NanasDay should_display_intake_view />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.DAY_INTAKE_NEW}
                element={
                  <ProtectedRouteWithAppLayout>
                    <NanasDay should_display_create_form />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.DAY_PRESCRIPTION}
                element={
                  <ProtectedRouteWithAppLayout>
                    <NanasDay should_display_detail_page />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.DAY_PRESCRIPTION_EDIT}
                element={
                  <ProtectedRouteWithAppLayout>
                    <NanasDay should_display_edit_page />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.FAVORITES}
                element={
                  <ProtectedRouteWithAppLayout>
                    <LibraryPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.BOOKMARK}
                element={
                  <ProtectedRouteWithAppLayout>
                    <BookmarkDetailPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.FAMILY_HUB}
                element={
                  <ProtectedRouteWithAppLayout>
                    <UpdatesPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={SharedRoute.ADMIN}
                element={
                  <ProtectedRouteWithAppLayout>
                    <AdminPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={SharedRoute.MY_ACCOUNT}
                element={
                  <ProtectedRouteWithAppLayout>
                    <MyAccountPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.SETTINGS}
                element={
                  <ProtectedRouteWithAppLayout>
                    <SettingsPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.LOCAL_SEARCH}
                element={
                  <ProtectedRouteWithAppLayout>
                    <ResourcesPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.EDUCATION}
                element={
                  <ProtectedRouteWithAppLayout>
                    <EducationPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CarePilotRoute.EDUCATION_CATEGORY}
                element={
                  <ProtectedRouteWithAppLayout>
                    <VideoCategoryPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              {/* Care Central Routes */}
              <Route
                path={CareCentralRoute.GUIDE_NAVIGATOR}
                element={
                  <ProtectedRouteWithAppLayout>
                    <TaskNavigatorPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.CARESPACES}
                element={
                  <ProtectedRouteWithAppLayout>
                    <CarespacesPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.SERVICE_HUB}
                element={
                  <ProtectedRouteWithAppLayout>
                    <ServiceHubPage />
                  </ProtectedRouteWithAppLayout>
                }
              />

              <Route
                path={CareCentralRoute.BILLING_PAGE}
                element={
                  <ProtectedRouteWithAppLayout>
                    <BillingPage />
                  </ProtectedRouteWithAppLayout>
                }
              />

              <Route
                path={CareCentralRoute.GUIDE_TASK_PAGE}
                element={
                  <ProtectedRouteWithAppLayout>
                    <GUIDETaskPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.GUIDE_CATEGORY}
                element={
                  <ProtectedRouteWithAppLayout>
                    <GuideCategoryPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.DISCUSSIONS}
                element={
                  <ProtectedRouteWithAppLayout>
                    <DiscussionsPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.ORG}
                element={
                  <ProtectedRouteWithAppLayout>
                    <OrganizationPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.TEMPLATES}
                element={
                  <ProtectedRouteWithAppLayout>
                    <TemplatesPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.SHAREABLE_RECOMMENDATION_ADD}
                element={
                  <ProtectedRouteWithAppLayout>
                    <CreateOrEditShareableRecommendationPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.SHAREABLE_RECOMMENDATION_ADD_ID}
                element={
                  <ProtectedRouteWithAppLayout>
                    <CreateOrEditShareableRecommendationPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.SHAREABLE_RECOMMENDATION}
                element={
                  <ProtectedRouteWithAppLayout>
                    <ShareableRecommendationPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.DIRECTORY}
                element={
                  <ProtectedRouteWithAppLayout>
                    <DirectoryPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.SHAREABLE_INTERVENTION}
                element={
                  <ProtectedRouteWithAppLayout>
                    <ShareableInterventionPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.SHAREABLE_INTERVENTION_ADD}
                element={
                  <ProtectedRouteWithAppLayout>
                    <CreateOrEditShareableInterventionPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.SHAREABLE_INTERVENTION_ADD_ID}
                element={
                  <ProtectedRouteWithAppLayout>
                    <CreateOrEditShareableInterventionPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.ADD_NEW_PROVIDER}
                element={
                  <ProtectedRouteWithAppLayout>
                    <AddNewProvider />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.ADD_NEW_PROVIDER_MANUALLY}
                element={
                  <ProtectedRouteWithAppLayout>
                    <AddNewProviderManually />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.CARESPACE}
                element={
                  <ProtectedRouteWithAppLayout>
                    <CarespacePage />
                  </ProtectedRouteWithAppLayout>
                }
              />

              <Route
                path={CareCentralRoute.NOTES}
                element={
                  <ProtectedRouteWithAppLayout>
                    <AdloNotes />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.GOAL_TEMPLATE_ID}
                element={
                  <ProtectedRouteWithAppLayout>
                    <GoalTemplatePage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.GOAL_TEMPLATE_EDIT}
                element={
                  <ProtectedRouteWithAppLayout>
                    <CreateOrEditGoalTemplatePage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={CareCentralRoute.GOAL_TEMPLATE_EDIT_ID}
                element={
                  <ProtectedRouteWithAppLayout>
                    <CreateOrEditGoalTemplatePage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              {/* Routes Shared/Neither Care central or Care Pilot */}
              <Route
                path={SharedRoute.SERVICE_PROVIDER_PAGE}
                element={
                  <ProtectedRouteWithAppLayout>
                    <ServiceProviderPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={SharedRoute.SERVICE_PAGE}
                element={
                  <ProtectedRouteWithAppLayout>
                    <ServicePage />
                  </ProtectedRouteWithAppLayout>
                }
              />

              <Route
                path={SharedRoute.SERVICE_REQUEST_NEW}
                element={
                  <ProtectedRouteWithAppLayout>
                    <CreateOrEditServiceRequestPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={SharedRoute.SERVICE_REQUEST_VIEW}
                element={
                  <ProtectedRouteWithAppLayout>
                    <ViewServiceRequestPage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={SharedRoute.SERVICE_REQUEST_EDIT}
                element={
                  <ProtectedRouteWithAppLayout>
                    <CreateOrEditServiceRequestPage />
                  </ProtectedRouteWithAppLayout>
                }
              />

              <Route
                path={SharedRoute.VIEW_INVITATION}
                element={
                  <ProtectedRouteWithAppLayout>
                    <InvitationDetailsPage />
                  </ProtectedRouteWithAppLayout>
                }
              />

              <Route
                path={SharedRoute.TEAM_EDIT_TEAM_MEMBER}
                element={
                  <ProtectedRouteWithAppLayout>
                    <EditMemberPage />
                  </ProtectedRouteWithAppLayout>
                }
              />

              <Route
                path={SharedRoute.ROOT}
                element={
                  <AppLayout hideSideNav>
                    <LogIn />
                  </AppLayout>
                }
              />
              <Route
                path={SharedRoute.LOGIN}
                element={
                  <AppLayout hideSideNav>
                    <LogIn />
                  </AppLayout>
                }
              />
              <Route
                path={SharedRoute.HOME}
                element={
                  <ProtectedRouteWithAppLayout>
                    <HomePage />
                  </ProtectedRouteWithAppLayout>
                }
              />
              <Route
                path={SharedRoute.TERMS_OF_SERVICE}
                element={<TermsOfServicePage />}
              />
              <Route
                path={SharedRoute.COOKIE_USE}
                element={<CookieUse />}
              />
              <Route
                path={SharedRoute.PRIVACY_POLICY}
                element={<PrivacyPolicyPage />}
              />
              <Route
                path={SharedRoute.RESET_PASSWORD}
                element={<ResetPassword />}
              />
              <Route
                path={SharedRoute.DELETED}
                element={
                  <AppLayout hideSideNav>
                    <DeletedUserPage />
                  </AppLayout>
                }
              />
              <Route
                path={SharedRoute.SIGN_UP}
                element={
                  <AppLayout hideSideNav>
                    <SignUp />
                  </AppLayout>
                }
              />
              <Route
                path={SharedRoute.INVITATION_INVALID}
                element={
                  <AppLayout hideSideNav>
                    <InvalidInvitationPage />
                  </AppLayout>
                }
              />
              <Route
                element={
                  <AppLayout hideSideNav>
                    <LogIn />
                  </AppLayout>
                }
              />
              <Route
                path={SharedRoute.WILDCARD}
                element={<ProtectedRoute component={<HomeRedirect />} />}
              />
              <Route
                path={SuperSuperUserRoute.PATIENT_SEARCH}
                element={
                  <ProtectedRouteWithAppLayout>
                    <PatientSearch />
                  </ProtectedRouteWithAppLayout>
                }
              />
            </Routes>
          </LaunchDarklyProvider>
        </Auth>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

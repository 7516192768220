import Alfred from "../../assets/Alfred.png";

export default function AlfredIcon({ className }: { className: string }) {
  return (
    <img
      className={className}
      src={Alfred}
      alt=""
    />
  );
}

import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import * as React from "react";
import { DrugClass, searchDrugClasses } from "../../backend/functions";
import { useUserStore } from "../../state/user";
import { capitalizeFirstLetter } from "../../utils";

type Props = {
  onValueChange: (drug: DrugClass | null) => void;
  disabled: boolean;
};

export function DrugAutocomplete({ onValueChange, disabled }: Props) {
  const authUser = useUserStore((state) => state.user);
  const authUserId = authUser?.id ? authUser.id : "";
  const [value, setValue] = React.useState<DrugClass | null>();
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<DrugClass[]>([]);
  const [open, setOpen] = React.useState(false);
  const [networkCallInProgress, setNetworkCallInProgress] =
    React.useState(false);
  const loading =
    networkCallInProgress ||
    (open && inputValue.length > 0 && options?.length === 0);

  React.useEffect(() => {
    if (inputValue.length === 0) return; // Return if input is empty

    const fetchDrugs = async () => {
      setNetworkCallInProgress(true);
      const res = await searchDrugClasses({
        searchText: inputValue,
        userId: authUserId,
      });
      if (!res.error) {
        setOptions(res);
      }
      setNetworkCallInProgress(false);
    };

    fetchDrugs();
  }, [inputValue, authUserId]); // trigger when inputValue or authUserId changes

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="drug-autocomplete"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) =>
        capitalizeFirstLetter(option.RoutedDoseFormDrugDesc)
      }
      options={options}
      loading={loading}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No matching drugs"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onValueChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      className="border-black"
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search for a drug"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      disabled={disabled}
    />
  );
}

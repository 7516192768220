/**
 * @fileoverview Sets up the Zustand product store.
 * This store holds information about the product state.
 */
import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../backend/queryKeys";
import { useActiveNetwork } from "../../backend/resources/network/network";
import type {
  Organization,
  OrganizationRole
} from "../../backend/resources/orgRole";
import {
  useOrgs,
} from "../../backend/resources/orgRole";
import { useActiveUserRole } from "../../backend/resources/userRole";
import { supabase } from "../../clients/supabaseClient";
import { useActiveOrganizationId } from "../../state/organization/organization";
import { useUserStore } from "../../state/user";

export enum CraniometrixProduct {
  CARE_PILOT = "care pilot",
  CARE_CENTRAL = "care central",
}

/**
 * Product access and URL state
 */

export function useProductAccess() {
  const { userRole, isUserRoleLoading } = useActiveUserRole();

  const { isLoading: isHasCareCentralAccessLoading, hasCareCentralAccess } =
    useOrgs();

  return {
    data: getCurrentProduct(hasCareCentralAccess, !!userRole),
    isLoading: isHasCareCentralAccessLoading || isUserRoleLoading,
  };
}

function getCurrentProduct(
  hasCareCentralAccess: boolean,
  hasUserRole: boolean
) {
  if (hasCareCentralAccess) return CraniometrixProduct.CARE_CENTRAL;
  else if (hasUserRole) return CraniometrixProduct.CARE_PILOT;
  else return undefined;
}

/**
 * Self-contained hook for checking if the user has CareBinder control
 *
 * @memo: we tried consolidating this but it was difficult to coordinate the org and orgRole loading states,
 * perhaps we can revisit this in the future but for now... coders beware
 */

export function useHasPlanBinderControl() {
  const authUser = useUserStore((state) => state.user);
  let activeOrgId = useActiveOrganizationId();
  const { data: networkData } = useActiveNetwork();
  const orgId = activeOrgId ?? networkData?.organization_id ?? ""
  const { isSuperSuperUser } = useOrgs()
  return useQuery({
    queryKey: [QUERY_KEYS.hasCarebinderControl, { orgId }],
    queryFn: () => isSuperSuperUser || fetchHasCareBinderControl(orgId, authUser?.id),
    refetchOnWindowFocus: false,
  });
}

async function fetchHasCareBinderControl(
  organization_id: string,
  user_id?: string
) {
  if (!user_id) return false;
  const { data: organization, error: errorData } = await supabase
    .from("organization")
    .select("*")
    .eq("id", organization_id)
    .limit(1)
    .maybeSingle();

  const { data: organizationRole, error } = await supabase
    .from("organization_role")
    .select("*")
    .eq("organization_id", organization_id)
    .eq("user_id", user_id)
    .returns<(OrganizationRole & { organization: Organization })[]>()
    .limit(1)
    .maybeSingle();


  //check if supersuper user
  const { data: isInSuperOrg } = await supabase
    .from("organization_role")
    .select("organization(is_super_org)")
    .eq("user_id", user_id)
    .limit(1)
    .maybeSingle();

  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }
  return (
    (organizationRole != undefined || organization?.is_family_organization || isInSuperOrg?.organization?.is_super_org) ??
    false
  );
}

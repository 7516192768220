import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../backend/queryKeys";
import type {
  PlanEntryWithBookmarks
} from "../../backend/resources/planEntry";
import {
  fetchAllPlanEntriesForUser,
} from "../../backend/resources/planEntry";
import { getInitials } from "../../components/AdloComponent/AdloComponent";
import { useMyPlanStore } from "../../state/myPlan";
import { useUserStore } from "../../state/user";

export function usePlanEntries({ parentPlanEntryId, includeAll }: { parentPlanEntryId?: string, includeAll?: boolean } = {}) {
  const authUser = useUserStore((state) => state.user);

  const myPlanFiltersState = useMyPlanStore(
    (state) => state.myPlanFiltersState
  );

  const myPlanSortsState = useMyPlanStore((state) => state.myPlanSortsState);

  const {
    isLoading,
    data: planEntries,
    refetch,
  } = useQuery({
    queryKey: [QUERY_KEYS.planEntries, { userId: authUser?.id, parentPlanEntryId, includeAll, ...myPlanSortsState }],
    queryFn: () => fetchAllPlanEntriesForUser({ userId: authUser?.id, parentPlanEntryId, includeAll }),
    enabled: !!authUser,
  });

  function filterAndSortPlanEntries(planEntries: PlanEntryWithBookmarks[]) {
    // 2 - filter by status
    let planEntriesFilteredByStatus: PlanEntryWithBookmarks[];
    // Get the keys with a value of true
    const trueStatusKeys = Object.entries(myPlanFiltersState.status_filters)
      .filter(([key, value]) => value)
      .map(([key]) => key);
    if (trueStatusKeys.length === 0) {
      // If no keys, filter out 'done' entries
      planEntriesFilteredByStatus = planEntries.filter(
        (entry) => entry.status !== "done"
      );
    } else {
      // If 'done' is present, show all entries
      planEntriesFilteredByStatus = planEntries;
    }

    // 2 - sort by sort key
    let planEntriesSortedAndFiltered: PlanEntryWithBookmarks[];
    planEntriesSortedAndFiltered = planEntriesFilteredByStatus.sort((a, b) => {
      let comparison = 0;

      switch (myPlanSortsState.activeSortKey) {
        case "who": {
          const aName = getInitials(a.user);
          const bName = getInitials(b.user);
          comparison = aName.localeCompare(bName);
          break;
        }
        case "name": {
          comparison = a.name?.localeCompare(b.name ?? "");
          break;
        }
        case "scheduled_date_time": {
          if (a.scheduled_date_time && b.scheduled_date_time) {
            comparison = a.scheduled_date_time?.localeCompare(
              b.scheduled_date_time
            );
          } else if (a.scheduled_date_time) {
            comparison = -1; // a is non-null, b is null; a comes first
          } else if (b.scheduled_date_time) {
            comparison = 1; // b is non-null, a is null; b comes first
          }
          break;
        }
        default: {
          break;
        }
      }

      return myPlanSortsState.activeSortOrder === "ascending"
        ? comparison
        : -comparison;
    });

    return planEntriesSortedAndFiltered;
  }

  const sortedAndFilteredPlanEntries = filterAndSortPlanEntries(
    planEntries || []
  );

  return {
    isLoading,
    refetch,
    planEntries,
    sortedAndFilteredPlanEntries,
  };
}

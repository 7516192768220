import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Database } from "../../../../types/supabase";
import { supabase } from "../../../clients/supabaseClient";
import { QUERY_KEYS } from "../../queryKeys";
import { PlanEntry } from "../planEntry";

export type GuideEventToPlanEntry = Database["public"]["Tables"]["guide_event_to_plan_entry"]["Row"];
const TABLE = "guide_event_to_plan_entry";

export function usePlanEntriesInGuideEvent(guideEventId?: string | null) {
  return useQuery({
    queryKey: [QUERY_KEYS.guideEventToPlanEntry, guideEventId],
    queryFn: async () => {
      if (!guideEventId) return null;
      const { data } = await supabase
        .from(TABLE)
        .select("plan_entry(*)")
        .eq("guide_event_id", guideEventId);
      const result = data?.map(row => row.plan_entry).filter(row => row !== null) as PlanEntry[];
      return result;
    },
  });
}

export function useUpsertPlanEntryToGuideEvent() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (guideEventToPlanEntry: GuideEventToPlanEntry) => {
      const { data, error } = await supabase
        .from(TABLE)
        .upsert(guideEventToPlanEntry);
      if (error) {
        throw error;
      }

      return data;
    },
    onMutate: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.guideEventToPlanEntry],
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.guideEventToPlanEntry],
      });
    },
  }
  );
}


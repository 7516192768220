import { useEffect, useState } from "react";
import { AssessmentQuestion } from "../../../backend/resources/assessmentQuestion";
import { useUserAdlos } from "../../../backend/resources/userAdlo";
import { UserAssessmentAnswer, useInProgressUserAssessmentAnswerForQuestionId, useUpdateUserAssessmentAnswer } from "../../../backend/resources/userAssessmentAnswer";
import { useAssessmentStore } from "../../../state/assessment";
import { ButtonWithIcon, IconOption } from "../../ButtonWithIcon";
import { Input } from "./Input";
import { FreeFormText } from "./Input/inputTypes/FreeFormText";
import { YesOrNoOptions } from "./Input/inputTypes/YesOrNoInput";
import { Question } from "./Question/Question";

export default function QuestionAndInput({ question, isInViewMode }: { question: AssessmentQuestion, isInViewMode: boolean }) {
  // Stores
  const activeUserAssessmentId = useAssessmentStore(
    (state) => state.activeUserAssessmentId
  );

  // Hooks
  const { data } = useUserAdlos();
  const userAdlo = data?.[0];

  // TODO lets make this a batch query in the parent and pass it down. 
  // That way we can make the api call once and then just use the data and have unified loading data.
  const { data: currentAnswerObject, isLoading: isLoadingCurrentAnswerObject } =
    useInProgressUserAssessmentAnswerForQuestionId(question.id);

  // State

  const [localAnswerObject, setLocalAnswerObject] = useState<UserAssessmentAnswer | undefined>();
  const [isContextBoxOpen, setIsContextBoxOpen] = useState(
    !!localAnswerObject?.context
  );

  // Mutation
  const updateUserAssessmentAnswer =
    useUpdateUserAssessmentAnswer().mutateAsync;


  // Constants
  const isOpenTextQuestion = question.raw_data.additional_options?.includes(YesOrNoOptions.OPEN);
  const isNotYesAnswer = Object.values(YesOrNoOptions).includes(localAnswerObject?.answer as YesOrNoOptions) && localAnswerObject?.answer !== YesOrNoOptions.YES;
  const isEmptyAnswer = !localAnswerObject || localAnswerObject.answer === 0 || isNotYesAnswer;

  // Effects
  useEffect(() => {
    // Three cases:
    // 1. Open text question, if answer is yes, context box is open
    // 2. Not open text question, if answer is 0, context box is not open
    // 3. Open text question, if answer is not 0, context box is open
    if (isEmptyAnswer) {
      setIsContextBoxOpen(false);
    } else if (isOpenTextQuestion) {
      setIsContextBoxOpen(!isEmptyAnswer);
    } else if (localAnswerObject?.context) {
      setIsContextBoxOpen(true);
    }
  }, [localAnswerObject, currentAnswerObject, isOpenTextQuestion]);

  useEffect(() => {
    // if first render, load currentAnswerObject to local state
    if (!currentAnswerObject && !isLoadingCurrentAnswerObject) {
      setLocalAnswerObject(undefined)
    } else if (currentAnswerObject && localAnswerObject?.id !== currentAnswerObject.id) {
      setLocalAnswerObject({ ...currentAnswerObject, answer: currentAnswerObject.answer })
    }
  }, [currentAnswerObject, activeUserAssessmentId, localAnswerObject, isLoadingCurrentAnswerObject]);

  // Handlers
  function updateLocalAnswerObject(update: Partial<UserAssessmentAnswer>) {
    setLocalAnswerObject((prevState: UserAssessmentAnswer | undefined) => ({
      ...prevState,
      ...update,
      assessment_question_id: question.id,
    }) as UserAssessmentAnswer);
    updateUserAssessmentAnswer({
      userAssessmentId: activeUserAssessmentId!,
      answer: update?.answer,
      questionId: question.id,
      context: update?.context,
    });
  }

  // -----*----------*----------*----------*----------*----------*----------*-----
  return <>
    {/* Question */}
    <p className="font-bold text-sm">{question.category}</p>
    <p/>
    <Question
      adlo={userAdlo}
      isLoadingAdloPreferredName={!userAdlo}
      question={question}
    />
    {/* Answer */}
    <Input
      disabled={isInViewMode}
      question={question}
      localAnswerObject={isLoadingCurrentAnswerObject ? undefined : localAnswerObject}
      updateLocalAnswerObject={updateLocalAnswerObject}
    />
    {/* Context */}
    <div className="mb-4 -mt-2">
      {/* Add context button */}
      {!isContextBoxOpen && !isInViewMode && !isOpenTextQuestion && question.type !== "select" ?
        <ButtonWithIcon
          onClick={() => setIsContextBoxOpen(true)}
          disabled={!localAnswerObject || isEmptyAnswer || isContextBoxOpen || isInViewMode}
          text={"Add Context"}
          size={"small"}
          className="mt-5 w-full flex text-sm"
          icon={IconOption.PLUS}
        />
        : null}
      {/* Context box */}
      <FreeFormText
        className={`w-full min-h-[50px] max-w-[500px] mt-2`}
        placeholder="(Optional) Add context here..."
        hidden={
          !isContextBoxOpen
          || ((isInViewMode && !localAnswerObject?.context) || isEmptyAnswer)
        }
        answer={localAnswerObject?.context}
        disabled={isInViewMode}
        length={"short"}
        onChange={(value) => {
          if (value !== localAnswerObject?.context && activeUserAssessmentId) {
            updateLocalAnswerObject({ context: value as string })
            updateUserAssessmentAnswer({
              userAssessmentId: activeUserAssessmentId,
              answer: localAnswerObject?.answer,
              questionId: question.id,
              context: value,
            });
          }
        }}
      />
    </div>
    {/* Filler div */}
    <div /> 
  </>
}
import { Cell, Row, flexRender } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { ReactComponent as MeetingSVG } from "../../assets/meeting.svg";
import RecurringIcon from "../../assets/recurring_icon.png";
import { PlanEntry, PlanEntryUpdate, ServiceStatus, ServiceStatusColor, TaskStatus, TaskStatusColor, TaskStatusLabel, TaskWithGuideInfo } from '../../backend/resources/planEntry';
import { useWindowSize } from '../../hooks/useWindowSize';
import { CareCentralRoute, CarePilotRoute, SharedRoute, useAppNavigate } from '../../lib/routing';
import { useTaskFilterStore } from '../../state/taskFilter/taskFilter';
import { useUserStore } from '../../state/user';
import Who, { UserType } from '../MyPlanPage/components/Who';
import { FilterConfig } from '../TaskNavigatorPage/components/TaskFilters';
import { GuideCategoryColorStyle, isTaskOverdue, useFilteredData } from '../TaskNavigatorPage/taskTableUtils';
import { InfiniteScrollingTable, PaginationOptions } from './InfiniteScrollingTable';


export enum TaskTableRowType {
  GUIDE = "GUIDE",
  GENERAL = "General",
  ServiceRequest = "SR"
}

const TaskTypeColor = {
  [TaskTableRowType.GUIDE]: "#75B0B4",
  [TaskTableRowType.ServiceRequest]: "#8690EE",
  [TaskTableRowType.GENERAL]: "#45C4EC",
}

export function getTaskType(task: TaskWithGuideInfo) {
  if (!!task.is_service_ticket) {
    return TaskTableRowType.ServiceRequest;
  } else if (!!task.guide_task_id) {
    return TaskTableRowType.GUIDE;
  } else {
    return TaskTableRowType.GENERAL;
  }
}


export enum TaskTableFields {
  TaskName = "Task Name",
  Account = "Account",
  Carespace = "Carespace",
  TaskType = "Type",
  CategoryFullTitle = "Category Title",
  Category = "Category",
  Status = "Status",
  Due = "Due",
  RecurringInterval = "recurring_interval",
  Who = "Who",
  NetworkId = "network_id",
  Id = "id",
  ServiceStatus = "Service Status",
  GUIDEIdentifier = "GUIDE_identifier",
  CreatedAt = "Created",
  RawData = "Raw Data"
}

export type TaskTableType = {
  [TaskTableFields.TaskName]: string;
  [TaskTableFields.Account]: string;
  [TaskTableFields.Carespace]: string;
  [TaskTableFields.CategoryFullTitle]: string;
  [TaskTableFields.Category]: string;
  [TaskTableFields.Status]: TaskStatus;
  [TaskTableFields.Due]: string;
  [TaskTableFields.Who]: string | null;
  [TaskTableFields.NetworkId]: string;
  [TaskTableFields.RecurringInterval]: string | null;
  [TaskTableFields.Id]: string;
  [TaskTableFields.ServiceStatus]: ServiceStatus;
  [TaskTableFields.GUIDEIdentifier]: string;
  [TaskTableFields.CreatedAt]: string;
  [TaskTableFields.TaskType]: string;
  ["subRows"]?: TaskTableType[];
  [TaskTableFields.RawData]: TaskWithGuideInfo;
};



interface TaskTableProps {
  filterConfig?:  FilterConfig;
  sortFunction?: (a: TaskTableType, b: TaskTableType) => number;
  data: TaskWithGuideInfo[] | undefined;
  hiddenColumns?: string[];
  updateTask: (taskUpdate: PlanEntryUpdate) => Promise<PlanEntry>;
  shouldPreventClicks?: boolean
  handleClick?(cell: Cell<TaskTableType, unknown>, row: Row<TaskTableType>): void;
  hideCheckbox?: boolean;
  overriddenHeaderNames?: { [key: string]: string };
  paginationOptions?: PaginationOptions<TaskTableType>;
  maxHeight?: string;
  isLoading?: boolean;
}

export function TaskTable({
  shouldPreventClicks,
  filterConfig,
  sortFunction,
  hiddenColumns,
  data: rawData,
  updateTask,
  handleClick,
  isLoading,
  hideCheckbox,
  overriddenHeaderNames,
  maxHeight,
  paginationOptions,
}: TaskTableProps) {
  const navigate = useAppNavigate();

  const [data, setData] = useState<TaskTableType[]>(() => []);
  const { carespace: network, category, who, status, dueDate, entryId, setEntryId, hideDone } = useTaskFilterStore();
  const authUser = useUserStore((state) => state.user);
  const { isMobile } = useWindowSize();
  const filteredData = useFilteredData({
    data: rawData,
    config: filterConfig
  });
  // ///////
  // Effects
  ////////

  useEffect(() => {
    // Updates data
    if (filteredData) {
      const data = filteredData.map(item => {
        return {
          [TaskTableFields.Id]: item.id,
          [TaskTableFields.CreatedAt]: new Date(item.created_at).toLocaleDateString('en-US'),
          [TaskTableFields.TaskName]: item.name,
          [TaskTableFields.Carespace]: item.network?.name ?? "",
          [TaskTableFields.Category]: item.guide_task?.guide_category?.short_title ?? "N/A",
          [TaskTableFields.CategoryFullTitle]: item.guide_task?.guide_category?.title ?? "N/A",
          [TaskTableFields.Account]: "All",
          [TaskTableFields.Due]: new Date(item.scheduled_date_time ?? "").toLocaleDateString('en-US'),
          [TaskTableFields.Who]: item.plan_entry_attendee?.find(attendee => attendee.user_id === authUser?.id)?.user_id ?? item.user_id,
          [TaskTableFields.Status]: item.status as TaskStatus,
          [TaskTableFields.NetworkId]: item.network_id ?? "",
          [TaskTableFields.RecurringInterval]: item.recurring_interval as string,
          [TaskTableFields.ServiceStatus]: item.service_request_to_engagement?.service_engagement
            ? (item.service_request_to_engagement?.service_engagement.is_active ? ServiceStatus.Active : ServiceStatus.Inactive)
            : ServiceStatus.NotApplicable,
          [TaskTableFields.TaskType]: getTaskType(item),
          [TaskTableFields.RawData]: item,
          [TaskTableFields.GUIDEIdentifier]: item.guide_task?.GUIDE_identifier ?? "",
          ["subRows"]: item.subRows?.map(subRow => {
            return {
              [TaskTableFields.Id]: subRow.id,
              [TaskTableFields.RawData]: subRow,
              [TaskTableFields.CreatedAt]: new Date(subRow.created_at).toLocaleDateString('en-US'),
              [TaskTableFields.TaskName]: subRow.name,
              [TaskTableFields.Carespace]: subRow.network?.name ?? "",
              [TaskTableFields.TaskType]: getTaskType(subRow),
              [TaskTableFields.Category]: "",
              [TaskTableFields.CategoryFullTitle]: "",
              [TaskTableFields.Account]: "All",
              [TaskTableFields.Due]: new Date(subRow.scheduled_date_time ?? "").toLocaleDateString('en-US'),
              [TaskTableFields.Who]: subRow.user_id,
              [TaskTableFields.Status]: subRow.status as TaskStatus,
              [TaskTableFields.NetworkId]: subRow.network_id ?? "",
              [TaskTableFields.RecurringInterval]: subRow.recurring_interval as string,
              [TaskTableFields.ServiceStatus]: ServiceStatus.NotApplicable,
              [TaskTableFields.GUIDEIdentifier]: "",
              "subRows": [],
            }
          })
        }
      }
      )

      const sortedData: TaskTableType[] = sortFunction ? data.sort(sortFunction) : data;
      setData(sortedData);

    }
  }, [filteredData, network, who, category, status, dueDate, hideDone]);

  // ///////
  // Handlers
  ////////


  function defaultHandleClick(cell: Cell<TaskTableType, unknown>, row: Row<TaskTableType>) {
    if (shouldPreventClicks) return
    switch (cell.column.id) {
      case TaskTableFields.Category:
        navigate({
          path: CareCentralRoute.GUIDE_CATEGORY,
          params: {
            category: encodeURIComponent(row.original[TaskTableFields.CategoryFullTitle] ?? "N/A"),
            network_id: row.original[TaskTableFields.NetworkId]
          }
        });
        break;
      default:
        setEntryId(row.original[TaskTableFields.Id]);
        switch (row.original[TaskTableFields.TaskType]) {
          case TaskTableRowType.GUIDE:
            navigate({
              path: CareCentralRoute.GUIDE_TASK_PAGE,
              params: {
                id: row.original[TaskTableFields.Id]
              }
            });
            break;
          case TaskTableRowType.GENERAL:
            navigate({
              path: CarePilotRoute.PLAN_ID,
              params: {
                id: row.original[TaskTableFields.Id]
              }
            });
            break;
          case TaskTableRowType.ServiceRequest:
            navigate({
              path: SharedRoute.SERVICE_REQUEST_VIEW,
              params: {
                id: row.original[TaskTableFields.Id]
              }
            });
            break;
        }
        break;
    }
  }

  return (
    <InfiniteScrollingTable
      data={data}
      isLoading={isLoading}
      paginationOptions={paginationOptions}
      maxHeight={maxHeight}
      headersToCenter={[TaskTableFields.Status, TaskTableFields.TaskType, TaskTableFields.Category, TaskTableFields.ServiceStatus]}
      checkboxOptions={!hideCheckbox ? {
        disabled: (row: Row<TaskTableType>) => row.original[TaskTableFields.Status] === TaskStatus.Done && !!row.original[TaskTableFields.RecurringInterval],
        onCheck: (row: Row<TaskTableType>, isChecked: boolean) => {
          const newStatus = isChecked ? TaskStatus.Done : TaskStatus.InProgress;
          updateTask({
            id: row.original[TaskTableFields.Id],
            status: newStatus,
          });
        },
        isChecked: (row: Row<TaskTableType>) => row.original[TaskTableFields.Status] === TaskStatus.Done
      } : undefined}
      mobileColumns={[TaskTableFields.TaskName, TaskTableFields.Due]}
      hiddenColumns={[
        ...(hiddenColumns ?? []),
        TaskTableFields.NetworkId,
        TaskTableFields.CategoryFullTitle,
        TaskTableFields.Id,
        TaskTableFields.Account,
        TaskTableFields.RawData,
        TaskTableFields.GUIDEIdentifier,
        "subRows"
      ]}
      hiddenColumnHeaders={[TaskTableFields.RecurringInterval]}
      CellContent={({ cell, row }) => <CellContent cell={cell} row={row} />}
      columnFields={Object.values(TaskTableFields)}
      overriddenHeaderNames={overriddenHeaderNames}
      handleClick={handleClick ?? defaultHandleClick}
    />
  )
}


function CellContent({ cell, row }: { cell: Cell<TaskTableType, unknown>, row: Row<TaskTableType> }) {
  if (!row.original[TaskTableFields.Id]) return null;
  switch (cell.column.id) {
    case TaskTableFields.Who:
      return (
        cell.row.original.Who ? <div className='flex w-[100px]'>
          <Who
            userType={UserType.ORG_USERS}
            entry={null}
            selectedPlanEntryOwnerId={cell.row.original.Who}
            setPlanEntryOwner={() => { }}
          />
        </div> : null
      );
    case TaskTableFields.Status:
      const rawData = cell.row.original[TaskTableFields.RawData]
      return (
        <div className='flex gap-2 items-center justify-center w-[150px] max-w-[150px]'>
          <p style={{
            background: TaskStatusColor[cell.row.original.Status]
          }}
            className={`rounded-full text-xs py-1 text-white text-center whitespace-nowrap px-4 w-[100px]  w-min-[100px] w-max-[100px]`}>
            {TaskStatusLabel[cell.row.original.Status]}
          </p>
          {(rawData.plan_entry_attendee?.length || rawData.google_meeting_code) && <MeetingSVG className="w-4 h-4" />}
        </div>
      );
    case TaskTableFields.TaskType:
      return <p
        className={`rounded-full text-xs py-1 text-white text-center whitespace-nowrap px-4  w-[120px] max-w-[120px]`}
        style={{
          background: TaskTypeColor[cell.row.original[TaskTableFields.TaskType] as TaskTableRowType]
        }}>{cell.row.original[TaskTableFields.TaskType]}</p>
    case TaskTableFields.Due:
      const isOverdue = isTaskOverdue(cell.row.original.Due, cell.row.original.Status);
      return <p
        className={
          `flex gap-1 rounded-full truncate text-left  w-[90px] w-min-[90px] max-w-[150px] ${isOverdue ? 'text-red-500' : ''}`
        } >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </p>
    case TaskTableFields.RecurringInterval:
      return <p
        className={
          `flex gap-1 rounded-full truncate text-left w-[20px] -ml-9`
        } >
        {row.original[TaskTableFields.RecurringInterval] &&
          <img src={RecurringIcon} alt="Recurring Icon" className='w-5' />}
      </p>
    case TaskTableFields.Category:
      const category = row.original[TaskTableFields.CategoryFullTitle] ?? "N/A";
      return (
        <p
          style={{
            background: GuideCategoryColorStyle[category]
          }}
          className={
            `rounded-full truncate text-xs py-1 text-center ${category === "N/A" ? "text-black" : "text-white"} px-4 w-min-[150px]  w-[150px] max-w-[150px]`
          } >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </p>
      );
    case TaskTableFields.TaskName:
      return (
        <p
          className={
            `rounded-full truncate text-left w-[160px] min-w-[160px] max-w-[160px] md:w-[300px] md:w-min-[300px] md:max-w-[300px]`
          } >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </p>
      );
    case TaskTableFields.Carespace:
      return (
        <p
          className={
            `rounded-full truncate text-left w-[150px] w-min-[150px] max-w-[150px]`
          } >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </p>
      );

    case TaskTableFields.ServiceStatus:
      return (
        <p style={{
          background: ServiceStatusColor[(cell.row.original[TaskTableFields.ServiceStatus])]
        }}
          className={`rounded-full text-xs py-1 text-white text-center whitespace-nowrap px-4 w-[100px]  w-min-[100px] w-max-[100px]`}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </p>
      );
    default:
      return (
        <p
          className={
            `truncate text-left max-w-[100px] w-[100px]`
          } >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </p>
      );
  }
}

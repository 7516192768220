import * as RadixSelect from "@radix-ui/react-select";
import { cx } from "class-variance-authority";
import type { ReactNode } from "react";
import { forwardRef, useState } from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import { Arrow } from "../../icons/Arrow";
import { BottomSheet } from "../Sheet";
import "./Select.css";

// Updated SelectItem component
const SelectItem = forwardRef<HTMLDivElement, RadixSelect.SelectItemProps>(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <RadixSelect.Item
        className={cx("SelectItem", className)}
        {...props}
        ref={forwardedRef}>
        <RadixSelect.ItemText>{children}</RadixSelect.ItemText>
        <RadixSelect.ItemIndicator className="SelectItemIndicator">
          {/* <CheckIcon /> */}
        </RadixSelect.ItemIndicator>
      </RadixSelect.Item>
    );
  }
);

interface Props {
  options: { value: any; label: string; background?: string, icon?: ReactNode }[];
  onChange: (value: any) => void;
  disabled?: boolean;
  currentOption?: { value: any; label: string; background?: string, icon?: ReactNode };
  classNames?: string; // pass sizing, etc to select
  fontSize?: string;
  textColor?: string;
  padding?: string;
  borderClass?: string;
  mobileTitle?: string;
  icon?: ReactNode;
  placeHolder?: string;
}

export function Select({
  options,
  onChange,
  disabled,
  currentOption,
  classNames,
  borderClass,
  fontSize,
  textColor,
  padding,
  icon,
  mobileTitle,
  placeHolder,
}: Props) {
  // Initialize the background color with the default selected option
  const defaultOption = currentOption || options[0];
  const defaultBackground = defaultOption ? defaultOption.background : "";
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  // State to store the background of the selected option
  const [selectedBackground, setSelectedBackground] = useState<string>(
    defaultBackground || ""
  );

  const { isMobile } = useWindowSize();

  // Handle value change to update the background
  const handleValueChange = (value: any) => {
    // Find the selected option
    const selectedOption = options.find((option) => option.value === value);

    // Update the background color state
    if (selectedOption && selectedOption.background) {
      setSelectedBackground(selectedOption.background);
    }

    // Call the onChange prop
    onChange(value);
  };

  if (isMobile) {
    return (
      <>
        <div
          tabIndex={0}
          className={`${classNames || ""} ${padding ?? "p-1 px-2"} ${borderClass || ""
            }  border border-zinc-300 rounded-md max-w-full flex gap-2 items-center justify-between`}
          onClick={() => {
            if (!disabled) setIsBottomSheetOpen(true);
          }}>
          {currentOption?.icon ? currentOption.icon : icon}
          <p
            className={`${fontSize ?? "text-sm font-light"} ${disabled ? "text-zinc-400" : ""
              } line-clamp-1`}>
            {currentOption?.value ? (currentOption?.label ?? options[0]?.label) : placeHolder}
          </p>
          <Arrow
            className="rotate-180 fill-brand-orange ml-1 min-w-[10px]"
            size={12}
          />
        </div>
        <BottomSheet
          isOpen={isBottomSheetOpen}
          onClose={() => setIsBottomSheetOpen(false)}>
          <div className="px-4 pb-20 font-light">
            <p className={`${fontSize ?? "text-lg"} mb-2`}>{mobileTitle}</p>
            {(placeHolder
              ? [{ value: undefined, label: placeHolder, background: null, icon: null }, ...options]
              : options
            ).map((option, index) => (
              <div
                key={`${option.value}-${index}`}
                className={`flex items-center py-2 ${index < options.length - 1 ? "border-b" : ""
                  } -ml-4 -mr-4 px-6 gap-1 `}
                onClick={() => {
                  if (option.value) {
                    handleValueChange(option.value);
                    setIsBottomSheetOpen(false);
                  }
                }}>
                {option.background ? (
                  <div
                    className={`rounded-full w-2 h-2 mr-2 ${option.background && `${option.background}`
                      }`}
                  />
                ) : null}
                {option.icon ? option.icon : icon}
                <div>{option.label}</div>
              </div>
            ))}
          </div>
        </BottomSheet>
      </>
    );
  }

  return (
    <RadixSelect.Root
      onValueChange={handleValueChange}
      disabled={disabled}
      value={currentOption?.value ?? (placeHolder ? undefined : options[0]?.value)}>
      <RadixSelect.Trigger
        className={`SelectTrigger overflow-clip  rounded-full ${disabled ? "" : "hover:bg-[rgb(243,244,246)]"
          } ${classNames || ""} ${disabled ? "border-faint-gray" : ""} ${borderClass || ""
          }`}
        aria-label="Select">
        {/* Wrap the value in a span and apply the background color to the span */}
        <span
          className={`font-normal ]   w-full  ${disabled ? "text-zinc-400" : !!textColor ? textColor : "text-black text-left"
            } ${padding ?? "p-1 px-2"}`}>
          <RadixSelect.Value
            defaultValue={currentOption?.value || options[0]?.value}
          />
        </span>
        {disabled ? null : (
          <RadixSelect.Icon
            className={`SelectIcon ${disabled ? "saturate-0" : ""}`}>
            <Arrow
              className="rotate-180 fill-brand-orange ml-1"
              size={12}
            />
          </RadixSelect.Icon>
        )}
      </RadixSelect.Trigger>
      <RadixSelect.Portal>
        <RadixSelect.Content
          className="SelectContent z-50 overflow-y-scroll max-h-[20rem] w-full"
          position="popper">
          <RadixSelect.Viewport className="SelectViewport">
            {(placeHolder
              ? [{ value: undefined, label: placeHolder, background: null, icon: null }, ...options]
              : options
            ).map((option, index) => (
              <SelectItem
                key={`option-${index}`}
                value={option.value}
                disabled={option.value === undefined}
                className={`m-1 ${disabled ? "" : "hover:brightness-90 focus:brightness-90"
                  }`}>
                <div className="flex items-center w-full">
                  {option.background ? (
                    <div
                      className={`
                        rounded-full w-2 h-2 mr-2 
                        ${option.background}
                      `}>
                    </div>
                  ) : null}
                  <div
                    className={`
                      flex gap-2  w-full truncate
                      ${fontSize ? fontSize : "text-sm"}
                      font-light
                      ${padding ? padding : "p-1 px-2"} 
                      ${option.label === placeHolder ? "text-gray-400" : ""}
                    `}>
                    {option.icon ? option.icon : icon}
                    <p>{option.label}</p>
                  </div>
                </div>
              </SelectItem>
            ))}
          </RadixSelect.Viewport>
        </RadixSelect.Content>
      </RadixSelect.Portal>
    </RadixSelect.Root>
  );
}

import { TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { ReactComponent as AfternoonIconSvg } from "../../assets/afternoon-icon.svg";
import { ReactComponent as BedtimeIconSvg } from "../../assets/bedtime-icon.svg";
import { ReactComponent as CancelIconSvg } from "../../assets/cancel-icon.svg";
import { ReactComponent as CheckmarkIconSvg } from "../../assets/checkmark-icon.svg";
import { ReactComponent as EditIconSvg } from "../../assets/edit-folder.svg";
import { ReactComponent as NotTakenCircleSvg } from "../../assets/ellipse-circle.svg";
import { ReactComponent as EveningIconSvg } from "../../assets/evening-icon.svg";
import { ReactComponent as MorningIconSvg } from "../../assets/morning-icon.svg";
import { ReactComponent as TakenCircleSvg } from "../../assets/taken-circle.svg";
import {
  useToggleDose,
  useUserPrescriptionsQuery,
} from "../../backend/resources/userPrescription/userPrescription";
import type { UserPrescriptionDoseTakenInsert } from "../../backend/resources/userPrescriptionDoseTaken/userPrescriptionDoseTaken";
import {
  combineScheduledAndTakenDoses,
  useDeleteUserPrescriptionDoseTaken,
  useDosesTakenQuery,
  useUpsertUserPrescriptionDoseTaken,
} from "../../backend/resources/userPrescriptionDoseTaken/userPrescriptionDoseTaken";
import type { ScheduledDoseInformation } from "../../backend/resources/userPrescriptionScheduledDose/userPrescriptionScheduledDose";
import { useScheduledDosesQuery } from "../../backend/resources/userPrescriptionScheduledDose/userPrescriptionScheduledDose";
import { useWindowSize } from "../../hooks/useWindowSize";
import { CarePilotRoute, createPath, useAppNavigate } from "../../lib/routing";
import ActionButtons from "../../shared/ui/action-buttons";
import { useNanasDayStore } from "../../state/nanasDay";
import { useUserStore } from "../../state/user";
import { capitalizeFirstLetter } from "../../utils";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
import { LoadingSpinner } from "../LoadingSpinner";
import { localTimeForPostgres } from "./utils";

export type ScheduleSectionKey =
  | "morning"
  | "afternoon"
  | "evening"
  | "bedtime";

export type ScheduleSectionDetails = {
  title: string;
  icon: JSX.Element | null;
  timeString: string;
};

export function getCurrentMedicationSection(): ScheduleSectionKey {
  const now = dayjs();
  const currentHour = now.hour();

  if (currentHour >= 8 && currentHour < 12) {
    return "morning";
  } else if (currentHour >= 12 && currentHour < 17) {
    return "afternoon";
  } else if (currentHour >= 17 && currentHour < 21) {
    return "evening";
  } else {
    return "bedtime";
  }
}

export const sectionDetails: Record<
  ScheduleSectionKey,
  ScheduleSectionDetails
> = {
  morning: {
    title: "Morning",
    icon: <MorningIconSvg className="w-12 h-12" />,
    timeString: "5-12",
  },
  afternoon: {
    title: "Afternoon",
    icon: <AfternoonIconSvg className="w-12 h-12" />,
    timeString: "12-5",
  },
  evening: {
    title: "Evening",
    icon: <EveningIconSvg className="w-12 h-12" />,
    timeString: "5-9",
  },
  bedtime: {
    title: "Bedtime",
    icon: <BedtimeIconSvg className="w-12 h-12" />,
    timeString: "9",
  },
};

export function ScheduleView() {
  const authUser = useUserStore((state) => state.user);
  const { isMobile } = useWindowSize();
  const navigate = useAppNavigate();

  const { isLoadingUserPrescriptions } = useUserPrescriptionsQuery();

  return (
    <>
      <div
        className={`flex flex-col gap-12 ${isMobile ? "pb-40" : "min-w-[800px] pb-36"
          }`}>
        {isLoadingUserPrescriptions ? (
          <div className="flex w-full h-full items-center justify-center">
            <LoadingSpinner className="w-20 h-20" />
          </div>
        ) : (
          <>
            <ScheduleSection sectionKey="morning" />
            <ScheduleSection sectionKey="afternoon" />
            <ScheduleSection sectionKey="evening" />
            <ScheduleSection sectionKey="bedtime" />
            <TotalsTaken />
          </>
        )}
      </div>

      {isMobile ?
        <ActionButtons title={"Actions"}>
          <ButtonWithIcon
            onClick={() => {
              navigate({
                path: CarePilotRoute.DAY_EDIT
              })
            }}
            text={`Edit schedule`}
            size={"small"}
            icon={IconOption.EDIT}
          />
          <ButtonWithIcon
            onClick={() => {
              navigate({
                path: CarePilotRoute.DAY_INTAKE
              })
            }}
            text={`Intake`}
            size="small"
            icon={IconOption.INTAKE}
          />
          <ButtonWithIcon
            onClick={() => {
              navigate({
                path: CarePilotRoute.DAY_NOTES
              })
            }}
            text={"Notes"}
            size="small"
            icon={IconOption.NOTES}
          />
        </ActionButtons>
        : null
      }
    </>
  );
}

export function ScheduleSection({
  sectionKey,
}: {
  sectionKey: ScheduleSectionKey;
}) {
  const authUser = useUserStore((state) => state.user);

  const { isMobile } = useWindowSize();

  const {
    // scheduledDoses,
    isLoadingScheduledDoses,
    morningScheduledDoses,
    afternoonScheduledDoses,
    eveningScheduledDoses,
    bedtimeScheduledDoses,
    // as needed
    morningAsNeededScheduledDoses,
    afternoonAsNeededScheduledDoses,
    eveningAsNeededScheduledDoses,
    bedtimeAsNeededScheduledDoses,
  } = useScheduledDosesQuery(authUser?.id);

  function getSectionScheduledPrescriptions() {
    switch (sectionKey) {
      case "morning": {
        return morningScheduledDoses;
      }
      case "afternoon": {
        return afternoonScheduledDoses;
      }
      case "evening": {
        return eveningScheduledDoses;
      }
      case "bedtime": {
        return bedtimeScheduledDoses;
      }
    }
  }

  function getSectionAsNeededPrescriptions() {
    switch (sectionKey) {
      case "morning": {
        return morningAsNeededScheduledDoses;
      }
      case "afternoon": {
        return afternoonAsNeededScheduledDoses;
      }
      case "evening": {
        return eveningAsNeededScheduledDoses;
      }
      case "bedtime": {
        return bedtimeAsNeededScheduledDoses;
      }
    }
  }

  const prescriptionScheduledDoses = getSectionScheduledPrescriptions() || [];
  const asNeededPrescriptionScheduledDoses =
    getSectionAsNeededPrescriptions() || [];

  return (
    <div className="flex flex-col gap-4">
      {/* section header */}
      <div className="flex gap-3 items-center">
        {/* icon */}
        <div className="flex items-center justify-center w-8 h-8">
          {sectionDetails[sectionKey].icon}
        </div>
        {/* text */}
        <h2 className="font-light text-[22px]">
          {sectionDetails[sectionKey].title}
          {/* time */}
          {` (${sectionDetails[sectionKey].timeString})`}
        </h2>
      </div>
      {/* scheduled prescriptions section */}
      {isLoadingScheduledDoses ? (
        <LoadingSpinner className="w-20 h-20" />
      ) : (
        <div
          className={`flex flex-col gap-4 ${!isMobile ? "pl-[2.80rem]" : ""}`}>
          {prescriptionScheduledDoses.length === 0 ? (
            <div className="text-gray-400">No scheduled prescriptions</div>
          ) : (
            <>
              {/* row header */}
              {isMobile ? (
                <ScheduleDoseRowHeaderMobile />
              ) : (
                <ScheduleDoseRowHeader />
              )}
              {/* rows */}
              <div className="flex flex-col gap-3">
                {prescriptionScheduledDoses.map((scheduledDoseInfo) => {
                  if (isMobile) {
                    return (
                      <ScheduledDoseRowMobile
                        scheduledDoseInfo={scheduledDoseInfo}
                      />
                    );
                  } else {
                    return (
                      <ScheduledDoseRow scheduledDoseInfo={scheduledDoseInfo} />
                    );
                  }
                })}
              </div>
            </>
          )}
        </div>
      )}
      {/* as needed section */}
      {isLoadingScheduledDoses ? (
        <LoadingSpinner className="w-20 h-20" />
      ) : (
        <>
          {asNeededPrescriptionScheduledDoses.length > 0 && (
            <div
              className={`flex flex-col gap-4 ${!isMobile ? "pl-[2.80rem]" : ""
                }`}>
              {/* header */}
              <div className="pl-1 bg-gray-300">As Needed</div>
              {/* rows */}
              <div className="flex flex-col gap-3">
                {asNeededPrescriptionScheduledDoses.map((scheduledDoseInfo) => {
                  if (isMobile) {
                    return (
                      <ScheduledDoseRowMobile
                        scheduledDoseInfo={scheduledDoseInfo}
                      />
                    );
                  } else {
                    return (
                      <ScheduledDoseRow scheduledDoseInfo={scheduledDoseInfo} />
                    );
                  }
                })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export function ScheduleDoseRowHeader() {
  return (
    <>
      {/* row header */}
      <div className="grid grid-cols-[2fr,1fr,1fr,1fr,1.5fr,1fr,1fr] items-end pr-5">
        {/* name */}
        <div className="text-sm font-medium">Name</div>
        {/* dosage */}
        <div className="text-sm font-medium text-center">
          Target/Max Quantity
        </div>
        {/* food */}
        <div className="text-sm font-medium mx-auto">w/ Food</div>
        {/* taken */}
        <div className="text-sm font-medium mx-auto">Taken?</div>
        {/* timestamp */}
        <div className="text-sm font-medium mx-auto">Taken At</div>
        {/* taken count */}
        <div className="text-sm font-medium mx-auto text-center">
          Quantity Taken
        </div>
        {/* edit button */}
        <div className="text-sm font-medium mx-auto" />
      </div>
    </>
  );
}

export function ScheduleDoseRowHeaderMobile() {
  return (
    <>
      {/* row header */}
      <div className="grid grid-cols-[2fr,1fr,1fr,1fr]">
        {/* name */}
        <div className="text-sm font-medium">Name</div>
        {/* dosage */}
        <div className="text-sm font-medium text-center">Qty</div>
        {/* taken */}
        <div className="text-sm font-medium mx-auto">Taken?</div>
        {/* when */}
        <div className="text-sm font-medium mx-auto">Time</div>
      </div>
    </>
  );
}

export function ScheduledDoseRow({
  scheduledDoseInfo,
}: {
  scheduledDoseInfo: ScheduledDoseInformation;
}) {
  const authUser = useUserStore((state) => state.user);

  const { toggleDose } = useToggleDose();

  const selectedScheduleDate = useNanasDayStore(
    (state) => state.selectedScheduleDate
  );

  const { isLoadingDosesTaken, dosesTaken, refetchDosesTaken } =
    useDosesTakenQuery(authUser?.id, selectedScheduleDate);

  if (!scheduledDoseInfo.id) {
    return <div>Error retrieving medication</div>;
  }

  // pull out dose taken information
  const doseTakenInfo = dosesTaken?.find(
    (doseTaken) =>
      doseTaken.user_prescription_scheduled_dose_id ===
      scheduledDoseInfo.user_prescription_scheduled_dose_id
  );

  const doseCount = scheduledDoseInfo.dose_count
    ? scheduledDoseInfo.dose_count
    : scheduledDoseInfo.max_doses_per_day
      ? scheduledDoseInfo.max_doses_per_day
      : "0";

  // editing state
  const [isEditing, setIsEditing] = useState(false);

  const [modifiedDoseTaken, setModifiedDoseTaken] = useState<number | null>();

  const [modifiedTakenAtTime, setModifiedTakenAtTime] =
    useState<dayjs.Dayjs | null>(dayjs(doseTakenInfo?.taken_at));

  // reset state when editing is toggled
  function resetState() {
    setIsEditing(false);
    setModifiedDoseTaken(doseTakenInfo?.dose_taken || 0);
    setModifiedTakenAtTime(dayjs(doseTakenInfo?.taken_at));
  }

  // update dose taken
  const upsertUserPrescriptionDoseTaken =
    useUpsertUserPrescriptionDoseTaken().mutateAsync;

  function createUserPrescriptionDoseTakenRow() {
    const userPrescriptionDoseTaken: UserPrescriptionDoseTakenInsert = {
      dose_taken: modifiedDoseTaken,
      taken_at: modifiedTakenAtTime?.toISOString(),
      taken_at_local: localTimeForPostgres(
        modifiedTakenAtTime,
        selectedScheduleDate
      ),
      taken_at_local_date: selectedScheduleDate,
      user_prescription_scheduled_dose_id:
        scheduledDoseInfo.user_prescription_scheduled_dose_id,
      user_id: authUser?.id,
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    return userPrescriptionDoseTaken;
  }

  async function updateUserPrescriptionDoseTakenRow() {
    const { data, error } = await upsertUserPrescriptionDoseTaken(
      createUserPrescriptionDoseTakenRow()
    );
    setIsEditing(false);

    // Calculate the difference between the modified dose and the original dose
    const doseDelta =
      (modifiedDoseTaken || 0) - (doseTakenInfo?.dose_taken || 0);

    // Update the doses_remaining with the calculated doseDelta
    toggleDose({
      scheduledDoseInfo,
      shouldIncrementDosesRemaining: doseDelta <= 0, // if negative, less doses taken than before so increment remaining
      doseTakenAmount: Math.abs(doseDelta),
    });
  }

  // keep doses in sync with selected schedule date
  useEffect(() => {
    refetchDosesTaken();
  }, [selectedScheduleDate]);

  // ensure default modified dose taken is set when dose taken info loads
  useEffect(() => {
    if (doseTakenInfo) {
      setModifiedDoseTaken(doseTakenInfo.dose_taken);
      setModifiedTakenAtTime(dayjs(doseTakenInfo?.taken_at));
    }
  }, [doseTakenInfo]);

  return (
    <div className="flex flex-col gap-3">
      <div className="grid grid-cols-[2fr,1fr,1fr,1fr,1.5fr,1fr,1fr] items-center min-h-[40px] pr-5">
        {/* name */}
        <a
          href={
            createPath({
              path: CarePilotRoute.DAY_PRESCRIPTION,
              params: {
                dispensable_drug_id: scheduledDoseInfo.dispensable_drug_id || ""
              }
            })
          }
          className="flex flex-grow items-center py-2 gap-2 border-zinc-100 relative cursor-pointer hover:underline">
          {capitalizeFirstLetter(scheduledDoseInfo.drug_name_desc || "")}
        </a>
        {/* dosage */}
        <div className="flex items-center justify-center">
          {doseCount}{" "}
          {/* {lowercaseFirstLetter(scheduledDoseInfo.dose_form_desc || "dose")}
          {"(s)"} */}
        </div>
        {/* food */}
        <div className="flex items-center justify-center">
          {scheduledDoseInfo.take_with_food ? "Yes" : "No"}
        </div>
        {/* taken? */}
        <div className="flex items-center justify-center">
          {isLoadingDosesTaken ? (
            <LoadingSpinner className="w-5 h-5" />
          ) : (
            <TakenButton
              scheduledDoseInfo={scheduledDoseInfo}
              doseTakenId={doseTakenInfo?.user_prescription_dose_taken_id}
              setIsEditing={setIsEditing}
            />
          )}
        </div>
        {/* taken at */}
        <div className="flex justify-center">
          {isLoadingDosesTaken ? (
            <LoadingSpinner className="w-5 h-5" />
          ) : (
            <TakenAtCell
              isEditing={isEditing}
              takenAt={doseTakenInfo?.taken_at_local}
              modifiedTakenAtTime={modifiedTakenAtTime}
              setModifiedTakenAtTime={setModifiedTakenAtTime}
            />
          )}
        </div>
        {/* dose taken */}
        <div className="flex justify-center">
          {isLoadingDosesTaken ? (
            <LoadingSpinner className="w-5 h-5" />
          ) : (
            <DoseTakenCell
              doseTaken={doseTakenInfo?.dose_taken}
              doseFormDesc={scheduledDoseInfo.dose_form_desc || "dose"}
              isEditing={isEditing}
              modifiedDoseTaken={modifiedDoseTaken}
              setModifiedDoseTaken={setModifiedDoseTaken}
            />
          )}
        </div>
        {/* edit button */}
        <div className="flex justify-center">
          {doseTakenInfo?.dose_taken === undefined ? (
            <div />
          ) : isEditing ? (
            <div className="flex h-full items-center gap-1">
              <button
                onClick={() => {
                  updateUserPrescriptionDoseTakenRow();
                }}>
                <CheckmarkIconSvg className="w-6" />
              </button>
              <button
                onClick={() => {
                  resetState();
                }}>
                <CancelIconSvg className="w-6" />
              </button>
            </div>
          ) : (
            <button
              onClick={() => {
                setIsEditing(true);
              }}>
              <EditIconSvg className="w-6 h-6" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export function ScheduledDoseRowMobile({
  scheduledDoseInfo,
}: {
  scheduledDoseInfo: ScheduledDoseInformation;
}) {
  const authUser = useUserStore((state) => state.user);

  const selectedScheduleDate = useNanasDayStore(
    (state) => state.selectedScheduleDate
  );

  const { isLoadingDosesTaken, dosesTaken, refetchDosesTaken } =
    useDosesTakenQuery(authUser?.id, selectedScheduleDate);

  if (!scheduledDoseInfo.id) {
    return <div>Error retrieving medication</div>;
  }

  // pull out dose taken information
  const doseTakenInfo = dosesTaken?.find(
    (doseTaken) =>
      doseTaken.user_prescription_scheduled_dose_id ===
      scheduledDoseInfo.user_prescription_scheduled_dose_id
  );

  const doseCount = scheduledDoseInfo.dose_count
    ? scheduledDoseInfo.dose_count
    : scheduledDoseInfo.max_doses_per_day
      ? scheduledDoseInfo.max_doses_per_day
      : "0";

  // editing state
  const [isEditing, setIsEditing] = useState(false);

  const [modifiedDoseTaken, setModifiedDoseTaken] = useState<number | null>();

  const [modifiedTakenAtTime, setModifiedTakenAtTime] =
    useState<dayjs.Dayjs | null>(dayjs(doseTakenInfo?.taken_at));

  // keep doses in sync with selected schedule date
  useEffect(() => {
    refetchDosesTaken();
  }, [selectedScheduleDate]);

  // ensure default modified dose taken is set when dose taken info loads
  useEffect(() => {
    if (doseTakenInfo) {
      setModifiedDoseTaken(doseTakenInfo.dose_taken);
      setModifiedTakenAtTime(dayjs(doseTakenInfo?.taken_at));
    }
  }, [doseTakenInfo]);

  return (
    <div className="flex flex-col gap-3">
      <div className="grid grid-cols-[2fr,1fr,1fr,1fr] items-center min-h-[40px]">
        {/* name */}
        <a
          href={
            createPath({
              path: CarePilotRoute.DAY_PRESCRIPTION,
              params: {
                dispensable_drug_id: scheduledDoseInfo.dispensable_drug_id || ""
              }
            })
          }
          className="flex flex-grow items-center py-2 gap-2 border-zinc-100 relative cursor-pointer hover:underline">
          {capitalizeFirstLetter(scheduledDoseInfo.drug_name_desc || "")}
        </a>
        {/* dosage */}
        <div className="flex items-center justify-center">{doseCount}</div>
        {/* taken? */}
        <div className="flex items-center justify-center">
          {isLoadingDosesTaken ? (
            <LoadingSpinner className="w-5 h-5" />
          ) : (
            <TakenButton
              scheduledDoseInfo={scheduledDoseInfo}
              doseTakenId={doseTakenInfo?.user_prescription_dose_taken_id}
              setIsEditing={setIsEditing}
            />
          )}
        </div>
        {/* taken at */}
        <div className="flex justify-center">
          {isLoadingDosesTaken ? (
            <LoadingSpinner className="w-5 h-5" />
          ) : (
            <TakenAtCell
              isEditing={isEditing}
              takenAt={doseTakenInfo?.taken_at_local}
              modifiedTakenAtTime={modifiedTakenAtTime}
              setModifiedTakenAtTime={setModifiedTakenAtTime}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export function TakenButton({
  scheduledDoseInfo,
  doseTakenId,
  setIsEditing,
}: {
  scheduledDoseInfo: ScheduledDoseInformation;
  doseTakenId?: string | null;
  setIsEditing: (isEditing: boolean) => void;
}) {
  const authUser = useUserStore((state) => state.user);

  const selectedScheduleDate = useNanasDayStore(
    (state) => state.selectedScheduleDate
  );

  const { toggleDose } = useToggleDose();

  const upsertUserPrescriptionDoseTaken =
    useUpsertUserPrescriptionDoseTaken().mutateAsync;

  const deleteUserPrescriptionDoseTaken =
    useDeleteUserPrescriptionDoseTaken().mutateAsync;

  function createUserPrescriptionDoseTakenRow() {
    /**
     * values in this row are based on the
     */
    const userPrescriptionDoseTaken: UserPrescriptionDoseTakenInsert = {
      dose_taken: scheduledDoseInfo.dose_count || 1,
      taken_at: new Date().toISOString(),
      taken_at_local: localTimeForPostgres(dayjs(), selectedScheduleDate),
      taken_at_local_date: selectedScheduleDate,
      user_prescription_scheduled_dose_id:
        scheduledDoseInfo.user_prescription_scheduled_dose_id,
      user_id: authUser?.id,
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    return userPrescriptionDoseTaken;
  }

  // pull out id for inference

  if (doseTakenId) {
    return (
      <button
        className={`flex items-center justify-center`}
        onClick={async () => {
          setIsEditing(false);
          await deleteUserPrescriptionDoseTaken(doseTakenId);
          toggleDose({
            scheduledDoseInfo,
            shouldIncrementDosesRemaining: true, // if was taken, should _add_ doses back to total remaining
            doseTakenAmount: scheduledDoseInfo.dose_count || 1,
          });
        }}>
        <TakenCircleSvg className="w-6 h-6" />
      </button>
    );
  } else {
    return (
      <button
        className={`flex items-center justify-center`}
        onClick={async () => {
          const { data, error } = await upsertUserPrescriptionDoseTaken(
            createUserPrescriptionDoseTakenRow()
          );
          toggleDose({
            scheduledDoseInfo,
            shouldIncrementDosesRemaining: false, // if was not taken, should _subtract_ doses from total remaining
            doseTakenAmount: scheduledDoseInfo.dose_count || 1,
          });
        }}>
        <NotTakenCircleSvg className="w-6 h-6" />
      </button>
    );
  }
}

export function TakenAtCell({
  textSize,
  isEditing,
  takenAt,
  modifiedTakenAtTime,
  setModifiedTakenAtTime,
}: {
  textSize?: string;
  isEditing: boolean;
  takenAt?: string | null;
  modifiedTakenAtTime: dayjs.Dayjs | null;
  setModifiedTakenAtTime: React.Dispatch<
    React.SetStateAction<dayjs.Dayjs | null>
  >;
}) {
  if (isEditing) {
    return (
      <TimePicker
        ampm={true}
        className={`max-w-[14ch] ${textSize || ""}`}
        onChange={(newValue: dayjs.Dayjs | null) =>
          setModifiedTakenAtTime(newValue)
        }
        slotProps={{ textField: { size: "small" } }}
        value={modifiedTakenAtTime}
      />
    );
  }
  if (!takenAt) {
    return <div className="flex h-full items-center">N/A</div>;
  } else if (takenAt && !isEditing) {
    return (
      <div className="flex h-full items-center">
        {new Date(takenAt).toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}
      </div>
    );
  } else {
    return (
      <TimePicker
        ampm={true}
        className="max-w-[14ch]"
        onChange={(newValue: dayjs.Dayjs | null) =>
          setModifiedTakenAtTime(newValue)
        }
        slotProps={{ textField: { size: "small" } }}
        value={modifiedTakenAtTime}
      />
    );
  }
}

export function DoseTakenCell({
  isEditing,
  doseTaken,
  doseFormDesc,
  modifiedDoseTaken,
  setModifiedDoseTaken,
}: {
  isEditing: boolean;
  doseTaken?: number | null;
  doseFormDesc: string;
  modifiedDoseTaken?: number | null;
  setModifiedDoseTaken: React.Dispatch<
    React.SetStateAction<number | null | undefined>
  >;
}) {
  if (doseTaken === undefined) {
    return <div className="flex justify-center">N/A</div>;
  } else if (doseTaken !== undefined && !isEditing) {
    return (
      <div className="flex relative h-full">
        {`${doseTaken} ${doseFormDesc}(s)`}
      </div>
    );
  } else {
    return (
      <TextField
        size="small"
        className="max-w-[8ch] text-sm"
        value={modifiedDoseTaken?.toString() || "0"}
        type="number"
        onKeyDown={(e) => {
          if (
            e.key === "e" ||
            e.key === "E" ||
            e.key === "-" ||
            e.key === "+"
          ) {
            e.preventDefault();
          }
        }}
        onChange={(event) => {
          const value = Number(event.target.value);
          if (value > 100) {
            setModifiedDoseTaken(100);
          } else if (value < 0) {
            setModifiedDoseTaken(0);
          } else {
            setModifiedDoseTaken(value);
          }
        }}
      />
    );
  }
}

export function TotalsTaken() {
  const authUser = useUserStore((state) => state.user);

  const { isMobile } = useWindowSize();

  const selectedScheduleDate = useNanasDayStore(
    (state) => state.selectedScheduleDate
  );

  const { allScheduledDoses } = useScheduledDosesQuery(authUser?.id);

  const { isLoadingDosesTaken, dosesTaken } =
    useDosesTakenQuery(authUser?.id, selectedScheduleDate);

  const dosesSumByDrug = combineScheduledAndTakenDoses(
    allScheduledDoses,
    dosesTaken
  );

  return (
    // container
    <div
      className={`flex flex-col ${!isMobile ? "pl-[2.80rem] w-[60%]" : "w-full"
        }`}>
      {/* title */}
      <h2 className="font-light text-2xl">Totals</h2>
      {isLoadingDosesTaken ? (
        <LoadingSpinner className="w-20 h-20" />
      ) : (
        <>
          {/* row header */}
          <div className="grid grid-cols-[2fr,1fr,1fr,1fr] pb-2">
            <div className="text-sm font-medium align-baseline flex flex-col justify-end">
              Name
            </div>
            <div className="text-sm font-medium text-center align-baseline flex flex-col justify-end">
              Target/Max Daily Quantity
            </div>
            <div className="text-sm font-medium text-center align-baseline flex flex-col justify-end">
              <span>Quantity</span> <span>Taken</span>
            </div>
            <div className="text-sm font-medium text-center align-baseline flex flex-col justify-end">
              Quantity Remaining
            </div>
          </div>
          {/* rows */}
          {Object.entries(dosesSumByDrug).map(([drugName, drugTotalsInfo]) => (
            <div
              key={drugName}
              className="grid grid-cols-[2fr,1fr,1fr,1fr]">
              {/* name */}
              <div>{capitalizeFirstLetter(drugName)}</div>
              {/* target dose count */}
              <div className="flex justify-center">
                {drugTotalsInfo.scheduled}
              </div>
              {/* doses taken */}
              <div className="flex justify-center">{drugTotalsInfo.taken}</div>
              {/* doses remaining */}
              <div className="flex justify-center">
                <span
                  className={
                    drugTotalsInfo.scheduled - drugTotalsInfo.taken < 0
                      ? "text-red-500 font-medium"
                      : ""
                  }>
                  {drugTotalsInfo.scheduled - drugTotalsInfo.taken}
                </span>
              </div>
              {/* Replace with the actual doses remaining */}
            </div>
          ))}
        </>
      )}
    </div>
  );
}

import { useMemo } from "react";
import { ServiceEngagementWithServiceResourceAndNetwork } from "../../backend/resources/services/serviceEngagement";
import { ServiceResourceWithServiceEngagements } from "../../backend/resources/services/serviceResource";
import { ServiceFilterTypes, useServiceFilterStore } from "../../state/serviceFilters/serviceFilters";
import { isMatch } from "../../utils";
import { ServiceFilterConfig } from "./components/ServiceFilters";




// This function is a custom hook that filters data based on the provided configuration.
// It takes an object as an argument that contains two properties: data and config.
// The data property is an array of either ServiceEngagementWithServiceResource or ServiceResourceWithServiceEngagements objects.
// The config property is an object of type ServiceFilterConfig, which specifies which filters are active.

// The function first retrieves the current filter values from the service filter store.
// It then uses the useMemo hook to optimize performance by only recalculating the filtered data when the data, config, or filters change.
// The useMemo hook returns a new array that is created by filtering the original data array. The filter method is called with a function that takes a row of data as its argument.
// The function first determines whether the row is of type ServiceEngagementWithServiceResource or ServiceResourceWithServiceEngagements by checking if the 'service_resource' property exists in the row. Depending on the type, it extracts the service resource, carespace ID, and active status from the row.
// It then checks each active filter in the config to see if the corresponding property in the row matches the filter value. If the filter is not active, it defaults to true.
// Finally, it returns the result of the logical AND operation of all the filter checks. This means that for a row to be included in the filtered data, it must pass all active filters.
// The filtered data array is then returned by the useMemo hook and by the useServicesFilteredData function.

export const useServicesFilteredData = <T extends ServiceEngagementWithServiceResourceAndNetwork | ServiceResourceWithServiceEngagements>(
  {
    data,
    config,
  }: {
    data?: T[],
    config: ServiceFilterConfig<T>,
  }
) => {
  const filters = useServiceFilterStore()
  const memoizedFilters = useMemo(() => filters, [filters.org, filters.provider, filters.serviceType, filters.carespace, filters.hideInactive]);

  return useMemo(() => {
    return data?.filter(row => {
      let service_resource, carespaceId, isActive;
      // if it's ServiceEngagementWithServiceResource
      if ('service_resource' in row) {
        service_resource = row.service_resource;
        carespaceId = row.network_id;
        isActive = row.is_active;
      } else {
        service_resource = row;
        carespaceId = row.service_engagement.map(service => service.network_id);
        isActive = row.service_engagement.some(service => service.is_active);
      }

      const isOrgMatch = config[ServiceFilterTypes.ORG] ? isMatch(filters.org, service_resource?.organization_id) : true;
      const isProviderMatch = config[ServiceFilterTypes.PROVIDER] ? isMatch(filters.provider, service_resource?.id) : true;
      const isServiceTypeMatch = config[ServiceFilterTypes.SERVICE_TYPE] ? isMatch(filters.serviceType, service_resource?.categories?.split(",")) : true;
      const isCarespaceMatch = config[ServiceFilterTypes.CARESPACE] ? isMatch(filters.carespace, carespaceId) : true;
      const isHideInactiveMatch = config[ServiceFilterTypes.HIDE_INACTIVE] ? !(filters.hideInactive && !isActive) : true;
      const isCustomFilterMatch = config.customAdditionalFilter ? config.customAdditionalFilter(row) : true;

      return isOrgMatch && isCarespaceMatch && isProviderMatch && isServiceTypeMatch && isHideInactiveMatch && isCustomFilterMatch;
    });
  }, [data, config, memoizedFilters]);
}
import Skeleton from "react-loading-skeleton";
import { useIntervention } from "../../../backend/resources/userIntervention";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useGptStore } from "../../../state/gpt";
import { CollapseButton } from "../ChatGptSideBar";
import { MessagesThread } from "../MessagesThread";

export function SideBarInterventionPage() {
  const { isMobile } = useWindowSize();

  const userAssessmentChatGptInterventionId = useGptStore(
    (state) => state.userAssessmentChatGptInterventionId
  );

  const { intervention, isLoading: isLoadingIntervention } = useIntervention(
    userAssessmentChatGptInterventionId || ""
  );

  return (
    <div className="flex flex-col max-h-full flex-grow w-full">
      <div className="flex py-3 px-2 gap-3">
        {!isMobile && <CollapseButton />}

        {isLoadingIntervention ? (
          <Skeleton className="w-24 h-4" />
        ) : (
          <h1 className="font-bold -mt-1">
            {intervention?.title}
          </h1>
        )}
      </div>
      <MessagesThread
        threadId={userAssessmentChatGptInterventionId || ""}
        pageType="interventionConversationPage"
      />
    </div>
  );
}
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { ReactComponent as ActivitiesIconSvg } from "../../assets/activities-icon.svg";
import { ReactComponent as ArchiveIcon } from "../../assets/archive.svg";
import { ReactComponent as CancelIconSvg } from "../../assets/cancel-icon.svg";
import { ReactComponent as ChatIconSvg } from "../../assets/chat-icon.svg";
import { ReactComponent as CheckmarkIconSvg } from "../../assets/checkmark-icon.svg";
import { ReactComponent as CirclePlusIcon } from "../../assets/circle-plus.svg";
import { ReactComponent as TrashIcon } from "../../assets/delete-icon.svg";
import { ReactComponent as DownloadIconSvg } from "../../assets/download.svg";
import { ReactComponent as EditIconSvg } from "../../assets/edit-folder.svg";
import { ReactComponent as EmailIconSvg } from "../../assets/email-icon.svg";
import { ReactComponent as EventsIconSvg } from "../../assets/events-icon.svg";
import { ReactComponent as FinishLaterIcon } from "../../assets/finish later.svg";
import { ReactComponent as ForwardIconSvg } from "../../assets/forward-icon.svg";
import { ReactComponent as IntakeIconSvg } from "../../assets/intake-icon.svg";
import { ReactComponent as LogoutIconSvg } from "../../assets/logout.svg";
import { ReactComponent as SearchIcon } from "../../assets/magnifying-glass.svg";
import { ReactComponent as MessageIconSvg } from "../../assets/message.svg";
import { ReactComponent as MoreIcon } from "../../assets/more-actions.svg";
import { ReactComponent as NotesIconSvg } from "../../assets/notes-icon.svg";
import PADLOCK from "../../assets/padlock.png";
import { ReactComponent as PhoneIconSvg } from "../../assets/phone-icon.svg";
import { ReactComponent as RemoveIcon } from "../../assets/remove.svg";
import { ReactComponent as RestoreSvg } from "../../assets/restore.svg";
import { ReactComponent as RightArrowButtonIcon } from "../../assets/right-arrow-button.svg";
import { ReactComponent as SaveDraftIcon } from "../../assets/save_draft_button.svg";
import { ReactComponent as SendInvitationIcon } from "../../assets/send-invitation.svg";
import { ReactComponent as SwapIcon } from "../../assets/swap.svg";
import { ReactComponent as TasksIconSvg } from "../../assets/tasks-icon.svg";
import { ReactComponent as UploadIconSvg } from "../../assets/upload.svg";
import { ReactComponent as VideoIconSvg } from "../../assets/video_chat.svg";
import { ReactComponent as ViewAssessmentIcon } from "../../assets/view-assessment.svg";
import { ReactComponent as WebIconSvg } from "../../assets/website-icon.svg";
import { Arrow } from "../../icons/Arrow";

import PrivacyPolicy from "../../assets/privacy.png";
import TermsOfService from "../../assets/tos.png";

export enum IconOption {
  PLUS,
  ARROW,
  CHAT_ICON,
  DOWN_ARROW,
  BACK_ARROW,
  UP_ARROW,
  EDIT,
  INTAKE,
  VIEW_ASSESSMENT,
  LATER,
  NOTES,
  TASK,
  ACTIVITY,
  EVENT,
  CANCEL,
  TRASH,
  CHECKMARK,
  SAVE_DRAFT,
  MORE,
  PHONE,
  EMAIL,
  WEB,
  PADLOCK,
  LOGOUT,
  SEARCH,
  SEND_INVITATION,
  TERMS_OF_SERVICE,
  PRIVACY_POLICY,
  ARCHIVE,
  MESSAGE,
  UPLOAD,
  SWAP,
  RESTORE,
  FORWARD,
  DOWNLOAD,
  VIDEO,
  REMOVE
}

interface Props {
  onClick: (() => void) | any;
  disabled?: boolean;
  text: string;
  icon: IconOption;
  className?: string;
  form?: string;
  type?: "submit" | "button"
}

const buttonSizeStyles = cva([], {
  variants: {
    size: {
      extra_small: ["h-5", "w-5"],
      small: ["h-6", "w-6"],
      medium: ["h-8", "w-8"],
    },
  },
  compoundVariants: [],
  defaultVariants: {
    size: "medium",
  },
});

const displayStyles = cva([], {
  variants: {
    display: { inline: ["inline"], flex: ["flex"] },
   
  },
  compoundVariants: [],
  defaultVariants: {
    display: "flex",
  },
});

const textStyles = cva([], {
  variants: {
    truncate: {
      true: "truncate",
      false: ""
    }
  },
  compoundVariants: [],
  defaultVariants: {
    truncate: true,
  },
});


export function ButtonWithIcon({
  onClick,
  disabled,
  text,
  icon,
  className,
  type = "button",
  ...variantProps
}: Props &
  VariantProps<typeof buttonSizeStyles> &
  VariantProps<typeof displayStyles>
  & VariantProps<typeof textStyles>) {
  const buttonStyle = buttonSizeStyles(variantProps);
  const displayStyle = displayStyles(variantProps);
  function getIcon() {
    switch (icon) {
      case IconOption.PLUS: {
        return <CirclePlusIcon />;
      }
      case IconOption.RESTORE: {
        return <RestoreSvg />;
      }
      case IconOption.UP_ARROW: {
        return <Arrow
          className={`bg-brand-orange fill-white rounded-full p-1`}
          size={30}
        />;
      }
      case IconOption.REMOVE: {
        return <RemoveIcon className="rounded-full overflow-clip w-5 h-5" />;
      }
      case IconOption.ARROW: {
        return <RightArrowButtonIcon />;
      }
      case IconOption.BACK_ARROW: {
        return <RightArrowButtonIcon className="rotate-180" />;
      }
      case IconOption.CHAT_ICON: {
        return <ChatIconSvg />;
      }
      case IconOption.DOWN_ARROW: {
        return <RightArrowButtonIcon className="rotate-90" />;
      }
      case IconOption.EDIT: {
        return <EditIconSvg className="w-full" />;
      }
      case IconOption.VIEW_ASSESSMENT: {
        return <ViewAssessmentIcon />;
      }
      case IconOption.INTAKE: {
        return <IntakeIconSvg />;
      }
      case IconOption.NOTES: {
        return <NotesIconSvg />;
      }
      case IconOption.LATER: {
        return <FinishLaterIcon />;
      }
      case IconOption.TASK: {
        return <TasksIconSvg />;
      }
      case IconOption.ACTIVITY: {
        return <ActivitiesIconSvg />;
      }
      case IconOption.EVENT: {
        return <EventsIconSvg />;
      }
      case IconOption.CANCEL: {
        return <CancelIconSvg />;
      }
      case IconOption.TRASH: {
        return <TrashIcon />;
      }
      case IconOption.CHECKMARK: {
        return <CheckmarkIconSvg className="w-full" />;
      }
      case IconOption.ARCHIVE: {
        return <ArchiveIcon />;
      }
      case IconOption.SEARCH: {
        return <SearchIcon />;
      }
      case IconOption.SEND_INVITATION: {
        return <SendInvitationIcon />;
      }
      case IconOption.SAVE_DRAFT: {
        return <SaveDraftIcon />;
      }
      case IconOption.MORE: {
        return <MoreIcon />;
      }
      case IconOption.PHONE: {
        return <PhoneIconSvg />;
      }
      case IconOption.MESSAGE: {
        return <MessageIconSvg />;
      }
      case IconOption.EMAIL: {
        return <EmailIconSvg />;
      }
      case IconOption.WEB: {
        return <WebIconSvg />;
      }
      case IconOption.UPLOAD: {
        return <UploadIconSvg />;
      }
      case IconOption.DOWNLOAD: {
        return <DownloadIconSvg />;
      }
      case IconOption.SWAP: {
        return <SwapIcon />;
      }
      case IconOption.VIDEO: {
        return <VideoIconSvg />;
      }
      case IconOption.PADLOCK: {
        return (
          <img
            src={PADLOCK}
            alt=""
          />
        );
      }
      case IconOption.LOGOUT: {
        return (
          <LogoutIconSvg />
        );
      }
      case IconOption.TERMS_OF_SERVICE: {
        return (
          <img
            src={TermsOfService}
            alt=""
          />
        );
      }
      case IconOption.PRIVACY_POLICY: {
        return (
          <img
            src={PrivacyPolicy}
            alt=""
          />
        );
      }
      case IconOption.FORWARD: {
        return <ForwardIconSvg />;
      }
      default: {
        return null;
      }
    }
  }

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`pointer-events-auto items-center gap-2 ${disabled ? "opacity-30" : "opacity-100"
        } ${displayStyle} ${className}`}>
      <div className={`flex items-center ${buttonStyle}`}>{getIcon()}</div>
      {text ? <p className={`${variantProps.truncate ? "truncate" : ""}`}>{text}</p> : null}
    </button>
  );
}

import { useRef } from "react";
import {
  useGuidanceResourceByUserInterventionId,
  type AmazonMetadata,
} from "../../../../../../../backend/resources/userGuidanceResource";
import { useGptStore } from "../../../../../../../state/gpt";
import AmazonCarousel from "../../../../../../AmazonCarousel/AmazonCarousel";
import { YouTubeVideo } from "../../../../../../YouTubeVideo";

interface Props {
  interventionId: string;
}

export function Resource({ interventionId }: Props) {
  const userAssessmentChatGptInterventionResourceId = useGptStore(
    (state) => state.userAssessmentChatGptInterventionResourceId
  );
  const ref = useRef<HTMLDivElement>(null);

  const { userGuidanceResource } =
    useGuidanceResourceByUserInterventionId(interventionId);

  if (
    !userGuidanceResource ||
    userGuidanceResource.guidance_resource?.type === "news"
  ) {
    return null;
  }

  const youtubeTitle = (
    userGuidanceResource.guidance_resource?.metadata as {
      youTubeMetadata: {
        id: string;
        title: string;
        description: string;
      };
    }
  ).youTubeMetadata?.title;

  return (
    <div
      className={`relative w-full h-full flex flex-col ${
        userAssessmentChatGptInterventionResourceId === userGuidanceResource?.id
          ? "border-4 border-[#443dc4]"
          : ""
      }`}>
      {userGuidanceResource?.guidance_resource?.type === "YouTube" ? (
        <div
          className="mt-4 self-center flex flex-col items-center gap-2"
          ref={ref}>
          <YouTubeVideo
            className="w-full h-[40vh] flex-grow flex"
            url={`https://www.youtube.com/embed/${
              (
                userGuidanceResource.guidance_resource?.metadata as {
                  youTubeMetadata: {
                    id: string;
                    title: string;
                    description: string;
                  };
                }
              )?.youTubeMetadata?.id
            }`}
          />
          <p className="text-sm text-center">
            {youtubeTitle.replace("&#39;", "").replace("&#39;", "")}
          </p>
        </div>
      ) : (
        <AmazonCarousel
          metadata={
            userGuidanceResource.guidance_resource?.metadata as AmazonMetadata
          }
        />
      )}
    </div>
  );
}

import { useState } from "react";
import { useLocation } from "react-router-dom";
import SupportIcon from "../../assets/nav/support-icon.png";
import { useActiveOrg, useOrgs } from "../../backend/resources/orgRole";
import { useUserAdlos } from "../../backend/resources/userAdlo";
import { SharedRoute, useAppNavigate } from "../../lib/routing";
import { ResponsiveModal } from "../../shared/ui/responsive-modal";
import { useUserStore } from "../../state/user";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
import Logo from "../Logo/logo";
import { HeaderNamePill } from "../PageMainHeader/PageMainHeaderWithNetwork";
import { MobileHeaderMenu } from "./MobileHeaderMenu/MobileHeaderMenu";
import { NotificationMenu } from "./NotificationMenu";
import { ProfileMenu } from "./ProfileMenu";
import OrganizationSwitcher from "./ProfileMenu/OrganizationSwitcher/OrganizationSwitcher";

export function Header() {
  const navigate = useAppNavigate();
  const location = useLocation();



  // query for all networks +user_adlo
  // return networks[0]
  function onLogoClick() {
    if (["/login", "/sign-up", "/"].includes(location.pathname)) {
      window.location.href = "https://www.craniometrix.com/";
    } else {
      navigate({
        path: SharedRoute.HOME
      })
    }
  }

  return (
    <div className="flex">
      <div className="w-2 h-full bg-brand-orange" />
      <div
        className={`relative px-4 py-2 flex items-center justify-between
       border-b border-faint-gray flex-grow`}>
        <div className="flex gap-2 items-center h-full">
          <Logo onClick={onLogoClick} />
        </div>
        <div className="flex items-center gap-5">
          <OrgNamePill />
          <SupportButton />
          <NotificationMenu />
          <ProfileMenu />
        </div>
      </div>
    </div>
  );
}

export function MobileHeader() {

  const navigate = useAppNavigate();
  const location = useLocation();
  const authUser = useUserStore((state) => state.user);

  function onLogoClick() {
    if (["/login", "/sign-up", "/"].includes(location.pathname)) {
      window.location.href = "https://www.craniometrix.com/";
    } else {
      navigate({
        path: SharedRoute.HOME
      })
    }
  }
  return (
    <div
      style={{ justifyContent: authUser ? "between" : "center" }}
      className="flex py-3 px-4 w-full items-center">
      <Logo onClick={onLogoClick} />

      {authUser ? (
        <div className="flex flex-grow justify-end gap-3 items-center">
          <OrgNamePill />
          <div className="w-6 h-6">
            <SupportButton />
          </div>
          <NotificationMenu />
          <div className="w-8 h-8">
            <MobileHeaderMenu />
          </div>
        </div>
      ) : null}
    </div>
  );
}


function OrgNamePill() {
  const { isSuperSuperUser, } = useOrgs();
  const { data: activeOrg } = useActiveOrg()
  const { data: userAdloData, isLoading: isLoadingAdloData } = useUserAdlos();
  const userAdloName = `${userAdloData?.[0]?.first_name} ${userAdloData?.[0]?.last_name}`;
  const [isOrganizationSwitcherOpen, setIsOrganizationSwitcherOpen] =
    useState(false);


  return isSuperSuperUser ?
    <>
      <OrganizationSwitcher
        isOpen={isOrganizationSwitcherOpen}
        close={() => setIsOrganizationSwitcherOpen(false)}
      />
      <button onClick={() => setIsOrganizationSwitcherOpen(true)}>
        <HeaderNamePill text={activeOrg?.is_family_organization ? userAdloName : activeOrg?.name} />
      </button>
    </>
    : null

}

function SupportButton() {
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false)

  return <>
    <button className="w-8 h-8" onClick={() => setIsSupportModalOpen(true)}>
      <img src={SupportIcon} alt="Support" />
    </button>
    <ResponsiveModal
      isOpen={isSupportModalOpen}
      onClose={() => setIsSupportModalOpen(false)}
      title="We’re here to help!"
      closeText="Close"
      footerButtons={<ButtonWithIcon icon={IconOption.CHECKMARK} onClick={() => setIsSupportModalOpen(false)} text="Close" />}>
      <p className="text-center w-full"> Please send any issues to us at <a className="text-brand-orange" href="mailto:support@craniometrix.com">support@craniometrix.com</a> and we’ll get back to you as soon as possible!</p>
    </ResponsiveModal>
  </>
}


import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import type { UserPrescriptionInsert } from "../../backend/resources/userPrescription/userPrescription";
import {
  useUpsertUserPrescription,
  useUserPrescriptionsQuery,
} from "../../backend/resources/userPrescription/userPrescription";
import type {
  ScheduledDoseInformation,
  UserPrescriptionScheduledDoseInsert,
  UserPrescriptionScheduledDoseUpdate,
} from "../../backend/resources/userPrescriptionScheduledDose/userPrescriptionScheduledDose";
import {
  TimeOfDay,
  useDeleteScheduledDoses,
  useScheduledDosesByIdQuery,
  useUpsertScheduledDoses,
} from "../../backend/resources/userPrescriptionScheduledDose/userPrescriptionScheduledDose";
import { useWindowSize } from "../../hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate, useNavigateBack } from "../../lib/routing";
import { useUserStore } from "../../state/user";
import { capitalizeFirstLetter } from "../../utils";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
import { Checkbox } from "../Checkbox";
import FooterButtons from "../FooterButtons/FooterButtons";
import { LoadingSpinner } from "../LoadingSpinner";
import ModalView from "../ModalView/ModalView";
import { PageMainHeader } from "../PageMainHeader";
import { RadioButtonsGroup } from "../RadioGroup";
import { TextArea } from "../TextArea";
import { PrescriptionDeleteButtonPopover } from "./PrescriptionDetailView";
import { getPluralDrugDoseDesc } from "./utils";

export function EditPrescriptionView() {
  const { isMobile } = useWindowSize();
  const navigateBack = useNavigateBack()

  if (isMobile) {
    return (
      <ModalView
        isOpen={true}
        onClose={() => {
          navigateBack()
        }}
        closeText="Back">
        <EditPrescriptionViewComponents />
      </ModalView>
    );
  } else {
    return <EditPrescriptionViewComponents />;
  }
}

function EditPrescriptionViewComponents() {
  const authUser = useUserStore((state) => state.user);
  const authUserId = authUser?.id;

  const { isMobile } = useWindowSize();

  const navigate = useAppNavigate();
  const navigateBack = useNavigateBack()

  const { dispensable_drug_id } = useParams();

  const { isLoadingUserPrescriptions, userPrescriptions } =
    useUserPrescriptionsQuery();

  const prescription = userPrescriptions?.find(
    (prescription) => prescription.dispensable_drug_id === dispensable_drug_id
  );

  const {
    isLoadingScheduledDosesForId,
    asNeededScheduledDoses,
    morningScheduledDoses,
    afternoonScheduledDoses,
    eveningScheduledDoses,
    bedtimeScheduledDoses,
  } = useScheduledDosesByIdQuery(authUserId, prescription?.id);
  // right now, only one per section so we grab the first value if the array exists
  const morningScheduledDose =
    morningScheduledDoses && morningScheduledDoses[0];
  const afternoonScheduledDose =
    afternoonScheduledDoses && afternoonScheduledDoses[0];
  const eveningScheduledDose =
    eveningScheduledDoses && eveningScheduledDoses[0];
  const bedtimeScheduledDose =
    bedtimeScheduledDoses && bedtimeScheduledDoses[0];

  const [fillQty, setFillQty] = useState<number>(0);

  const [dosesRemaining, setDosesRemaining] = useState<number>(0);

  const [scheduledDoses, setScheduledDoses] = useState<{
    morning: number | null;
    afternoon: number | null;
    evening: number | null;
    bedtime: number | null;
  }>({
    morning: null,
    afternoon: null,
    evening: null,
    bedtime: null,
  });

  const [asNeeded, setAsNeeded] = useState<boolean>(false);
  const [maxDosesPerDay, setMaxDosesPerDay] = useState<number>(0);
  const [takeWithFood, setTakeWithFood] = useState<boolean>(false);
  const [doctorsComments, setDoctorsComments] = useState<string>("");

  const [error, setError] = useState("");

  // mutations
  const upsertUserPrescription = useUpsertUserPrescription().mutateAsync;
  const upsertScheduledDoses = useUpsertScheduledDoses().mutateAsync;
  const deleteScheduledDoses = useDeleteScheduledDoses().mutateAsync;

  // use defaults from found prescription
  useEffect(() => {
    if (prescription) {
      setFillQty(prescription?.doses_fill_qty || 0);

      setDosesRemaining(prescription?.doses_remaining || 0);

      setScheduledDoses({
        morning:
          morningScheduledDose?.dose_count != null
            ? morningScheduledDose?.dose_count
            : null,
        afternoon:
          afternoonScheduledDose?.dose_count != null
            ? afternoonScheduledDose?.dose_count
            : null,
        evening:
          eveningScheduledDose?.dose_count != null
            ? eveningScheduledDose?.dose_count
            : null,
        bedtime:
          bedtimeScheduledDose?.dose_count != null
            ? bedtimeScheduledDose?.dose_count
            : null,
      });

      setAsNeeded(
        asNeededScheduledDoses != null && asNeededScheduledDoses.length > 0
      );

      setMaxDosesPerDay(
        (asNeededScheduledDoses &&
          asNeededScheduledDoses[0]?.max_doses_per_day) ||
        0
      );

      setTakeWithFood(prescription?.take_with_food || false);

      setDoctorsComments(prescription?.doctor_comments || "");
    }
  }, [prescription, isLoadingScheduledDosesForId]);

  const validateForm = () => {
    if (fillQty <= 0) {
      setError("enter a valid number of doses.");
      return false;
    }

    if (dosesRemaining < 0) {
      setError("enter a valid number of doses remaining.");
      return false;
    }

    // If all the above conditions pass, clear any previous error.
    setError("");
    return true;
  };

  const resetScheduledDoses = () => {
    setScheduledDoses({
      morning: null,
      afternoon: null,
      evening: null,
      bedtime: null,
    });
  };

  const resetAsNeededDosage = () => {
    setAsNeeded(false);
    setMaxDosesPerDay(0);
  };

  async function submitForm() {
    // Validate the form, and if validation fails, return early.
    if (!validateForm()) {
      return;
    }

    // If validation passes, attempt to submit the form.
    if (authUser?.id) {
      try {
        // 1 - create and upsert the user_prescription insert object
        const userPrescriptionInsert: UserPrescriptionInsert = {
          id: prescription?.id,
          user_id: authUser.id,
          drug_name_id: prescription?.drug_name_id,
          drug_name_desc: prescription?.drug_name_desc,
          dose_form_desc: prescription?.dose_form_desc,
          dispensable_drug_id: prescription?.dispensable_drug_id,
          routed_dose_form_drug_id: prescription?.routed_dose_form_drug_id,
          routed_dose_form_drug_desc: capitalizeFirstLetter(
            prescription?.routed_dose_form_drug_desc || ""
          ),
          med_strength: prescription?.med_strength,
          med_strength_unit: prescription?.med_strength_unit,
          take_with_food: takeWithFood,
          doctor_comments: doctorsComments,
          doses_remaining: dosesRemaining,
          doses_fill_qty: fillQty,
        };

        const { data: prescriptionInsertRes, error: prescriptionInsertError } =
          await upsertUserPrescription(userPrescriptionInsert);

        // 2 - handle deletes
        const scheduledDoseIdsForDeletion: string[] = [];
        if (!asNeeded && asNeededScheduledDoses) {
          for (const dose of asNeededScheduledDoses) {
            if (dose.user_prescription_scheduled_dose_id) {
              scheduledDoseIdsForDeletion.push(
                dose.user_prescription_scheduled_dose_id
              );
            }
          }
        }
        if (
          !scheduledDoses.morning &&
          morningScheduledDose &&
          morningScheduledDose.user_prescription_scheduled_dose_id
        ) {
          scheduledDoseIdsForDeletion.push(
            morningScheduledDose.user_prescription_scheduled_dose_id
          );
        }
        if (
          !scheduledDoses.afternoon &&
          afternoonScheduledDose &&
          afternoonScheduledDose.user_prescription_scheduled_dose_id
        ) {
          scheduledDoseIdsForDeletion.push(
            afternoonScheduledDose.user_prescription_scheduled_dose_id
          );
        }
        if (
          !scheduledDoses.evening &&
          eveningScheduledDose &&
          eveningScheduledDose.user_prescription_scheduled_dose_id
        ) {
          scheduledDoseIdsForDeletion.push(
            eveningScheduledDose.user_prescription_scheduled_dose_id
          );
        }
        if (
          !scheduledDoses.bedtime &&
          bedtimeScheduledDose &&
          bedtimeScheduledDose.user_prescription_scheduled_dose_id
        ) {
          scheduledDoseIdsForDeletion.push(
            bedtimeScheduledDose.user_prescription_scheduled_dose_id
          );
        }

        const { data: deletionData, error: deletionError } =
          await deleteScheduledDoses(scheduledDoseIdsForDeletion);

        // 3 - create and upsert the user_prescription_scheduled_dose insert objects
        const scheduledDoseInserts: UserPrescriptionScheduledDoseInsert[] = [];
        const scheduledDoseUpdates: UserPrescriptionScheduledDoseUpdate[] = [];

        const processScheduledDose = (
          dose: number | null,
          existingDoses: ScheduledDoseInformation[] | undefined,
          timeOfDayKeys: Array<keyof typeof TimeOfDay>,
          asNeeded?: boolean
        ) => {
          for (const timeOfDayKey of timeOfDayKeys) {
            const scheduledDoseInsert: UserPrescriptionScheduledDoseInsert = {
              user_id: authUser.id,
              user_prescription_id: prescriptionInsertRes[0]?.id,
              dose_count: dose,
              dose_time: TimeOfDay[timeOfDayKey],
              as_needed: asNeeded,
              max_doses_per_day: asNeeded ? dose : null,
            };

            const existingDose = existingDoses?.find((d) => {
              const timeOfDayTime = TimeOfDay[timeOfDayKey];
              if (d.dose_time === null) return false;
              if (timeOfDayTime === null) return false;

              const doseTime = new Date(d.dose_time);
              const targetTime = new Date(timeOfDayTime);

              return doseTime.getTime() === targetTime.getTime();
            });

            if (existingDose) {
              // If it's an update (existing dose object)
              scheduledDoseUpdates.push({
                id:
                  existingDose.user_prescription_scheduled_dose_id || undefined,
                ...scheduledDoseInsert,
              });
            } else {
              // If it's an insert (new dose object)
              scheduledDoseInserts.push(scheduledDoseInsert);
            }
          }
        };

        if (asNeeded) {
          processScheduledDose(
            maxDosesPerDay,
            asNeededScheduledDoses,
            ["morning", "afternoon", "evening", "bedtime"],
            true
          );
        } else {
          if (scheduledDoses.morning != null)
            processScheduledDose(
              scheduledDoses.morning,
              morningScheduledDoses,
              ["morning"]
            );
          if (scheduledDoses.afternoon != null)
            processScheduledDose(
              scheduledDoses.afternoon,
              afternoonScheduledDoses,
              ["afternoon"]
            );
          if (scheduledDoses.evening != null)
            processScheduledDose(
              scheduledDoses.evening,
              eveningScheduledDoses,
              ["evening"]
            );
          if (scheduledDoses.bedtime != null)
            processScheduledDose(
              scheduledDoses.bedtime,
              bedtimeScheduledDoses,
              ["bedtime"]
            );
        }

        const { data: updateData, error: updateError } =
          await upsertScheduledDoses(scheduledDoseUpdates);

        const { data: insertData, error: insertError } =
          await upsertScheduledDoses(scheduledDoseInserts);

        if (updateError) {
          setError("Error inserting ");
        } else {
          if (prescription?.dispensable_drug_id) {
            navigate({
              path: CarePilotRoute.DAY_PRESCRIPTION,
              params: {
                dispensable_drug_id: prescription.dispensable_drug_id
              }
            })
          }
          // TODO - stay here
        }
      } catch {
        // Handle submission error here. You can set an error message if needed.
        setError("Failed to submit the user prescription. Please try again.");
      }
    }
  }

  const handleDone = (event: React.FormEvent) => {
    event.preventDefault();
    submitForm();
  };

  return (
    <div className={`flex flex-col h-full gap-4 ${isMobile ? "px-6" : ""}`}>
      {!isMobile ? (
        <div className={`-ml-4 min-h-[40px] flex items-center justify-between`}>
          <ButtonWithIcon
            onClick={() => {
              navigateBack()
            }}
            disabled={false}
            text={"Back to prescription"}
            icon={IconOption.BACK_ARROW}
          />
          {/* delete button */}
          <PrescriptionDeleteButtonPopover drugId={prescription?.id} />
        </div>
      ) : null}
      <PageMainHeader
        text={`Editing prescription for ${capitalizeFirstLetter(
          prescription?.drug_name_desc || ""
        )}`}
      />
      {isLoadingUserPrescriptions ? (
        <LoadingSpinner className="w-20 h-20" />
      ) : (
        <div className="flex flex-col pb-20">
          <form className="flex flex-col gap-6 max-w-[80ch] pt-4">
            {/* per bottle */}
            <div className="grid grid-cols-[3fr,2fr]">
              {/* text */}
              <div>
                How many{" "}
                {getPluralDrugDoseDesc(prescription?.dose_form_desc || "doses")}{" "}
                are in a full bottle?
              </div>
              {/* input */}
              <TextField
                label={capitalizeFirstLetter(
                  getPluralDrugDoseDesc(prescription?.dose_form_desc || "doses")
                )}
                className="max-w-[15ch]"
                value={fillQty.toString()}
                type="number"
                onKeyDown={(e) => {
                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "-" ||
                    e.key === "+"
                  ) {
                    e.preventDefault();
                  }
                }}
                onChange={(event) => {
                  const value = Number(event.target.value);
                  if (value > 100) {
                    setFillQty(100);
                  } else if (value < 0) {
                    setFillQty(0);
                  } else {
                    setFillQty(value);
                  }
                }}
              />
            </div>
            {/* tablets remaining */}
            <div className="grid grid-cols-[3fr,2fr]">
              {/* text */}
              <div>
                How many{" "}
                {getPluralDrugDoseDesc(prescription?.dose_form_desc || "doses")}{" "}
                remaining?
              </div>
              {/* input */}
              <TextField
                label={capitalizeFirstLetter(
                  getPluralDrugDoseDesc(prescription?.dose_form_desc || "doses")
                )}
                className="max-w-[15ch]"
                value={dosesRemaining.toString()}
                type="number"
                onKeyDown={(e) => {
                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "-" ||
                    e.key === "+"
                  ) {
                    e.preventDefault();
                  }
                }}
                onChange={(event) => {
                  const value = Number(event.target.value);
                  if (value > 100) {
                    setDosesRemaining(100);
                  } else if (value < 0) {
                    setDosesRemaining(0);
                  } else {
                    setDosesRemaining(value);
                  }
                }}
              />
            </div>
            {/* use instructions */}
            <div className="flex flex-col">
              {/* when */}
              <div className="grid grid-cols-1 md:grid-cols-[3fr,2fr] gap-4 md:gap-0">
                {/* label */}
                <div>How should it be given? (check all that apply)</div>
                {/* input */}
                <div className="flex flex-col gap-6 mx-auto md:mx-0">
                  {/* morning */}
                  <div className="grid grid-cols-[15ch,auto] items-center">
                    <Checkbox
                      isChecked={scheduledDoses.morning != null}
                      disabled={false}
                      onCheck={(isChecked) => {
                        setScheduledDoses({
                          ...scheduledDoses,
                          morning: isChecked
                            ? scheduledDoses.morning != null &&
                              scheduledDoses.morning >= 0
                              ? scheduledDoses.morning
                              : 0
                            : null,
                        });
                        if (isChecked) resetAsNeededDosage();
                      }}
                      label="Morning"
                    />
                    <TextField
                      label={capitalizeFirstLetter(
                        getPluralDrugDoseDesc(
                          prescription?.dose_form_desc || "doses"
                        )
                      )}
                      className="max-w-[15ch] text-sm"
                      value={scheduledDoses.morning?.toString() || 0}
                      type="number"
                      onKeyDown={(e) => {
                        if (
                          e.key === "e" ||
                          e.key === "E" ||
                          e.key === "-" ||
                          e.key === "+"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        const value = Number(event.target.value);
                        if (value > 100) {
                          setScheduledDoses({
                            ...scheduledDoses,
                            morning: 100,
                          });
                        } else if (value < 0) {
                          setScheduledDoses({
                            ...scheduledDoses,
                            morning: 0,
                          });
                        } else {
                          setScheduledDoses({
                            ...scheduledDoses,
                            morning: value,
                          });
                        }
                      }}
                    />
                  </div>
                  {/* afternoon */}
                  <div className="grid grid-cols-[15ch,auto] items-center">
                    <Checkbox
                      isChecked={scheduledDoses.afternoon != null}
                      disabled={false}
                      onCheck={(isChecked) => {
                        setScheduledDoses({
                          ...scheduledDoses,
                          afternoon: isChecked
                            ? scheduledDoses.afternoon != null &&
                              scheduledDoses.afternoon >= 0
                              ? scheduledDoses.afternoon
                              : 0
                            : null,
                        });
                        if (isChecked) resetAsNeededDosage();
                      }}
                      label="Afternoon"
                    />
                    <TextField
                      label={capitalizeFirstLetter(
                        getPluralDrugDoseDesc(
                          prescription?.dose_form_desc || "doses"
                        )
                      )}
                      className="max-w-[15ch] text-sm"
                      value={scheduledDoses.afternoon?.toString() || 0}
                      type="number"
                      onKeyDown={(e) => {
                        if (
                          e.key === "e" ||
                          e.key === "E" ||
                          e.key === "-" ||
                          e.key === "+"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        const value = Number(event.target.value);
                        if (value > 100) {
                          setScheduledDoses({
                            ...scheduledDoses,
                            afternoon: 100,
                          });
                        } else if (value < 0) {
                          setScheduledDoses({
                            ...scheduledDoses,
                            afternoon: 0,
                          });
                        } else {
                          setScheduledDoses({
                            ...scheduledDoses,
                            afternoon: value,
                          });
                        }
                      }}
                    />
                  </div>
                  {/* evening */}
                  <div className="grid grid-cols-[15ch,auto] items-center">
                    <Checkbox
                      isChecked={scheduledDoses.evening != null}
                      disabled={false}
                      onCheck={(isChecked) => {
                        setScheduledDoses({
                          ...scheduledDoses,
                          evening: isChecked
                            ? scheduledDoses.evening != null &&
                              scheduledDoses.evening >= 0
                              ? scheduledDoses.evening
                              : 0
                            : null,
                        });
                        if (isChecked) resetAsNeededDosage();
                      }}
                      label="Evening"
                    />
                    <TextField
                      label={capitalizeFirstLetter(
                        getPluralDrugDoseDesc(
                          prescription?.dose_form_desc || "doses"
                        )
                      )}
                      className="max-w-[15ch] text-sm"
                      value={scheduledDoses.evening?.toString() || 0}
                      type="number"
                      onKeyDown={(e) => {
                        if (
                          e.key === "e" ||
                          e.key === "E" ||
                          e.key === "-" ||
                          e.key === "+"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        const value = Number(event.target.value);
                        if (value > 100) {
                          setScheduledDoses({
                            ...scheduledDoses,
                            evening: 100,
                          });
                        } else if (value < 0) {
                          setScheduledDoses({
                            ...scheduledDoses,
                            evening: 0,
                          });
                        } else {
                          setScheduledDoses({
                            ...scheduledDoses,
                            evening: value,
                          });
                        }
                      }}
                    />
                  </div>
                  {/* bedtime */}
                  <div className="grid grid-cols-[15ch,auto] items-center">
                    <Checkbox
                      isChecked={scheduledDoses.bedtime != null}
                      disabled={false}
                      onCheck={(isChecked) => {
                        setScheduledDoses({
                          ...scheduledDoses,
                          bedtime: isChecked
                            ? scheduledDoses.bedtime != null &&
                              scheduledDoses.bedtime >= 0
                              ? scheduledDoses.bedtime
                              : 0
                            : null,
                        });
                        if (isChecked) resetAsNeededDosage();
                      }}
                      label="Bedtime"
                    />
                    <TextField
                      label={capitalizeFirstLetter(
                        getPluralDrugDoseDesc(
                          prescription?.dose_form_desc || "doses"
                        )
                      )}
                      className="max-w-[15ch] text-sm"
                      value={scheduledDoses.bedtime?.toString() || 0}
                      type="number"
                      onKeyDown={(e) => {
                        if (
                          e.key === "e" ||
                          e.key === "E" ||
                          e.key === "-" ||
                          e.key === "+"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        const value = Number(event.target.value);
                        if (value > 100) {
                          setScheduledDoses({
                            ...scheduledDoses,
                            bedtime: 100,
                          });
                        } else if (value < 0) {
                          setScheduledDoses({
                            ...scheduledDoses,
                            bedtime: 0,
                          });
                        } else {
                          setScheduledDoses({
                            ...scheduledDoses,
                            bedtime: value,
                          });
                        }
                      }}
                    />
                  </div>
                  {/* as needed */}
                  <span className="mx-auto">-OR-</span>
                  {/* as needed checkbox */}
                  <div className="grid grid-cols-[15ch,auto] items-center pb-3">
                    <Checkbox
                      isChecked={asNeeded}
                      disabled={false}
                      onCheck={(isChecked) => {
                        setAsNeeded(isChecked);
                        if (isChecked) resetScheduledDoses();
                      }}
                      label="As Needed"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* max doses per day */}
            <div className="grid grid-cols-[3fr,2fr] items-center">
              <span>Max doses per day?</span>
              <TextField
                label={`Max ${capitalizeFirstLetter(
                  getPluralDrugDoseDesc(prescription?.dose_form_desc || "doses")
                )}`}
                className="max-w-[15ch] text-sm"
                value={maxDosesPerDay.toString()}
                type="number"
                onKeyDown={(e) => {
                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "-" ||
                    e.key === "+"
                  ) {
                    e.preventDefault();
                  }
                }}
                onChange={(event) => {
                  setMaxDosesPerDay(Number(event.target.value));
                }}
              />
            </div>
            {/* with food? */}
            <div className="grid grid-cols-[3fr,2fr]">
              {/* text */}
              <div>Take with food?</div>
              {/* input */}
              <RadioButtonsGroup
                id="take_with_food_checkbox"
                options={[
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" },
                ]}
                value={takeWithFood ? "yes" : "no"}
                onChange={(selectedOption) => {
                  setTakeWithFood(selectedOption === "yes");
                }}
              />
            </div>
            {/* doctors comments */}
            <div className="flex flex-col gap-3">
              {/* text */}
              <div>Doctor’s Comments</div>
              {/* input */}
              <TextArea
                className="min-h-[150px]"
                data-hj-allow
                value={doctorsComments}
                onChange={(event) => {
                  setDoctorsComments(event.target.value);
                }}
                placeholder="Type doctor's comments..."
              />
            </div>
          </form>
          {/* buttons */}
          {isMobile ? (
            <FooterButtons>
              <ButtonWithIcon
                text="Cancel"
                icon={IconOption.CANCEL}
                onClick={(event: any) => {
                  navigateBack()
                }}
              />
              <ButtonWithIcon
                text="Save"
                icon={IconOption.CHECKMARK}
                onClick={handleDone}
              />
            </FooterButtons>
          ) : (
            <div className="flex w-full justify-end gap-4 pt-14">
              <ButtonWithIcon
                text="Cancel"
                icon={IconOption.CANCEL}
                onClick={(event: any) => {
                  navigateBack()
                }}
              />
              <ButtonWithIcon
                text="Save"
                icon={IconOption.CHECKMARK}
                onClick={handleDone}
              />
            </div>
          )}
          {/* error message row — @TODO: per element error messaging using something like react-hook-form */}
          {error && (
            <div className="flex w-full justify-end gap-2 text-red-700">
              Error: {error}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

import { ReactNode } from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useNavigateBack } from "../../lib/routing";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
import { PageContainer } from "../PageContainer";
import { PageMainHeader } from "../PageMainHeader";
import ModalView from "./ModalView";


/**
 * This component renders a modal view on mobile devices and a regular page view on desktop devices.
 * 
 * @param {Object} props - The properties object.
 * @param {ReactNode} props.children - The content to be displayed inside the modal or page.
 * @param {Function} props.onClose - The function to call when the modal or page is closed.
 * @param {string} props.title - The title of the modal or page.
 */
export default function ModalInMobileView({ children, onClose, title }: { children: ReactNode, onClose: () => void, title: string }) {
  const { isMobile } = useWindowSize();
  const navigateBack = useNavigateBack();
  if (isMobile) {
    return (
      <ModalView
        isOpen={true}
        title={title}
        closeText="Back"
        onClose={() => {
          onClose();
          navigateBack()
        }}>
        <PageContainer>
          {children}
        </PageContainer>
      </ModalView>
    );
  } else {
    return (
      <PageContainer>
        <ButtonWithIcon
          onClick={() => {
            navigateBack();
            onClose();
          }}
          text="Back"
          size="small"
          className="w-full justify-center flex md:justify-start mb-4"
          icon={IconOption.BACK_ARROW}
        />
        <PageMainHeader text={title} />
        {children}
      </PageContainer>
    );
  }
}
import { SplideSlide } from "@splidejs/react-splide";
import type { CarePlanHighlight } from "../../../../backend/resources/carePlanHighlights/carePlanHighlights";
import { useCarePlanHighlights } from "../../../../backend/resources/carePlanHighlights/carePlanHighlights";
import {
  UserBookmarkType,
  useBookmarkQuery,
  useBookmarkQueryByBookmarkableItem,
} from "../../../../backend/resources/userBookmark";
import { CareCentralRoute, CarePilotRoute, useAppNavigate } from "../../../../lib/routing";
import { useAssessmentStore } from "../../../../state/assessment";
import { useGptStore } from "../../../../state/gpt";
import { useUserStore } from "../../../../state/user";
import BookmarkableCard from "../../../Card/BookmarkableCard";
import { Card } from "../../../Card/Card";
import Carousel from "../../../Carousel/Carousel";

export default function CarePlanHighlights() {

  //Store

  // Queries
  const { data: carePlanHightlights } = useCarePlanHighlights();

  if (!carePlanHightlights) {
    return null;
  }

  // Render recommendations
  return (
    <div className="flex flex-col gap-5">
      <h1 className="text-[22px] font-light">Care Plan Highlights</h1>
      <div>
        <Carousel
          items={carePlanHightlights.map((pick: CarePlanHighlight) => (
            <SplideSlide>
              <CarePlanHighlightCard pick={pick} />
            </SplideSlide>
          ))}
        />
      </div>
    </div>
  );
}

function CarePlanHighlightCard({ pick }: { pick: CarePlanHighlight }) {
  const navigate = useAppNavigate();
  const setActiveUserAssessmentId = useAssessmentStore((state) => state.setActiveUserAssessmentId);

  // GPT Stores:
  const authUser = useUserStore((state) => state.user);
  const setUserAssessmentChatGptRecommendationId = useGptStore(
    (state) => state.setUserAssessmentChatGptRecommendationId
  );
  const setUserAssessmentChatGptInterventionId = useGptStore(
    (state) => state.setUserAssessmentChatGptInterventionId
  );

  const isCanonicalPick = (pick: CarePlanHighlight | undefined) => {
    return !(
      pick?.user_assessment_id &&
      pick?.recommendation_id &&
      pick?.intervention_id
    );
  };

  function handleOnClick(pick: CarePlanHighlight) {
    if (!isCanonicalPick(pick)) {
      setActiveUserAssessmentId(pick.user_assessment_id ?? undefined);
      setUserAssessmentChatGptRecommendationId(pick.recommendation_id);
      setUserAssessmentChatGptInterventionId(pick.intervention_id);
      setSidebarType("recommendationConversationPage");
      navigate({
        path: CarePilotRoute.RECOMMENDATION,
        params: { id: `${pick.recommendation_id}` },
      })
    }
    else {
      navigate({
        path: CareCentralRoute.SHAREABLE_RECOMMENDATION,
        params: { id: `${pick.recommendation_id}` },
      })
    }
  }

  const setSidebarType = useGptStore((state) => state.setType);

  const { refetchUserBookmark } = useBookmarkQueryByBookmarkableItem({
    itemId: pick.intervention_id || "",
    type: UserBookmarkType.INTERVENTION,
  });

  const { refetchBookmarks } = useBookmarkQuery();

  if (isCanonicalPick(pick)) {
    return (
      <Card color={"aqua"}>
        <button
          className="flex flex-col justify-around h-full w-full text-left"
          key={pick.intervention_id}
          onClick={() => handleOnClick(pick)}>
          <span className="text-lg line-clamp-2">{pick.title}</span>
          <span className="line-clamp-1 text-sm">
            {pick.intervention_title}
          </span>
        </button>
      </Card>
    );
  } else {
    return (
      <BookmarkableCard
        bookmarkableItem={{
          itemId: pick.intervention_id || "",
          type: UserBookmarkType.INTERVENTION,
        }}
        refetchBookmarks={() => {
          refetchUserBookmark();
          refetchBookmarks();
        }}>
        <button
          className="flex flex-col justify-center h-full w-full text-left px-6 py-4 md:py-3"
          key={pick.intervention_id}
          onClick={() => handleOnClick(pick)}>
          <span className="text-lg line-clamp-2">{pick.title}</span>
          <span className="line-clamp-1 text-sm">
            {pick.intervention_title}
          </span>
        </button>
      </BookmarkableCard>
    );
  }
}

import type { ChangeEvent, FormEvent } from "react";
import { useEffect, useState } from "react";
import { supabase } from "../../../clients/supabaseClient";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useLibraryFoldersStore } from "../../../state/libraryFolders";
import { useUserStore } from "../../../state/user";
import { ButtonWithIcon, IconOption } from "../../ButtonWithIcon";
import FooterButtons from "../../FooterButtons/FooterButtons";
import { Popup } from "../../Popup";
import { BottomSheet } from "../../Sheet";
import { TextArea } from "../../TextArea";
import { TextInput } from "../../TextInput";

interface Props {
  close: () => void;
  refetchLibraryFolders: () => void;
}

export function EditFolderPopup({ close, refetchLibraryFolders }: Props) {
  const { isMobile } = useWindowSize();
  if (isMobile) {
    return (
      <BottomSheet
        isOpen={true}
        onClose={close}>
        <EditFolderComponent
          close={close}
          refetchLibraryFolders={refetchLibraryFolders}
        />
      </BottomSheet>
    );
  } else {
    return (
      <Popup
        containerClassName="w-[400px]"
        closeOnClickOutside={false}
        close={close}>
        <EditFolderComponent
          close={close}
          refetchLibraryFolders={refetchLibraryFolders}
        />
      </Popup>
    );
  }
}

export function EditFolderComponent({ close, refetchLibraryFolders }: Props) {
  const activeFolder = useLibraryFoldersStore((state) => state.activeFolder);
  const setActiveFolder = useLibraryFoldersStore(
    (state) => state.setActiveFolder
  );
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const authUser = useUserStore((state) => state.user);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (activeFolder) {
      setName(activeFolder.name);
      setDescription(activeFolder.description || "");
    }
  }, [activeFolder]);

  function onChangeName(e: ChangeEvent<HTMLInputElement>) {
    setError("");
    setName(e.currentTarget.value);
  }

  function onChangeDescription(e: ChangeEvent<HTMLTextAreaElement>) {
    setError("");
    setDescription(e.currentTarget.value);
  }

  async function editFolder(
    e: FormEvent<HTMLFormElement> | FormEvent<HTMLButtonElement>
  ) {
    e.preventDefault();
    if (authUser?.id && activeFolder) {
      const { data, error } = await supabase
        .from("library_folder")
        .update({
          name,
          description,
        })
        .eq("id", activeFolder.id)
        .select();

      if (data && data.length > 0) {
        setActiveFolder(data[0]);
        refetchLibraryFolders();
        close();
      } else if (error?.message.includes("unique")) {
        setError(`You already have a folder named "${name}"`);
      } else {
        setError("Something went wrong.");
      }
    }
  }

  return (
    <form
      className="flex flex-col gap-3 px-8 md:px-0 pb-20 md:pb-0"
      onSubmit={editFolder}>
      <h1 className="text-xl">Edit Folder</h1>
      <div className="flex flex-col gap-1">
        <p>Name</p>
        <TextInput
          data-hj-allow
          name={name}
          onChange={onChangeName}
          value={name}
        />
      </div>
      <div className="flex flex-col gap-1">
        <p>Description</p>
        <TextArea
          data-hj-allow
          name={description}
          onChange={onChangeDescription}
          value={description}
        />
      </div>
      <FooterButtons>
        <ButtonWithIcon
          text="Cancel"
          onClick={close}
          icon={IconOption.CANCEL}
        />
        <ButtonWithIcon
          text="Done"
          onClick={editFolder}
          icon={IconOption.CHECKMARK}
        />
      </FooterButtons>
      {error && (
        <p className="self-center text-center text-red-500 text-sm">{error}</p>
      )}
    </form>
  );
}

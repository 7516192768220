import { useEffect } from "react";
import {
  useLocation,
  useParams,
  useSearchParams
} from "react-router-dom";
import { ConversationType, useMutateChatGptConversations } from "../../../../backend/resources/chatGptConversation";
import {
  useRecommendationStatusForShareableRecommendation,
  useToggleMarkedAsDone,
  useUpdateRating,
} from "../../../../backend/resources/goal/goalUserRecommendationStatus";
import { useInterventionsForRecommendation } from "../../../../backend/resources/userIntervention";
import { useAddConversationIdToRecommendation, useRecommendation } from "../../../../backend/resources/userRecommendation";
import { useUserIsFamilyMember } from "../../../../backend/resources/userRole";
import { useHasPlanBinderControl } from "../../../../hooks/product/product";
import { CarePilotRoute, useAppNavigate } from "../../../../lib/routing";
import { useGptStore } from "../../../../state/gpt";
import { useActiveNetworkId } from "../../../../state/network/network";
import { userUpdatesStore } from "../../../../state/userUpdate/userUpdate";
import BackButton from "../../../BackButton/BackButton";
import { ButtonWithIcon, IconOption } from "../../../ButtonWithIcon";
import { Checkbox } from "../../../Checkbox";
import { GoalRecSuggestionsBreadcrumbs } from "../../../GoalPage/GoalRecSuggestionsBreadcrumbs";
import { GoalRatings } from "../../../GoalPage/Rating";
import { PageContainer } from "../../../PageContainer";
import { PageMainHeader } from "../../../PageMainHeader";
import InterventionCard from "../Recommendation/Interventions/Intervention/InterventionCard";

export default function RecommendationPage() {
  const { id: recommendationId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const goalId = searchParams.get("goalId");
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const networkId = useActiveNetworkId();

  const onlyShowInterventionsInUserUpdate =
    queryParams.get("only-show-interventions-in-user-update") === "true";
  const { recommendation } = useRecommendation(recommendationId);
  const navigate = useAppNavigate();

  const setUserAssessmentChatGptRecommendationId = useGptStore(
    (state) => state.setUserAssessmentChatGptRecommendationId
  );

  const interventionIdsInUserUpdate = userUpdatesStore(
    (state) => state.interventionIdsInUserUpdate
  );
  const { userIsFamilyMember } = useUserIsFamilyMember();

  const { data: recommendationStatus } =
    useRecommendationStatusForShareableRecommendation(recommendation?.recommendation.id);
  const { data } = useInterventionsForRecommendation(recommendationId);
  const createConversation = useMutateChatGptConversations().mutateAsync;
  const { data: hasCarePlanControl } = useHasPlanBinderControl();

  const updateRating = useUpdateRating(recommendation?.recommendation.id).mutateAsync;
  const toggleMarkedAsDone = useToggleMarkedAsDone(recommendation?.recommendation.id).mutateAsync;
  const addConversationIdToRecommendation = useAddConversationIdToRecommendation().mutateAsync;
  const setType = useGptStore((state) => state.setType);
  const setConversationId = useGptStore((state) => state.setConversationId);
  useEffect(() => {
    async function setupConversation() {
      setType("recommendationConversationPage");
      setUserAssessmentChatGptRecommendationId(recommendation?.id);
      setConversationId(recommendation?.conversation_id || "");
      if (recommendation && !recommendation.conversation_id) {
        const newConversation = await createConversation({ type: ConversationType.Alfred, networkId });
        setConversationId(newConversation?.id || "");
        addConversationIdToRecommendation({ userRecommendationId: recommendation.id, conversationId: newConversation?.id })
      }
    }
    setupConversation()
  }, [recommendation])

  if (!recommendation) return null;
  return (
    <PageContainer>
      <div className="flex gap-4 flex-col">
        <BackButton />

        <div className="flex flex-col gap-1">
          {/* breadcrumbs */}
          <div className="flex justify-between">
            <GoalRecSuggestionsBreadcrumbs />
            {/* Edit Button */}
            {hasCarePlanControl &&
              <ButtonWithIcon
                onClick={() => {
                  if (recommendationId) {
                    navigate({
                      path: CarePilotRoute.RECOMMENDATION_EDIT,
                      params: {
                        id: recommendationId
                      }
                    })
                  }
                }}
                text={"Edit"}
                size={"small"}
                icon={IconOption.EDIT}
              />}
          </div>
          <PageMainHeader text={recommendation.title} />
        </div>

        {!userIsFamilyMember ? (
          <div className="w-full flex justify-between">
            <label className="flex gap-2">
              Mark as Done{" "}
              <Checkbox
                isChecked={recommendationStatus?.is_marked_done ?? false}
                onCheck={() =>
                  toggleMarkedAsDone()
                }
              />
            </label>
            <div className="flex items-center gap-1">
              <label>Rating: </label>
              <GoalRatings
                setRating={(newRating: number) =>
                  updateRating({
                    newRating,
                  })
                }
                currentRating={recommendationStatus?.rating ?? null}
                size="large"
              />
            </div>
          </div>
        ) : null}
        <h1>
          {recommendation.details
            .replace('"', "")
            .replace('"', "")}
        </h1>
        <div className="flex flex-wrap gap-4 justify-center md:justify-start pb-40">
          <div className="flex w-full">Suggestions ({data?.length || 0})</div>
          {data
            ?.sort((a, b) => {
              return a.title.localeCompare(b.title);
            })
            .filter(
              (intervention) =>
                !onlyShowInterventionsInUserUpdate ||
                interventionIdsInUserUpdate.includes(intervention.id)
            )
            ?.map((intervention) => (
              <InterventionCard intervention={intervention as any} />
            ))}
        </div>
      </div>
    </PageContainer>
  );
}

import { useState } from "react";
import type { BookmarkableItem } from "../../../../../backend/resources/userBookmark";
import {
  useBookmarkQueryByBookmarkableItem,
  useQueryTagsAndFoldersBookmark,
} from "../../../../../backend/resources/userBookmark";
import { ShowAllTagsPopup } from "../Popups/ShowAllTagsPopup";

export default function Tags({
  bookmarkableItem,
  limit,
}: {
  bookmarkableItem: BookmarkableItem;
  limit: number;
}) {
  const [showAllTagsPopup, setShowAllTagsPopup] = useState(false);

  const { userBookmark } = useBookmarkQueryByBookmarkableItem(bookmarkableItem);
  const { tags } = useQueryTagsAndFoldersBookmark(userBookmark?.id);

  return !userBookmark ? null : tags && tags.length > 0 ? (
    <div className="flex gap-2 px-2 items-center">
      {showAllTagsPopup && (
        <ShowAllTagsPopup
          close={() => setShowAllTagsPopup(false)}
          tags={tags}
        />
      )}
      {tags.slice(0, limit).map(({ tag }) => (
        <p
          key={tag.id}
          className="rounded-full text-xs  font-semibold px-4 py-[2px] border-2 border-brand-orange">
          {tag.name.length > 8 ? `${tag.name.slice(0, 6)}...` : tag.name}
        </p>
      ))}
      {tags.length > limit ? (
        <button
          className="rounded-full text-xs font-semibold px-2 py-[2px] border-2 border-brand-orange"
          onClick={(e) => {
            e.stopPropagation();
            setShowAllTagsPopup(true);
          }}>
          +{tags.length - limit}
        </button>
      ) : null}
    </div>
  ) : null;
}

import { useMutation, useQuery } from "@tanstack/react-query";
import { endOfYear, startOfYear } from 'date-fns';
import { Database } from '../../../../types/supabase';
import { queryClient } from "../../../App";
import { supabase } from "../../../clients/supabaseClient";
import { RespiteServiceActivityLocation } from "../../../shared/forms/AddRespiteServiceActivityForm";
import { QUERY_KEYS } from "../../queryKeys";

const TABLE = "respite_service_activity"
type RespiteServiceActivityType = Database["public"]["Tables"]["respite_service_activity"]["Row"];
type InsertRespiteServiceActivityType = Database["public"]["Tables"]["respite_service_activity"]["Insert"];
export function useRespiteServiceActivityForNetwork({ networkId }: { networkId: string }) {
  const now = new Date();
  const firstSecondOfYear = startOfYear(now).toISOString();
  const lastSecondOfYear = endOfYear(now).toISOString();

  const result = useQuery({
    queryKey: [QUERY_KEYS.respiteServiceActivity, { networkId, firstSecondOfYear, lastSecondOfYear }],
    queryFn: async () => {
      if (!networkId) return null
      const { data } = await supabase
        .from(TABLE)
        .select("*")
        .eq("network_id", networkId)
        .gte("date_of_service", firstSecondOfYear)
        .lte("date_of_service", lastSecondOfYear)
      return data as RespiteServiceActivityType[];
    },
  });
  const totalCost = result.data?.reduce((acc, activity) => acc + activity.cost, 0) ?? 0
  const totalHours = result.data?.reduce((acc, activity) => acc + activity.hours_of_service, 0) ?? 0
  const annualCap = 2500
  const remaining = annualCap - totalCost
  return { ...result, totalCost, totalHours, annualCap, remaining }
}



export function useInsertRespiteServiceActivity() {

  const LOCATION_TO_SERVICE_UNITS_AND_RATE: Record<RespiteServiceActivityLocation, { serviceUnits: number, rate: number }> = {
    [RespiteServiceActivityLocation["In Home"]]: { serviceUnits: 4, rate: 120 },
    [RespiteServiceActivityLocation["Adult Day Center"]]: { serviceUnits: 8, rate: 78 },
    [RespiteServiceActivityLocation["Facility Based"]]: { serviceUnits: 24, rate: 260 },
  }
  return useMutation({
    mutationFn: async (newActivity: Omit<InsertRespiteServiceActivityType, 'units_of_service' | 'cost'>) => {
      const units_of_service = newActivity.hours_of_service / LOCATION_TO_SERVICE_UNITS_AND_RATE[newActivity.location].serviceUnits
      const cost = LOCATION_TO_SERVICE_UNITS_AND_RATE[newActivity.location].rate * units_of_service
      const { data, error } = await supabase
        .from(TABLE)
        .insert({ ...newActivity, units_of_service, cost })
        .single();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.respiteServiceActivity] });
    },
  });
}


export function useDeleteRespiteServiceActivity() {
  return useMutation({
    mutationFn: async (id: string) => {
      const { data, error } = await supabase
        .from(TABLE)
        .delete()
        .eq('id', id)
        .single();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.respiteServiceActivity] });
    },
  });
}

import { TaskStatus, TaskStatusLabel, useAllPlanEntries, useBaseAllPlanEntries, useUpdatePlanEntry } from "../../backend/resources/planEntry";
import { CareCentralRoute, CarePilotRoute, useAppNavigate } from "../../lib/routing";
import { useTaskFilterStore } from "../../state/taskFilter/taskFilter";
import { Sorter } from "../../utils";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
import { Checkbox } from "../Checkbox";
import { Select } from "../Select";
import { TaskTable, TaskTableFields, TaskTableType } from '../Tables/TaskTable';
import { TaskDonutCharts } from "./components/TaskDonutChart";
import { FilterConfig, TaskFilterTypes, TaskFilters } from "./components/TaskFilters";
import { GuideCategories } from "./taskTableUtils";




export default function TaskNavigatorPage() {
  const navigate = useAppNavigate();

  const { setStatus, status, hideDone, setHideDone } = useTaskFilterStore();
  const filterConfig: FilterConfig = {
    [TaskFilterTypes.CARESPACE]: true,
    [TaskFilterTypes.CATEGORY]: true,
    [TaskFilterTypes.WHO]: true,
    [TaskFilterTypes.DUE_DATE]: true,
    [TaskFilterTypes.STATUS]: true,
    [TaskFilterTypes.HIDE_DONE]: true,
    [TaskFilterTypes.TASK_TYPE]: true,
    [TaskFilterTypes.GUIDE_TASK]: true,
  };
  const { isLoading: isLoadingTasks, data: tasks, fetchNextPage, isFetchingNextPage, hasNextPage } = useAllPlanEntries(filterConfig);
  const { data: baseEntries, isLoading: isLoadingDonutData } = useBaseAllPlanEntries(filterConfig);
  const updatePlanEntry = useUpdatePlanEntry().mutateAsync;

  function sortRows(a: TaskTableType, b: TaskTableType) {
    return new Sorter<TaskTableType>()
      .sortBy((a, b) => new Date(a.Due).getTime() - new Date(b.Due).getTime())
      .sortBy((a, b) => a.Carespace.localeCompare(b.Carespace))
      .sortBy((a, b) => {
        // prioritize onboarding category
        const aIsOnboarding = a[TaskTableFields.Category] === GuideCategories.Onboarding;
        const bIsOnboarding = b[TaskTableFields.Category] === GuideCategories.Onboarding;
        return aIsOnboarding === bIsOnboarding ? 0 : aIsOnboarding ? -1 : 1;
      })
      .sortBy((a, b) => a[TaskTableFields.Category].localeCompare(b[TaskTableFields.Category]))
      .sortBy((a, b) => a[TaskTableFields.GUIDEIdentifier].localeCompare(b[TaskTableFields.GUIDEIdentifier]))
      .sort(a, b);
  }


  return (
    <div className='flex flex-col gap-4 pb-20'>
      <div className="flex items-end w-full">
        <TaskDonutCharts data={baseEntries} title="Task Status"
          filterConfig={{
            [TaskFilterTypes.CARESPACE]: true,
            [TaskFilterTypes.CATEGORY]: true,
            [TaskFilterTypes.WHO]: true,
            [TaskFilterTypes.DUE_DATE]: true,
            [TaskFilterTypes.TASK_TYPE]: true,
            [TaskFilterTypes.GUIDE_TASK]: true
          }}
        />
        <TaskFilters
          filterConfig={{
            [TaskFilterTypes.CARESPACE]: true,
            [TaskFilterTypes.CATEGORY]: true,
            [TaskFilterTypes.WHO]: true,
            [TaskFilterTypes.DUE_DATE]: true,
            [TaskFilterTypes.TASK_TYPE]: true,
            [TaskFilterTypes.GUIDE_TASK]: true,
          }} />
      </div>

      <div className="flex gap-20 items-center">
        {/* Status  */}
        <div className="flex gap-5 items-center">
          <p>Status</p>
          <Select
            currentOption={status ? { value: status, label: status } : { value: "All", label: "All" }}
            options={[
              { value: "All", label: "All" },
              ...Object.values(TaskStatus).map((status) => ({ label: TaskStatusLabel[status], value: status })),
              { value: "Overdue", label: "Overdue" }
            ]}
            onChange={(status) => setStatus(status)}
          />
          {status === "All" &&
            <div className="flex gap-2 items-center">
              <Checkbox isChecked={hideDone} onCheck={(isChecked) => setHideDone(isChecked)} />
              <p className="text-sm">Hide Done</p>
            </div>
          }
          <ButtonWithIcon icon={IconOption.PLUS} onClick={() => navigate({ path: CarePilotRoute.PLAN_NEW })} text="Add General Task" />
        </div>

      </div>
      {
        (tasks?.length ?? 0) > 0 || isLoadingTasks ? (
          <TaskTable
            isLoading={isLoadingTasks}
            updateTask={updatePlanEntry}
            data={tasks}
            filterConfig={filterConfig}
            sortFunction={sortRows}
            maxHeight="40rem"
            paginationOptions={{
              fetchNextPage: async () => { await fetchNextPage() },
              isFetchingNextPage: isFetchingNextPage,
              hasNextPage: hasNextPage ?? false
            }}
            hiddenColumns={[TaskTableFields.CreatedAt, TaskTableFields.ServiceStatus]}
          />
        ) : (
          <p className="text-sm">
            Currently, there are no tasks available. To create the necessary tasks list, please add a carespace from the
            <a
              className="text-brand-orange cursor-pointer"
              onClick={() => navigate({ path: CareCentralRoute.CARESPACES })}>
              {" "}carespace page</a>.
          </p>
        )
      }
    </div>
  )
}



// ... other imports

import { useState } from "react";
import { useLatestMessages } from "../../backend/resources/chatGptMessage";
import { useOrgs } from "../../backend/resources/orgRole";
import { useWindowSize } from "../../hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate } from "../../lib/routing";
import ActionButtons from "../../shared/ui/action-buttons";
import { useDiscussionFilterStore } from "../../state/discussionsFilter/discussionsFilter";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
import { LoadingSpinner } from "../LoadingSpinner";
import DiscussionTable from "../Tables/DiscussionTable";
import CreateConversationModal from "./components/CreateConversationModal";
import { DiscussionFields, DiscussionFilterTypes, DiscussionFilters } from "./components/DiscussionsFilter";

export default function DiscussionsComponent() {
  const navigate = useAppNavigate();

  // Stores
  const { carespace: carespaceFilter } = useDiscussionFilterStore();
  const { isMobile } = useWindowSize();
  // States
  const [showStartCarespaceDiscussion, setShowStartCarespaceDiscussion] =
    useState(false);

  // Queries
  const { data: latestMessages, isLoading: isLoadingLatestMessages } = useLatestMessages();
  const { hasCareCentralAccess } = useOrgs();

  // Consts
  const unreadMessages = latestMessages?.filter(
    (message) => message?.read_message?.length === 0
  );
  const readMessages = latestMessages?.filter(
    (message) => message?.read_message && message.read_message.length > 0
  );

  // Determine which columns to hide based on the screen size and access rights
  const baseHiddenColumns = isMobile
    ? []
    : [DiscussionFields.SentBy];

  const hiddenColumns = hasCareCentralAccess
    ? baseHiddenColumns
    : [...baseHiddenColumns, DiscussionFields.Carespace];

  // *---------------**---------------**---------------**---------------**---------------**---------------*

  if (isLoadingLatestMessages) {
    return <div
      className="flex justify-center items-center h-screen">
      <LoadingSpinner className="w-32" />
    </div>
  }
  return (
    <>
      <CreateConversationModal isOpen={showStartCarespaceDiscussion} onClose={() => setShowStartCarespaceDiscussion(false)} />
      <div className="max-w-4xl pb-20">
        <div className="flex flex-col gap-2 items-center mt-5">
          <DiscussionFilters filterConfig={{
            [DiscussionFilterTypes.CARESPACE]: hasCareCentralAccess,
            [DiscussionFilterTypes.TYPE]: true,
          }} />
          <ActionButtons>
            {hasCareCentralAccess ? <ButtonWithIcon
              onClick={() => setShowStartCarespaceDiscussion(true)}
              text={"Create New Discussion"}
              size="small"
              className="self-start "
              icon={IconOption.PLUS}
            /> : <ButtonWithIcon
              onClick={() => navigate({ path: CarePilotRoute.GENERAL_DISCUSSION, queryParams: { network_id: carespaceFilter ?? null } })}
              text={"Go to General Discussion"}
              size="small"
              className="self-start"
              icon={IconOption.ARROW}
            />}
          </ActionButtons>

        </div>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-2">
            <h2 className="text-xl mt-4">Unread Messages</h2>
            <DiscussionTable
              data={unreadMessages ?? []}
              latestMessageWidth="300px"
              hiddenColumns={hiddenColumns} />
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="text-xl mt-4">Read Messages</h2>
            <DiscussionTable
              data={readMessages ?? []}
              latestMessageWidth="300px"
              hiddenColumns={hiddenColumns}
            />
          </div>
        </div>
      </div>
    </>
  );
}

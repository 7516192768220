import * as Sentry from "@sentry/react";
import type { UseQueryResult } from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";
import type { Database } from "../../../../types/supabase";
import { queryClient } from "../../../App";
import { supabase } from "../../../clients/supabaseClient";
import { QUERY_KEYS } from "../../queryKeys";

const TABLE = "intervention";

export type ShareableInterventionType =
  Database["public"]["Tables"]["intervention"]["Row"];

export type ShareableInterventionUpsertType =
  Database["public"]["Tables"]["intervention"]["Update"];

export type ShareableInterventionInsertType =
  Database["public"]["Tables"]["intervention"]["Insert"];

export function useShareableInterventionsFromShareableRecommendationId(
  recommendationId: string | undefined
): UseQueryResult<ShareableInterventionType[]> {
  return useQuery({
    queryKey: [QUERY_KEYS.shareableIntervention, { recommendationId }],
    queryFn: async () => {
      if (!recommendationId) return [];
      const { data, error } = await supabase
        .from(TABLE)
        .select("*")
        .eq("recommendation_id", recommendationId);

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }

      return data;
    },
  });
}

export function useShareableInterventionFromId(
  interventionId: string | undefined
): UseQueryResult<ShareableInterventionType> {
  return useQuery({
    queryKey: [QUERY_KEYS.shareableIntervention, { interventionId }],
    queryFn: async () => {
      if (!interventionId) return null;
      const { data, error } = await supabase
        .from(TABLE)
        .select("*")
        .eq("id", interventionId)
        .limit(1)
        .maybeSingle();

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }

      return data;
    },
  });
}

export function useSaveInterventionsToRecommendation(
  recommendationId: string | undefined
) {
  const { data: existingInterventions } =
    useShareableInterventionsFromShareableRecommendationId(recommendationId);
  return useMutation({
    mutationFn: async (newInterventions: ShareableInterventionUpsertType[]) => {
      const existingInterventionIds = new Set(
        existingInterventions?.map((int) => int.id)
      );
      const newInterventionIds = new Set(
        newInterventions.map((intervention) => intervention.id)
      );

      const toDelete = [...existingInterventionIds].filter(
        (interventionId) => !newInterventionIds.has(interventionId)
      );

      await deleteInterventions(toDelete);
      // we insert/update exisitng interventions.
      await upsertInterventions(
        newInterventions as ShareableInterventionInsertType[]
      );

      return { recommendationId };
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.shareableIntervention] });
    },
  }
  );
}
async function deleteInterventions(interventionIds: string[] | undefined) {
  if (!interventionIds) return;

  const { data, error } = await supabase
    .from(TABLE)
    .delete()
    .in("id", interventionIds)
    .select();
  if (error) {
    Sentry.captureException(error);
  }
}

async function upsertInterventions(
  interventions: ShareableInterventionInsertType[] | undefined
) {
  if (!interventions || interventions.length === 0) return;
  const validInterventions = interventions.filter(
    (intervention) =>
      intervention.details &&
      intervention.recommendation_id &&
      intervention.title
  );

  if (validInterventions.length === 0) return;

  const existingInterventions = validInterventions.filter(
    (intervention) => intervention.id
  );
  const newInterventions = validInterventions.filter(
    (intervention) => !intervention.id
  ).map(({ id, ...rest }) => rest);

  const updateExisting = supabase.from(TABLE).upsert(existingInterventions);

  const insertNew = supabase.from(TABLE).insert(newInterventions);

  const [updateResult, insertResult] = await Promise.all([
    updateExisting,
    insertNew,
  ]);
  const error = updateResult.error || insertResult.error;

  if (error) {
    Sentry.captureException(error);
  }
}

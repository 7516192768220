import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from "react";
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { YellowPagesListing, useSearchLocalResources } from "../../../backend/functions";
import { Form, FormField, FormItem, FormLabel, FormMessage, FormRoot } from '../../../shared/ui/form';
import { ButtonWithIcon, IconOption } from "../../ButtonWithIcon";
import { LoadingSpinner } from '../../LoadingSpinner';
import { Select } from "../../Select";
import { TextInput } from '../../TextInput';
import { LocalResourceCard } from './LocalResourceCard';
import { yellowPageCategoryOptions } from './categories';

const searchFormSchema = z.object({
  zipCode: z.string().refine(zipCode => zipCode.length === 5, {
    message: "Zip code must be exactly 5 characters long",
  }),
  searchText: z.string().optional(),
  selectedCategory: z.string().optional(),
}).refine(data => !!data.searchText || !!data.selectedCategory, {
  message: "Please provide either a search text or select a category",
  path: ["selectedCategory"]
});

type FormData = z.infer<typeof searchFormSchema>;

/**
 * LocalServicesSearch is a component that allows users to search for local services.
 * It takes categories, backgroundColor, selectedLocalResource, setSelectedLocalResource, hideCheckbox, and showReferralButton as props.
 */
export function LocalServicesSearch({
  backgroundColor,
  selectedLocalResources,
  onSelectLocalResource,
  hideCheckbox,
  showReferralButton,
  isCheckBoxSquare
}: {
  backgroundColor: string;
  selectedLocalResources: YellowPagesListing[] | undefined | null;
  onSelectLocalResource: (value: YellowPagesListing | null | undefined) => void;
  hideCheckbox?: boolean;
  showReferralButton?: boolean;
  isCheckBoxSquare?: boolean;
}) {
  // Forms
  const form = useForm<FormData>({
    resolver: zodResolver(searchFormSchema),
    mode: "onSubmit",
  });
  const formValues = form.watch();

  // states
  // `searchParams` is used to update the search query only on form submission, reducing API calls and enhancing performance.
  const [searchParams, setSearchParams] = useState<FormData | null>(null);

  // queries
  const { data: results, isLoading } = useSearchLocalResources(searchParams?.zipCode, searchParams?.searchText || searchParams?.selectedCategory || "");


  // handler
  const onSubmit = (data: FormData) => {
    setSearchParams(data);
  };

  // --------------*---------------*---------------*---------------*---------------*---------------
  return (
    <div className='flex flex-col gap-2  min-w-3xl w-3xl max-w-3xl '>
      <div className={`flex flex-col gap-4 p-4 text-sm overflow-scroll ${backgroundColor}`}>
        {/* Form */}
        <Form {...form}>
          <FormRoot id={"local services search"}>
            {/* Form Inputs */}
            <div className="flex gap-6 justify-between">
              {/* zip */}
              <FormField
                control={form.control}
                name="zipCode"
                render={({ field }) => (
                  <FormItem >
                    <div className="flex h-min">
                      {/* zip and error */}
                      <div className="flex flex-col">
                        <div className="flex items-center gap-2">
                          <FormLabel>Zip*</FormLabel>
                          <TextInput
                            data-hj-allow
                            {...field}
                            className="max-w-[10ch] placeholder:text-xs"
                          />
                        </div>
                      </div>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className='flex flex-col gap-5 w-full flex-grow'>
                {/* text and category */}
                <FormField
                  control={form.control}
                  name="searchText"
                  render={({ field }) => (
                    <FormItem >
                      <div className="flex flex-col w-full items-center gap-2">
                        {/* free text */}
                        <div className="flex justify-end gap-1 w-full">
                          <TextInput
                            {...field}
                            data-hj-allow
                            className="w-full placeholder:text-sm"
                            placeholder="Enter text..."
                          />
                        </div>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="selectedCategory"
                  render={({ field }) => {
                    const disabled = !!formValues.searchText
                    return (
                      <FormItem >
                        <div className="flex flex-col items-center gap-2 w-full">
                          <span className="text-xs">OR</span>
                          <Select
                            {...field}
                            placeHolder={disabled ? "Remove text to select a category" : "Please select..."}
                            disabled={disabled}
                            classNames={`p-0.5 pr-2 h-[2.5rem] w-full bg-white`}
                            borderClass="border-2 border-faint-gray"
                            currentOption={yellowPageCategoryOptions.find(
                              (option) => option.value === field.value
                            )}
                            options={yellowPageCategoryOptions}
                          />
                        </div>
                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />

              </div>
            </div>
            {/* Submit (search) button */}
            <ButtonWithIcon
              size={"small"}
              className='w-full justify-end'
              onClick={form.handleSubmit(onSubmit)}
              text={"Search"}
              type="button"
              icon={IconOption.ARROW}
            />
          </FormRoot >
        </Form >
      </div>
      {/* results */}
      <LocalServicesSearchResults searchParams={searchParams} isLoading={isLoading} results={results ?? []} hideCheckbox={hideCheckbox} showReferralButton={showReferralButton} selectedLocalResources={selectedLocalResources ?? []} onSelectLocalResource={onSelectLocalResource} />
    </div>
  );
}

interface Props {
  searchParams: any;
  isLoading: boolean;
  results: any[];
  hideCheckbox?: boolean;
  showReferralButton?: boolean;
  selectedLocalResources: any[];
  onSelectLocalResource: (value: any) => void;
  isCheckBoxSquare?: boolean;
}

function LocalServicesSearchResults({ searchParams, isLoading, results, hideCheckbox, showReferralButton, selectedLocalResources, onSelectLocalResource, isCheckBoxSquare }: Props) {
  return (
    searchParams && (
      <div className="flex flex-col gap-4 mt-2 max-h-[45vh] overflow-auto">
        {isLoading ? (
          <div className="flex flex-col justify-center items-center">
            <LoadingSpinner className="w-8 h-8" />
          </div>
        ) : (
          results?.map((result) => {
            return (
              <LocalResourceCard
                isCheckBoxSquare
                hideCheckbox={hideCheckbox}
                showServiceRequestButton={showReferralButton}
                listing={result}
                selectedLocalResources={selectedLocalResources}
                onSelectLocalResource={onSelectLocalResource}
              />
            );
          })
        )}
      </div>
    )
  );
};

// edit carespace

import { useState } from "react";
import { z } from "zod";
import {
  NetworksWithAdlosAndCaregivers,
  useActiveNetwork,
  useUpdateNetworkNameMutation
} from "../../backend/resources/network/network";
import { useSaveUserAdlo } from "../../backend/resources/userAdlo";
import {
  CarespaceInformationForm,
  DyadTier,
  carespaceInformationFormSchema,
  useCarespaceInformationForm,
} from "../../shared/forms/CarespaceCreationWizard/CarespaceInformationForm";
import { ResponsiveModalWizardProps } from "../../shared/forms/types";
import { ResponsiveModal } from "../../shared/ui/responsive-modal";
import { useUserStore } from "../../state/user";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
import { LoadingSpinner } from "../LoadingSpinner";

export function EditCarespaceView({ onClose }: ResponsiveModalWizardProps) {
  const PAGE_TITLE = "Edit Carespace";

  const authUser = useUserStore((state) => state.user);
  const { data: networkData, isLoading } = useActiveNetwork();
  const form = useCarespaceInformationForm(getDefaultValues(networkData));
  const formValues = form.watch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateNetworkName = useUpdateNetworkNameMutation().mutateAsync;
  const saveUserAdlo = useSaveUserAdlo().mutateAsync;

  function getDefaultValues(
    data: NetworksWithAdlosAndCaregivers | null | undefined
  ) {
    if (data && data.user_adlo[0]) {
      const { name: carespaceName } = data;
      
      const { first_name, last_name, birthday, sex, dyad, has_caregiver } = data.user_adlo[0];
      let patientBirthday = new Date();
      if (birthday) {
        const [year, month, day] = birthday.split('-').map(Number);
        patientBirthday = new Date(year, month - 1, day);
      }
      if (carespaceName && first_name && last_name && sex) {
        return {
          carespaceName: carespaceName,
          patientFirstName: first_name,
          patientLastName: last_name,
          patientBirthday: patientBirthday,
          patientLegalSex: sex,
          dyad: dyad as DyadTier,
          hasCaregiver: has_caregiver,
        } satisfies z.infer<typeof carespaceInformationFormSchema>;
      }
    }

    return undefined;
  }

  async function handleSubmit(
    validatedForm: z.infer<typeof carespaceInformationFormSchema>
  ) {
    const {
      carespaceName,
      patientFirstName,
      patientLastName,
      patientBirthday,
      patientLegalSex,
      hasCaregiver,
      dyad,
    } = validatedForm;

    setIsSubmitting(true);

    // 1 - update carespace name
    const { data, error } = await updateNetworkName({
      providedNetworkId: networkData?.id,
      name: carespaceName,
    });

    // TODO this should be handled by backend
    // 2 - create user adlo
    if (!error && authUser && networkData?.id && networkData?.user_adlo[0].id) {
      const data = await saveUserAdlo({
        submission: {
          id: networkData?.user_adlo[0].id, // TODO: multi-adlo
          user_id: authUser.id,
          first_name: patientFirstName,
          last_name: patientLastName,
          birthday: patientBirthday.toISOString(),
          sex: patientLegalSex,
          dyad: dyad,
          has_caregiver: hasCaregiver,
        },
        networkId: networkData?.id,
      });
    }
    setIsSubmitting(false);
  }

  return (
    <ResponsiveModal
      isOpen={true}
      title={PAGE_TITLE}
      closeText="Back"
      onClose={() => {
        onClose();
        form.reset();
      }}
      isNetworkCallInProgress={isSubmitting}
      footerButtons={
        <>
          <ButtonWithIcon
            text="Cancel"
            icon={IconOption.CANCEL}
            onClick={() => {
              onClose();
              form.reset();
            }}
          />
          <ButtonWithIcon
            text="Save"
            icon={IconOption.ARROW}
            onClick={() => {
              form.handleSubmit(async (values) => {
                await handleSubmit(values);
                onClose()
                form.reset();
              })();
            }}
            disabled={!Object.values(formValues).some((value) => value)}
          />
        </>
      }>
      {isLoading ? (
        <LoadingSpinner className="w-6 h-6" />
      ) : (
        <CarespaceInformationForm form={form} isEditing={true} />
      )}
    </ResponsiveModal>
  );
}

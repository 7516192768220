import { zodResolver } from "@hookform/resolvers/zod";
import { outlinedInputClasses } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { addMonths } from "date-fns";
import dayjs from "dayjs";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as z from 'zod';
import { YellowPagesListing } from "../../../backend/functions";
import { TaskStatus, useUpdatePlanEntry } from "../../../backend/resources/planEntry";
import { useUpsertServiceResourceAndPlanEntry } from "../../../backend/resources/services/serviceResource";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate } from '../../../lib/routing';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRoot } from "../../../shared/ui/form";
import { ResponsiveModal } from "../../../shared/ui/responsive-modal";
import { useActiveNetworkId } from "../../../state/network/network";
import { useActiveOrganizationId } from "../../../state/organization/organization";
import { useUserStore } from "../../../state/user";
import { ButtonWithIcon, IconOption } from "../../ButtonWithIcon";
import { TextArea } from "../../TextArea";
import { LocalSearchTabs } from "../ResourcesPage";

const serviceRequestSchema = z.object({
  description: z.string(),
  scheduled_date_time: z.string().refine(value => !isNaN(Date.parse(value)), {
    message: "Invalid date string",
  }),
})

type FormData = z.infer<typeof serviceRequestSchema>;
const CREATE_CP_SERVICE_REQUEST_FORM_ID = "CREATE_CP_SERVICE_REQUEST_FORM_ID"
export default function LocalResourceServiceRequestPopupButton({ listing, }: { listing: YellowPagesListing; }) {
  const navigate = useAppNavigate();
  const { isMobile } = useWindowSize()
  const authUser = useUserStore((state) => state.user);
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  // queries 
  const activeNetworkId = useActiveNetworkId()
  const activeOrgId = useActiveOrganizationId()

  // mutations
  const createNewServiceRequest = useUpdatePlanEntry().mutateAsync;
  const upsertServiceResource = useUpsertServiceResourceAndPlanEntry().mutateAsync;

  // form
  const form = useForm<FormData>({
    resolver: zodResolver(serviceRequestSchema),
    mode: "onSubmit",
    defaultValues: {
      scheduled_date_time: addMonths(new Date(), 1).toISOString(),
    }
  });


  // handler
  const onSubmit = async (data: FormData) => {
    const { ...serviceRequestData } = data;
    const newPlanEntry = await createNewServiceRequest({
      ...serviceRequestData,
      network_id: activeNetworkId,
      user_id: authUser?.id,
      status: TaskStatus.NotStarted,
      name: `Request: ${listing.name}`,
      is_service_ticket: true,
    });
    if (listing && newPlanEntry && activeOrgId) {
      await upsertServiceResource({
        serviceResourceUpdate: {
          ...listing,
          organization_id: activeOrgId,
        },
        plan_entry_id: newPlanEntry.id,
      });
    }
    navigate({ path: CarePilotRoute.LOCAL_SEARCH, queryParams: { tab: LocalSearchTabs.SERVICE_REQUESTS } });
  };
  return (<>
    {/* Button */}
    <div className="flex flex-grow justify-end text-white text-sm gap-2 items-center">
      <ButtonWithIcon
        text="Request Service"
        type="button"
        className="flex-row-reverse"
        icon={IconOption.FORWARD}
        onClick={() => setIsPopupOpen(true)}
      />
    </div>
    {/* Modal */}
    <ResponsiveModal
      isOpen={isPopupOpen}
      onClose={() => setIsPopupOpen(false)}
      title="Service Request"
      footerButtons={<>
        <ButtonWithIcon
          text="Cancel"
          type="button"
          icon={IconOption.CANCEL}
          onClick={() => setIsPopupOpen(false)}
        />
        <ButtonWithIcon
          text="Submit To Care Team"
          icon={IconOption.FORWARD}
          onClick={form.handleSubmit((data) => {
            const result = serviceRequestSchema.safeParse(data);
            if (result.success) {
              onSubmit(result.data);
            }
          })}
        />
      </>
      }>
      <Form {...form}>
        <FormRoot id={CREATE_CP_SERVICE_REQUEST_FORM_ID}>
          <div className="flex flex-col gap-4">
            <p className="text-sm">
              Please enter a few details about this request to help your Care Team
              better understand what you need. Once you submit it, this request will
              be visible in the Requests section.
            </p>
            {/* description */}
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem >
                  <FormControl>
                    <TextArea {...field} className='w-full' />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          {/* Date field */}
          <FormField
            control={form.control}
            name="scheduled_date_time"
            render={({ field }) => (
              <FormItem className='flex flex-col min-w-[200px]'>
                <FormLabel>Needed By*</FormLabel>
                <FormControl>
                  <DatePicker value={dayjs(field.value)} onChange={date => field.onChange(date?.toDate())}
                    disableOpenPicker={!isMobile}
                    slotProps={{
                      field: {
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                          }
                        },
                      },
                      textField: {
                        sx: {
                          fieldset: {
                            borderRadius: 2, borderColor: '#E4E4E7',
                          },
                          [`.${outlinedInputClasses.root}`]: {
                            height: 40,
                            width: 165,
                            fontSize: 14,
                            fontWeight: 400,
                            fontFamily: 'Poppins',
                          },
                        },
                      },
                    }} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </FormRoot>
      </Form>
    </ResponsiveModal>
  </>
  );
}

/**
 * @fileoverview Sets up the Zustand library organization store.
 * This store holds information about organization state.
 */
import { useEffect } from "react";
import { persist } from "zustand/middleware";
import { useOrgs } from "../../backend/resources/orgRole";
import { LocalStorageKeys } from "../localStorageKeys";
import { create } from "../zustand-create";

/**
 * Hook to access the organization store
 */

type State = {
  activeOrganizationId?: string | undefined;
  organizationIds?: string[];
};

type Actions = {
  setActiveOrganizationId: (activeOrganizationId?: string) => void;
  setOrganizationIds: (organizationIds?: string[]) => void;
  reset: () => void;
};

const initialState: State = {
  activeOrganizationId: undefined,
  organizationIds: [],
};

export const useOrganizationStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      setActiveOrganizationId: (activeOrganizationId?: string) =>
        set({ activeOrganizationId }),
      setOrganizationIds: (organizationIds?: string[]) =>
        set({ organizationIds }),
      reset: () => set({ ...initialState }),
    }),
    {
      name: LocalStorageKeys.organization, // unique name
      getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
  )
);

export function useActiveOrganizationId() {
  const { firstOrganizationId } = useOrgs();

  const { activeOrganizationId, setActiveOrganizationId } =
    useOrganizationStore();


  useEffect(() => {
    if (!activeOrganizationId && firstOrganizationId) {
      setActiveOrganizationId(firstOrganizationId);
    }
  }, [activeOrganizationId, firstOrganizationId]);

  return activeOrganizationId ?? firstOrganizationId ?? undefined;
}

export function getActiveOrganizationIdDirectly() {
  return useOrganizationStore.getState().activeOrganizationId;
}

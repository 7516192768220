import { ReactNode } from "react";
import { useGuideTasks } from "../../../backend/resources/guide/guideTask";
import { useNetworksInOrganization } from "../../../backend/resources/network/network";
import { useActiveOrgRole, useOrgs } from "../../../backend/resources/orgRole";
import { TaskWithGuideInfo } from "../../../backend/resources/planEntry";
import { TaskDueDateRange, useTaskFilterStore } from "../../../state/taskFilter/taskFilter";
import { Select } from "../../Select";
import { TaskTableRowType } from "../../Tables/TaskTable";
import { GuideCategories } from "../taskTableUtils";

export enum TaskFilterTypes {
  CARESPACE = "Carespace",
  CATEGORY = "Category",
  WHO = "Who",
  DUE_DATE = "Due Date",
  TASK_TYPE = "Type",
  HIDE_DONE = "HIDE_DONE",
  STATUS = "Status",
  GUIDE_TASK = "Task",
}

export interface FilterConfig {

  [TaskFilterTypes.CARESPACE]?: boolean;
  [TaskFilterTypes.CATEGORY]?: boolean;
  [TaskFilterTypes.WHO]?: boolean;
  [TaskFilterTypes.DUE_DATE]?: boolean;
  [TaskFilterTypes.TASK_TYPE]?: boolean;
  [TaskFilterTypes.STATUS]?: boolean;
  [TaskFilterTypes.HIDE_DONE]?: boolean;
  [TaskFilterTypes.GUIDE_TASK]?: boolean;
  overrideFilter?: (row: TaskWithGuideInfo) => boolean;
  customAdditionalFilter?: (row: TaskWithGuideInfo) => boolean;
}

interface TaskFiltersProps {
  filterConfig: FilterConfig;
}

export function TaskFilters({ filterConfig }: TaskFiltersProps) {
  const { carespace: network, category, who, setCarespace: setNetwork, setCategory, setWho, dueDate, setDueDate, taskType, setTaskType, guideTask, setGuideTask } = useTaskFilterStore();
  const { networks } = useNetworksInOrganization();
  const { data: guideTasks } = useGuideTasks();

  const { ownOrgIdentities } = useOrgs();
  const { data: activeOrgAndIdentity } = useActiveOrgRole();
  const activeOrgRoles = ownOrgIdentities?.filter(
    (organizationRole) =>
      organizationRole.organization_id === activeOrgAndIdentity?.organization_id
  );

  const whoOptions = [
    { value: "All", label: "All" },
    ...(activeOrgRoles?.map((orgRole) => ({
      label: `${orgRole.user.first_name} ${orgRole.user.last_name}`,
      value: orgRole.user.id,
    })) ?? [])
  ];


  return <div className="flex flex-col gap-y-2">
    <div className="grid grid-cols-2 w-[700px] gap-x-5 gap-y-2 ">
      {filterConfig[TaskFilterTypes.CARESPACE] &&
        <FilterComponent>
          <>
            <p className="w-[80px]">{TaskFilterTypes.CARESPACE}</p>
            <Select
              currentOption={network ? { value: network, label: network } : { value: undefined, label: "All" }}
              options={[{ value: "All", label: "All" }, ...(networks?.map((network) => ({ label: network.name || 'Default', value: network.id })) ?? [])]}
              onChange={(networkId) => setNetwork(networkId)}
              classNames="  w-full"
            />
          </>
        </FilterComponent>
      }
      {filterConfig[TaskFilterTypes.WHO] && (
        <FilterComponent>
          <>
            <p className="w-[80px]">{TaskFilterTypes.WHO}</p>
            <Select
              currentOption={who ? { label: who, value: who, } : { value: undefined, label: "All" }}
              options={whoOptions}
              onChange={(who) => setWho(who)}
              classNames="  w-full"
            />
          </>
        </FilterComponent>
      )}
      {filterConfig[TaskFilterTypes.TASK_TYPE] && (
        <FilterComponent>
          <>
            <p className="w-[80px]">{TaskFilterTypes.TASK_TYPE}</p>
            <Select
              currentOption={taskType ? { value: taskType, label: taskType } : { value: undefined, label: "All" }}
              options={[{ value: "All", label: "All" }, ...Object.values(TaskTableRowType).map((taskType) => ({ label: taskType, value: taskType }))]}
              onChange={(taskType) => setTaskType(taskType)}
              classNames="w-full"
            />
          </>
        </FilterComponent>
      )}
      {filterConfig[TaskFilterTypes.DUE_DATE] && (
        <FilterComponent>
          <>
            <p className="whitespace-nowrap line-clamp-1 w-[80px]">{TaskFilterTypes.DUE_DATE}</p>
            <Select
              currentOption={dueDate ? { value: dueDate, label: dueDate } : { value: TaskDueDateRange.All, label: TaskDueDateRange.All }}
              options={[...(Object.values(TaskDueDateRange)).map((date) => ({ label: date, value: date }))]}
              onChange={(date) => setDueDate(date)}
              classNames="  w-full  "
            />
          </>
        </FilterComponent>
      )}
    </div>
    {filterConfig[TaskFilterTypes.CATEGORY] &&
      <div className="flex items-center text-sm gap-5">
        <p>{TaskFilterTypes.CATEGORY}</p>
        <Select
          currentOption={category ? { value: category, label: category } : { value: undefined, label: "All" }}
          options={[{ value: "All", label: "All" }, ...Object.values(GuideCategories).map((guideCategory) => ({ label: guideCategory, value: guideCategory }))]}
          onChange={(category) => setCategory(category)}
          classNames="w-full"
          disabled={!(taskType === TaskTableRowType.GUIDE || !taskType || taskType === "All")}
        />
      </div>
    }

    {filterConfig[TaskFilterTypes.GUIDE_TASK] && (
      <div className="flex items-center text-sm gap-[54px]">
        <p>{TaskFilterTypes.GUIDE_TASK}</p>
        <Select
          currentOption={guideTask ? { value: guideTask, label: guideTask } : { value: "All", label: "All" }}
          options={[{ value: "All", label: "All" }, ...(guideTasks?.map((guideTask) => ({ label: guideTask.title, value: guideTask.id })) ?? [])]}
          onChange={(guideTaskId) => setGuideTask(guideTaskId)}
          classNames="w-full"
          disabled={!(taskType === TaskTableRowType.GUIDE || !taskType || taskType === "All")}
        />
      </div>
    )}
  </div >
}


function FilterComponent({ children, className }: { children: ReactNode, className?: string }) {
  return <div className={`whitespace-nowrap w-min-[340px] w-[340px] grid grid-cols-[.5fr,2fr] items-center  text-sm h-min gap-2 ${className}`}>
    {children}
  </div>
}


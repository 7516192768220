import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as z from 'zod';
import { YellowPagesListing } from '../../backend/functions';
import { useGuideTasks } from '../../backend/resources/guide/guideTask';
import { useNetworksInOrganization } from '../../backend/resources/network/network';
import { TaskStatus, TaskStatusLabel, usePlanEntryData, useUpdatePlanEntry } from '../../backend/resources/planEntry';
import { useServiceResources, useServiceResourcesFromPlanEntry, useUpsertServiceResourceAndPlanEntry } from '../../backend/resources/services/serviceResource';
import { SharedRoute, useAppNavigate, useNavigateBack } from '../../lib/routing';
import { YellowPagesListingSchema } from '../../shared/forms/types';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRoot } from '../../shared/ui/form';
import { Input } from '../../shared/ui/input';
import { useActiveOrganizationId } from '../../state/organization/organization';
import { useUserStore } from '../../state/user';
import { ButtonWithIcon, IconOption } from '../ButtonWithIcon';
import Who, { UserType } from '../MyPlanPage/components/Who';
import { PageContainer } from '../PageContainer';
import { PageMainHeader } from '../PageMainHeader';
import { LocalServicesSearch } from '../ResourcesPage/components/LocalServicesSearch';
import { Select } from '../Select';
import { DocumentSchema } from '../ServicePage/components/DocumentsSection';
import { TextArea } from '../TextArea';

import { outlinedInputClasses } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { useUpsertPlanEntryToServiceEngagement } from '../../backend/resources/services/serviceEngagementToPlanEntry';
import { ResponsiveModal } from '../../shared/ui/responsive-modal';
import BackButton from '../BackButton/BackButton';
import { Checkbox } from '../Checkbox';
import { LocalResourceCard } from '../ResourcesPage/components/LocalResourceCard';
import { GUIDE_IDENTIFIER_FOR_RESPITE_SERVICE } from '../ServicePage/ServicePage';

const CREATE_SERVICE_REQUEST_FORM_ID = "create-service-request-form";


export const ServiceRequestSchema = z.object({
  name: z.string(),
  description: z.string().optional().nullable(),
  network_id: z.string(),
  scheduled_date_time: z.string().refine(value => !isNaN(Date.parse(value)), {
    message: "Invalid date string",
  }),
  status: z.string(),
  user_id: z.string(),
  guide_task_id: z.string().optional().nullable(),
  service: YellowPagesListingSchema,
  documents: z.array(DocumentSchema).optional().nullable(),
});

type FormData = z.infer<typeof ServiceRequestSchema>;

type FormDataOptional = {
  [K in keyof FormData]?: FormData[K] | null;
};

const useCreateServiceRequestForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  return useForm({
    resolver: zodResolver(ServiceRequestSchema),
    mode: "onSubmit",
    defaultValues: {
      name: queryParams.get('name') ?? undefined,
      description: queryParams.get('description') ?? undefined,
      network_id: queryParams.get('network_id') ?? undefined,
      scheduled_date_time: queryParams.get('scheduled_date_time') ?? new Date(Date.now()).toISOString(),
      status: queryParams.get('status') ?? TaskStatus.NotStarted,
      user_id: queryParams.get('user_id') ?? undefined,
      guide_task_id: queryParams.get('guide_task_id') ?? undefined,
      service: queryParams.get('service') ?? undefined,
    } as FormDataOptional,
  });
};

export default function CreateOrEditServiceRequestPage() {
  const navigateBack = useNavigateBack()
  const navigate = useAppNavigate()
  const inputRef = useRef<HTMLInputElement>(null);
  const authUser = useUserStore((state) => state.user);
  const { id: service_request_id } = useParams()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const serviceEngagementId = queryParams.get('service_engagement_id');
  // Queries
  const { networks } = useNetworksInOrganization();
  const { data: guideTasks } = useGuideTasks();
  const { data: planEntry } = usePlanEntryData(service_request_id);
  const { data: serviceResource } = useServiceResourcesFromPlanEntry(service_request_id);
  const activeOrgId = useActiveOrganizationId();

  // Form
  const form = useCreateServiceRequestForm();
  const formValues = form.watch();

  const [originalResource, setOriginalResource] = useState<YellowPagesListing | null | undefined>(null);

  // Effects
  useEffect(() => {
    form.reset({
      ...planEntry,
      service: serviceResource ?? undefined,
    });
    setOriginalResource(serviceResource);
  }, [planEntry, serviceResource]);

  // Mutations
  const createNewServiceRequest = useUpdatePlanEntry().mutateAsync;
  const upsertServiceResource = useUpsertServiceResourceAndPlanEntry().mutateAsync;
  const upsertPlanEntryToServiceEngagement = useUpsertPlanEntryToServiceEngagement().mutateAsync;

  // Consts
  const carespaceOptions = networks?.map(network => ({
    value: network.id,
    label: network.name ?? "",
  })) ?? [];

  const statusOptions = Object.values(TaskStatus).map(status => ({
    value: status,
    label: TaskStatusLabel[status],
  }));

  const guideTaskOptions = guideTasks?.map(guideTask => ({
    value: guideTask.id,
    label: guideTask.title,
  })) ?? [];

  const respiteGuideTask = guideTasks?.find((task) => task.GUIDE_identifier === GUIDE_IDENTIFIER_FOR_RESPITE_SERVICE)

  // Functions
  const onSubmit = async (data: FormData) => {
    const { service, ...serviceRequestData } = data;
    const newPlanEntry = await createNewServiceRequest({
      id: service_request_id,
      ...serviceRequestData,
      scheduled_date_time: data.scheduled_date_time,
      is_service_ticket: true
    });
    if (service && newPlanEntry) {
      await upsertServiceResource({
        serviceResourceUpdate: {
          ...service,
          organization_id: activeOrgId,
        },
        plan_entry_id: newPlanEntry.id,
      });
    }

    if (serviceEngagementId) {
      const newServiceEngagement = await upsertPlanEntryToServiceEngagement({
        service_engagement_id: serviceEngagementId,
        plan_entry_id: newPlanEntry.id
      });
    }
    // if is editing
    if (service_request_id) {
      navigateBack();
    } else {
      navigate({ path: SharedRoute.SERVICE_REQUEST_VIEW, params: { id: newPlanEntry.id } }, { replace: true });
    }
  }

  return (
    <PageContainer >
      <div className='relative'>
        <div className='max-w-4xl flex-grow overflow-auto'>
          <BackButton className="mb-6 " />
          <PageMainHeader text={service_request_id ? "Edit Service Request" : "Create New Service Request"} />
          <Form {...form}>
            <FormRoot id={CREATE_SERVICE_REQUEST_FORM_ID}>
              {/* Title Field */}
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem >
                    <FormLabel>Title*</FormLabel>
                    <FormControl>
                      <Input {...field} value={field.value ?? undefined} disabled={queryParams.has(field.name)} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {/* Details Field */}
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem >
                    <FormLabel>Details</FormLabel>
                    <FormControl>
                      <TextArea {...field} value={field.value ?? undefined} className='w-full' disabled={queryParams.has(field.name)} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className='flex flex-wrap gap-y-4 gap-x-10 xl:w-[1000px]'>

                {/* Carespace Field */}
                <FormField
                  control={form.control}
                  name="network_id"
                  render={({ field }) => (
                    <FormItem className='flex flex-col min-w-[200px]'>
                      <FormLabel>Carespace*</FormLabel>
                      <FormControl>
                        <Select
                          {...field}
                          disabled={queryParams.has(field.name)}
                          placeHolder='Please Select'
                          onChange={(value) => { if (value) form.setValue("network_id", value) }}
                          options={carespaceOptions}
                          currentOption={carespaceOptions.find((option) => option.value === field.value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {/* Status field */}
                <FormField
                  control={form.control}
                  name="status"
                  render={({ field }) => (
                    <FormItem className='flex flex-col min-w-[200px]'>
                      <FormLabel>Status</FormLabel>
                      <FormControl>
                        <Select
                          {...field}
                          disabled={queryParams.has(field.name)}
                          onChange={(value) => { if (value) form.setValue("status", value) }}
                          options={statusOptions}
                          currentOption={statusOptions.find((option) => option.value === field.value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {/* Who field */}
                <FormField
                  control={form.control}
                  name="user_id"
                  render={({ field }) => (
                    <FormItem className='flex flex-col min-w-[200px]'>
                      <FormLabel>Assigned To*</FormLabel>
                      <FormControl>
                        <Who
                          userType={UserType.ORG_USERS}
                          isEditing={!queryParams.has(field.name)}
                          networkId={formValues.network_id}
                          entry={undefined}
                          selectedPlanEntryOwnerId={field.value ?? undefined}
                          setPlanEntryOwner={(planEntryOwner: string) => form.setValue("user_id", planEntryOwner)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {/* Date field */}

                <FormField
                  control={form.control}
                  name="scheduled_date_time"
                  render={({ field }) => (
                    <FormItem className='flex flex-col min-w-[200px]'>
                      <FormLabel>Date*</FormLabel>
                      <FormControl>
                        <DatePicker
                          onChange={(date) => { if (date) field.onChange(date.format()) }}
                          value={dayjs(field.value ?? dayjs())}
                          slotProps={{
                            field: {
                              onKeyDown: (e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                }
                              },
                            },
                            textField: {
                              sx: {
                                fieldset: {
                                  borderRadius: 2, borderColor: '#E4E4E7',
                                },
                                [`.${outlinedInputClasses.root}`]: {
                                  height: 40,
                                  width: 165,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  fontFamily: 'Poppins',
                                },
                              },
                            },
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {/* GUIDE Task ID Field */}
                <FormField
                  control={form.control}
                  name="guide_task_id"
                  render={({ field }) => (
                    <FormItem className='flex flex-col min-w-[400px]'>
                      <FormLabel>GUIDE Task</FormLabel>
                      <FormControl>
                        <Select
                          key={field.value}
                          classNames=' w-[440px]'
                          {...field}
                          disabled={queryParams.has(field.name)}
                          placeHolder='Please Select'
                          onChange={(value) => { if (value) form.setValue("guide_task_id", value) }}
                          options={guideTaskOptions}
                          currentOption={guideTaskOptions.find((option) => option.value === field.value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {/* Is Respite Field */}
                <FormField
                  control={form.control}
                  name="guide_task_id"
                  render={({ field }) => (
                    <FormItem className='flex flex-col min-w-[200px]'>
                      <FormLabel>Respite Request</FormLabel>
                      <FormControl>
                        <div className='h-full flex items-center'>
                          <Checkbox
                            isChecked={respiteGuideTask?.id === field.value}
                            onCheck={(isChecked) => {
                              if (isChecked) {
                                form.setValue("guide_task_id", respiteGuideTask?.id)
                              } else {
                                form.setValue("guide_task_id", null)
                              }
                            }}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              {/* Local Service Field */}
              <FormField
                control={form.control}
                name="service"
                render={({ field }) => (
                  <FormItem className='flex flex-col min-w-[200px] gap-2'>
                    <div className='flex gap-5'>
                      <FormLabel className='text-base'>Add Resource</FormLabel>
                      <FormMessage />
                      <OpenLocalResourceModalButton
                        setSelectedResource={(service) => {
                          if (service) {
                            form.setValue("service", service);
                            setOriginalResource(service);
                          }
                        }}
                      />
                    </div>

                    <FormControl>
                      <div className='flex flex-col gap-5'>
                        <LocalResourceCard
                          listing={originalResource}
                          selectedLocalResources={field.value ? [field.value as YellowPagesListing] : []}
                          onSelectLocalResource={(service) => { if (service) form.setValue("service", service) }}
                        />
                        <LocalServicesSearch
                          selectedLocalResources={field.value ? [field.value as YellowPagesListing] : []}
                          onSelectLocalResource={(service) => { if (service) { form.setValue("service", service) } }}
                          backgroundColor="bg-gray-100/80"
                        />
                      </div>
                    </FormControl>
                  </FormItem>
                )}
              />
            </FormRoot>
          </Form>
        </div>
        <div className='bg-white h-20 z-1 sticky -bottom-10 left-0 flex gap-5 justify-end max-w-4xl'>
          <ButtonWithIcon
            onClick={navigateBack}
            text={'Cancel'}
            icon={IconOption.CANCEL}
            size="small"
            type="button"
          />
          <ButtonWithIcon
            onClick={form.handleSubmit((data) => {
              const result = ServiceRequestSchema.safeParse(data);
              if (result.success) {
                onSubmit(result.data);
              }
            })}
            text={'Submit'}
            icon={IconOption.CHECKMARK}
            size="small"
          />
        </div>
      </div>
    </PageContainer>
  );
}

interface LocalResourceModalProps {
  setSelectedResource: (resource: YellowPagesListing | null) => void;
}

function OpenLocalResourceModalButton({ setSelectedResource }: LocalResourceModalProps) {
  const { data: localResources, isLoading } = useServiceResources();
  const [localSelectedResource, setLocalSelectedResource] = useState<YellowPagesListing | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ButtonWithIcon
        onClick={() => setIsOpen(true)}
        text={'Add from Inventory'}
        icon={IconOption.PLUS}
        size="small"
      />
      <ResponsiveModal isOpen={isOpen} onClose={() => setIsOpen(false)} title="Select a Local Resource">
        <div className="overflow-y-auto max-h-96 flex flex-col gap-5">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            localResources?.map(resource => (
              <div key={resource.id} className="flex items-center gap-2">
                <LocalResourceCard
                  listing={resource}
                  selectedLocalResources={localSelectedResource ? [localSelectedResource] : []}
                  onSelectLocalResource={(resource) => setLocalSelectedResource(resource)}
                />
              </div>
            ))
          )}
        </div>
        <div className="flex justify-end mt-4 gap-2">
          <ButtonWithIcon
            onClick={() => setIsOpen(false)}
            text={"Cancel"}
            size={"small"}
            icon={IconOption.CANCEL}
          />
          <ButtonWithIcon
            onClick={() => {
              setIsOpen(false);
              setSelectedResource(localSelectedResource)
            }}
            text={"Save"}
            size={"small"}
            icon={IconOption.CHECKMARK}
          />
        </div>
      </ResponsiveModal >
    </>
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { useServiceEngagementById, useUpdateServiceEngagement } from "../../../backend/resources/services/serviceEngagement";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRoot, FormSection, FormSectionHeader } from "../../../shared/ui/form";
import { Input } from "../../../shared/ui/input";
import { ResponsiveModal } from "../../../shared/ui/responsive-modal";
import { ButtonWithIcon, IconOption } from "../../ButtonWithIcon";
import { LoadingSpinner } from "../../LoadingSpinner";
import { TextArea } from "../../TextArea";
/**
 * schema
 */
const editServicePageFormSchema = z.object({
  name: z.string(),
  details: z.string().optional(),
});

/**
 * form hook
 */
const useEditServicePageForm = (defaultValues?: z.infer<typeof editServicePageFormSchema>) => useForm<z.infer<typeof editServicePageFormSchema>>({
  resolver: zodResolver(editServicePageFormSchema),
  defaultValues: defaultValues ?? {},
  mode: "onSubmit",
});

/**
 * form component
 */
const editServicePageFormId = "edit-service-page-form";

export function EditServicePageButton() {
  const [isEditServicePageModalOpen, setIsEditServicePageModalOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { id: serviceId } = useParams<{ id: string }>();

  const { data: serviceEngagementData, isLoading } = useServiceEngagementById(serviceId);
  const form = useEditServicePageForm({ name: serviceEngagementData?.name ?? "", details: serviceEngagementData?.details ?? "" });
  const updateServiceEngagement = useUpdateServiceEngagement().mutateAsync;


  useEffect(() => {
    form.reset({
      name: serviceEngagementData?.name ?? "",
      details: serviceEngagementData?.details ?? ""
    });
  }, [serviceEngagementData]);


  async function handleSubmit(validatedForm: z.infer<typeof editServicePageFormSchema>) {
    setIsSending(true);
    if (serviceId)
      await updateServiceEngagement({
        id: serviceId!!,
        ...validatedForm,
      })
    setIsSending(false)
  }

  if (isLoading) {
    return null
  }

  return (
    <>
      {/* Button */}
      <ButtonWithIcon
        onClick={() => setIsEditServicePageModalOpen(true)}
        size={"small"}
        text={""}
        icon={IconOption.EDIT}
      />
      {/* Modal */}
      <ResponsiveModal
        isOpen={isEditServicePageModalOpen}
        closeText="Back"
        title={"Edit Service Page"}
        isNetworkCallInProgress={isSending}
        onClose={() => {
          setIsEditServicePageModalOpen(false);
          form.reset();
        }}
        footerButtons={
          <>
            <ButtonWithIcon
              text="Cancel"
              icon={IconOption.CANCEL}
              onClick={() => {
                setIsEditServicePageModalOpen(false);
                form.reset();
              }}
            />
            <ButtonWithIcon
              text="Done"
              icon={IconOption.CHECKMARK}
              onClick={async () => {
                await form.handleSubmit((values) => {
                  handleSubmit(values);
                  setIsEditServicePageModalOpen(false);
                  form.reset();
                })();
              }}
            />
          </>
        }>
        {isLoading ? (
          <LoadingSpinner className="w-6 h-6" />
        ) : (
          <Form {...form}>
            <FormRoot id={editServicePageFormId}>
              <FormSection>
                <FormSectionHeader title="Edit Service Page" />
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="details"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Details</FormLabel>
                      <FormControl>
                        <TextArea className="w-full text-sm" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </FormSection>
            </FormRoot>
          </Form>
        )}
      </ResponsiveModal>

    </>
  )
}

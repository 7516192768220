import {
  UserBookmarkType,
  useBookmarkQuery,
  useBookmarkQueryByResourceId,
} from "../../../../backend/resources/userBookmark";
import type { NewsMetadata } from "../../../../backend/resources/userGuidanceResource";
import BookmarkableCard from "../../../Card/BookmarkableCard";
import NewsComponent from "./NewsComponent";

interface Props {
  guidance_resource_id: string;
  metadata: NewsMetadata;
  notClickable?: boolean;
}

export default function NewsArticle({
  metadata,
  guidance_resource_id,
  notClickable,
}: Props) {
  const url = metadata.image?.thumbnail.contentUrl
    ? `${metadata.image.thumbnail.contentUrl}&h=250&p=0`
    : undefined;
  if (!url) return null;

  const { refetch: refetchUserBookmark } =
    useBookmarkQueryByResourceId(guidance_resource_id);
  const { refetchBookmarks } = useBookmarkQuery();

  return (
    <BookmarkableCard
      bookmarkableItem={{
        itemId: guidance_resource_id,
        type: UserBookmarkType.GUIDANCE_RESOURCE,
      }}
      refetchBookmarks={() => {
        refetchBookmarks();
        refetchUserBookmark();
      }}>
      <a
        href={metadata.url}
        target="_blank"
        onClick={(event) => {
          if (notClickable) {
            event.preventDefault();
          }
        }}
        className="flex flex-col items-center h-full justify-center ">
        <NewsComponent metadata={metadata} />
      </a>
    </BookmarkableCard>
  );
}

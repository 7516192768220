import { ServiceEngagementWithServiceResourceAndNetwork } from './serviceEngagement';



import * as Sentry from '@sentry/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../../../App';
import { supabase } from '../../../clients/supabaseClient';
import { useUserStore } from '../../../state/user';
import { QUERY_KEYS } from '../../queryKeys';

const TABLE = "service_engagement_to_guide_task"

export function useServiceEngagementInCarespaceGuideTask({ guide_task_id, network_id }: { guide_task_id: string | null | undefined, network_id: string | null | undefined }) {
  return useQuery({
    queryKey: [QUERY_KEYS.serviceEngagementsToGuideTask, { guide_task_id, network_id }],
    queryFn: async () => {
      if (!guide_task_id || !network_id) {
        return []
      }
      const { data, error } = await supabase
        .from(TABLE)
        .select("service_engagement(*, service_resource(*), network(*))")
        .eq("network_id", network_id)
        .eq("guide_task_id", guide_task_id)
        .returns<{ service_engagement: ServiceEngagementWithServiceResourceAndNetwork }[]>();
      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
      return data.map(row => row.service_engagement);
    }
  }
  )
}

export function useGuideTaskByServiceEngagementId({ service_engagement_id, network_id }: { service_engagement_id: string | null | undefined, network_id: string | null | undefined }) {
  return useQuery({
    queryKey: [QUERY_KEYS.serviceEngagementsToGuideTask, { service_engagement_id, network_id }],
    queryFn: async () => {
      if (!service_engagement_id || !network_id) {
        return null
      }
      const { data, error } = await supabase
        .from(TABLE)
        .select("guide_task(*)")
        .eq("network_id", network_id)
        .eq("service_engagement_id", service_engagement_id);
      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
      return data?.map(item => item.guide_task);
    }
  }
  )
}

export function useUpsertServiceEngagementToGuideTask() {
  return useMutation({
    mutationFn: async (serviceEngagementToGuideTask: { guide_task_id: string, service_engagement_id: string, network_id: string }) => {
      const { data, error } = await supabase
        .from(TABLE)
        .upsert(serviceEngagementToGuideTask, { onConflict: 'guide_task_id, service_engagement_id' });
      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
      return data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceEngagementsToGuideTask],
      });
    },
  });
}


export function useSaveGuideTasksInServiceEngagementMutation() {
  const authUser = useUserStore((state) => state.user);

  return useMutation({
    mutationFn: async (variables: { newGuideTaskIds: string[], serviceEngagementId: string, networkId: string }) => {
      const existingGuideTasks = await fetchGuideTasksByServiceEngagementId(variables.serviceEngagementId)
      const existingGuideTaskIds = existingGuideTasks?.map((task) => task.guide_task_id)

      const existingGuideTaskIdsAsSet = new Set(existingGuideTaskIds);
      const newGuideTaskIdsAsSet = new Set(variables.newGuideTaskIds);

      const toDelete = existingGuideTaskIds?.filter(
        (guideTaskId) => !newGuideTaskIdsAsSet.has(guideTaskId)
      );

      const toInsert = variables.newGuideTaskIds.filter(
        (guideTaskId) => !existingGuideTaskIdsAsSet.has(guideTaskId)
      );

      await deleteGuideTasks(variables.serviceEngagementId, toDelete ?? []);
      await insertGuideTasks(variables.serviceEngagementId, toInsert, variables.networkId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceEngagementsToGuideTask],
      });
    },
  });
}

async function deleteGuideTasks(
  serviceEngagementId: string | undefined,
  guideTaskIds: string[]
) {
  if (guideTaskIds.length === 0) return;

  const { data, error } = await supabase
    .from(TABLE)
    .delete()
    .in("guide_task_id", guideTaskIds)
    .eq("service_engagement_id", serviceEngagementId ?? "");

  if (error) {
    Sentry.captureException(error);
  }
}

async function insertGuideTasks(
  serviceEngagementId: string | undefined,
  guideTaskIds: string[],
  networkId: string,
) {
  if (guideTaskIds.length === 0 || !serviceEngagementId) return;

  const itemsToInsert = guideTaskIds.map((guideTaskId) => ({
    service_engagement_id: serviceEngagementId,
    guide_task_id: guideTaskId,
    network_id: networkId,
  }));

  const { data, error } = await supabase.from(TABLE).insert(itemsToInsert);

  if (error) {
    Sentry.captureException(error);
  }
}

async function fetchGuideTasksByServiceEngagementId(serviceEngagementId?: string) {
  if (!serviceEngagementId) return []
  const { data, error } = await supabase
    .from(TABLE)
    .select("guide_task_id")
    .eq("service_engagement_id", serviceEngagementId);

  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }

  return data;
}

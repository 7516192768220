import { useState } from "react";
import { ReactComponent as UnselectedFolderIconSvg } from "../../../assets/unselected_folder.svg";
import { useQueryLibraryFolders } from "../../../backend/resources/libraryFolder";
import { useLibraryFoldersStore } from "../../../state/libraryFolders";
import { CreateNewFolderPopup } from "../CreateNewFolderPopup";

import { useWindowSize } from "../../../hooks/useWindowSize";
import ActionButtons from "../../../shared/ui/action-buttons";
import { useUserStore } from "../../../state/user";
import { ButtonWithIcon, IconOption } from "../../ButtonWithIcon";
import { Select } from "../../Select";
import { DeleteFolderPopup } from "../DeleteFolderPopup";
import { EditFolderPopup } from "../EditFolderPopup";

export function LibraryFolders() {
  const authUser = useUserStore((state) => state.user);
  const { isMobile } = useWindowSize();
  const activeFolder = useLibraryFoldersStore((state) => state.activeFolder);
  const setActiveFolder = useLibraryFoldersStore(
    (state) => state.setActiveFolder
  );
  const [isCreateNewFolderPopupOpen, setIsCreateNewFolderPopupOpen] =
    useState(false);
  const [isDeleteFolderPopupOpen, setIsDeleteFolderPopupOpen] = useState(false);
  const [isEditFolderPopupOpen, setIsEditFolderPopupOpen] = useState(false);

  const { libraryFolders, refetchLibraryFolders } = useQueryLibraryFolders();

  return (
    <div className="flex flex-col gap-2 max-w-full w-full items-center md:items-start md:w-auto">
      {isEditFolderPopupOpen && (
        <EditFolderPopup
          close={() => setIsEditFolderPopupOpen(false)}
          refetchLibraryFolders={refetchLibraryFolders}
        />
      )}
      {isDeleteFolderPopupOpen && (
        <DeleteFolderPopup close={() => setIsDeleteFolderPopupOpen(false)} />
      )}
      {isCreateNewFolderPopupOpen && (
        <CreateNewFolderPopup
          close={() => setIsCreateNewFolderPopupOpen(false)}
        />
      )}
      <div className="flex justify-center md:justify-between  md:mr-10 items-center pt-1 gap-1 md:gap-2 md:overflow-x-scroll md:w-full">
        <div className="flex  md:mr-5 max-w-full overflow-clip  w-full">
          <Select
            key={libraryFolders?.length}
            classNames="w-full md:w-[20ch]"
            mobileTitle="Folders"
            icon={<UnselectedFolderIconSvg />}
            options={[
              { value: "All", label: "All" },
              ...(libraryFolders?.map((folder) => ({
                value: folder.id,
                label: isMobile
                  ? folder.name
                  : getStringWithMaxChars(folder.name, 15) || "",
              })) ?? []),
            ]}
            currentOption={
              activeFolder
                ? {
                  value: activeFolder?.id,
                  label: isMobile
                    ? activeFolder.name
                    : `${activeFolder?.name.slice(0, 15)}...` || "",
                }
                : { value: "All", label: "All" }
            }
            onChange={(value) => {
              setActiveFolder(
                libraryFolders?.find((folder) => folder.id === value)
              );
            }}
          />
        </div>

        <ActionButtons title="Folder Actions">
          <ButtonWithIcon
            onClick={() => setIsCreateNewFolderPopupOpen(true)}
            text={"Create"}
            size={isMobile ? "small" : "medium"}
            icon={IconOption.PLUS}
          />

          <ButtonWithIcon
            onClick={() => setIsEditFolderPopupOpen(true)}
            text={"Edit"}
            size={isMobile ? "small" : "medium"}
            icon={IconOption.EDIT}
            disabled={activeFolder === undefined}
          />
          <ButtonWithIcon
            onClick={() => setIsDeleteFolderPopupOpen(true)}
            text={"Delete"}
            size={isMobile ? "small" : "medium"}
            icon={IconOption.TRASH}
            disabled={activeFolder === undefined}
          />
        </ActionButtons>

      </div>
      <div className="flex justify-between items-center">
        <button
          className="font-light text-sm"
          onClick={() => setIsEditFolderPopupOpen(true)}>
          <p className="line-clamp-1">
            {activeFolder
              ? activeFolder.description
              : "All of your bookmarks are always available here."}
          </p>
        </button>
      </div>
    </div>
  );
}

function getStringWithMaxChars(originalString: string, maxChars: number) {
  if (originalString.length > maxChars) {
    return `${originalString.slice(0, maxChars)}...`;
  } else {
    return originalString;
  }
}

import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  useAlfredPageSideBar
} from "../../backend/resources/chatGptConversation";
import { useOrgs } from "../../backend/resources/orgRole";
import OrganizationPage from "../OrganizationPage/OrganizationPage";
import { PageContainer } from "../PageContainer";
import { PageMainHeader } from "../PageMainHeader";
import Tabs from "../Tabs/Tabs";
import { CareSpace } from "./CareSpace";
import { Team } from "./Team";

/**
 *
 */
export enum AdminTabs {
  CareSpace = "Carespace",
  Team = "Team",
}

export function AdminPage() {
  
  const location = useLocation();
  const [searchParams] = useSearchParams(location.search);
  const tabQueryParam = searchParams.get("tab");
  const [tab, setTab] = useState<AdminTabs>(AdminTabs.CareSpace);

  // Queries
  const { hasCareCentralAccess } = useOrgs();
  useAlfredPageSideBar("adminPage")


  useEffect(() => {
    if (
      tabQueryParam &&
      Object.values(AdminTabs).includes(tabQueryParam as AdminTabs)
    ) {
      setTab(tabQueryParam as AdminTabs);
    }
  }, [tabQueryParam]);


  if (hasCareCentralAccess) {
    return <OrganizationPage />;
  }

  return (
    <>
      <PageContainer>
        <div className="flex flex-col w-full pb-20 gap-2">
          <PageMainHeader text="Admin" />
          <Tabs
            tabs={[AdminTabs.CareSpace, AdminTabs.Team]}
            currentTab={tab}
            setCurrentTab={setTab}
          />
          {tab === AdminTabs.CareSpace && <CareSpace />}
          {tab === AdminTabs.Team && <Team />}
        </div>
      </PageContainer>
    </>
  );
}
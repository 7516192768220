import { useState } from "react";

import { useBookmarkQuery } from "../../backend/resources/userBookmark";
import { useWindowSize } from "../../hooks/useWindowSize";
import {
  deleteBookmark,
  setBookmarkIds,
  userUpdatesStore,
} from "../../state/userUpdate/userUpdate";
import { AddBookmarksPopover } from "../AddBookmarksPopover/AddBookmarksPopover";
import { ButtonWithIcon, IconOption } from "../ButtonWithIcon";
import Carousel from "../Carousel/Carousel";
import { CloseButton } from "../CloseButton/CloseButton";
import { Bookmark } from "../LibraryPage/Bookmarks/Bookmark";

export default function AdditionalContent({
  isEditing,
}: {
  isEditing: boolean;
}) {
  const bookmarkIdsInUserUpdate = userUpdatesStore(
    (state) => state.bookmarkIdsInUserUpdate
  );
  const { isMobile } = useWindowSize();
  const activeUserUpdateId = userUpdatesStore(
    (state) => state.activeUserUpdateId
  );
  const [isAddBookmarksPopoverOpen, setIsAddBookmarksPopoverOpen] =
    useState(false);
  const { bookmarks, refetchBookmarks } = useBookmarkQuery();

  const filteredBookmarks = bookmarks?.filter((bookmark) =>
    bookmarkIdsInUserUpdate.includes(bookmark.id)
  );

  if (!activeUserUpdateId) {
    return (
      <div className="flex flex-col  mt-12">
        <p className="text-xl ">Additional Content</p>
        <p className="text-sm mt-10">
          Once an update is posted, you'll be able to see additional content
          here.
        </p>
      </div>
    );
  } else if (!isEditing && filteredBookmarks?.length === 0) {
    return null;
  }
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <p className="text-xl">Additional Content</p>
        {isEditing ? (
          <ButtonWithIcon
            onClick={() => setIsAddBookmarksPopoverOpen(true)}
            text={"Add"}
            size={isMobile ? "small" : "medium"}
            icon={IconOption.PLUS}
          />
        ) : null}
      </div>
      {/* if there are bookmarks, show */}
      {filteredBookmarks && filteredBookmarks.length > 0 ? (
        <Carousel
          items={filteredBookmarks.map((bookmark) => (
            <div>
              <button
                type="button"
                className="absolute top-2 right-1 hover:scale-[1.15] transition-transform z-[2]"
                onClick={() => {
                  deleteBookmark(bookmark.id);
                }}>
                {isEditing ? (
                 <CloseButton/>
                ) : null}
              </button>
              <Bookmark
                key={bookmark.id}
                bookmark={bookmark}
                refetchBookmarks={refetchBookmarks}
                hideActionbar={true}
                isInPlanEntry={true}
              />
            </div>
          ))}
        />
      ) : isEditing ? (
        <p className="text-center">
          Add additional content clicking the add button.
        </p>
      ) : null}

      {isAddBookmarksPopoverOpen && (
        <div>
          <AddBookmarksPopover
            closeText="Update Page"
            savedBookmarkIds={bookmarkIdsInUserUpdate}
            saveSelectedBookmarkIds={setBookmarkIds}
            setShowPopover={setIsAddBookmarksPopoverOpen}
          />
        </div>
      )}
    </div>
  );
}
